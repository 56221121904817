const KEY = `AIzaSyCSO4yxHoNMzkK8Tj1P0rdSUGUs9Mw8gog`;
const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/staticmap`;
const GOOGLE_REDIRECT_MAP_URL = `http://maps.google.co.in/maps`;

export const getUrlRedirectMap = ({ lat, lng, zoom = 17 }) =>
  `${GOOGLE_REDIRECT_MAP_URL}/?q=loc:${lat},${lng}&zoom=${zoom}`;

export const getGoogleMapWithMarker = ({ lat, lng, zoom = 17 }) =>
  `${GOOGLE_MAP_URL}?center=${lat},${lng}&zoom=${zoom}&scale=2&size=600x300&maptype=roadmap&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${lat},${lng}&key=${KEY}`;

export const getGoogleMapWithoutMarker = ({ lat, lng, zoom = 11 }) =>
  `${GOOGLE_MAP_URL}?center=${lat},${lng}&zoom=${zoom}&scale=2&size=600x300&maptype=roadmap&format=png&visual_refresh=true&key=${KEY}`;

export const getUrlRedirectMapOnIos = ({ lat, lng, zoom = 17 }) =>
  `http://maps.apple.com/?q=${lat},${lng}&zoom=${zoom}`;
