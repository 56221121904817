import { Button, Container, Icon } from '@lideralia/alife-uikit';
import { IconArrowCircleLeft } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { CardFlowerBack } from '@lideralia/alife-uikit/dist/organisms/Cards';
import { CardUsersResultOfFlowers } from '@lideralia/alife-uikit/dist/organisms/Cards/CardUsersResultOfFlowers';
import { CardSearchSendFlowers } from '@lideralia/alife-uikit/dist/organisms/Cards/SearchSendFlowers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHeaderStyles } from '../../../common/contexts';
import { setLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import { getCompany, getCompanyLines } from '../actions';
import { IMAGE_URL_FLOWERS } from '../constants';

export const SearchPage = ({ history }) => {
  const tokenCaptcha =
    process.env.REACT_APP_TOKEN_RECAPTCHA_V3 || env?.REACT_APP_TOKEN_RECAPTCHA_V3;

  const query = useSearchParams();
  const { slug } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { updateHeaderStyles } = useHeaderStyles();
  const search = useSelector((state) => state.search);
  const [inputValue, setInputValue] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const isSearchComponent = currentStep === 1;
  const companyUuid = query.get('company_id');
  const identifier = slug || companyUuid;

  const valueToken = useRef(null);

  const getCompanyLinesAPI = useCallback(
    (name, valueTokenCurrent) => dispatch(getCompanyLines(identifier, name, valueTokenCurrent)),
    [dispatch]
  );

  const getCompanyAPI = useCallback(() => dispatch(getCompany(identifier)), [dispatch]);

  useEffect(() => {
    if (identifier) {
      getCompanyAPI();
    }
  }, [companyUuid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (search.company) {
      updateHeaderStyles({
        customColor: search.company?.style?.navbarColor,
        customButtonColor: search.company?.style?.navbarBgButtonColor,
        customTextColor: search.company?.style?.navbarTextButtonColor,
        logo: search.company?.logo?.src,
      });
    }
  }, [search.company]);

  const goToHome = () => {
    history.push(`/${actualLanguage}`);
  };

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);
  const isDisabledButton = inputValue.length < 3;

  const goToLine = (lineId, nameUrl) => {
    setLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE, lineId);
    setLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE, nameUrl);
    history.push(`/${actualLanguage}/lines/${lineId}/${nameUrl}/ceremony`);
  };

  const addSearch = (name, value) => {
    if (!value) return;
    setInputValue(value);
  };

  const buttonBackFlowers = () => {
    if (isSearchComponent) {
      return goToHome();
    }

    return prevStep();
  };

  const handleSubmit = () => {
    if (!inputValue) return;
    getCompanyLinesAPI(inputValue, valueToken.current);
    setInputValue('');
    setTimeout(nextStep, 250);
  };

  const renderSearchStep = () => (
    <CardSearchSendFlowers
      title={intl.formatMessage({ id: 'flowerEcommerce.whoDoYouWantSendFlowers' })}
      description={intl.formatMessage({ id: 'flowerEcommerce.chooseFlowersTypeTheName' })}
      placeholderMessage={intl.formatMessage({ id: 'flowerEcommerce.nameAndSurname' })}
      titleSearch={intl.formatMessage({ id: 'common.search' })}
      marginTop="18px"
      urlImage={IMAGE_URL_FLOWERS}
      onChange={(name, value) => addSearch(name, value)}
      isDisabled={isDisabledButton}
      customColor={search.company?.style?.primaryColor}
      customTextColor={search.company?.style?.textColor}
      tokenCaptcha={tokenCaptcha}
      actionButton={handleSubmit}
      actionValueToken={(value) => {
        valueToken.current = value;
      }}
    />
  );

  const renderResultsStep = () => (
    <CardUsersResultOfFlowers
      description={intl.formatMessage({ id: 'flowerEcommerce.onlyServicesFlowerDelivery' })}
      lines={search?.lines}
      messageNoResults={intl.formatMessage({ id: 'common.noResults' })}
      actionLinkUser={(lineId, nameUrl) => goToLine(lineId, nameUrl)}
      urlImage={IMAGE_URL_FLOWERS}
      messageLoading={intl.formatMessage({ id: 'common.loading' })}
      isLoading={search.loading}
      marginTop="18px"
      marginBottom="18px"
    />
  );

  return (
    <Container paddingY={3} paddingBottom={8} width="100%" maxWidth="400px">
      <CardFlowerBack
        titleLabel={<FormattedMessage id="flowerEcommerce.flowerBackTitle" />}
        descriptionLabel={<FormattedMessage id="flowerEcommerce.flowers" />}
      >
        <Button
          colorType="white"
          rounded
          padding={0}
          action={buttonBackFlowers}
          borderColor="black36"
        >
          <Icon sizeIcon="display24" color="black36">
            <IconArrowCircleLeft />
          </Icon>
        </Button>
      </CardFlowerBack>
      {isSearchComponent ? renderSearchStep() : renderResultsStep()}
    </Container>
  );
};
