import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  lines: [],
  company: null,
};

export default function setSearchReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_COMPANY_LINE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_COMPANY_LINE_SUCCESS: {
      return { ...state, loading: false, done: true, lines: action.payload };
    }
    case constants.GET_COMPANY_LINE_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    case constants.GET_COMPANY:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_COMPANY_SUCCESS: {
      return { ...state, loading: false, done: true, company: action.payload };
    }
    case constants.GET_COMPANY_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    default:
      return state;
  }
}
