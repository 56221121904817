import { Avatar } from '@lideralia/alife-uikit/dist/atoms/Avatar';
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconAddCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconAddCircle';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import IconBlock from '@lideralia/alife-uikit/dist/atoms/Icons/IconBlock';
import IconClock from '@lideralia/alife-uikit/dist/atoms/Icons/IconClock';
import IconLock from '@lideralia/alife-uikit/dist/atoms/Icons/IconLock';
import IconSee from '@lideralia/alife-uikit/dist/atoms/Icons/IconSee';
import IconStop from '@lideralia/alife-uikit/dist/atoms/Icons/IconStop';
import IconUsers from '@lideralia/alife-uikit/dist/atoms/Icons/IconUsers';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Switch } from '@lideralia/alife-uikit/dist/atoms/Switch';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Modal } from '@lideralia/alife-uikit/dist/molecules/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SettingsTemplate = ({
  goBack,
  line,
  customColor,
  handleSubmit,
  handleOpenListFollowers,
  followers,
  isOpenedFollowerModal,
}) => (
  <Container paddingY={4} height="100vh" paddingBottom={7}>
    <Flex
      width="100%"
      justifyContent="space-between"
      flexDirection="column"
      maxWidth="339px"
      minWidth="290px"
      margin="0 auto"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="black84" fontWeight="bold" sizeText="display24">
          <FormattedMessage id="communication.configuration.title" />
        </Text>
        <Button
          paddingY={1}
          paddingX={1}
          colorType="black"
          sizeButton="big"
          rounded
          action={() => goBack()}
        >
          <Icon sizeIcon="display24" color="black84">
            <IconArrowLeft />
          </Icon>
        </Button>
      </Flex>
      <Box marginTop={4}>
        <Card fullWidth>
          <Box marginY={1} padding={3}>
            <Text color="black" fontWeight="bold" sizeText="display16">
              Zona Familiar
            </Text>
            <Text color="black84" sizeText="display14" marginTop={1}>
              Por defecto es un espacio cerrado al que sólo se puede acceder por invitación. Si lo
              haces público, todos podrán verlo.
            </Text>
          </Box>
          <Box borderTopColor="black12" borderTopStyle="solid" borderTopWidth="1px" width="100%" />
          <Flex padding={3} alignItems="center" justifyContent="space-between">
            <Icon sizeIcon="display24" color="black60" marginRight={2}>
              <IconLock />
            </Icon>
            <Text color="black84" style={{ flex: 1 }} sizeText="display13" fontWeight="bold">
              ZONA FAMILIAR PRIVADA
            </Text>
            <Switch
              defaultValue={!line?.isPublic}
              sizeInput="small"
              themeColor={customColor}
              action={(value) => handleSubmit('is_public', !value)}
            />
          </Flex>
          {/* <Box borderTopColor="black12" borderTopStyle="solid" borderTopWidth="1px" width="100%" />
          <Flex padding={3} alignItems="center" justifyContent="space-between">
            <Text color="black84" style={{ flex: 1 }} sizeText="display13">
              Has invitado a{' '}
              <Text as="span" color="green" sizeText="display13">
                {' '}
                23{' '}
              </Text>{' '}
              personas
            </Text>
            <Icon sizeIcon="display24" color="black84" marginRight={2}>
              <IconLock />
            </Icon>
          </Flex> */}
        </Card>
      </Box>
      <Box marginTop={1}>
        <Card marginY={1}>
          <Box padding={3}>
            <Text color="black" fontWeight="bold">
              Moderar contenidos
            </Text>
            <Text color="black84" sizeText="display14" marginTop={1}>
              Por defecto el contenido se publicará automáticamente. Si quieres aprobar las fotos o
              comenarios antes de que se muestren, activa la opción de moderación.
            </Text>
          </Box>
          <Box borderTopColor="black12" borderTopStyle="solid" borderTopWidth="1px" width="100%" />
          <Flex padding={3} alignItems="center" justifyContent="space-between">
            <Icon sizeIcon="display24" color="black60" marginRight={2}>
              <IconStop />
            </Icon>
            <Text color="black84" style={{ flex: 1 }} sizeText="display13" fontWeight="bold">
              MODERAR CONTENIDOS
            </Text>
            <Switch
              defaultValue={line?.isModerated}
              sizeInput="small"
              themeColor={customColor}
              action={(value) => handleSubmit('is_moderated', value)}
            />
          </Flex>
        </Card>
      </Box>
      <Box marginTop={1}>
        <Card marginY={1}>
          <Box padding={3}>
            <Text color="black" fontWeight="bold">
              Administradores
            </Text>
            <Text color="black84" sizeText="display14" marginY={1}>
              Puedes añadir a gente de confianza para que aprueben las solicitudes o autoricen el
              contenido en caso de estar moderado.
            </Text>
          </Box>
          <Box borderTopColor="black12" borderTopStyle="solid" borderTopWidth="1px" width="100%" />
          <Flex padding={3} alignItems="center" justifyContent="space-between">
            {line?.numAdmins ? (
              <>
                <Text color="black84" style={{ flex: 1 }} sizeText="display13">
                  <Text as="span" color="green" sizeText="display13">
                    {line?.numAdmins}
                  </Text>{' '}
                  administradores
                </Text>
                <Icon sizeIcon="display24" color="black60" marginRight={2}>
                  <IconSee />
                </Icon>
                <Icon
                  sizeIcon="display24"
                  color="black60"
                  styles={{ cursor: 'pointer' }}
                  onClick={() => handleOpenListFollowers(1)}
                >
                  <IconAddCircle />
                </Icon>
              </>
            ) : (
              <Button fullWidth>
                <Icon sizeIcon="display24" color="black60" marginRight={2}>
                  <IconUsers />
                </Icon>
                Añadir administradores
              </Button>
            )}
          </Flex>
        </Card>
      </Box>
      <Box marginTop={1} marginBottom={4}>
        <Card marginY={1}>
          <Box padding={3}>
            <Text color="black" fontWeight="bold">
              Usuarios Bloqueados
            </Text>
            <Text color="black84" sizeText="display14" marginY={1}>
              No es lo habitual, pero si lo necesitas, puedes impedir que quien elijas acceda al
              contenido mostrado.
            </Text>
          </Box>
          <Box borderTopColor="black12" borderTopStyle="solid" borderTopWidth="1px" width="100%" />
          <Flex padding={3} alignItems="center" justifyContent="space-between">
            <Button fullWidth>
              <Icon sizeIcon="display24" color="black60" marginRight={2}>
                <IconBlock />
              </Icon>
              Bloquear usuarios
            </Button>
          </Flex>
        </Card>
      </Box>
    </Flex>
    <Modal
      width="339px"
      id="followers-alife"
      isOpen={isOpenedFollowerModal}
      duration={100}
      title="algo"
      onModalClose={() => {}}
      marginX={0}
      marginY={0}
    >
      <Box overflow="scroll" maxHeight="300px" width="100%" padding={2}>
        {followers &&
          followers.map((el) => (
            <Box
              marginY={1}
              backgroundColor="black06"
              borderRadius={5}
              width="100%"
              styles={{
                pointerEvents: el.isPendingAdmin && 'none',
                cursor: !el.isPendingAdmin && 'pointer',
              }}
              opacity={(el.isPendingAdmin && 2) || 4}
            >
              <Flex padding={1} alignItems="center">
                <Avatar
                  width="56px"
                  height="56px"
                  src={el.picture?.m}
                  alt={el.name}
                  styleImg={{ objectFit: 'cover' }}
                />
                <Text fontWeight="bold" marginLeft={2} color="black84" style={{ flex: 1 }}>
                  {el.name}
                </Text>
                <Icon sizeIcon="display24" marginRight={2} color="black36">
                  <IconClock />
                </Icon>
              </Flex>
            </Box>
          ))}
      </Box>
    </Modal>
  </Container>
);
