import qs from 'qs';
import { getLocalStorage } from '../../cli/common/functions/cookies';
import { MapperError } from './MapperErrors';
import axios from './axios';

const apiBase = process.env.REACT_APP_URL_BASE || env?.REACT_APP_URL_BASE;
const apiBaseV2 = process.env.REACT_APP_V2_URL_BASE || env?.REACT_APP_V2_URL_BASE;
export const apiUrl = `${apiBase}`;
export const apiUrlV2 = `${apiBaseV2}`;

const csrf = async () => {
  await axios.get('/sanctum/csrf-cookie');
};

export function getAuthorizationConfigToken() {
  const apiToken = getLocalStorage(
    window.RUNTIME_COOKIE_NAME || process.env.REACT_APP_COOKIE_LOGIN_NAME
  );

  if (apiToken) {
    const token = apiToken;
    return {
      // 'X-Auth-Token': `${token}`,
      Authorization: `Bearer ${token}`,
    };
  }

  return {};
}

const getCookie = function (name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

function getAuthorizationConfig() {
  // const apiToken = getLocalStorage(
  //   window.RUNTIME_COOKIE_NAME || process.env.REACT_APP_COOKIE_LOGIN_NAME
  // );

  // if (apiToken) {
  //   const token = apiToken;
  //   return {
  //     'X-Auth-Token': `${token}`,
  //   };
  // }
  const cookieToken = getCookie('XSRF-TOKEN');
  if (cookieToken) {
    return {
      'X-XSRF-TOKEN': cookieToken,
    };
  }

  return {};
}

const headerApi = (url, service) => {
  const newUrl = service.extern ? `${service.route}` : `${url}${service.route}`;
  // const apiToken = getLocalStorage(
  //   window.RUNTIME_COOKIE_NAME || process.env.REACT_APP_COOKIE_LOGIN_NAME
  // );
  // const authorization = apiToken ? `Bearer ${apiToken}` : '';
  let configRequest = {
    method: service.method,
    credentials: true,
    headers: {
      accept: 'application/json',
    },
    crossorigin: service.extern,
    url: newUrl,
    withCredentials: true,
  };

  if (service.configRequest) {
    configRequest = {
      ...configRequest,
      ...service.configRequest,
    };
  }

  if (service.method === 'post' || service.method === 'put' || service.method === 'patch') {
    configRequest.data = qs.stringify(service.body);
  }

  return configRequest;
};

const errorRequest = (err) => {
  const { response } = err;
  if (response) {
    const { data } = response;
    const mapError = {
      ...data,
      status: response.status,
      message: MapperError.hydrate(
        // eslint-disable-next-line no-nested-ternary
        data.message
      ),
    };
    throw mapError;
  } else {
    throw err;
  }
};

// eslint-disable-next-line no-unused-vars
const request = (url, service, dispatch, action, version2) => {
  const newUrl = service.extern ? `${service.route}` : `${apiUrl}${service.route}`;
  const configRequest = {
    method: service.method,
    credentials: 'same-origin',
    'Access-Control-Allow-Origin': '*',
    headers: {
      'alife-app': process.env.REACT_APP_NAME,
      'alife-source': 'web',
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      accept: 'application/json, text/plain, */*',
      ...getAuthorizationConfig(),
      ...service.headers,
    },
    url: newUrl,
  };

  if (service.method === 'post' || service.method === 'put' || service.method === 'patch') {
    configRequest.data = qs.stringify(service.body);
  }
  return axios(configRequest.url, configRequest)
    .then((response) => response)
    .catch((err) => errorRequest(err));
};

const request2 = (url, service) => {
  const configRequest = headerApi(url, service);

  return axios(configRequest.url, configRequest)
    .then((response) => response)
    .catch((err) => errorRequest(err));
};

const requestCSRF = async (url, service) => {
  // solo el register -> send_passthrough_code, login -> send_passthough_code, reset password -> /me/telephone
  await csrf();

  return request2(url, service);
};

export const requestApi = (...requestProps) => request(apiUrl, ...requestProps);
export const requestApi2 = (...requestProps) => request2(apiUrlV2, ...requestProps);
export const requestApiCSRF = (...requestProps) => requestCSRF(apiUrlV2, ...requestProps);
