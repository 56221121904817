import { ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconCheckCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCheckCircle';
import IconCloseCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCloseCircle';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import { FamilyGridFull } from '@lideralia/alife-uikit/dist/molecules/FamilyZone/FamilyGridFull';
import { CardContent } from '@lideralia/alife-uikit/dist/organisms/Cards/Content';
import {
  FamilyHomeWithoutAccessSection,
  FamilyHomeWithoutContentSection,
} from '@lideralia/alife-uikit/dist/organisms/FamilyZone';
import { FormattedMessage } from 'react-intl';
import { HeaderSectionStyled } from '../../lines/styles';
import { SectionStyled } from '../styles';

export const FamilyZoneHomeTemplate = (props) => {
  const {
    user,
    actionSeeComments,
    line,
    goToFamilyZone,
    itemsTab,
    tabActive,
    goToGalleryExpanded,
    actionLike,
    actionAddComment,
    goToAddContent,
    onInviteToLine,
    handleDeleteContent,
    customColor,
    customTextColor,
    handleModerateContent,
    haveAlertPendingContent,
    disabled,
  } = props;
  return (
    <Container paddingY={3} paddingBottom={8} maxWidth={['400px']}>
      {line && (
        <SectionStyled as="section" id="familyZone">
          {line.userGroup < 2 && (
            <>
              <HeaderSectionStyled>
                <Text
                  as="h3"
                  sizeText="display24"
                  color={customColor || 'blue'}
                  fontWeight="bold"
                  textAlign="center"
                >
                  <FormattedMessage id="familyZone.title" />
                </Text>
              </HeaderSectionStyled>
              {line.permissionType === 1 && (
                <Alert
                  title={<FormattedMessage id="familyZone.alertTitleInvitation" />}
                  message={<FormattedMessage id="familyZone.alertDescriptionInvitation" />}
                  marginBottom={3}
                />
              )}
              <FamilyHomeWithoutAccessSection
                userGroup={line.permissionType}
                accessDescription={
                  <FormattedMessage
                    id="familyZone.accessDescription"
                    values={{
                      name: line?.name,
                      br: <br />,
                    }}
                  />
                }
                disabled={disabled}
                customColor={customColor}
                customTextColor={customTextColor}
                actionGoToFamilyZone={goToFamilyZone}
                actionAccessLabel={<FormattedMessage id="familyZone.actionAccessLabel" />}
              />
            </>
          )}
          {line.userGroup >= 2 && !line.content[line.content?.show]?.length && (
            <>
              <HeaderSectionStyled>
                <Text
                  as="h3"
                  sizeText="display24"
                  color={customColor || 'blue'}
                  fontWeight="bold"
                  textAlign="center"
                >
                  <FormattedMessage id="familyZone.title" />
                </Text>
              </HeaderSectionStyled>

              <FamilyHomeWithoutContentSection
                line={{
                  content: line?.content?.contentGrid,
                  userGroup: line?.userGroup,
                }}
                customColor={customColor}
                customTextColor={customTextColor}
                actionQuoteLabel={<FormattedMessage id="common.actionQuoteLabel" />}
                actionInviteLabel={<FormattedMessage id="familyZone.actionInviteLabel" />}
                actionVideoLabel={<FormattedMessage id="common.actionVideoLabel" />}
                actionPictureLabel={<FormattedMessage id="common.actionPictureLabel" />}
                noContentDescription={
                  <FormattedMessage
                    id="familyZone.noContentDescription"
                    values={{
                      name: line?.name,
                    }}
                  />
                }
                actionVideo={() => {
                  goToAddContent('video');
                }}
                actionPicture={() => {
                  goToAddContent('image');
                }}
                actionInvite={() => onInviteToLine()}
                actionQuote={() => {
                  goToAddContent('text');
                }}
              />
            </>
          )}
          {line.userGroup >= 2 && line.content?.contentAll?.length ? (
            <>
              <Flex marginBottom={4} alignItems="center" justifyContent="center">
                <ButtonTabs items={itemsTab} />
              </Flex>
              {haveAlertPendingContent ? (
                <Alert
                  title={
                    <FormattedMessage id="communication.moderation.alertTitlePendingContent" />
                  }
                  message={
                    <FormattedMessage id="communication.moderation.alertDescriptionPendingContent" />
                  }
                  marginBottom={3}
                />
              ) : (
                ''
              )}
              <Box style={{ display: tabActive === 'gallery' ? 'block' : 'none' }}>
                <FamilyGridFull
                  elements={line?.content?.contentAll}
                  isModerated={line?.isModerated}
                  id="galery-zone"
                  actionGoTo={(contentId) => goToGalleryExpanded(contentId)}
                />
              </Box>
              <Flex
                style={{ display: tabActive === 'expanded' ? 'flex' : 'none' }}
                width={[1]}
                alignItems="center"
                flexDirection="column"
              >
                {line.content?.contentAll?.map((el) => (
                  <Box
                    id={`content-${el.contentId}`}
                    width={[1]}
                    key={el.contentId}
                    maxWidth={339}
                    minWidth={290}
                    paddingY={1}
                  >
                    <CardContent
                      {...el}
                      authorName={el.author.name}
                      date={el.date?.readableStringDate}
                      authorPicture={el.author.pictures?.s}
                      numLikes={el.numLikes}
                      hasLiked={el.hasLiked}
                      numComments={el.numComments}
                      actionLike={() => actionLike && actionLike(el.contentId, !el.hasLiked)}
                      actionComment={(id, text) => {
                        actionAddComment(id, text);
                      }}
                      onDeleteContent={() => {
                        handleDeleteContent(el.contentId);
                      }}
                      onUpdateContent={() => {
                        goToAddContent(el.contentType, el.contentId);
                      }}
                      updateLabel={<FormattedMessage id="familyZone.updateContentLabel" />}
                      deleteLabel={<FormattedMessage id="familyZone.deleteContentLabel" />}
                      isAdmin={line.userGroup > 2}
                      isAuthor={el.author.id === user?.data?.id}
                      isModerated={line?.isModerated}
                      moderationStatus={el.moderationStatus}
                      haveAccess={line.permissionType === 2}
                      actionSeeComments={actionSeeComments}
                      commentPlaceholder={intl.formatMessage({ id: 'common.comment' })}
                      actionAccept={(e) => handleModerateContent(el.contentId, e)}
                      labelAccept={
                        <>
                          <Icon marginRight={1} sizeIcon="display24">
                            <IconCheckCircle />
                          </Icon>
                          Si
                        </>
                      }
                      actionDeny={(e) => handleModerateContent(el.contentId, e)}
                      labelDeny={
                        <>
                          <Icon marginRight={1} sizeIcon="display24">
                            <IconCloseCircle />
                          </Icon>
                          No
                        </>
                      }
                      titleLabelModeration={
                        <FormattedMessage id="communication.moderation.approveQuestionLabel" />
                      }
                    />
                  </Box>
                ))}
              </Flex>
            </>
          ) : null}
        </SectionStyled>
      )}
    </Container>
  );
};

FamilyZoneHomeTemplate.defaultProps = {
  line: [],
};
