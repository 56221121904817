export const constants = {
  GET_CONDITIONS_GENERAL_ECOMMERCE: 'GET_CONDITIONS_GENERAL_ECOMMERCE',
  GET_CONDITIONS_GENERAL_ECOMMERCE_SUCCESS: 'GET_CONDITIONS_GENERAL_ECOMMERCE_SUCCESS',
  GET_CONDITIONS_GENERAL_ECOMMERCE_FAIL: 'GET_CONDITIONS_GENERAL_ECOMMERCE_FAIL',
};

export const services = {
  getConditionsGeneralEcommerce: (lang) => ({
    route: `/api/v2/legals/legal-terms?lang=${lang}&title=general-conditions-ecommerce`,
    method: 'get',
  }),
};
