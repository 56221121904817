import { actualLanguage } from '../../common/functions/lang';

export const constants = {
  SET_MOBILE_PASSTROUGH: 'SET_MOBILE_LOGIN_PASSTROUGH',
  SET_MOBILE_PASSTROUGH_SUCCESS: 'SET_MOBILE_LOGIN_PASSTROUGH_SUCCESS',
  SET_MOBILE_PASSTROUGH_FAIL: 'SET_MOBILE_LOGIN_PASSTROUGH_FAIL',
  SET_VERIFY_LOGIN: 'SET_VERIFY_LOGIN',
  SET_VERIFY_LOGIN_SUCCESS: 'SET_VERIFY_LOGIN_SUCCESS',
  SET_VERIFY_LOGIN_FAIL: 'SET_VERIFY_LOGIN_FAIL',
  RESET_PASSTROUGH: 'RESET_PASSTROUGH',
  RESET_ERROR: 'RESET_ERROR',
};

export const services = {
  setMobilePasstrough: ({ name, countryCode, phoneNumber, isFor, gdprAccepted, token }) => ({
    route: `/api/v2/auth/send-passthrough-code`,
    method: 'post',
    body: {
      name,
      country_code: countryCode,
      phone_number: phoneNumber,
      is_for: isFor,
      gdpr_accepted: gdprAccepted && '1',
      language: actualLanguage,
      token,
    },
  }),
  setVerifyLogin: (data) => ({
    route: `/api/v2/auth/login`,
    method: 'post',
    body: {
      ...data,
    },
  }),
};
