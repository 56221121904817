import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getExternalServices, getLine, postAjax } from '../../../common/schemes/line';
import { ArrangementsTemplate } from '../template/arrangementsTemplate';

const ArrangementsPage = ({ history }) => {
  const dispatch = useDispatch();
  const [showAgencyModal, setShowAgencyModal] = useState(false);
  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);

  const {
    active,
    active: { company },
  } = line;

  const getExternalServicesAPI = useCallback(
    (id) => {
      dispatch(getExternalServices(id));
    },
    [dispatch]
  );
  const postAjaxAPI = useCallback(
    (url) => {
      dispatch(postAjax(url));
    },
    [dispatch]
  );
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const { idLine, idName } = useParams();

  useEffect(() => {
    if (active?.lineId && typeof active?.external === 'undefined') {
      getExternalServicesAPI(active?.lineId);
    }
  }, [active?.lineId]);

  useEffect(() => {
    if (!line.done && !line.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }

    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName]);

  useEffect(() => {
    if (active?.ajax != null && active?.ajax.url != null) {
      const url = active?.ajax.url;
      window.open(url, '_blank');
    }
  }, [active?.ajax]);

  const goToBereavement = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_ARRANGEMENTS_BEREAVEMENT_SUPPORT_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    history.push(
      `/${actualLanguage}/profile/${line.active?.lineId}/${line.active?.nameUrl}/bereavement-support`
    );
  };

  const callbackAjax = (url) => {
    postAjaxAPI(url);
    setShowAgencyModal(true);
  };

  return (
    <ArrangementsTemplate
      user={user?.data}
      active={active}
      external={line?.active?.external?.filter(
        (item) => item.url_service_type !== 'bereavement' && item.url_service_type !== 'flowers'
      )}
      goToBereavement={goToBereavement}
      callbackAjax={callbackAjax}
      showAgencyModal={showAgencyModal}
      setShowAgencyModal={setShowAgencyModal}
      goBack={() => history.goBack()}
      customColor={company?.style?.primaryColor}
      customTextColor={company?.style?.textColor}
      companyAgent={active?.company_agent}
      eventExternalSupportAction={(custom) =>
        setEventAnalytics(
          KEYS_ANALYTICS.OUTBOUND_LINK_CLICKED,
          user,
          history?.location?.pathname,
          active,
          custom
        )
      }
    />
  );
};
export default ArrangementsPage;
