export const setTypeNotification = {
  2: 'adminLineInvitation',
  3: 'accessToLineRequest',
  4: 'moderateContent',
  201: 'contentAccepted',
  202: 'contentRejected',
  2010: 'contentAdded',
  2012: 'lineFollowerAccepted',
  2013: 'lineInviteFollower',
  2020: 'badgeAdded',
  2021: 'adminAddedThankYouLetter',
  2034: 'adminRequestAccepted',
  2035: 'adminRequestRejected',
  2050: 'likeAdded',
  2060: 'userAddedYouAsFriend',
  2072: 'eventAccomplished',
  2074: 'userAddedCommentToLine',
};
