import { Button, ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Input } from '@lideralia/alife-uikit/dist/atoms/Input';
import { Container, Flex, Grid, GridItem } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TextBadgeTemplate = ({
  line,
  isLogged,
  data,
  handleChange,
  handleSubmit,
  itemsTab,
  customColor,
  customTextColor,
}) => {
  const changeInputValue = (event) => {
    const id = 'text';
    handleChange(id, event.target.value);
  };
  const MAX_LENGTH_TEXTAREA = 280;
  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" justifyContent="center" marginBottom={2} width="100%">
        <CardGone
          name={line?.name}
          date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
          picture={line?.pictures?.m}
          customColor={customColor}
          customTextColor={customTextColor}
        />
      </Flex>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" paddingBottom={7}>
        <Card padding={3}>
          <Flex marginBottom={4} alignItems="center" justifyContent="center">
            <ButtonTabs items={itemsTab} />
          </Flex>
          <Grid
            gridTemplateAreas="'icon title' 'icon description' 'contentBox contentBox' 'nameInput nameInput' 'action action'"
            gridTemplateColumns="auto 1fr"
            gridAutoRows="auto"
            gridGap={2}
          >
            <GridItem gridArea="icon">
              <Icon color={customColor || 'orange'} sizeIcon="display24">
                <IconWrite />
              </Icon>
            </GridItem>
            <GridItem gridArea="title">
              <Text sizeText="display16" fontWeight="bold">
                <FormattedMessage id="badge.writeMessageTitle" />
              </Text>
            </GridItem>
            <GridItem gridArea="description">
              <Text sizeText="display14" color="black84" fontWeight="medium">
                <FormattedMessage
                  id="badge.writeMessageDescription"
                  values={{
                    name: line?.name,
                  }}
                />
              </Text>
            </GridItem>
            <GridItem gridArea="contentBox">
              <Field
                label={<FormattedMessage id="badge.writeMessageLabel" />}
                color="black60"
                fontWeight="bold"
                sizeText="display16"
                marginTop={0}
                zIndex={0}
              >
                <Textarea
                  placeholderMessage={intl.formatMessage({ id: 'badge.writeMessagePlaceholder' })}
                  counter
                  name="text"
                  id="text"
                  typeAction={(id, content) => handleChange(id, content)}
                  onChange={changeInputValue}
                  maxLength={MAX_LENGTH_TEXTAREA}
                />
              </Field>
            </GridItem>
            {!isLogged && (
              <GridItem gridArea="nameInput">
                <Field
                  label={<FormattedMessage id="common.nameLarge" />}
                  color="black60"
                  fontWeight="bold"
                  sizeText="display16"
                  marginTop={0}
                >
                  <Input
                    action={(id, content) => handleChange(id, content)}
                    id="name"
                    sizeInput="big"
                    placeholder={intl.formatMessage({ id: 'badge.drawMessageLabel' })}
                  />
                </Field>
              </GridItem>
            )}
            <GridItem gridArea="action">
              <Button
                className="button-publish"
                backgroundColor={customColor}
                colorType={customColor || 'orange'}
                color={customTextColor}
                fullWidth
                sizeButton="big"
                disabled={!data?.contentBody?.length}
                type="submit"
                action={() => handleSubmit()}
              >
                <Icon sizeIcon="display24" marginRight={2}>
                  <IconWrite />
                </Icon>
                <FormattedMessage id="badge.writeMessageAction" />
              </Button>
            </GridItem>
          </Grid>
        </Card>
      </Flex>
    </Container>
  );
};

TextBadgeTemplate.defaultProps = {
  line: [],
};
