/* eslint-disable eqeqeq */
/* eslint-disabled */
import { Container } from '@lideralia/alife-uikit';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconViewExpanded from '@lideralia/alife-uikit/dist/atoms/Icons/IconViewExpanded';
import IconViewThumbnails from '@lideralia/alife-uikit/dist/atoms/Icons/IconViewThumbnails';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { actualLanguage } from '../../../common/functions/lang';
import {
  acceptContent,
  addComments,
  deleteContent,
  followLine,
  getComments,
  getContents,
  getLine,
  sendInvitationToLine,
  setLikes,
} from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import NotShowingPage from '../../notShowing/container';
import { FamilyZoneHomeTemplate } from '../template';

const FamilyZonePage = ({ history }) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const lines = useSelector((state) => state.lines) || { all: [] };
  const line = useSelector((state) => state.line);
  const { active } = line;
  const { company, content } = active;
  const isShowLogin = useSelector((state) => state.showLogin);

  const { idLine, idName } = useParams();

  const [tabActive, setTabActive] = useState('gallery');
  const [disabled, setDisabled] = useState(false);

  const { all } = lines;

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getContentsAPI = useCallback((lineId) => dispatch(getContents(lineId)), [dispatch]);

  const followLineAPI = useCallback(
    (id, requestId) => dispatch(followLine(id, requestId)),
    [dispatch]
  );
  const sendInvitationToLineAPI = useCallback(
    (id, name) => dispatch(sendInvitationToLine(id, name)),
    [dispatch]
  );
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));
  const setLikeContentAPI = useCallback(
    (id, hasliked) => dispatch(setLikes(id, hasliked)),
    [dispatch]
  );
  const getCommentsAPI = useCallback((id) => dispatch(getComments(id)), [dispatch]);
  const addCommentsAPI = useCallback((id, text) => dispatch(addComments(id, text)), [dispatch]);
  const deleteContentAPI = useCallback(
    (id) => {
      dispatch(deleteContent(id));
    },
    [dispatch]
  );
  const acceptContentAPI = useCallback(
    (idContent, value) => {
      dispatch(acceptContent(idContent, value));
    },
    [dispatch]
  );

  const goToGalleryExpanded = (contentId) => {
    setTabActive('expanded');

    setTimeout(() => {
      const element = document.getElementById(`content-${contentId}`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const goToFamilyZone = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.ACCESS_TO_FAMILY_ZONE_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    if (user.done) {
      setDisabled(true);
      followLineAPI(idLine);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  useEffect(() => {
    if (line?.done || line?.error) {
      setDisabled(false);
    }
  }, [line]);

  const goToAddContent = (type, idContent) => {
    setEventAnalytics(
      KEYS_ANALYTICS.UPLOAD_A_MOMENT_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );

    history.push(
      `/${actualLanguage}/lines/${idLine}/${idName}/add/${type}${idContent ? `/${idContent}` : ''}`
    );
  };

  const actionLike = (contentId, hasLiked) => {
    if (line?.active?.userGroup > 1) {
      setLikeContentAPI(contentId, hasLiked);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  const actionSeeComments = (id) => {
    getCommentsAPI(id);
  };

  const actionAddComment = (id, text) => {
    if (text) {
      addCommentsAPI(id, text);
    }
  };

  const onInviteToLine = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.INVITE_TO_FAMILY_ZONE_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    if (idLine) {
      sendInvitationToLineAPI(idLine, active.name);
    }
  };

  useEffect(() => {
    if (!line.done && !line.loading && !line.error && idLine && idName) {
      getLineAPI(idLine, idName);
    }
  }, [line, getLineAPI, idLine, idName]);

  useEffect(() => {
    if (
      (!content && idLine) ||
      (content?.contentAll.length > 0 && content?.contentAll[0]?.lineId != idLine && idLine)
    ) {
      getContentsAPI(idLine);
    }
  }, [content, idLine]);

  useEffect(() => {
    if (hash) {
      const id = hash.split('#');
      setTimeout(() => {
        goToGalleryExpanded(id[1]);
      }, 500);
    }
  }, [hash]);

  const haveAlertPendingContent = line?.active?.content?.contentAll?.filter(
    (item) => item.moderationStatus == '0' && item.author.id == user?.data?.user_id
  );

  if (!line?.active.isLegalTermAccepted) {
    return (
      <Container paddingY={6} paddingBottom={8} maxWidth={['400px']}>
        <NotShowingPage actualLanguage={actualLanguage} />
      </Container>
    );
  }

  return content ? (
    <>
      <FamilyZoneHomeTemplate
        customColor={company?.style?.primaryColor}
        customTextColor={company?.style?.textColor}
        existLine={!!all?.length}
        countLines={all?.length}
        line={line?.active}
        goToFamilyZone={goToFamilyZone}
        disabled={disabled}
        tabActive={tabActive}
        goToGalleryExpanded={goToGalleryExpanded}
        itemsTab={[
          {
            id: 'gallery',
            children: (
              <>
                <Icon marginRight={1}>
                  <IconViewThumbnails />
                </Icon>{' '}
                <FormattedMessage id="common.squaredView" />
              </>
            ),
            isActive: tabActive === 'gallery',
            action: () => {
              setTabActive('gallery');
              setEventAnalytics(
                KEYS_ANALYTICS.FAMIY_ZONE_GRID_VIEW_BUTTON_CLICKED,
                user,
                history?.location?.pathname,
                active
              );
            },
          },
          {
            id: 'expanded',
            children: (
              <>
                <Icon marginRight={1}>
                  <IconViewExpanded />
                </Icon>{' '}
                <FormattedMessage id="common.expandedView" />
              </>
            ),
            isActive: tabActive === 'expanded',
            action: () => {
              setTabActive('expanded');
              setEventAnalytics(
                KEYS_ANALYTICS.FAMIY_ZONE_EXT_VIEW_BUTTON_CLICKED,
                user,
                history?.location?.pathname,
                active
              );
            },
          },
        ]}
        user={user}
        actionLike={actionLike}
        actionSeeComments={actionSeeComments}
        actionAddComment={actionAddComment}
        goToAddContent={goToAddContent}
        onInviteToLine={onInviteToLine}
        handleDeleteContent={deleteContentAPI}
        handleModerateContent={acceptContentAPI}
        haveAlertPendingContent={haveAlertPendingContent?.length}
      />
    </>
  ) : (
    <Loader customColor={line?.active?.company?.style?.primaryColor} />
  );
};

export default FamilyZonePage;
