export const constants = {
  GET_WHOIS: 'GET_WHOIS',
  GET_WHOIS_SUCCESS: 'GET_WHOIS_SUCCESS',
  GET_WHOIS_FAIL: 'GET_WHOIS_FAIL',
};

export const services = {
  getWhois: () => ({
    route: `/api/v2/utils/whois`,
    method: 'get',
  }),
};
