/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actualLanguage } from '../../../common/functions/lang';
import { getLine } from '../../../common/schemes/line';
import {
  getNotifications,
  getRequests,
  setNotificationStatus,
  setRequestAccepted,
  setRequestRejected,
} from '../../../common/schemes/notifications';
import { NotificationsViewTemplate } from '../template';

const NotificationsPage = ({ history }) => {
  const [lastNotificationPollCalled, setLastNotificationPollCalled] = useState(null);

  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifications);
  const line = useSelector((state) => state.line);
  const {
    active: { company },
  } = line;
  const userId = useSelector((state) => state.user?.data?.id);
  const setNotificationsStatusSeeAPI = useCallback(
    (notificationId) => dispatch(setNotificationStatus({ notificationId, statusId: 1 })),
    [dispatch]
  );
  const setNotificationsStatusClickAPI = useCallback(
    (notificationId) => dispatch(setNotificationStatus({ notificationId, statusId: 2 })),
    [dispatch]
  );
  const setRequestAcceptedAPI = useCallback(
    (requestId) => dispatch(setRequestAccepted({ requestId })),
    [dispatch]
  );
  const setRequestRejectedAPI = useCallback(
    (requestId) => dispatch(setRequestRejected({ requestId })),
    [dispatch]
  );
  const getNotificationsAPI = useCallback((id) => dispatch(getNotifications(id)), [dispatch]);
  const getRequestsAPI = useCallback(() => dispatch(getRequests()), [dispatch]);
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  useEffect(() => {
    if (userId != null && notifications?.data?.length === 0) {
      getNotificationsAPI(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userId != null) {
      getRequestsAPI();
    }
  }, [userId]);

  const { done } = notifications;

  useEffect(() => {
    const newNotifications = notifications?.data?.filter(
      (el) => el.status === 0 && el.notificationId !== undefined && el.notificationId !== null
    );
    const newRequests = notifications?.requests?.filter((el) => el.status === 0);
    if (notifications.done && newNotifications.length > 0) {
      for (let i = 0; i < newNotifications.length; i += 1) {
        if (
          newNotifications[i].notificationId !== undefined &&
          newNotifications[i].notificationId !== null
        ) {
          setNotificationsStatusSeeAPI(newNotifications[i].notificationId);
        }
      }
      getNotificationsAPI(userId);
    }
    if (notifications.done && newRequests.length > 0) {
      for (let i = 0; i < newRequests.length; i += 1) {
        if (newRequests[i].notificationId !== undefined && newRequests[i].notificationId !== null) {
          setNotificationsStatusSeeAPI(newRequests[i].notificationId);
        }
      }
      getRequestsAPI();
    }
  }, [done]);

  const getHashPoll = async (notification) => {
    if (notification?.line?.lineId == line?.active?.lineId) {
      history.push(`/${actualLanguage}/poll/${line.active.userPollHash}`);
    } else {
      setLastNotificationPollCalled(notification);
      await getLineAPI(notification?.line?.lineId, notification?.line?.nameUrl);
    }
  };

  useEffect(() => {
    if (line.done && lastNotificationPollCalled) {
      getHashPoll(lastNotificationPollCalled);
      setLastNotificationPollCalled(null);
    }
  }, [line]);

  return (
    <NotificationsViewTemplate
      history={history}
      customColor={company?.style?.primaryColor}
      info={notifications}
      notifications={notifications?.data}
      handleClickNotification={setNotificationsStatusClickAPI}
      requests={notifications.requests}
      handleAcceptRequest={setRequestAcceptedAPI}
      handleRejectRequest={setRequestRejectedAPI}
      handleGetHashPoll={getHashPoll}
      getLineAPI={getLineAPI}
    />
  );
};

export default NotificationsPage;

NotificationsPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
