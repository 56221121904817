import { constants } from '../config';

export const getWhois = () => ({
  type: constants.GET_WHOIS,
});

export const getWhoisSuccess = (data) => ({
  type: constants.GET_WHOIS_SUCCESS,
  payload: data,
});

export const getWhoisFail = (error) => ({
  type: constants.GET_WHOIS_FAIL,
  error,
});
