import { constants } from '../config';

export default function setMeReducer(
  state = {
    data: null,
    loading: false,
    done: false,
    errorRequest: false,
    doneUpdate: false,
    doneCodePost: false,
    doneUpdatePhone: false,
    doneUpdateVerifyPhone: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case constants.GET_ME:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_ME_SUCCESS: {
      return { ...state, loading: false, data: { ...action.payload }, done: true };
    }
    case constants.GET_ME_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    case constants.UPDATE_ME:
      return { ...state, loading: true, errorRequest: false, doneUpdate: false };
    case constants.UPDATE_ME_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        doneUpdate: true,
      };
    }
    case constants.UPDATE_ME_FAIL:
      return {
        ...state,
        loading: false,
        errorRequest: true,
        error: { ...action.error },
        doneUpdate: false,
      };
    case constants.UPDATE_MOBILE_PASSTROUGH:
      return { ...state, loading: true, errorRequest: false, doneUpdatePhone: false };
    case constants.UPDATE_MOBILE_PASSTROUGH_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        doneUpdatePhone: true,
      };
    }
    case constants.UPDATE_MOBILE_PASSTROUGH_FAIL:
      return {
        ...state,
        loading: false,
        errorRequest: true,
        error: { ...action.error },
        doneUpdatePhone: false,
      };
    case constants.UPDATE_MOBILE_VERIFY:
      return { ...state, loading: true, errorRequest: false, doneUpdateVerifyPhone: false };
    case constants.UPDATE_MOBILE_VERIFY_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        doneUpdatePhone: false,
        doneUpdateVerifyPhone: true,
      };
    }
    case constants.UPDATE_MOBILE_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        errorRequest: true,
        error: { ...action.error },
        doneUpdateVerifyPhone: false,
      };
    case constants.GET_OWNER_AUTH_CODE:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_OWNER_AUTH_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        doneCode: true,
        done: true,
      };
    }
    case constants.GET_OWNER_AUTH_CODE_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    case constants.SET_VERIFY_REGISTER:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_VERIFY_REGISTER_SUCCESS: {
      return { ...state, loading: false, data: { ...state.data, ...action.payload }, done: true };
    }
    case constants.SET_VERIFY_REGISTER_FAIL: {
      const data = { ...state, loading: false, errorRequest: true, error: { ...action.error } };
      // console.log('SET_VERIFY_REGISTER_FAIL', data);
      return data;
    }
    case constants.POST_OWNER_AUTH_CODE:
      return { ...state, loading: true, done: false, errorRequest: false, doneCode: false };
    case constants.POST_OWNER_AUTH_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        doneCodePost: true,
      };
    }
    case constants.POST_OWNER_AUTH_CODE_FAIL:
      return {
        ...state,
        loading: false,
        errorRequest: true,
        error: { ...action.error },
        doneCodePost: false,
      };
    case constants.SET_PROSPECT_USER:
      return {
        ...state,
        prospect: action.payload,
      };
    default:
      return state;
  }
}
