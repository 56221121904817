import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import * as action from '../actions';
import { constants, services } from '../config';

export function* getInfoAuthCodeSaga({ authCode }) {
  try {
    const response = yield requestApi2(services.getInfoAuthCode(authCode));
    if (response && response.status >= 200 && response.status < 300) {
      const data = response?.data;
      yield put(action.getInfoAuthCodeSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getInfoAuthCodeFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_INFO_AUTH_CODE, getInfoAuthCodeSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
