import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  error: null,
  data: {
    projects: [],
    treesFromProject: [],
    treeDescription: {
      description: {},
      translations: {},
      tree: {},
    },
    order: {},
    udianaStripeAccount: null,
  },
};

export default function setUdianasReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_PROJECTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case constants.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          projects: action.payload,
        },
      };
    case constants.GET_UDIANA_KEY_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          udianaStripeAccount: action.payload.key,
        },
      };
    case constants.GET_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case constants.GET_TREES_FROM_PROJECT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case constants.GET_TREES_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          treesFromProject: action.payload,
        },
      };
    case constants.GET_TREES_FROM_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case constants.GET_TREE_DESCRIPTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case constants.GET_TREE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          treeDescription: action.payload,
        },
      };
    case constants.GET_TREE_DESCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
