import { constants } from '../config';

export const showParticipants = (show) => ({
  type: constants.SHOW_PARTICIPANTS,
  show,
});

export const getParticipants = (lineId) => ({
  type: constants.GET_PARTICIPANTS,
  lineId,
});

export const getParticipantsSuccess = (data) => ({
  type: constants.GET_PARTICIPANTS_SUCCESS,
  payload: data,
});

export const getParticipantsFail = (error) => ({
  type: constants.GET_PARTICIPANTS_FAIL,
  error,
});

export const putParticipants = (data) => ({
  type: constants.PUT_PARTICIPANTS,
  data,
});

export const putParticipantsSuccess = (data) => ({
  type: constants.PUT_PARTICIPANTS_SUCCESS,
  payload: data,
});

export const putParticipantsFail = (error) => ({
  type: constants.PUT_PARTICIPANTS_FAIL,
  error,
});

export const putParticipantsToAdmin = (data) => ({
  type: constants.PUT_PARTICIPANTS_TO_ADMIN,
  data,
});

export const removeParticipantsAdmin = (data) => ({
  type: constants.REMOVE_PARTICIPANTS_ADMIN,
  data,
});
