import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import css from '@styled-system/css';
import styled from 'styled-components';

export const SectionStyled = styled(Flex)({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const HeaderSectionStyled = styled('header')(
  css({
    marginTop: 4,
    marginBottom: 2,
    marginX: 'auto',
    width: '100%',
  })
);
