import { constants } from '../config';

/* **** getPoll *** */
export const getPoll = (data) => ({
  type: constants.GET_POLL,
  ...data,
});

export const getPollSuccess = (data) => ({
  type: constants.GET_POLL_SUCCESS,
  payload: data,
});

export const getPollFail = (error) => ({
  type: constants.GET_POLL_FAIL,
  error,
});

/* **** setPoll *** */
export const setPoll = (data) => ({
  type: constants.SET_POLL,
  data,
});

export const setPollSuccess = (data) => ({
  type: constants.SET_POLL_SUCCESS,
  payload: data,
});

export const setPollFail = (error) => ({
  type: constants.SET_POLL_FAIL,
  error,
});

export const resetPoll = () => ({
  type: constants.RESET_POLL,
});
