import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../lib/request';
import showAlertSaga from '../../../../utilities/alert';
import * as action from '../actions';
import { constants, services } from '../config';
import { PollMapper } from '../mappers/PollMapper';

export function* getPollSaga({ pollCode }) {
  try {
    const response = yield requestApi2(services.getPoll(pollCode));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getPollSuccess(PollMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getPollFail(error));
    if ((error.message || error.response?.data?.message) !== 'alife_poll_already_exists') {
      yield showAlertSaga({
        status: error.status,
        color: 'red',
        message: window.intl.formatMessage({
          id: error.message || 'error.generic',
        }),
      });
    }
  }
}

export function* setPollSaga({ data }) {
  try {
    const response = yield requestApi2(services.setPoll(data));
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.setPollSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setPollFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_POLL, getPollSaga);
  yield takeLatest(constants.SET_POLL, setPollSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
