import { ModalLegal } from '@lideralia/alife-uikit/dist/organisms/Modals';
import parse from 'html-react-parser';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { acceptCookieConsent, deleteCookieFromStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getCookies } from '../../../common/helpers/cookies/actions';
import reducer from '../../login/reducer';
import { CookiesTemplate } from '../template';

const CookiesPage = ({ hideModalCookie }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(true);
  const [showCookies, setShowCookies] = useState(false);
  const cookies = useSelector((state) => state.helper?.cookies);

  const dataCookies = cookies?.data;

  const getCookiesAPI = useCallback((lang) => dispatch(getCookies(lang)), [dispatch]);

  useEffect(() => {
    if (showCookies && !dataCookies) {
      getCookiesAPI(actualLanguage);
    }
  }, [showCookies]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    let timeout;
    if (!isOpen && !showCookies) {
      timeout = setTimeout(() => {
        setIsOpen(true);
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, showCookies]);

  const listOfCookies = [
    {
      name: '__lc_cid',
      domain: '.accounts.livechatinc.com',
      expiration: '1 year 1 month',
    },
    {
      name: '__lc_cst',
      domain: '.accounts.livechatinc.com	',
      expiration: '1 year 1 month',
    },
    {
      name: 'XSRF-TOKEN',
      domain: '.nextlives.com',
      expiration: '15 days',
    },
    {
      name: '_ga_Y0N3FZB9FJ',
      domain: '.nextlives.com',
      expiration: '1 year 1 month',
    },
    {
      name: '_ga_X1JQLZ9X5Q',
      domain: '.nextlives.com',
      expiration: '1 year 1 month',
    },
    {
      name: '_ga',
      domain: '.nextlives.com',
      expiration: '1 year 1 month',
    },
    {
      name: 'nextlives_session',
      domain: '.nextlives.com',
      expiration: '15 days',
    },
    {
      name: 'frontend_nextlives_session ',
      domain: '.nextlives.com',
      expiration: '15 days',
    },
    {
      name: '__oauth_redirect_detector',
      domain: 'accounts.livechatinc.com',
      expiration: '24 seconds',
    },
    {
      name: 'FPID',
      domain: '.nextlives.com',
      expiration: '1 year 1 month',
    },
    {
      name: 'FPLC',
      domain: '.nextlives.com',
      expiration: '20 hours',
    },
    {
      name: '__stripe_mid',
      domain: '.app.nextlives.com',
      expiration: '1 year',
    },
    {
      name: '__stripe_sid',
      domain: '.alife.social',
      expiration: '30 minutes',
    },
    {
      name: 'm',
      domain: 'm.stripe.com',
      expiration: '2 years',
    },
  ];

  const clickLink = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
    setShowCookies(true);
  };

  const handleSubmit = () => {
    acceptCookieConsent(cookieConsent ? 'no-accept' : 'accept');
    hideModalCookie(false);
    if (cookieConsent) {
      deleteCookieFromStorage('_gat_gtag_UA_62023801_2');
      deleteCookieFromStorage('_ga_Y0N3FZB9FJ');
    }
  };
  if (showCookies) {
    return (
      <ModalLegal
        // customColor={company?.style?.primaryColor}
        customColor="#000000"
        // customTextColor={company?.style?.textColor}
        customTextColor="#000000"
        isOpen={showCookies}
        id="alife-cookies-details-of-cookies"
        title={<FormattedMessage id="profile.personalData.cookies" />}
        content={dataCookies ? parse(dataCookies) : null}
        onModalClose={() => {
          setShowCookies(false);
          hideModalCookie(true);
        }}
      />
    );
  }

  return (
    <CookiesTemplate
      isOpen={isOpen}
      listOfCookies={listOfCookies}
      id="alife-cookies"
      hasError={false}
      title={intl.formatMessage({ id: 'cookies.title' })}
      content={intl.formatMessage({ id: 'cookies.content' })}
      contentLink={intl.formatMessage({ id: 'cookies.ourCookiesPolicy' })}
      clickLink={clickLink}
      labelCheckInput={intl.formatMessage({ id: 'cookies.labelCheckInput' })}
      idCheckInput="cookies_accepted"
      labelAcceptButon={intl.formatMessage({ id: 'cookies.labelAcceptButon' })}
      labelCancelButon={intl.formatMessage({ id: 'cookies.labelCancelButon' })}
      onDetail={false}
      labelShowDetail={intl.formatMessage({ id: 'cookies.labelShowDetail' })}
      labelMenuCookieDeclaration={intl.formatMessage({ id: 'cookies.labelMenuCookieDeclaration' })}
      labelMenuAboutCookies={intl.formatMessage({ id: 'cookies.labelMenuAboutCookies' })}
      aboutCookie={intl.formatMessage({ id: 'cookies.aboutCookie' })}
      cookieDeclarationText={intl.formatMessage({ id: 'cookies.cookieDeclarationText' })}
      labelHideDetail={intl.formatMessage({ id: 'cookies.labelHideDetail' })}
      actionInput={(name, value) => setCookieConsent(value)}
      onSubmit={() => handleSubmit()}
      cookieConsent={cookieConsent}
    />
  );
};

const withReducer = injectReducer({ key: 'cookies', reducer });

export default compose(withReducer)(CookiesPage);
