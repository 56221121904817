/* eslint-disable */
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { postBranchOrder, putBranchOrder } from '../../../common/schemes/branch_order';
import { getLine } from '../../../common/schemes/line';
import { showLoading } from '../../../common/schemes/loading';
import { showRegister } from '../../../common/schemes/login';
import { showProductDetail } from '../../../common/schemes/product_detail';
import { showAlert } from '../../../common/states/alerts';
import { EcommerceTemplate } from '../template';

import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { COUNTRIES_LIST } from '../../../../utilities/constants';
import { getConditionsGeneralEcommerce } from '../../../common/helpers/conditionsGeneralEcommerce';
import { showNotAllowedToBuy } from '../../../common/schemes/not_allowed_to_buy';
import { getProductsByCategory } from '../../../common/schemes/products_by_category';


const EcommercePage = ({ history, ...props }) => {
  const [stripeConf, setStripeConf] = useState(null);
  const dispatch = useDispatch();

  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const { active: {company, isTesting} } = line;

  const { idLine, idName } = useParams();
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);


  useEffect(() => {
    if (!line.done && !line.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }

    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.replace(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName, idLineStored, idNameStored]);

  useEffect(() => {
    const stripeApiKey = isTesting ? process.env.REACT_APP_STRIPE_KEY_TEST : process.env.REACT_APP_STRIPE_KEY;
    let stripeAccount = undefined;
    if(company && company?.ecommerce_enabled) {
      if(!company?.base.is_indirect_payment) {
        if(isTesting) stripeAccount = process.env.REACT_APP_STRIPE_ACCOUNT_TEST;
        else{
          stripeAccount = company?.cms_company_society?.stripe_connect_account?.stripe_id;
        }
      }
      else stripeAccount = undefined;
    }

    setStripeConf(
      loadStripe(stripeApiKey, stripeAccount != undefined ? { stripeAccount: stripeAccount } : undefined)
    );
  }, [company]);
  return (
    <>
      {stripeConf !== null && (
        <Elements stripe={stripeConf}>
          <EcommercePage.Content history={history} {...props} />
        </Elements>
      )}
    </>
  );
};

export default EcommercePage;

EcommercePage.Content = (props) => {
  const { history } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const products_by_category = useSelector((state) => state.products_by_category);
  const branch_order = useSelector((state) => state.branch_order);
  const isShowProductDetail = useSelector((state) => state.showProductDetail);
  const conditionsGeneralEcommerce = useSelector((state) => state.helper?.conditionsGeneralEcommerce);
  const dataConditionsGeneralEcommerce = conditionsGeneralEcommerce?.data;
  const { active, active: { isTesting } } = line;
  const stripeKey = isTesting ? process.env.REACT_APP_STRIPE_KEY_TEST : process.env.REACT_APP_STRIPE_KEY;
  let stripeAccount = undefined;
  if(active?.company && active?.company?.ecommerce_enabled) {
    if(!active?.company?.base.is_indirect_payment) {
      if(isTesting) stripeAccount = process.env.REACT_APP_STRIPE_ACCOUNT_TEST;
      else stripeAccount = active?.company?.cms_company_society?.stripe_connect_account?.stripe_id;
    }
    else stripeAccount = undefined;
  }

  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const { idLine, idName } = useParams();

  const [lineDefault, setLineDefault] = useState(idLine || idLineStored);
  const [nameDefault, setNameDefault] = useState(idName || idNameStored);
  const [showConditionsGeneralEcommerce, setShowConditionsGeneralEcommerce] = useState(false);
  const [orderState, setOrderState] = useState({
    store_product_id: null,
    store_product_category_id: null,
    branch_id: null,
    line_id: null,
    invoice_requested: null,
    ribbon_text: null,
    fact_name: null,
    fact_surname: null,
    fact_email: null,
    fact_cif: null,
    fact_address: null,
    fact_city: null,
    fact_zipcode: null,
    fact_county: null,
    fact_country_iso2: null,
    payment_method_id: null,
  });

  const setShowAlert = useCallback(
    (colorType, value, message) => dispatch(showAlert(colorType, value, message)),
    [dispatch]
  );
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  const setShowNotAllowedToBuy = useCallback((value) => dispatch(showNotAllowedToBuy(value)));

  const setShowProductDetailState = useCallback((value, selected) =>
    dispatch(showProductDetail(true, value, selected))
  );
  const setShowLoadingState = useCallback((value) => dispatch(showLoading(value)));
  const setBranchOrderState = useCallback((value) => dispatch(postBranchOrder(value)));
  const setPutBranchOrderState = useCallback((value) => dispatch(putBranchOrder(value)));
  const getProductsByCategoryAPI = useCallback((branch_id) => dispatch(getProductsByCategory(branch_id)), [dispatch]);
  const getConditionsGeneralEcommerceAPI = useCallback((lang) => dispatch(getConditionsGeneralEcommerce(lang)), [dispatch]);

  const stripe = useStripe();

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const goToCeremony = () => {
    setEventAnalytics(KEYS_ANALYTICS.VIEW_CEREMONY_BUTTON_CLICKED, user, history?.location?.pathname, active);
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/ceremony`
    );
  };

  useEffect(() => {
    if (lineDefault || nameDefault) {
      if (line?.done && line?.active?.lineId !== lineDefault) {
        getLineAPI(lineDefault, nameDefault);
      }
    }
  }, [lineDefault, nameDefault, getLineAPI]);

  useEffect(() => {
    if (idLine !== lineDefault) {
      setLineDefault(idLine);
    }
    if (idName !== nameDefault) {
      setNameDefault(idName);
    }
  }, [idLine, idName]);

  useEffect(() => {
    let selected = isShowProductDetail?.selected;
    let selected_category = selected ? getSelectedCategory(selected) : 0;
    setOrderState({
      ...orderState,
      store_product_id: selected,
      store_product_category_id: selected_category,
    });
  }, [isShowProductDetail]);

  useEffect(() => {
    if (
      Object.keys(active).length &&
      active?.company?.branchId &&
      !line.loading &&
      !products_by_category.loading
    ) {
      getProductsByCategoryAPI(active?.company?.branchId);
    }
  },[active]);

  useEffect(() => {
    if (showConditionsGeneralEcommerce && !dataConditionsGeneralEcommerce) {
      getConditionsGeneralEcommerceAPI(actualLanguage);
    }
  }, [showConditionsGeneralEcommerce]);

  const openDetailPopUp = (event) => {
    if (!user.done) {
      setShowRegisterState(1);
    }else if (!line?.active?.ecommerceIsOpen) {
      setShowNotAllowedToBuy(true);
    } else {
      const id = event?.target?.closest('button')?.id;
      var product = null;
      Object.values(products_by_category?.info).map((category) => {
        category.products.map((pr) => {
          if (pr.id === id) product = pr;
        });
      });
      setShowProductDetailState(product, orderState.store_product_id);
    }
  };

  const selectProduct = (event) => {
    if (getLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME) == null) {
      setShowRegisterState(1);
    } else if (!line?.active?.ecommerceIsOpen) {
      setShowNotAllowedToBuy(true);
    } else {
      let id = event?.target?.id;
      let selected = orderState.store_product_id == id ? 0 : id;
      let selected_category = selected ? getSelectedCategory(selected) : 0;
      if(selected){
        const topAux=event.target.parentElement.offsetTop + event.target.parentElement.offsetHeight - 20;
        setTimeout(() => {
          window.scroll({
            top: topAux,
            left: 0,
            behavior: 'smooth',
          });
        }, 0);
      }

      setOrderState({
        ...orderState,
        store_product_id: selected,
        store_product_category_id: selected_category,
      });
    }
  };

  const getSelectedCategory = (product_id) => {
    let category_id = 0;
    if (products_by_category?.info !== null) {
      Object.values(products_by_category?.info).map((element, key) => {
        let res = element.products.find((product) => {
          return product.uuid == product_id;
        });
        if (res !== undefined) category_id = element.id;
      });
    }
    return category_id;
  };

  const typeAction = (id, content) => {
    setOrderState({
      ...orderState,
      ribbon_text: content,
    });
  };

  const handleSubmit = (content) => {
    content = {
      ...content,
      alias: content.invoice_requested===true ? content.alias : null,
      address: content.invoice_requested===true ? content.address : null,
      country: content.invoice_requested===true ? content.country : null,
      country_iso: content.invoice_requested===true ? content.country_iso : null,
      cp: content.invoice_requested===true ? content.cp : null,
      dni: content.invoice_requested===true ? content.dni : null,
      email: content.invoice_requested===true ? content.email : null,
      location: content.invoice_requested===true ? content.location : null,
    }

    setShowLoadingState(true);
    //setShowNotAllowedToBuy(true);
    setEventAnalytics(KEYS_ANALYTICS.ECOMMERCE_TRY_PAYMENT, user, history?.location?.pathname, active);
    setOrderState({
      ...orderState,
      invoice_requested: content.invoice_requested === true ? 1 : 0,
      fact_name: content.alias,
      fact_surname: content.surname,
      fact_address: content.address,
      fact_county: content.country,
      fact_country_iso2: content.country_iso,
      fact_zipcode: content.cp,
      fact_cif: content.dni,
      fact_email: content.email,
      fact_city: content.location,
      payment_method_id: content.payment_method_id,
      branch_id: active.branchId,
      line_id: active.lineId,
    });

    setBranchOrderState({
      store_product_id: orderState.store_product_id,
      store_product_category_id: orderState.store_product_category_id,
      branch_id: active.branchId,
      line_id: active.lineId,
      ribbon_text: orderState.ribbon_text,

      invoice_requested: content.invoice_requested === true ? 1 : 0,
      fact_name: content.alias,
      fact_surname: content.surname,
      fact_address: content.address,
      fact_county: content.country,
      fact_country_iso2: content.country_iso,
      fact_zipcode: content.cp,
      fact_cif: content.dni,
      fact_email: content.email,
      fact_city: content.location,
      payment_method_id: content.payment_method_id,
    });
  };

  useEffect(async () => {
    if (branch_order && branch_order.info && branch_order.done) {
      if (branch_order.info.status === 'requires_action') {
        if (!stripe) {
          return;
        }

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          branch_order?.info?.client_secret
        );

        setShowLoadingState(true);

        if (error) {
          setShowLoadingState(false);
          setShowAlert('red', 1, 'Error en la confirmación del pago');
          setTimeout(function () {
            setShowAlert('', -1, '');
          }, 5000);
        }

        if (paymentIntent?.status === 'succeeded') {
          setShowLoadingState(false);
          setPutBranchOrderState({
            id: branch_order.info.id,
            order_status: 'order_received',
          });
          // goToDetail();
        }
      } else if (branch_order.info.status === 'success') {
        setShowLoadingState(false);
        goToDetail();
      }
    }
  }, [branch_order]);

  const goToDetail = () => {
    history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/branch-order-resume`);
  };

  return (
    <>
      {Object.keys(active).length === 0 && line.loading && <Loader customColor={active?.company?.style?.primaryColor} />}
      <EcommerceTemplate
        line={active}
        company={active?.company}
        productsCategory={products_by_category?.info}
        openDetailPopUp={openDetailPopUp}
        onSelect={selectProduct}
        actionGoBack={goToCeremony}
        selected={orderState.store_product_id}
        category_selected={parseInt(orderState.store_product_category_id)}
        typeAction={typeAction}
        handleSubmit={handleSubmit}
        stripeKey={stripeKey}
        stripeAccount={stripeAccount}
        optionsCountry={COUNTRIES_LIST}
        locale={user?.data?.language}
        showConditions={showConditionsGeneralEcommerce}
        setShowConditions={setShowConditionsGeneralEcommerce}
        dataConditions={dataConditionsGeneralEcommerce}
      />
    </>

  );
};
