import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../lib/request';
import showAlertSaga from '../../../../utilities/alert';
import * as action from '../actions';
import { constants, services } from '../config';

export function* setRgpdSaga(body) {
  try {
    const response = yield requestApi2(services.setRgpd(body));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.setRgpdSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setRgpdFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(constants.SET_RGPD, setRgpdSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
