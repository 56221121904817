import { constants } from '../config';

/* **** setRgpd *** */
export const setRgpd = (data) => ({
  type: constants.SET_RGPD,
  ...data,
});

export const setRgpdSuccess = (data) => ({
  type: constants.SET_RGPD_SUCCESS,
  payload: data,
});

export const setRgpdFail = (error) => ({
  type: constants.SET_RGPD_FAIL,
  error,
});
