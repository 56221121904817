export const constants = {
  SET_RGPD: 'SET_RGPD',
  SET_RGPD_SUCCESS: 'SET_RGPD_SUCCESS',
  SET_RGPD_FAIL: 'SET_RGPD_FAIL',
};

export const services = {
  setRgpd: (gdprAccepted) => ({
    route: `/api/v2/me/legal/acceptance`,
    method: 'post',
    body: {
      gdpr_accepted: gdprAccepted && 1,
    },
  }),
};
