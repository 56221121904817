import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { TreeProductDescriptionMapper, TreeProductMapper } from '../mapper';

export function* getPlantationProjectsSaga() {
  try {
    const response = yield requestApi2(services.getPlantationProjects());

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        action.getPlantationProjectsSuccess(data.map((el) => TreeProductMapper.hydrate(el)))
      );
    } else {
      throw response;
    }
  } catch (error) {
    // yield put(getProjectsFailure(error));
  }
}

export function* getTreesFromProjectSaga({ id }) {
  try {
    const response = yield requestApi2(services.getTreesFromProject(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getTreesFromProjectSuccess(data.map((el) => TreeProductMapper.hydrate(el))));
    }
  } catch (error) {
    // yield put(getProjectsFailure(error));
  }
}

export function* getTreeDescriptionSaga({ id, udianaUuid }) {
  try {
    const response = yield requestApi2(services.getTreeDescription(id, udianaUuid));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getTreeDescriptionSuccess(TreeProductDescriptionMapper.hydrate(data)));
    }
  } catch (error) {
    // yield put(getProjectsFailure(error));
  }
}

export function* getUdianaKeyStripeSaga() {
  try {
    const response = yield requestApi2(services.getUdianaKeyStripe());

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getUdianaKeyStripeSuccess(data));
    }
  } catch (error) {
    // yield put(getProjectsFailure(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_PROJECTS, getPlantationProjectsSaga);
  yield takeLatest(constants.GET_TREES_FROM_PROJECT, getTreesFromProjectSaga);
  yield takeLatest(constants.GET_TREE_DESCRIPTION, getTreeDescriptionSaga);
  yield takeLatest(constants.GET_UDIANA_KEY_STRIPE, getUdianaKeyStripeSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
