import {
  Button,
  Container,
  Field,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
} from '@lideralia/alife-uikit';
import { ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { IconHeadphones } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { CreateAudio } from '@lideralia/alife-uikit/dist/organisms/Audios/CreateAudio';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards';
import { FormattedMessage, useIntl } from 'react-intl';

export const AudioBadgeTemplate = ({
  line,
  itemsTab,
  customColor,
  customTextColor,
  isLogged,
  handleChange,
  data,
  handleSubmit,
}) => {
  const intl = useIntl();

  const changeAudioValue = (result) => {
    handleChange('audio', result);
  };

  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" justifyContent="center" marginBottom={2} width="100%">
        <CardGone
          name={line?.name}
          date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
          picture={line?.pictures?.m}
          customColor={customColor}
          customTextColor={customTextColor}
        />
      </Flex>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" paddingBottom={7}>
        <Card padding={3}>
          <Flex marginBottom={4} alignItems="center" justifyContent="center">
            <ButtonTabs items={itemsTab} />
          </Flex>
          <Grid
            gridTemplateAreas="'icon title' 'icon description' 'contentBox contentBox' 'nameInput nameInput' 'action action'"
            gridTemplateColumns="auto 1fr"
            gridAutoRows="auto"
            gridGap={2}
          >
            <GridItem gridArea="icon">
              <Icon color={customColor || 'orange'} sizeIcon="display24">
                <IconHeadphones />
              </Icon>
            </GridItem>
            <GridItem gridArea="title">
              <Text sizeText="display16" fontWeight="bold">
                <FormattedMessage id="badge.audioMessageTitle" />
              </Text>
            </GridItem>
            <GridItem gridArea="description">
              <Text sizeText="display14" color="black84" fontWeight="medium">
                <FormattedMessage
                  id="badge.audioMessageDescription"
                  values={{
                    name: line?.name,
                  }}
                />
              </Text>
            </GridItem>
            <GridItem gridArea="contentBox">
              <CreateAudio
                textStopRecording={intl.formatMessage({
                  id: 'badge.audioMessageStopRecording',
                })}
                textStartRecording={intl.formatMessage({
                  id: 'badge.audioMessageStartRecording',
                })}
                textMicrophonePermissionDenied={intl.formatMessage({
                  id: 'badge.audioMicrophonePermissionDenied',
                })}
                audioData={(result) => changeAudioValue(result)}
                contentColor={customColor}
              />
            </GridItem>
            {!isLogged && (
              <GridItem gridArea="nameInput">
                <Field
                  label={<FormattedMessage id="badge.badgeSignItLabel" />}
                  color="black60"
                  fontWeight="bold"
                  sizeText="display16"
                  marginTop={0}
                >
                  <Input
                    action={(id, content) => handleChange(id, content)}
                    id="name"
                    sizeInput="big"
                    placeholder={intl.formatMessage({ id: 'badge.drawMessageLabel' })}
                  />
                </Field>
              </GridItem>
            )}
            <GridItem gridArea="action">
              <Button
                backgroundColor={customColor}
                colorType={customColor || 'orange'}
                color={customTextColor}
                fullWidth
                sizeButton="big"
                disabled={!data?.contentBody}
                type="submit"
                action={() => handleSubmit()}
              >
                <Icon sizeIcon="display24" marginRight={2}>
                  <IconHeadphones />
                </Icon>
                <FormattedMessage id="badge.writeMessageAction" />
              </Button>
            </GridItem>
          </Grid>
        </Card>
      </Flex>
    </Container>
  );
};
