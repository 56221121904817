import { delay, put } from 'redux-saga/effects';
import { showAlert } from '../cli/common/states/alerts';

function* showAlertSaga({
  color = 'red',
  duration = 1,
  message = '',
  delayTime = 5000,
  status = 0,
}) {
  try {
    if (status === 401) return;
    // Enviamos una acción al store para mostrar la alerta
    yield put(showAlert(color, duration, message));

    // Esperamos un tiempo determinado antes de enviar la acción para ocultar la alerta
    yield delay(delayTime);

    // Enviamos una acción al store para ocultar la alerta
    yield put(showAlert('', -1, ''));
  } catch (error) {
    console.error(error);
  }
}

export default showAlertSaga;
