import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { CountriesMapper } from '../mapper';

export function* getCountriesSaga({ lang }) {
  try {
    const response = yield requestApi2(services.getCountries(lang));
    if (response && response.status >= 200 && response.status < 300) {
      const data = response.data.map((elemt) => CountriesMapper.hydrate(elemt));
      yield put(action.getCountriesSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getCountriesFail(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_COUNTRIES, getCountriesSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
