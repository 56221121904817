/* eslint-disable */
import * as FlagIcons from '@lideralia/alife-uikit/dist/atoms/Flags';
import { IconDrawComment, IconHeadphones } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconQuote from '@lideralia/alife-uikit/dist/atoms/Icons/IconQuote';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import React from 'react';
import { timeAgo } from '../../../functions/date';
import { FormatDate, FormatDateWithoutTimeZone } from '../../../mappers/FormatDate';

const getEventTypeLabel = (id) => {
  switch (id) {
    case 1:
      return 'ceremony.typeCeremony';
    case 2:
      return 'ceremony.typeFuneral';
    case 3:
      return 'ceremony.typeBurial';
    case 4:
      return 'ceremony.typeIncineration';
    case 5:
      return 'ceremony.typeFarewell';
    case 6:
      return 'ceremony.typeWake';
    case 7:
      return 'ceremony.typeProcession';
    case 104:
      return 'ceremony.typeVelacion';
    case 105:
      return 'ceremony.typeCeremoniaSolemne';
    case 106:
      return 'ceremony.typeUltimoAdios';
    case 107:
      return 'ceremony.typeMisa';
    case 108:
      return 'ceremony.typeMiseEnBiere';
    default:
      return 'ceremony.typeCeremony';
  }
};

export const AuthorBadgeMapper = {
  hydrate: (data) => {
    const hydrated = {
      id: data.user_uuid,
      name: typeof data.name === 'string' ? data.name : '',
      nameUppercase: typeof data.name === 'string' ? data.name.toUpperCase() : '',
      pictures: typeof data.picture_all === 'object' ? data.picture_all : null,
    };
    return hydrated;
  },
};

export const BadgeMapper = {
  hydrate: (data) => {
    const Flag = startCase(toLower(data?.geo_country_code));
    const FlagIcon = FlagIcons[Flag];
    const hydrated = {
      id: data.uuid || null,
      author: typeof data.author === 'object' ? AuthorBadgeMapper.hydrate(data.author) : {},
      contentType: typeof data.picture_all === 'object' && data.picture_all ? 'picture' : 'text',
      contentColor: typeof data.picture_all === 'object' && data.picture_all ? 'green' : 'orange',
      contentIcon:
        typeof data.picture_all === 'object' && data.picture_all ? (
          <IconDrawComment />
        ) : (
          typeof data.audio_filename === 'string'  ? <IconHeadphones /> : <IconQuote />
        ),
      text: typeof data.caption === 'string' ? data.caption : '',
      pictures: typeof data.picture_all === 'object' ? data.picture_all : null,
      audio: data.audio_filename,
      hasLiked: data.has_liked,
      numLikes: data.num_likes ?? 0,
      countryCode: <FlagIcon /> || '',
    };
    return hydrated;
  },
};

export const PermissionTypeMapper = {
  hydrate: (data) => {
    let permission = 0;
    if(data?.user?.permission_type === -1){
      permission = 0;
    }else if(data?.user?.permission_type >= 3){
      permission = 3;
    }else if(data?.is_public) {
      permission = 2;
    }else{
      if( data?.user?.permission_type == 2) permission = 2;
    }
    const hydrated = {
      permissionType: data?.user?.permission_type ? data?.user?.permission_type : 0,
      // si permission_type = -1, no puede ver nada
      // si is_public = 1, todos pueden ver, excepto permission_type = -1
      // si is_public = 0, pueden ver si permission_type > 1
      userGroup: permission
    };
    return hydrated;
  },
};

export const CommentMapper = {
  hydrate: ({ comment_id, text, date, author }) => ({
    commentId: comment_id,
    text,
    date: { timeAgo: timeAgo(`${date} GMT+0000 (UTC)`) || {} },
    author: author && AuthorBadgeMapper.hydrate(author),
  }),
};

export const CommentsMapper = {
  hydrate: ({ comments, content_id }) => ({
    comments: comments?.map((el) => CommentMapper.hydrate(el)) || [],
    contentId: content_id,
  }),
};

export const ContentItemMapper = {
  hydrate: ({
    user,
    caption,
    content_creator,
    uuid,
    content_type,
    content_url,
    original_date,
    has_liked,
    hashtags,
    line_uuid,
    line_name,
    moderation_status,
    local_likes,
    picture_all,
    tagged_users,
    title,
    external_url,
    video_data,
    local_comments,
    line
  }) => {
    const lineUuid = line?.line_uuid || line_uuid;
    const hydrated = {
      contentId: uuid,
      contentUrl: content_url,
      lineId: lineUuid,
      nameUrl: line_name && line_name.split(' ').join('-').toLowerCase(),
      contentType: content_type,
      url: external_url || null,
      contentCreator: content_creator || null,
      picture: content_type == 'video' ? video_data?.thumbnail_url : picture_all?.l || null,
      title: title || '',
      caption: caption || '',
      numLikes: local_likes || false,
      hasLiked: has_liked > 0 || false,
      hashtags: hashtags || null,
      date: typeof original_date === 'string' ? FormatDate.hydrate(original_date) : {},
      author: typeof user === 'object' ? AuthorBadgeMapper.hydrate(user) : {},
      moderationStatus: moderation_status ? moderation_status.toString() : null,
      videoData: video_data || null,
      // taggedUsers: tagged_users, //
      numComments: local_comments || 0,
    };
    return hydrated;
  },
};

export const ContentMapper = {
  hydrate: (data, dateFrom, dateTo) => {
    const generateArrays = data?.map((el) => ContentItemMapper.hydrate(el));
    const hydrated = {
      contentAll: data?.map((el) => ContentItemMapper.hydrate(el)),
      contentGridShort:
        generateArrays && dateFrom?.year
          ? [
              { yearFrom: dateFrom?.year },
              ...generateArrays?.filter((item) => item.moderationStatus == 1)?.slice(0, 1),
              { yearTo: dateTo?.year },
            ]
          : [...generateArrays?.filter((item) => item.moderationStatus == 1)?.slice(0, 1)],
      contentGridLarge:
        generateArrays && dateFrom?.year
          ? [
              { yearFrom: dateFrom?.year },
              ...generateArrays?.filter((item) => item.moderationStatus == 1)?.slice(0, 4),
              { yearTo: dateTo?.year },
            ]
          : [...generateArrays?.filter((item) => item.moderationStatus == 1)?.slice(0, 4)],
      show: generateArrays?.length >= 4 ? 'contentGridLarge' : 'contentGridShort',
    };
    return hydrated;
  },
};

export const VenueMapper = {
  hydrate: ({
    name,
    add1,
    add2,
    address1,
    address2,
    city,
    zip,
    province,
    country,
    phone_country_code,
    phone_number,
    web,
    map_img,
    map_lat,
    map_long,
    has_streaming,
    map_zoom,
  }) => {
    const hydrated = {
      name: typeof name === 'string' ? name : '',
      add1: add1 || address1 || '',
      add2: add2 || address2 || '',
      has_streaming: has_streaming || false,
      city: typeof city === 'string' ? city : '',
      zip: typeof zip === 'string' ? zip : '',
      province: typeof province === 'string' ? province : '',
      country: typeof country === 'string' ? country : '',
      web: typeof web === 'string' ? web : '',
      phoneCountryCode: typeof phone_country_code === 'string' ? phone_country_code : '',
      phone: typeof phone_number === 'string' ? phone_number : '',
      mapImg: typeof map_img === 'string' ? map_img : '',
      mapLat: typeof map_lat === 'string' ? map_lat : '',
      mapLong: typeof map_long === 'string' ? map_long : '',
      mapZoom: map_zoom || '',
    };
    return hydrated;
  },
};

export const EventMapper = {
  hydrate: ({
    event_uuid,
    event_ends_at_localtime,
    event_starts_at_localtime,
    event_info,
    event_is_outsourced,
    event_room,
    event_is_main,
    event_is_accomplished,
    event_type_id,
    venue,
    event_line_uuid,
    num_attendees,
    rsvp_status,
    hide_event,
    is_answered
  }) => {
    const hydrated = {
      eventId: event_uuid ?? '',
      eventStartsAtLocaltime: event_starts_at_localtime
        ? FormatDateWithoutTimeZone.hydrate(event_starts_at_localtime)
        : null,
      eventEndsAtLocaltime: event_ends_at_localtime
        ? FormatDateWithoutTimeZone.hydrate(event_ends_at_localtime)
        : null,
      eventIsOutsourced: typeof event_is_outsourced === 'boolean' ? event_is_outsourced : false,
      eventIsAccomplished:
        typeof event_is_accomplished === 'boolean' ? event_is_accomplished : false,
      eventTypeId: getEventTypeLabel(event_type_id),
      // TO DO:
      // numAttendees: (typeof num_attendees === 'number' ? num_attendees.toString() : '0') + ' asistentes confirmados',
      rsvpStatus: typeof rsvp_status === 'number' ? rsvp_status.toString() : '',
      eventIsMain: typeof event_is_main === 'boolean' ? event_is_main : false,
      eventRoom: typeof event_room === 'string' ? event_room : null,
      eventInfo: typeof event_info === 'string' ? event_info : '',
      eventLineId: event_line_uuid ?? '',
      hideEvent: typeof hide_event === 'boolean' ? hide_event : false,
      venue: typeof venue === 'object' ? VenueMapper.hydrate(venue) : null,
      is_answered: typeof is_answered === 'boolean' ? is_answered : false,
    };
    return hydrated;
  },
};

export const StreamMapper = {
  hydrate: (data) => {
    const hydrated = {
      streamId: typeof data.stream_id === 'string' ? data.stream_id : '',
      streamProvider: data.stream_provider || null,
      streamTypeId: typeof data.stream_type_id === 'number' ? data.stream_type_id.toString() : '2',
      streamPrivacy: typeof data.stream_privacy === 'number' ? data.stream_privacy.toString() : '0',
      streamTitle: typeof data.stream_title === 'string' ? data.stream_title : null,
      streamTimezone: typeof data.stream_timezone === 'string' ? data.stream_timezone : null,
      stream360: typeof data.stream_360 === 'boolean' ? data.stream_360 : false,
      streamStartDate: FormatDate.hydrate(data.stream_started_date),
      streamEndDate: FormatDate.hydrate(data?.finished_at),
      streamEmbedId: data.stream_embed_id || data.stream_external_url,
      streamPassword: typeof data.password === 'string' ? data.password : null,
      streamUrl: data.stream_url,
      provider: data.stream_video_provider,
    };

    return hydrated;
  },
};

export const LineMapper = {
  hydrate: (data) => {
    const hydrated = {
      lineId: typeof data.line_uuid === 'string' ? data.line_uuid : '',
      nameUrl: typeof data.name === 'string' ? data.name.split(' ').join('-').toLowerCase() : '',
      name: typeof data.alias === 'string' ? data.alias : typeof data.name === 'string' ? data.name : '',
      branchId: typeof data?.company?.cmscompany_uuid === 'string' ? data?.company?.cmscompany_uuid : '',
      company_agent: typeof data.creator_agent === 'object' && data.creator_agent,
      dateFrom: typeof data.date_from === 'string' ? FormatDateWithoutTimeZone.hydrate(data.date_from) : {},
      dateTo: typeof data.date_to === 'string' ? FormatDateWithoutTimeZone.hydrate(data.date_to) : {},
      createdAt: typeof data.published_at === 'string' ? FormatDate.hydrate(data.published_at) : {},
      pictures:
        typeof data?.picture_all === 'object' && data?.picture_all !== null && Object.entries(data?.picture_all)?.length
          ? data?.picture_all
          : null,
      rememberedBy: typeof data.remembered_by === 'string' ? data.remembered_by : '',
      interval: {},
      isPublic: data?.is_public ? data.is_public === 1 : false,
      numAdmins: data?.num_admins || 0,
      // streams:
      //   typeof data?.streams === 'object' && data?.streams && Object.entries(data?.streams)?.length
      //     ? data.streams?.map((el) => StreamMapper.hydrate(el))
      //     : [],
      ...PermissionTypeMapper.hydrate(data),
      userPollHash: data.user?.user_poll_hash || null,
      isModerated: data.is_moderated || false,
      flowersEcommerce: data.flowers_ecommerce || null,
      ecommerceIsOpen: data.ecommerce_is_open || false,
      ecommerceIsAvailable: data.ecommerce_is_available || false,
      timeBeforeCloseEcommerce: data.ecommerce_is_open ? FormatDate.hydrate(data.time_before_close_ecommerce) : null,
      hoursRemainingToCloseEcommerce: data.ecommerce_is_open ? getHoursAndMinutesRemainingFromNowByDate(data.time_before_close_ecommerce).hours : null,
      minutesRemainingToCloseEcommerce: data.ecommerce_is_open ? getHoursAndMinutesRemainingFromNowByDate(data.time_before_close_ecommerce).minutes : null,
      isLegalTermAccepted: data.is_legal_term_accepted || false,
      udianasIsOpen: data.udianas_is_open || false,
      treeSumPerLine: data.tree_sum_per_line || 0,
      isTesting: data.is_testing || false,
      openingServiceHours: data.opening_service_hours ? openingServiceHoursMapper.hydrate(data.opening_service_hours) : [],
    };
    return hydrated;
  },
};

export const ContentUpdatedMapper = {
  hydrate: (data) => {
    const hydrated = {
      contentId: typeof data.content_id === 'string' ? data.content_id : '',
      hasLiked: data.has_liked || false,
      lineId: typeof data.line_uuid === 'string' ? data.line_uuid : '',
      numLikes: typeof data.num_likes === 'number' ? data.picture_all : '',
    };

    return hydrated;
  },
};

export const ContentSimpleMapper = {
  hydrate: (data) => {
    const hydrated = {
      contentId: data.content_id || '',
      contentType: data.content_type || 'none',
      pictures: typeof data.picture_all === 'object' ? data.picture_all : null,
    };

    return hydrated;
  },
};

export const FollowerMapper = {
  hydrate: (data) => {
    const hydrated = {
      id: data.user_id,
      name: typeof data.name === 'string' ? data.name : '',
      nameUppercase: typeof data.name === 'string' ? data.name.toUpperCase() : '',
      pictures: typeof data.picture_all === 'object' ? data.picture_all : null,
      isFavorite: data.is_favorite,
      friendStatus: data.friend_status,
      permissionType: data.permission,
      isPendingAdmin: data.is_pending_admin,
    };

    return hydrated;
  },
};

export const LineRequestMapper = {
  hydrate: (data) => data
};

export const InvitationMapper = {
  hydrate: (data) => {
    // TODO: BACKEND SHOULD CHANGE THE RETURN ON IS_EXPIRED AND IS_CONSUMED BECAUSE NOW WE HAVE "0" OR "1" AND IT'S A MISTAKE
    const hydrated = {
      id: data.line_uuid,
      name: typeof data.line_name === 'string' ? data.line_name : '',
      invitingUserName: typeof data.inviting_user_name === 'string' ? data.inviting_user_name : '',
      invitingUserId: typeof data.inviting_user_id === 'string' ? data.inviting_user_id : '',
      permissionType: typeof data.permission_type === 'number' ? data.permission_type : '',
      isExpired: typeof data.is_expired === 'number' ? data.is_expired : '',
      isConsumed: typeof data.is_consumed === 'number' ? data.is_consumed : '',
      invitationHash: typeof data.invitation_hash === 'string' ? data.invitation_hash : '',
      invitationUrl: typeof data.invitation_url === 'string' ? data.invitation_url : '',
    };
    return hydrated;
  },
};

export const openingServiceHoursMapper = {
  hydrate: (data) => {
    const hydrated = {
      start: data.start,
      end: data.end,
    };
    return hydrated;
  }
};


const getHoursAndMinutesRemainingFromNowByDate = function(dateInYYYYMMddhhmmssFormat) {
  //TODO: Eliminar estos comentarios, se dejan durante unos dias por si hay que volver atras

  // ojo si no es format Y-m-d H:i:s podem tenir problemes. Hem fet això perquè safari no permet fer new Date("XXXX-XX-XX XX:XX:XX")
  // const dateInArraySep = dateInYYYYMMddhhmmssFormat.split(/[\s,:,-]+/);
  // const date = new Date(+dateInArraySep[0], (+dateInArraySep[1])-1, +dateInArraySep[2], +dateInArraySep[3], +dateInArraySep[4], +dateInArraySep[5]);
  // const date = new Date(dateInYYYYMMddhhmmssFormat.replace('Z', ''))
  const date = new Date(dateInYYYYMMddhhmmssFormat)
  const now = new Date();

  const diffMs = date - now;
  if(diffMs > 0) {
    let diffMins = Math.floor(diffMs / 1000 / 60);
    const diffHours = Math.floor(diffMins / 60);
    diffMins = Math.floor(diffMins % 60);

    return {
      hours: diffHours,
      minutes: diffMins
    }
  }
  else return {
    hours: 0,
    minutes: 0
  }
}

export const ForestMapper = {
  hydrate: (data) => {
    const hydrated = {
      totalKgCo2OffsettedOnForest: data?.total_kgC_co2_offsettedO_on_forest || 0,
      trees: data?.trees?.map((el) => TreeMapper.hydrate(el)) || [],
    };
    return hydrated;
  }
}

export const TreeMapper = {
  hydrate: (data) => {
    const hydrated = {
      treeProductId: data?.tree_product_id,
      quantityPurchased: data.quantity_purchased || 0,
      treeProduct: data?.tree_product !== null && typeof data?.tree_product === 'object' ? TreeProductMapper.hydrate(data?.tree_product) : {},
      userName: data.user_name || '',
      udianaUuid: data.udiana_uuid || '',
    };
    return hydrated;
  },
};

export const TreeProductMapper = {
  hydrate: (data) => {
    const hydrated = {
      id: data.id,
      liveSpanInYears: data.live_span_in_years,
      name: data.name,
      quantityAvailable: data.quantity_available,
      projectName: data.reforestation_project_name,
      priceInEuros: data.price_in_euros,
      projectHashId: data.reforestation_project_hash_id,
      totalKgCo2Offsetted: data.total_kg_co2_offsetted,
      imageUrl: data.tree_image_url,
      vat: data.vat
    }
    return hydrated;
  }
}
