import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InvitationPage from '../../../pages/invitation/container';

export const routes = (initationPath) => ({
  path: initationPath,
  pages: {
    invitation: {
      path: `${initationPath}/:hash`,
    },
  },
});

export const InvitationRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.invitation.path} component={InvitationPage} />
      <Redirect to={applicationRoutes.pages.invitation.path} />
    </Switch>
  );
};

InvitationRoutes.propTypes = {};
