import { constants } from '../config';

export default function setInfoAuthCodeReducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_INFO_AUTH_CODE:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_INFO_AUTH_CODE_SUCCESS: {
      return { ...state, loading: false, data: action.payload, done: true };
    }
    case constants.GET_INFO_AUTH_CODE_FAIL:
      return { ...state, loading: false, errorRequest: true };

    default:
      return state;
  }
}
