/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
import { getLocalStorage } from '../../../functions/cookies';
import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  addBadgesDone: false,
  active: {},
  contentActive: {},
  invitation: null,
  addContentDone: false,
  homeDone: false,
  home: {},
  redirectLine: null,
};

export default function setLineReducer(state = initialState, action) {
  const idNameStorage = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  switch (action.type) {
    case constants.GET_LINE:
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        done: false,
      };
    case constants.GET_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: {
          ...state.active,
          ...action.payload,
        },
      };
    }
    case constants.UPDATE_LINE:
      return { ...state, loading: true, errorRequest: false, error: null, done: false };
    case constants.UPDATE_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: { ...state.active, ...action.payload },
      };
    }
    case constants.GET_COMPANY_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: { ...state.active, company: action.payload },
      };
    }
    case constants.GET_EVENTS: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        addEventsDone: false,
      };
    }
    case constants.GET_EVENTS_SUCCESS: {
      const events = action.payload.filter((event) => !event.hideEvent);
      return {
        ...state,
        loading: false,
        done: true,
        active: { ...state.active, events },
      };
    }
    case constants.GET_BADGES: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        addBadgesDone: false,
      };
    }
    case constants.GET_BADGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: { ...state.active, ...action.payload },
      };
    }
    case constants.GET_LAST_BADGES:
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        homeDone: false,
      };
    case constants.GET_LAST_BADGES_SUCCESS:
      return {
        ...state,
        loading: false,
        homeDone: true,
        home: action.payload,
      };
    case constants.GET_BADGE_FROM_SOCKETS:
      return {
        ...state,
        loading: true,
        errorRequest: false,
      };
    case constants.ADD_BADGE_FROM_SOCKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          badges: [action.payload, ...state.active.badges],
        },
      };
    case constants.DELETE_BADGE_FROM_SOCKETS_SUCCESS:
      const badgesFilter = state.active.badges.filter((badge) => badge.id !== action.payload.id);
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          badges: badgesFilter,
        },
      };
    case constants.GET_CONTENTS: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        addContentDone: false,
      };
    }
    case constants.GET_CONTENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: {
          ...state.active,
          content: {
            ...action.payload,
          },
        },
      };
    }
    case constants.GET_CONTENT_FROM_SOCKETS:
      return {
        ...state,
        loading: true,
        errorRequest: false,
      };
    case constants.ADD_CONTENT_FROM_SOCKETS_SUCCESS:
      const { contentGridLarge } = state.active.content;
      let newContentGridLarge = contentGridLarge.pop();
      newContentGridLarge = [action.payload, ...contentGridLarge];
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          content: {
            ...state.active.content,
            contentAll: [action.payload, ...state.active.content.contentAll],
            contentGridShort: action.payload,
            contentGridLarge: newContentGridLarge,
          },
        },
      };
    case constants.UPDATE_CONTENT_FROM_SOCKETS_SUCCESS:
      const { contentAll } = state.active.content;
      const updateContentAllFilter = contentAll.map((content) => {
        if (content.contentId === action.payload.contentId) {
          return { ...action.payload, hasLiked: content.hasLiked };
        }
        return content;
      });
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          content: {
            ...state.active.content,
            contentAll: updateContentAllFilter,
          },
        },
      };
    case constants.DELETE_CONTENT_FROM_SOCKETS_SUCCESS:
      const contentAllFilter = state.active.content.contentAll.filter(
        (content) => content.contentId !== action.payload.contentId
      );
      const contentGridShort = contentAllFilter[0];
      const contentGridLargeFilter = contentAllFilter.slice(0, 4);
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          content: {
            ...state.active.content,
            contentAll: contentAllFilter,
            contentGridShort,
            contentGridLarge: contentGridLargeFilter,
          },
        },
      };
    case constants.ADD_CONTENT:
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
        addContentDone: false,
        contentActive: {},
      };
    case constants.ADD_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        addContentDone: true,
        contentActive: { ...action.payload, nameUrl: idNameStorage },
      };
    }
    case constants.DELETE_CONTENT:
      return { ...state, loading: true, errorRequest: false, error: null, addContentDone: false };
    case constants.DELETE_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        addContentDone: false,
      };
    }
    case constants.GET_CONTENT:
      return { ...state, loading: true, addContentDone: false, errorRequest: false, error: null };
    case constants.GET_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        contentActive: {
          ...action.payload,
        },
      };
    }
    case constants.EDIT_CONTENT:
      return { ...state, loading: true, errorRequest: false, error: null, addContentDone: false };
    case constants.EDIT_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        contentActive: {
          ...action.payload,
          nameUrl: state.contentActive?.nameUrl,
        },
        addContentDone: true,
      };
    }

    case constants.FOLLOW_LINE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.FOLLOW_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        active: {
          ...state.active,
          ...action.payload,
        },
      };
    }
    case constants.SET_LIKES_CONTENT:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_LIKES_CONTENT_SUCCESS: {
      const { contentId } = action.payload;
      const {
        active: {
          content: { contentAll },
        },
      } = state;
      let newContentData = contentAll?.find((el) => el.contentId === contentId);
      newContentData = {
        ...newContentData,
        ...action.payload,
      };
      return {
        ...state,
        loading: false,
        done: true,
        active: {
          ...state.active,
          ...newContentData,
        },
      };
    }
    case constants.SET_LIKES_LINE_BADGE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_LIKES_LINE_BADGE_SUCCESS: {
      const { uuid, num_likes, has_liked } = action.payload;
      const lineBadgeId = uuid;
      const {
        home: { badges },
      } = state;
      const lineBadgeAll = badges;
      let newLineBadgeData = lineBadgeAll?.find((el) => el.id === lineBadgeId);
      newLineBadgeData = {
        ...newLineBadgeData,
        hasLiked: has_liked,
        numLikes: num_likes,
      };
      const finalBadges = lineBadgeAll.map((el) => {
        if (el.id === lineBadgeId) {
          return newLineBadgeData;
        }
        return el;
      });
      return {
        ...state,
        loading: false,
        done: true,
        home: {
          ...state.home,
          badges: finalBadges,
        },
      };
    }
    case constants.DELETE_BADGE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_COMMENTS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_COMMENTS_SUCCESS: {
      const newComments = state.active.content.contentAll.map((el) => {
        if (el.contentId === action.payload.contentId) {
          // eslint-disable-next-line no-param-reassign
          el.numComments = action.payload.comments.length;
          // eslint-disable-next-line no-param-reassign
          el.comments = action.payload.comments;
        }
        return el;
      });
      return {
        ...state,
        loading: false,
        done: true,
        active: {
          ...state.active,
          content: {
            ...state.active.content,
            contentAll: newComments,
          },
        },
      };
    }
    case constants.ADD_COMMENTS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.ADD_COMMENTS_SUCCESS: {
      return { ...state, loading: false, done: true, line: action.payload };
    }

    case constants.POST_BADGE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.POST_BADGE_SUCCESS: {
      return { ...state, loading: false, addBadgesDone: true };
    }
    case constants.RESET_BADGE:
      return { ...state, addBadgesDone: false };
    case constants.RESET_CONTENT:
      return { ...state, contentActive: {}, addContentDone: false };
    case constants.INVITATION_TO_LINE_SEND:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.INVITATION_TO_LINE_SEND_SUCCESS: {
      return { ...state, loading: false, invitation: { ...state.invitation, ...action.payload } };
    }
    case constants.GET_INVITATION_TO_LINE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_INVITATION_TO_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        invitation: { ...state.invitation, ...action.payload },
      };
    }
    case constants.GET_LINE_UUID_FROM_INVITATION_TO_LINE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_LINE_UUID_FROM_INVITATION_TO_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        redirectLine: { ...state.redirectLine, ...action.payload },
      };
    }
    case constants.PUT_INVITATION_TO_LINE:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.PUT_INVITATION_TO_LINE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.GET_STREAMS_FROM_SOCKETS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.ADD_STREAMS_FROM_SOCKETS_SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          streams: [...state.active.streams, action.payload],
        },
      };
    case constants.FINSIH_STREAMS_FROM_SOCKETS_SUCCESS:
      // update stream that is finished
      const newStreams = state.active.streams.map((stream) => {
        if (stream.streamId === action.payload.streamId) {
          return { ...stream, ...action.payload };
        }
        return stream;
      });

      return {
        ...state,
        active: {
          ...state.active,
          streams: newStreams,
        },
      };

    case constants.GET_UDIANAS_FROM_SOCKETS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.ADD_UDIANAS_FROM_SOCKETS_SUCCESS: {
      const treeSumPerLine = Number(state.active.treeSumPerLine) + Number(action.payload);
      return {
        ...state,
        active: {
          ...state.active,
          treeSumPerLine,
        },
      };
    }
    case constants.GET_STREAMS_SUCCESS: {
      if (state.active?.streams?.length === action.payload.length) {
        if (state.active?.streams?.find((el) => el.streamTypeId === '1')) {
          const newHaveLive = action.payload?.find((el) => el.streamTypeId === '1');
          const nowHaveOnLive = state.active.streams?.find((el) => el.streamTypeId === '1');
          if (!!newHaveLive && !!nowHaveOnLive) {
            return { ...state };
          }
          if (!newHaveLive && !!nowHaveOnLive) {
            return {
              ...state,
              active: {
                ...state.active,
                streams: [...action.payload],
              },
            };
          }
        }

        return {
          ...state,
          active: {
            ...state.active,
            streams: [...action.payload],
          },
        };
      }
      if (action.payload?.length) {
        const streams = state.active?.streams || [];
        return {
          ...state,
          active: {
            ...state.active,
            streams: [...streams, ...action.payload],
          },
        };
      }
      return {
        ...state,
        active: {
          ...state.active,
          streams: [],
        },
      };
    }

    case constants.GET_EXTERNAL_SERVICES_SUCCESS: {
      return {
        ...state,
        active: {
          ...state.active,
          external: action.payload,
        },
      };
    }

    case constants.GET_BEREAVEMENT_SERVICES_SUCCESS: {
      return {
        ...state,
        active: {
          ...state.active,
          company: {
            ...state.active.company,
            bereavement: action.payload,
          },
        },
      };
    }

    case constants.POST_AJAX_SUCCESS: {
      return {
        ...state,
        active: {
          ...state.active,
          ajax: action.payload,
        },
      };
    }

    case constants.GET_CONTENTS_MODERATED_SUCCESS: {
      return {
        ...state,
        active: {
          ...state.active,
          moderation: action.payload,
        },
      };
    }

    case constants.GET_FOLLOWERS_SUCCESS: {
      return {
        ...state,
        active: {
          ...state.active,
          followers: action.payload,
        },
      };
    }

    case constants.GET_TREES: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
      };
    }
    case constants.GET_TREES_SUCCESS: {
      return {
        ...state,
        loading: false,
        active: {
          ...state.active,
          forest: action.payload,
        },
      };
    }

    case constants.LINES_REQUEST_FAILS:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };

    case constants.MODERATE:
      return { ...state, loading: true, errorRequest: false, error: null };

    case constants.ACCEPT_LEGAL_TERMS:
      return { ...state, loading: true, errorRequest: false, error: null };

    case constants.ACCEPT_LEGAL_TERMS_SUCCESS: {
      return { ...state, loading: false, done: true };
    }

    default:
      return state;
  }
}
