import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { requestApi, requestApi2 } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import * as Constants from '../../../../../utilities/constants';
import { getLocalStorage, setLocalStorage } from '../../../functions/cookies';
import { showAlert } from '../../../states/alerts';
import { showLoading } from '../../../states/loading';
import { CompanyMapper } from '../../company/mapper';
import * as action from '../actions';
import { resetContent } from '../actions';
import { constants, services } from '../config';
import {
  BadgeMapper,
  CommentsMapper,
  ContentItemMapper,
  ContentMapper,
  EventMapper,
  FollowerMapper,
  ForestMapper,
  InvitationMapper,
  LineMapper,
  PermissionTypeMapper,
  StreamMapper,
} from '../mapper';

export function* getLineSaga(params) {
  try {
    const response = yield requestApi2(services.getLine(params));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      setLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE, params.name);
      setLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE, params.id);
      yield put(action.getLineSuccess(LineMapper.hydrate(data)));
      yield put(action.getCompanyLineSuccess(CompanyMapper.hydrate(data?.company)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });

    // if (error.message === 'error.line_does_not_exist') {
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: 'error.line_does_not_exist',
    //     })
    //   );
    //   yield delay(5000);
    //   yield put(showAlert('', -1, ''));
    //   // TODO: Ir al página 404
    // } else {
    //   yield put(
    //     showAlert(
    //       'red',
    //       1,
    //       window.intl.formatMessage({
    //         id: error?.message || 'error.generic',
    //       })
    //     )
    //   );
    //   yield delay(5000);
    //   yield put(showAlert('', -1, ''));
    // }
  }
}

export function* updateLineSaga({ id, body }) {
  try {
    const response = yield requestApi2(services.updateLine(id, body));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.updateLineSuccess(LineMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getEventsSaga({ lineId }) {
  try {
    const response = yield requestApi2(services.getEvents(lineId));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getEventsSuccess(data.map((el) => EventMapper.hydrate(el))));
    } else {
      throw response;
    }
  } catch (error) {
    // yield put(action.linesRequestFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

export function* getContentsSaga({ lineId }) {
  try {
    const response = yield requestApi2(services.getContents(lineId));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getContentsSuccess(ContentMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    // yield put(action.linesRequestFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

export function* getBadgesSaga({ lineId }) {
  try {
    const response = yield requestApi2(services.getBadges(lineId));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      const messages = data?.map((elemnt) => BadgeMapper.hydrate(elemnt));
      yield put(action.getBadgesSuccess({ badges: messages, totalItems: data?.total }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getLastBadges({ companyId, n, p }) {
  try {
    const response = yield requestApi2(services.getLastBadges(companyId, n, p));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      const messages = data?.data.map((elemnt) => BadgeMapper.hydrate(elemnt));
      yield put(action.getLastBadgesSuccess({ badges: messages, totalItems: data?.total }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getBadgeFromSockets({ data }) {
  const badge = BadgeMapper.hydrate(data.badge);
  if (data.action === Constants.SOCKET_ADD) {
    if (badge?.author.id !== data.author_uuid) {
      // Si el creador del badge es el mismo que el usuario logueado no se muestra la notificación
      yield put(action.AddBadgeFromSocketsSuccess(badge));
    }
  } else if (data.action === Constants.SOCKET_DELETE) {
    yield put(action.DeleteBadgeFromSocketsSuccess(badge));
  }
}

export function* postFollowLineSaga(id) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  try {
    const response = yield requestApi2(services.followLine(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.followLineSuccess(PermissionTypeMapper.hydrate(data)));
      yield call(getLineSaga, { id: idLineStored, name: idNameStored });
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.petitionFollowLine',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setLikesSaga({ id, hasLiked }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    const response = hasLiked
      ? yield requestApi2(services.setContentLike(id, hasLiked))
      : yield requestApi2(services.setContentUnLike(id, hasLiked));

    if (response && response.status >= 200 && response.status < 300) {
      yield call(getContentsSaga, { lineId: idLineStored });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setLikesLineBadgeHomeSaga({ id }) {
  try {
    const response = yield requestApi2(services.setLineBadgeLike(id));
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.setLikesLineBadgeSuccess(response.data.line_badge));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setLikesLineBadgeSaga({ id }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    const response = yield requestApi2(services.setLineBadgeLike(id));
    if (response && response.status >= 200 && response.status < 300) {
      yield call(getBadgesSaga, { lineId: idLineStored });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getContentSaga(id) {
  try {
    const response = yield requestApi2(services.getContent(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getContentSuccess(ContentItemMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* addContentSaga({ id, data }) {
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.addContent(id, data));
    if (response && response.status >= 200 && response.status < 300) {
      yield call(getContentsSaga, { lineId: response?.data?.data?.line_uuid });
      yield put(action.addContentSuccess(ContentItemMapper.hydrate(response?.data?.data)));
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.addContent',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

// eslint-disable-next-line require-yield
export function* getContentFromSockets({ data }) {
  let body = data.content;

  if (data.content_uuid) {
    const response = yield requestApi2(services.getContent({ id: data.content_uuid }));
    body = response.data;
  }

  const content = ContentItemMapper.hydrate(body);
  if (data.action === Constants.SOCKET_ADD) {
    yield put(action.AddContentFromSocketsSuccess(content));
  } else if (data.action === Constants.SOCKET_DELETE) {
    yield put(action.DeleteContentFromSocketsSuccess(content));
  } else if (data.action === Constants.SOCKET_UPDATE) {
    yield put(action.UpdateContentFromSocketsSuccess(content));
  } else {
    console.error('action not found');
  }
}

export function* editContentSaga({ idContent, data }) {
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.editContent(idContent, data));
    if (response && response.status >= 200 && response.status < 300) {
      yield call(getContentsSaga, {
        lineId: response?.data?.line?.line_uuid,
      });
      yield put(action.editContentSuccess(ContentItemMapper.hydrate(response?.data)));
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.editContent',
          })
        )
      );
      yield delay(5000);
      yield call(resetContent, {});
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* deleteContentSaga(id) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.deleteContent(id));
    if (response && response.status >= 200 && response.status < 300) {
      yield call(getContentsSaga, { lineId: idLineStored });
      yield put(action.deleteContentSuccess(response));
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.deleteContent',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* deleteBadgeSaga({ id }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.deleteBadge(id));
    if (response && response.status >= 200 && response.status < 300) {
      yield call(getBadgesSaga, { lineId: idLineStored });
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.deleteBadge',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* putAcceptContentSaga({ idContent, value }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.acceptContent(idContent, value));
    if (response && response.status >= 200 && response.status < 300) {
      // eslint-disable-next-line no-use-before-define
      yield call(getModerateContentSaga, { id: idLineStored });
      yield call(getContentsSaga, { lineId: idLineStored });
      yield put(showLoading(-1));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* postBadgeSaga({ id, body }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.addBadge(id, body?.contentType, body?.contentBody));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield call(getBadgesSaga, { lineId: idLineStored });
      yield put(action.postBadgeSuccess(data?.data));
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.addBadge',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* sendInvitationToLineSaga({ id, name }) {
  try {
    const response = yield requestApi2(services.inviteToLineAnon(id));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.sendInvitationToLineSuccess(InvitationMapper.hydrate(data)));
      if (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      ) {
        window.location.assign(
          `https://api.whatsapp.com/send?text=${window.intl.formatMessage(
            { id: 'common.actionShareTextWhatsapp' },
            { name, url: data?.invitation_url }
          )}?o=w`
        );
      } else {
        window.open(
          `https://api.whatsapp.com/send?text=${window.intl.formatMessage(
            { id: 'common.actionShareTextWhatsapp' },
            { name, url: data?.invitation_url }
          )}?o=w`
        );
      }
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getInvitationToLineSaga({ hash }) {
  try {
    const response = yield requestApi2(services.getInvitationAnon(hash));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getInvitationToLineSuccess(InvitationMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.invitation_used_before',
      }),
      delayTime: 15000,
    });
  }
}

export function* getLineUuidFromInvitationToLine({ hash }) {
  try {
    const response = yield requestApi2(services.getLineUuidFromInvitationAnon(hash));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getLineUuidFromInvitationToLineSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic_error',
      }),
      delayTime: 15000,
    });
  }
}

export function* putConsumedInvitationToLineSaga({ hash }) {
  try {
    const response = yield requestApi2(services.consumeInvitationAnon(hash));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.putConsumeInvitationToLineSuccess(InvitationMapper.hydrate(data)));
      const name = data.line_name.split(' ').join('-').toLowerCase();
      yield call(getLineSaga, { id: data.line_uuid, name });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getCommentsSaga({ id }) {
  try {
    const response = yield requestApi2(services.getComments(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getCommentsSuccess(CommentsMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* addCommentsSaga({ id, text }) {
  try {
    const response = yield requestApi2(services.addComment(id, text));

    if (response && response.status >= 200 && response.status < 300) {
      yield call(getCommentsSaga, { id });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setEventAttendanceSaga({ idLine, idEvent, status }) {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.setEventAttendance(idLine, idEvent, status));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield call(getEventsSaga, { lineId: idLineStored });
      yield put(action.setEventAttendanceSuccess(data));
      yield put(showLoading(-1));
      yield put(
        showAlert(
          'green',
          1,
          window.intl.formatMessage({
            id: 'success.attendance',
          })
        )
      );
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(showLoading(-1));
    yield put(action.linesRequestFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getStreamsSaga({ id }) {
  try {
    const response = yield requestApi2(services.getStreams(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      const result =
        typeof data === 'object' && data?.length ? data?.map((el) => StreamMapper.hydrate(el)) : [];
      yield put(action.getStreamsSuccess(result));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getStreamsFromSockets({ data }) {
  const stream = StreamMapper.hydrate(data.stream);
  if (data.action === Constants.SOCKET_ADD) {
    yield put(action.addStreamsFromSocketsSuccess(stream));
  } else if (data.action === Constants.SOCKET_DELETE) {
    yield put(action.finishStreamsFromSocketsSuccess(stream));
  }
}

export function* getExternalServicesSaga({ id }) {
  try {
    const response = yield requestApi2(services.getExternServices(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getExternalServicesSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getBereavementServicesSaga({ id }) {
  try {
    const response = yield requestApi2(services.getBereavementServices(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getBereavementServicesSuccess(data[0].value));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* postAjaxSaga({ url }) {
  try {
    const response = yield requestApi(services.postAjax(url));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(action.postAjaxSuccess(data?.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getModerateContentSaga({ id }) {
  try {
    const response = yield requestApi2(services.getContentsModerated(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(
        action.getModerateContentsSuccess(data?.map((el) => ContentItemMapper.hydrate(el)))
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getFollowersSaga({ id, excludeAdmins }) {
  try {
    const response = yield requestApi2(services.getFollowers(id, excludeAdmins));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(
        action.getFollowersSuccess(data?.followers.map((el) => FollowerMapper.hydrate(el)))
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* moderateSaga({ id }) {
  try {
    const response = yield requestApi2(services.moderate(id));
    const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
    const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);

    if (response || response.status < 200 || response.status >= 300) {
      yield call(getLineSaga, { id: idLineStored, name: idNameStored });
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* acceptLegalTermsSaga({ id }) {
  try {
    const response = yield requestApi2(services.acceptLegalTerms(id));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
      yield put(action.acceptLegalTermsSuccess(data?.data));
      yield call(getLineSaga, { id, name: idNameStored });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getTreesSaga({ lineId }) {
  try {
    const response = yield requestApi2(services.getTrees(lineId));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      const total_kgC_co2_offsettedO_on_forest = data
        ?.map((el) => el.total_co2_kg)
        .reduce((prev, current) => prev + current, 0);
      const forest = { trees: data, total_kgC_co2_offsettedO_on_forest };
      const forestSuccess = ForestMapper.hydrate(forest);
      yield put(action.getTreesSuccess(forestSuccess));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.linesRequestFail(error));
  }
}

export function* getUdianasFromSockets({ data }) {
  const { quantity_purchased } = data;
  yield put(action.addUdianaFromSocketsSuccess(quantity_purchased));
}

function* actionWatcher() {
  yield takeLatest(constants.GET_LINE, getLineSaga);
  yield takeLatest(constants.UPDATE_LINE, updateLineSaga);
  yield takeLatest(constants.GET_EVENTS, getEventsSaga);
  yield takeLatest(constants.GET_BADGES, getBadgesSaga);
  yield takeLatest(constants.GET_LAST_BADGES, getLastBadges);
  yield takeLatest(constants.GET_BADGE_FROM_SOCKETS, getBadgeFromSockets);
  yield takeLatest(constants.FOLLOW_LINE, postFollowLineSaga);
  yield takeLatest(constants.SET_LIKES_CONTENT, setLikesSaga);
  yield takeLatest(constants.SET_LIKES_LINE_BADGE, setLikesLineBadgeSaga);
  yield takeLatest(constants.SET_LIKES_LINE_BADGE_HOME, setLikesLineBadgeHomeSaga);
  yield takeLatest(constants.DELETE_BADGE, deleteBadgeSaga);
  yield takeLatest(constants.POST_BADGE, postBadgeSaga);
  yield takeLatest(constants.INVITATION_TO_LINE_SEND, sendInvitationToLineSaga);
  yield takeLatest(constants.GET_INVITATION_TO_LINE, getInvitationToLineSaga);
  yield takeLatest(
    constants.GET_LINE_UUID_FROM_INVITATION_TO_LINE,
    getLineUuidFromInvitationToLine
  );
  yield takeLatest(constants.PUT_INVITATION_TO_LINE, putConsumedInvitationToLineSaga);
  yield takeLatest(constants.GET_COMMENTS, getCommentsSaga);
  yield takeLatest(constants.ADD_COMMENTS, addCommentsSaga);
  yield takeLatest(constants.ADD_CONTENT, addContentSaga);
  yield takeLatest(constants.GET_CONTENT, getContentSaga);
  yield takeLatest(constants.GET_CONTENT_FROM_SOCKETS, getContentFromSockets);
  yield takeLatest(constants.GET_CONTENTS, getContentsSaga);
  yield takeLatest(constants.ACCEPT_CONTENT, putAcceptContentSaga);
  yield takeLatest(constants.EDIT_CONTENT, editContentSaga);
  yield takeLatest(constants.DELETE_CONTENT, deleteContentSaga);
  yield takeLatest(constants.SET_EVENT_ATTENDANCE, setEventAttendanceSaga);
  yield takeLatest(constants.GET_STREAMS, getStreamsSaga);
  yield takeLatest(constants.GET_STREAMS_FROM_SOCKETS, getStreamsFromSockets);
  yield takeLatest(constants.GET_EXTERNAL_SERVICES, getExternalServicesSaga);
  yield takeLatest(constants.GET_BEREAVEMENT_SERVICES, getBereavementServicesSaga);
  yield takeLatest(constants.POST_AJAX, postAjaxSaga);
  yield takeLatest(constants.GET_CONTENTS_MODERATED, getModerateContentSaga);
  yield takeLatest(constants.GET_FOLLOWERS, getFollowersSaga);
  yield takeLatest(constants.ACCEPT_LEGAL_TERMS, acceptLegalTermsSaga);
  yield takeLatest(constants.MODERATE, moderateSaga);
  yield takeLatest(constants.GET_TREES, getTreesSaga);
  yield takeLatest(constants.GET_UDIANAS_FROM_SOCKETS, getUdianasFromSockets);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
