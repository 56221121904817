import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import {
  acceptContent,
  getLine,
  getModerateContents,
  moderate,
} from '../../../common/schemes/line';
import { ModerationTemplate } from '../template/moderationTemplate';

const ModerationPage = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const {
    active,
    active: { lineId, moderation, company, isModerated },
  } = line;

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const { idLine, idName } = useParams();
  const getContentModerateAPI = useCallback((id) => dispatch(getModerateContents(id)), [dispatch]);
  const moderateAPI = useCallback(
    (id) => {
      dispatch(moderate(id));
    },
    [dispatch]
  );

  const acceptContentAPI = useCallback(
    (idContent, value) => {
      dispatch(acceptContent(idContent, value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (lineId && !moderation) {
      getContentModerateAPI(active?.lineId);
    }
  }, [lineId]);

  useEffect(() => {
    if (!line.done && !line.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }

    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName]);

  return (
    <ModerationTemplate
      user={user?.data}
      goBack={() => history.goBack()}
      active={active}
      moderation={moderation}
      isModerated={isModerated}
      themeColor={company?.style?.primaryColor}
      moderate={moderate}
      handleModerateContent={acceptContentAPI}
      handleModerate={moderateAPI}
    />
  );
};
export default ModerationPage;
