import { Box, Button, Container, Flex, Icon, Text } from '@lideralia/alife-uikit';
import { IconArrowLeft } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { CardAdministrator } from '@lideralia/alife-uikit/dist/organisms/Cards/Administrator';
import { ModalAdministratorsOrUsers } from '@lideralia/alife-uikit/dist/organisms/Modals/AdministratorsOrUsers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage } from '../../../common/functions/cookies';
import { getLine } from '../../../common/schemes/line';
import {
  getParticipants,
  putParticipantsToAdmin,
  removeParticipantsAdmin,
} from '../../../common/schemes/participants';
import { ConfirmationTemplate } from '../../confirmation/template';

export const ConfigurationTemplate = ({ goBack }) => {
  const dispatch = useDispatch();
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);

  useEffect(() => {
    if (!line.done && !line.loading && idLineStored && idNameStored) {
      getLineAPI(idLineStored, idNameStored);
    }
  }, [getLineAPI]);
  return (
    <Container paddingY={4} height="100vh" paddingBottom={7}>
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        maxWidth="339px"
        minWidth="290px"
        margin="0 auto"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="black84" fontWeight="bold" sizeText="display24">
            <FormattedMessage id="communication.configuration.title" />
          </Text>
          <Button
            paddingY={1}
            paddingX={1}
            colorType="black"
            sizeButton="big"
            rounded
            action={() => goBack()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconArrowLeft />
            </Icon>
          </Button>
        </Flex>
        <Administrator dispatch={dispatch} />
      </Flex>
    </Container>
  );
};

const buttonsText = {
  confirm: <FormattedMessage id="common.yes" />,
  cancel: <FormattedMessage id="common.no" />,
};

const Confirmation = ({
  openConfirmation,
  setOpenConfirmation,
  actionConfirmation,
  title,
  body,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(openConfirmation);
  }, [openConfirmation]);

  const actionButton = (action) => {
    if (action) {
      actionConfirmation();
    }
    setOpenConfirmation(false);
    setIsOpen(false);
  };

  return (
    <ConfirmationTemplate
      title={title}
      body={body}
      isOpen={isOpen}
      buttons={buttonsText}
      confirmationButton={actionButton}
      onModalClose={() => {
        setOpenConfirmation(false);
        setIsOpen(false);
      }}
    />
  );
};

const Administrator = ({ dispatch }) => {
  const intl = useIntl();
  const getParticipantsAPI = useCallback((lineId) => dispatch(getParticipants(lineId)), [dispatch]);
  const putParticipantsToAdminAPI = useCallback(
    (body) => dispatch(putParticipantsToAdmin(body)),
    [dispatch]
  );
  const removeParticipantsAdminAPI = useCallback(
    (body) => dispatch(removeParticipantsAdmin(body)),
    [dispatch]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationRemove, setOpenConfirmationRemove] = useState(false);
  const [seeParticipants, setSeeParticipants] = useState(false);
  const [participantToAdmin, setParticipantToAdmin] = useState({});
  const [participantToRemove, setParticipantToRemove] = useState({});
  const line = useSelector((state) => state.line);
  const { data } = useSelector((state) => state.showParticipants);
  const numOfAdmins = data?.participants?.filter((item) => item.userRole >= 3).length;
  const onlyParticipants = data?.participants?.filter((item) => item.userRole === 2);
  const onlyAdmins = data?.participants?.filter((item) => item.userRole >= 3);
  const convertToAdmin = () => putParticipantsToAdminAPI(participantToAdmin);
  const removeAdmin = () => removeParticipantsAdminAPI(participantToRemove);
  const {
    active: { lineId },
  } = line;

  useEffect(() => {
    if (lineId !== null && lineId !== undefined) {
      getParticipantsAPI(lineId);
    }
  }, [lineId]);

  return (
    <Box marginTop={4} marginBottom={5}>
      <CardAdministrator
        title={<FormattedMessage id="profile.administratorsZone.administrators.title" />}
        body={<FormattedMessage id="profile.administratorsZone.administrators.description" />}
        numberOfAdministrators={numOfAdmins || 0}
        administrators={intl
          .formatMessage({
            id: 'profile.administratorsZone.administrators.title',
          })
          .toLowerCase()}
        actionSeeAdministrator={() => {
          setIsOpen(true);
          setSeeParticipants(false);
        }}
        actionAddAdministrator={() => {
          setIsOpen(true);
          setSeeParticipants(true);
        }}
      />
      <ModalAdministratorsOrUsers
        isOpen={isOpen}
        title={
          seeParticipants
            ? intl.formatMessage({
                id: 'familyZone.participants',
              })
            : intl.formatMessage({
                id: 'profile.administratorsZone.administrators.title',
              })
        }
        seeParticipants={seeParticipants}
        onModalClose={() => setIsOpen(false)}
        listparticipants={seeParticipants ? onlyParticipants : onlyAdmins}
        actionAddAdmin={(userId, idLine) => {
          setIsOpen(false);
          setOpenConfirmation(true);
          setParticipantToAdmin({ userId, idLine });
        }}
        actionRemoveContent={(userId, idLine) => {
          setIsOpen(false);
          setOpenConfirmationRemove(true);
          setParticipantToRemove({ userId, idLine });
        }}
        me={data?.me}
      />
      <Confirmation
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        actionConfirmation={convertToAdmin}
        title={<FormattedMessage id="messages.eliminate_title" />}
        body={<FormattedMessage id="profile.administratorsZone.confirmation" />}
      />
      <Confirmation
        openConfirmation={openConfirmationRemove}
        setOpenConfirmation={setOpenConfirmationRemove}
        actionConfirmation={removeAdmin}
        title={<FormattedMessage id="messages.eliminate_title" />}
        body={<FormattedMessage id="profile.administratorsZone.confirmationRemoveContent" />}
      />
    </Box>
  );
};

ConfigurationTemplate.propTypes = {
  goBack: PropTypes.func.isRequired,
};

Confirmation.propTypes = {
  openConfirmation: PropTypes.bool.isRequired,
  setOpenConfirmation: PropTypes.func.isRequired,
  actionConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

Administrator.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
