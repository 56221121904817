/* eslint-disable camelcase */

export const constants = {
  GET_PRODUCTS_BY_CATEGORY: 'GET_PRODUCTS_BY_CATEGORY',
  GET_PRODUCTS_BY_CATEGORY_SUCCESS: 'GET_PRODUCTS_BY_CATEGORY_SUCCESS',
  GET_PRODUCTS_BY_CATEGORY_FAIL: 'GET_PRODUCTS_BY_CATEGORY_FAIL',
  RESET_PRODUCTS_BY_CATEGORY: 'RESET_PRODUCTS_BY_CATEGORY',
};

export const services = {
  getProductsByCategory: (branch_id) => ({
    route: `/api/v2/store-products-by-category?all=true&branch_id=${branch_id}`,
    method: 'get',
  }),
};
