import { constants } from '../config';

/* **** showLogin *** */
export const showLogin = (value) => ({
  type: constants.SHOW_LOGIN,
  value,
});
export const showRegister = (value) => ({
  type: constants.SHOW_REGISTER,
  value,
});
