export const constants = {
  GET_CONDITIONS: 'GET_CONDITIONS',
  GET_CONDITIONS_SUCCESS: 'GET_CONDITIONS_SUCCESS',
  GET_CONDITIONS_FAIL: 'GET_CONDITIONS_FAIL',
};

export const services = {
  getConditions: (lang) => ({
    route: `/api/v2/legals/legal-terms?lang=${lang}&title=terms`,
    method: 'get',
  }),
};
