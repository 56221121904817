/*eslint-disable*/
import { all, takeLatest } from 'redux-saga/effects';
import { constants } from '../config';

export function* getConfirmationModalSaga() {

}

function* actionWatcher() {
  yield takeLatest(constants.SHOW_CONFIRMATION, getConfirmationModalSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
