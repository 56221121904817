import { Container, Flex } from '@lideralia/alife-uikit';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { actualLanguage } from '../../../common/functions/lang';
import { getLine } from '../../../common/schemes/line';
import { getPoll, resetPoll, setPoll } from '../actions';
import reducer from '../reducer';
import { PollOrderDeliveredTemplate } from '../template/PollOrderDeliveredTemplate';
import { PollTemplate } from '../template/PollTemplate';

const PollPage = ({ history }) => {
  const line = useSelector((state) => state.line);
  const poll = useSelector((state) => state.poll);
  const [isFinishPoll, setFinishPoll] = useState(false);
  const [valueRecommendation, setValueRecommendation] = useState(null);
  const [valueStarsHuman, setValueStarsHuman] = useState(null);
  const [valueStarsInstallations, setValueStarsInstallations] = useState(null);
  const [valueStarsServices, setValueStarsServices] = useState(null);
  const [valuePollAlifeTechnologyQ1, setValuePollAlifeTechnologyQ1] = useState(null);
  const [valuePollAlifeTechnologyQ2, setValuePollAlifeTechnologyQ2] = useState(null);
  const [valuePollAlifeTechnologyQ3, setValuePollAlifeTechnologyQ3] = useState(null);
  const [valuePollAlifeTechnologyQ4, setValuePollAlifeTechnologyQ4] = useState(null);
  const [valueRangeQuestion, setValueRangeQuestion] = useState({});
  const [valuePollFuneralAttendanceQ1, setValuePollFuneralAttendanceQ1] = useState(null);
  const [valuePollFuneralOptionsQ1, setValuePollFuneralOptionsQ1] = useState(null);
  const [valuePollSuggestions, setValuePollSuggestions] = useState('');

  const [valueOrderDeliveredAnswers, setValueOrderDeliveredAnswers] = useState({});

  const isFirstLoad = useRef(true);

  const {
    active: { company },
  } = line;
  const dispatch = useDispatch();

  const { pollCode } = useParams();

  const getPollAPI = useCallback(() => {
    dispatch(getPoll({ pollCode }));
  });

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const setPollAPI = useCallback((data) => dispatch(setPoll(data)), [dispatch]);
  const resetPollAPI = useCallback(() => dispatch(resetPoll()), [dispatch]);

  const onSubmit = () => {
    const formData = {
      h: pollCode,
      poll_funeral_attendance_q1: valuePollFuneralAttendanceQ1,
      poll_recommendation: valueRecommendation,
      poll_cert_guests: valueStarsHuman,
      poll_cert_facilities: valueStarsInstallations,
      poll_cert_services: valueStarsServices,
      poll_suggestions: valuePollSuggestions,
      poll_funeral_options_q1: valuePollFuneralOptionsQ1,
      poll_alife_technology_q1: valuePollAlifeTechnologyQ1,
      poll_alife_technology_q2: valuePollAlifeTechnologyQ2,
      poll_alife_technology_q3: valuePollAlifeTechnologyQ3,
      poll_alife_technology_q4: valuePollAlifeTechnologyQ4,
      ...valueRangeQuestion,
      ...valueOrderDeliveredAnswers,
    };
    if (poll?.data?.lineId) {
      setPollAPI(formData);
    }
  };

  const goToFamilyZone = () => {
    history.push(
      `/${actualLanguage}/lines/${poll?.data?.lineId}/${line?.active?.nameUrl}/family-zone`
    );
  };

  useEffect(() => {
    if (
      !!valueStarsHuman ||
      !!valueStarsInstallations ||
      !!valueStarsServices ||
      !!valuePollFuneralAttendanceQ1 ||
      !!valuePollFuneralOptionsQ1
    )
      if (isFirstLoad.current) {
        // al cargar los datos desde getPollAPI se inicializan los valores de la encuesta y con isFirstLoad evitamos que se envien sin la interacción del usaurio
        isFirstLoad.current = false;
      } else {
        onSubmit();
      }
  }, [
    valueRecommendation,
    valueStarsHuman,
    valueStarsInstallations,
    valueStarsServices,
    valuePollFuneralAttendanceQ1,
    valuePollFuneralOptionsQ1,
  ]);

  useEffect(() => {
    if (pollCode && !poll.done && !poll.loading) {
      isFirstLoad.current = true;
      getPollAPI();
    }
  }, []);

  useEffect(() => {
    if (!line.done && !line.loading && poll.done) {
      getLineAPI(poll?.data?.lineId, poll?.data?.nameUrl);
    }
  }, [poll]);

  useEffect(() => {
    if (poll.done) {
      // if (poll.data.pollAnswers.poll_funeral_attendance_q1 !== undefined) setValuePollFuneralAttendanceQ1(Number(poll.data.pollAnswers.poll_funeral_attendance_q1));
      // if (poll.data.pollAnswers.poll_recommendation !== undefined) setValueRecommendation(Number(poll.data.pollAnswers.poll_recommendation));
      setValuePollSuggestions(poll.data.pollAnswers.poll_suggestions);
      setValueStarsHuman(Number(poll.data.pollAnswers.poll_cert_guests));
      setValueStarsInstallations(Number(poll.data.pollAnswers.poll_cert_facilities));
      setValueStarsServices(Number(poll.data.pollAnswers.poll_cert_services));
      setValuePollAlifeTechnologyQ1(Number(poll.data.pollAnswers.poll_alife_technology_q1));
      setValuePollAlifeTechnologyQ2(Number(poll.data.pollAnswers.poll_alife_technology_q2));
      setValuePollAlifeTechnologyQ3(Number(poll.data.pollAnswers.poll_alife_technology_q3));
      setValuePollAlifeTechnologyQ4(poll.data.pollAnswers.poll_alife_technology_q4);
      setValueRangeQuestion({ ...poll.data.pollAnswers.poll_custom });
      setValueOrderDeliveredAnswers({
        poll_order_delivered_q1: Number(poll.data.pollAnswers.poll_order_delivered_q1) || undefined,
        poll_order_delivered_q2: Number(poll.data.pollAnswers.poll_order_delivered_q2) || undefined,
        poll_order_delivered_q3: Number(poll.data.pollAnswers.poll_order_delivered_q3) || undefined,
        poll_order_delivered_q4: poll.data.pollAnswers.poll_order_delivered_q4,
      });
    }
  }, [poll.done]);

  useEffect(() => {
    if (poll.done) {
      if (valuePollFuneralAttendanceQ1) {
        setValuePollAlifeTechnologyQ1(null);
        setValuePollAlifeTechnologyQ2(null);
        setValuePollAlifeTechnologyQ3(null);
        setValuePollAlifeTechnologyQ4(null);
      } else {
        setValuePollSuggestions(null);
        setValueStarsHuman(null);
        setValueStarsInstallations(null);
        setValueStarsServices(null);
        setValueRangeQuestion({});
      }
    }
  }, [valuePollFuneralAttendanceQ1]);

  const changeValueRecommendation = (value) => {
    setValueRecommendation(value);
    // logica window mover
    setTimeout(() => {
      window.scroll({
        top: document.querySelector('#card_OwnerNps').clientHeight / 2,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);
  };

  return (
    <>
      {!poll.data.pollTypeId ||
        (poll.data.pollTypeId !== 2 && (
          <PollTemplate
            poll={poll}
            line={line}
            company={company}
            isFinishPoll={isFinishPoll}
            setFinishPoll={setFinishPoll}
            valueRecommendation={valueRecommendation}
            changeValueRecommendation={changeValueRecommendation}
            valueStarsHuman={valueStarsHuman}
            setValueStarsHuman={setValueStarsHuman}
            valueStarsInstallations={valueStarsInstallations}
            setValueStarsInstallations={setValueStarsInstallations}
            valueStarsServices={valueStarsServices}
            setValueStarsServices={setValueStarsServices}
            valueRangeQuestion={valueRangeQuestion}
            setValueRangeQuestion={setValueRangeQuestion}
            valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
            setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
            valuePollSuggestions={valuePollSuggestions}
            setValuePollSuggestions={setValuePollSuggestions}
            valuePollFuneralOptionsQ1={valuePollFuneralOptionsQ1}
            setValuePollFuneralOptionsQ1={setValuePollFuneralOptionsQ1}
            setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
            setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
            setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
            setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
            valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
            valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
            valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
            valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
            showFuneralOptions={poll.data?.leads?.userIsLeadElegible}
            goToFamilyZone={goToFamilyZone}
            handleResetPoll={resetPollAPI}
            onSubmit={onSubmit}
            ownerNpsNoLead={
              poll?.data?.userIsOwner &&
              poll?.data?.showNPS &&
              !poll.data?.leads?.userIsLeadElegible
            }
            ownerNoNpsLead={
              poll?.data?.userIsOwner &&
              !poll?.data?.showNPS &&
              poll.data?.leads?.userIsLeadElegible
            }
            ownerNpsLead={
              poll?.data?.userIsOwner && poll?.data?.showNPS && poll.data?.leads?.userIsLeadElegible
            }
            noOwnerNpsNoLead={
              !poll?.data?.userIsOwner &&
              poll?.data?.showNPS &&
              !poll.data?.leads?.userIsLeadElegible
            }
            noOwnerNoNpsNoLead={
              !poll?.data?.userIsOwner &&
              !poll?.data?.showNPS &&
              !poll.data?.leads?.userIsLeadElegible
            }
            noOwnerNpsLead={
              !poll?.data?.userIsOwner &&
              poll?.data?.showNPS &&
              poll.data?.leads?.userIsLeadElegible
            }
            noOwnerNoNpsLead={
              !poll?.data?.userIsOwner &&
              !poll?.data?.showNPS &&
              poll.data?.leads?.userIsLeadElegible
            }
          />
        ))}

      {poll.data.pollTypeId === 2 && (
        <PollOrderDeliveredTemplate
          poll={poll}
          line={line}
          company={company}
          valueOrderDeliveredAnswers={valueOrderDeliveredAnswers}
          setValueOrderDeliveredAnswers={setValueOrderDeliveredAnswers}
          isFinishPoll={isFinishPoll}
          setFinishPoll={setFinishPoll}
          goToFamilyZone={goToFamilyZone}
          handleResetPoll={resetPollAPI}
          onSubmit={onSubmit}
        />
      )}
      {poll.error?.message === 'alife_poll_already_exists' && (
        <Container paddingY={3} paddingBottom={8}>
          <Flex alignItems="center" flexDirection="column" marginBottom={6}>
            <Alert
              message={
                <FormattedMessage
                  id="poll.alreadyExist"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                    lineBreak: () => <br />,
                  }}
                />
              }
              marginBottom={3}
              marginTop={3}
            />
          </Flex>
        </Container>
      )}
    </>
  );
};

const withReducer = injectReducer({ key: 'poll', reducer });

export default compose(withReducer)(PollPage);

PollPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
