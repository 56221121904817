import { Button, ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconVideo from '@lideralia/alife-uikit/dist/atoms/Icons/IconVideo';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Input } from '@lideralia/alife-uikit/dist/atoms/Input';
import { Container, Flex, Grid, GridItem } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const VideoContentTemplate = ({
  line,
  handleChange,
  handleSubmit,
  itemsTab,
  content,
  haveContent,
  customColor,
  customTextColor,
}) => {
  const [desactivateButton, setDesactivateButton] = useState(false);
  const intl = useIntl();
  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" justifyContent="center" marginBottom={2} width="100%">
        <CardGone
          name={line?.name}
          date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
          picture={line?.pictures?.m}
          customColor={customColor}
        />
      </Flex>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" paddingBottom={7}>
        <Card padding={3} maxWidth={339} minWidth={290}>
          <Flex marginBottom={4} alignItems="center" justifyContent="center">
            <ButtonTabs items={itemsTab} />
          </Flex>
          <Grid
            gridTemplateAreas="'icon title' 'icon description' 'urlInput urlInput' 'contentCaption contentCaption' 'action action'"
            gridTemplateColumns="auto 1fr"
            gridAutoRows="auto"
            gridGap={2}
          >
            <GridItem gridArea="icon">
              <Icon color={customColor || 'blue'} sizeIcon="display24">
                <IconVideo />
              </Icon>
            </GridItem>
            <GridItem gridArea="title">
              <Text sizeText="display16" fontWeight="bold">
                <FormattedMessage id="common.shareAVideo" />
              </Text>
            </GridItem>
            <GridItem gridArea="description">
              <Text sizeText="display14" color="black84" fontWeight="medium">
                <FormattedMessage id="common.uploadVideofromMobile" />
              </Text>
            </GridItem>
            <GridItem gridArea="urlInput">
              <Field
                label={intl.formatMessage({
                  id: 'common.typeURLOfVideo',
                })}
                color="black60"
                fontWeight="bold"
                sizeText="display16"
                marginTop={0}
                zIndex={0}
              >
                <Input
                  type="url"
                  name="url"
                  id="url"
                  sizeInput="big"
                  onChange={(id, url) => handleChange(id, url)}
                  defaultValue={content?.url}
                  placeholderMessage={intl.formatMessage({
                    id: 'common.typeURLOfVideo',
                  })}
                />
              </Field>
            </GridItem>
            {haveContent && (
              <GridItem gridArea="contentCaption">
                <Field
                  label={intl.formatMessage({ id: 'messages.writeAMessageIfYouWant' })}
                  color="black60"
                  fontWeight="bold"
                  sizeText="display16"
                  marginTop={0}
                  zIndex={0}
                >
                  <Textarea
                    counter
                    name="caption"
                    id="caption"
                    typeAction={(id, caption) => handleChange(id, caption)}
                    defaultValue={content?.caption}
                    placeholderMessage={intl.formatMessage({ id: 'common.writeSomething' })}
                  />
                </Field>
              </GridItem>
            )}
            <GridItem gridArea="action">
              <Button
                backgroundColor={customColor}
                colorType={customColor || 'blue'}
                color={customTextColor}
                fullWidth
                sizeButton="big"
                disabled={!haveContent || desactivateButton}
                type="submit"
                action={() => {
                  setDesactivateButton(true);
                  handleSubmit();
                  setTimeout(() => {
                    setDesactivateButton(false);
                  }, 3000);
                }}
              >
                <Icon sizeIcon="display24" marginRight={2}>
                  <IconWrite />
                </Icon>
                <FormattedMessage id="common.typeURLOfVideoButton" />
              </Button>
            </GridItem>
          </Grid>
        </Card>
      </Flex>
    </Container>
  );
};

VideoContentTemplate.defaultProps = {
  line: [],
};
