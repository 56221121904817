import { constants } from '../config';
/* **** getCountries *** */
export const getCountries = (lang) => ({
  type: constants.GET_COUNTRIES,
  lang,
});

export const getCountriesSuccess = (data) => ({
  type: constants.GET_COUNTRIES_SUCCESS,
  payload: data,
});

export const getCountriesFail = (error) => ({
  type: constants.GET_COUNTRIES_FAIL,
  error,
});
