import { constants } from '../config';
/* **** getConditions *** */
export const getConditionsGeneralEcommerce = (lang) => ({
  type: constants.GET_CONDITIONS_GENERAL_ECOMMERCE,
  lang,
});

export const getConditionsGeneralEcommerceSuccess = (data) => ({
  type: constants.GET_CONDITIONS_GENERAL_ECOMMERCE_SUCCESS,
  payload: data,
});

export const getConditionGeneralEcommerceFail = (error) => ({
  type: constants.GET_CONDITIONS_GENERAL_ECOMMERCE_FAIL,
  error,
});
