import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotificationsPage } from '../../../pages/notifications';
import { ProfilePage } from '../../../pages/profile';
import ArrangementsPage from '../../../pages/profile/container/arrangements';
import { ConfigurationPage } from '../../../pages/profile/container/configuration';
import DataUserPage from '../../../pages/profile/container/dataUser';
import ModerationPage from '../../../pages/profile/container/moderation';
import SettingsPage from '../../../pages/profile/container/settings';
import SupportPage from '../../../pages/profile/container/support';

export const routes = (profilePath) => ({
  path: profilePath,
  pages: {
    profile: {
      path: `${profilePath}`,
      notifications: {
        path: `${profilePath}/notifications`,
      },
      dataUser: {
        path: `${profilePath}/personal-zone`,
      },
      arrangements: {
        path: `${profilePath}/:idLine/:idName/arrangements`,
      },
      settings: {
        path: `${profilePath}/:idLine/:idName/settings`,
      },
      bereavementSupport: {
        path: `${profilePath}/:idLine/:idName/bereavement-support`,
      },
      moderation: {
        path: `${profilePath}/:idLine/:idName/moderation-content`,
      },
      configuration: {
        path: `${profilePath}/:idLine/:idName/configuration`,
      },
    },
  },
});

export const ProfileRoutes = ({ match }) => {
  const applicationRoutes = routes(match.url);
  return (
    <Switch>
      <Route
        path={applicationRoutes.pages.profile.notifications.path}
        component={NotificationsPage}
        exact
      />
      <Route path={applicationRoutes.pages.profile.path} component={ProfilePage} exact />
      <Route path={applicationRoutes.pages.profile.dataUser.path} component={DataUserPage} exact />
      <Route
        path={applicationRoutes.pages.profile.arrangements.path}
        component={ArrangementsPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.profile.bereavementSupport.path}
        component={SupportPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.profile.moderation.path}
        component={ModerationPage}
        exact
      />
      <Route
        path={applicationRoutes.pages.profile.configuration.path}
        component={ConfigurationPage}
        exact
      />
      <Route path={applicationRoutes.pages.profile.settings.path} component={SettingsPage} exact />
      <Redirect to={applicationRoutes.path} />
    </Switch>
  );
};

ProfileRoutes.propTypes = {};
