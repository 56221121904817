import { constants } from '../config';

/* **** getLine *** */
export const getLine = (id, name) => ({
  type: constants.GET_LINE,
  id,
  name,
});

export const getLineSuccess = (data) => ({
  type: constants.GET_LINE_SUCCESS,
  payload: data,
});
/* **** updateLine *** */
export const updateLine = (id, body) => ({
  type: constants.UPDATE_LINE,
  id,
  body,
});

export const updateLineSuccess = (data) => ({
  type: constants.UPDATE_LINE_SUCCESS,
  payload: data,
});

/* **** getCompany *** */
export const getCompanyLineSuccess = (data) => ({
  type: constants.GET_COMPANY_LINE_SUCCESS,
  payload: data,
});

/* BADGES */
/* **** getBadges *** */
export const getBadges = (lineId) => ({
  type: constants.GET_BADGES,
  lineId,
});

export const getBadgesSuccess = (data) => ({
  type: constants.GET_BADGES_SUCCESS,
  payload: data,
});

/* **** getLastBadges *** */
export const getLastBadges = (companyId, n, p) => ({
  type: constants.GET_LAST_BADGES,
  companyId,
  n,
  p,
});

export const getLastBadgesSuccess = (data) => ({
  type: constants.GET_LAST_BADGES_SUCCESS,
  payload: data,
});

/* **** deleteBadge *** */
export const deleteBadge = (id) => ({
  type: constants.DELETE_BADGE,
  id,
});

/* **** postBadge *** */
export const postBadge = (id, body) => ({
  type: constants.POST_BADGE,
  id,
  body,
});

export const postBadgeSuccess = (data) => ({
  type: constants.POST_BADGE_SUCCESS,
  payload: data,
});

export const resetBadge = () => ({
  type: constants.RESET_BADGE,
});

export const getBadgeFromSockets = (data) => ({
  type: constants.GET_BADGE_FROM_SOCKETS,
  data,
});

export const AddBadgeFromSocketsSuccess = (data) => ({
  type: constants.ADD_BADGE_FROM_SOCKETS_SUCCESS,
  payload: data,
});

export const DeleteBadgeFromSocketsSuccess = (data) => ({
  type: constants.DELETE_BADGE_FROM_SOCKETS_SUCCESS,
  payload: data,
});

/* TREES */
export const getTrees = (lineId) => ({
  type: constants.GET_TREES,
  lineId,
});

export const getTreesSuccess = (data) => ({
  type: constants.GET_TREES_SUCCESS,
  payload: data,
});

/* **** getEvents *** */
export const getEvents = (lineId) => ({
  type: constants.GET_EVENTS,
  lineId,
});

export const getEventsSuccess = (data) => ({
  type: constants.GET_EVENTS_SUCCESS,
  payload: data,
});

/* CONTENTS */
/* **** getContent *** */
export const getContents = (lineId) => ({
  type: constants.GET_CONTENTS,
  lineId,
});

export const getContentsSuccess = (data) => ({
  type: constants.GET_CONTENTS_SUCCESS,
  payload: data,
});

export const getContent = (id) => ({
  type: constants.GET_CONTENT,
  id,
});

export const getContentSuccess = (data) => ({
  type: constants.GET_CONTENT_SUCCESS,
  payload: data,
});

/* **** addContent *** */
export const addContent = (id, data) => ({
  type: constants.ADD_CONTENT,
  id,
  data,
});

export const addContentSuccess = (data) => ({
  type: constants.ADD_CONTENT_SUCCESS,
  payload: data,
});

/* **** editContent *** */
export const editContent = (idLine, idContent, data) => ({
  type: constants.EDIT_CONTENT,
  idLine,
  idContent,
  data,
});

export const editContentSuccess = (data) => ({
  type: constants.EDIT_CONTENT_SUCCESS,
  payload: data,
});

/* **** deleteContent *** */
export const deleteContent = (id) => ({
  type: constants.DELETE_CONTENT,
  id,
});

export const deleteContentSuccess = (data) => ({
  type: constants.DELETE_CONTENT_SUCCESS,
  payload: data,
});

/* **** acceptContent *** */
export const acceptContent = (idContent, value) => ({
  type: constants.ACCEPT_CONTENT,
  idContent,
  value,
});

/* **** Sockets of Content *** */
export const getContentFromSockets = (data) => ({
  type: constants.GET_CONTENT_FROM_SOCKETS,
  data,
});

export const AddContentFromSocketsSuccess = (data) => ({
  type: constants.ADD_CONTENT_FROM_SOCKETS_SUCCESS,
  payload: data,
});

export const UpdateContentFromSocketsSuccess = (data) => ({
  type: constants.UPDATE_CONTENT_FROM_SOCKETS_SUCCESS,
  payload: data,
});

export const DeleteContentFromSocketsSuccess = (data) => ({
  type: constants.DELETE_CONTENT_FROM_SOCKETS_SUCCESS,
  payload: data,
});

/* **** followLine *** */
export const followLine = (id) => ({
  type: constants.FOLLOW_LINE,
  id,
});

export const resetContent = () => ({
  type: constants.RESET_CONTENT,
});

export const followLineSuccess = (data) => ({
  type: constants.FOLLOW_LINE_SUCCESS,
  payload: data,
});

export const setLikes = (id, hasLiked) => ({
  type: constants.SET_LIKES_CONTENT,
  id,
  hasLiked,
});

export const setLikesLineBadgeHome = (id) => ({
  type: constants.SET_LIKES_LINE_BADGE_HOME,
  id,
});

export const setLikeLineBadge = (id) => ({
  type: constants.SET_LIKES_LINE_BADGE,
  id,
});

export const setLikesLineBadgeSuccess = (data) => ({
  type: constants.SET_LIKES_LINE_BADGE_SUCCESS,
  payload: data,
});

export const setLikesSuccess = (data) => ({
  type: constants.SET_LIKES_CONTENT_SUCCESS,
  payload: data,
});

/* **** sendInvitationToLine *** */
export const sendInvitationToLine = (id, name) => ({
  type: constants.INVITATION_TO_LINE_SEND,
  id,
  name,
});

export const sendInvitationToLineSuccess = (data) => ({
  type: constants.INVITATION_TO_LINE_SEND_SUCCESS,
  payload: data,
});

/* **** getInvitationToLine *** */
export const getInvitationToLine = (hash) => ({
  type: constants.GET_INVITATION_TO_LINE,
  hash,
});

export const getInvitationToLineSuccess = (data) => ({
  type: constants.GET_INVITATION_TO_LINE_SUCCESS,
  payload: data,
});

/* **** getLineUuidFromInvitationToLine *** */
export const getLineUuidFromInvitationToLine = (hash) => ({
  type: constants.GET_LINE_UUID_FROM_INVITATION_TO_LINE,
  hash,
});

export const getLineUuidFromInvitationToLineSuccess = (data) => ({
  type: constants.GET_LINE_UUID_FROM_INVITATION_TO_LINE_SUCCESS,
  payload: data,
});

/* **** putConsumeInvitationToLine *** */
export const putConsumeInvitationToLine = (hash) => ({
  type: constants.PUT_INVITATION_TO_LINE,
  hash,
});

export const putConsumeInvitationToLineSuccess = (data) => ({
  type: constants.PUT_INVITATION_TO_LINE_SUCCESS,
  payload: data,
});

/* **** getComments *** */
export const getComments = (id) => ({
  type: constants.GET_COMMENTS,
  id,
});

export const getCommentsSuccess = (data) => ({
  type: constants.GET_COMMENTS_SUCCESS,
  payload: data,
});

/* **** addComments *** */
export const addComments = (id, text) => ({
  type: constants.ADD_COMMENTS,
  id,
  text,
});

export const addCommentsSuccess = (data) => ({
  type: constants.ADD_COMMENTS_SUCCESS,
  payload: data,
});

export const setEventAttendance = (idLine, idEvent, status) => ({
  type: constants.SET_EVENT_ATTENDANCE,
  idLine,
  idEvent,
  status,
});

export const setEventAttendanceSuccess = (data) => ({
  type: constants.SET_EVENT_ATTENDANCE_SUCCESS,
  payload: data,
});

/* **** getStreams *** */
export const getStreams = (id) => ({
  type: constants.GET_STREAMS,
  id,
});

export const getStreamsSuccess = (data) => ({
  type: constants.GET_STREAMS_SUCCESS,
  payload: data,
});

/* **** getStreamsFromSockets *** */
export const getStreamsFromSockets = (data) => ({
  type: constants.GET_STREAMS_FROM_SOCKETS,
  data,
});

export const addStreamsFromSocketsSuccess = (data) => ({
  type: constants.ADD_STREAMS_FROM_SOCKETS_SUCCESS,
  payload: data,
});

export const finishStreamsFromSocketsSuccess = (data) => ({
  type: constants.FINSIH_STREAMS_FROM_SOCKETS_SUCCESS,
  payload: data,
});

/* **** getExternalServices *** */
export const getExternalServices = (id) => ({
  type: constants.GET_EXTERNAL_SERVICES,
  id,
});

export const getExternalServicesSuccess = (data) => ({
  type: constants.GET_EXTERNAL_SERVICES_SUCCESS,
  payload: data,
});

/* **** getBereavementServices *** */
export const getBereavementServices = (id) => ({
  type: constants.GET_BEREAVEMENT_SERVICES,
  id,
});

export const getBereavementServicesSuccess = (data) => ({
  type: constants.GET_BEREAVEMENT_SERVICES_SUCCESS,
  payload: data,
});

/* **** postAjax *** */
export const postAjax = (url) => ({
  type: constants.POST_AJAX,
  url,
});

export const postAjaxSuccess = (data) => ({
  type: constants.POST_AJAX_SUCCESS,
  payload: data,
});

/* **** getModerateContents *** */
export const getModerateContents = (id) => ({
  type: constants.GET_CONTENTS_MODERATED,
  id,
});

export const getModerateContentsSuccess = (data) => ({
  type: constants.GET_CONTENTS_MODERATED_SUCCESS,
  payload: data,
});

/* **** getFollowers *** */
export const getFollowers = (id, excludeAdmins) => ({
  type: constants.GET_FOLLOWERS,
  id,
  excludeAdmins,
});

export const getFollowersSuccess = (data) => ({
  type: constants.GET_FOLLOWERS_SUCCESS,
  payload: data,
});

/* Error Lines */
export const linesRequestFail = (error) => ({
  type: constants.LINES_REQUEST_FAILS,
  error,
});

/* **** Moderate Line *** */
export const moderate = (id) => ({
  type: constants.MODERATE,
  id,
});

/* **** postLegalTerms *** */
export const acceptLegalTerms = (id) => ({
  type: constants.ACCEPT_LEGAL_TERMS,
  id,
});

export const acceptLegalTermsSuccess = (data) => ({
  type: constants.ACCEPT_LEGAL_TERMS_SUCCESS,
  payload: data,
});

/* **** getUdianasFromSockets *** */
export const getUdianasFromSockets = (data) => ({
  type: constants.GET_UDIANAS_FROM_SOCKETS,
  data,
});

export const addUdianaFromSocketsSuccess = (data) => ({
  type: constants.ADD_UDIANAS_FROM_SOCKETS_SUCCESS,
  payload: data,
});
