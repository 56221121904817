import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import css from '@styled-system/css';
import styled from 'styled-components';

export const SectionStyled = styled(Flex)({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const HeaderSectionStyled = styled('header')(
  css({
    marginTop: 4,
    marginBottom: 2,
    marginX: 'auto',
    width: '100%',
  })
);

export const HeaderSectionDescriptionStyled = styled('header')(
  css({
    marginTop: 2,
    marginBottom: 4,
    marginX: 'auto',
    width: '100%',
  })
);

export const SectionWithMarginStyled = styled(Flex)(
  css({
    marginTop: 2,
    marginBottom: 2,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  })
);

export const DivSectionStyled = styled('div')(
  css({
    marginTop: 2,
    marginBottom: 1,
    marginX: 'auto',
    width: '100%',
  })
);
