import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getParticipants,
  putParticipants,
  showParticipants,
} from '../../../common/schemes/participants';
import { ParticipantsTemplate } from '../template';

const ParticipantsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setOpenConfirmation] = useState(false);
  const [action, setAction] = useState(false);
  const [body, setBody] = useState({});

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 1);
    setTimeout(() => setOpenConfirmation(false), 1);
    setTimeout(() => setAction(false), 1);
  }, []);

  const { show } = useSelector((state) => state.showParticipants);
  const { data } = useSelector((state) => state.showParticipants);
  const line = useSelector((state) => state.line);

  const dispatch = useDispatch();

  const {
    active,
    active: { lineId },
  } = line;

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const onModalClose = () => {
    dispatch(showParticipants(false));
  };

  const eliminateUserofParticipants = (user_uuid, line_uuid) => {
    const body = { user_uuid, line_uuid, user_role: -1 };
    setBody(body);
    setIsOpen(false);
    setAction(true);
    setTimeout(() => setOpenConfirmation(true), 10);
  };

  const confirmationButton = (actionButton) => {
    setOpenConfirmation(false);
    setAction(false);
    setTimeout(() => setIsOpen(true), 10);
    if (actionButton) {
      dispatch(putParticipants(body));
      dispatch(getParticipants(lineId));
    }
  };

  return (
    <ParticipantsTemplate
      isOpen={isOpen}
      isOpenConfirmation={isOpenConfirmation}
      id="alife-participants"
      hasError={false}
      title={intl.formatMessage({ id: 'familyZone.title' })}
      onModalClose={() => onModalClose()}
      listparticipants={data?.participants}
      me={data.me}
      isAdmin={active?.permissionType >= 3}
      actionRemoveContent={eliminateUserofParticipants}
      action={action}
      confirmationButton={confirmationButton}
    />
  );
};

export default ParticipantsPage;
