import { LinkText } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { ModalLegal } from '@lideralia/alife-uikit/dist/organisms/Modals';
import parse, { domToReact } from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getConditions } from '../../../common/helpers/conditions';
import { getCookies } from '../../../common/helpers/cookies/actions';
import { getPrivacy } from '../../../common/helpers/privacy';
import { getRGPD } from '../../../common/helpers/rgpd';
import { postOwnerAuthCode } from '../../../common/schemes/user';
import { setRgpd } from '../actions';
import reducer from '../reducer';
import { RGPDTemplate } from '../template';

const LinkTextRGPD = ({ children, onClick }) => (
  <LinkText color="blueHover" sizeText="display13" underline onClick={onClick}>
    {children}
  </LinkText>
);

const RGPDPage = ({ history }) => {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dataUser, setDataUser] = useState({ gdpr_accepted: false });
  const [disabled, setDisabled] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [showCookies, setShowCookies] = useState(false);

  const { authCode } = useParams();
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const line = useSelector((state) => state.line);
  const data = useSelector((state) => state.helper?.rgpd);
  const rgpd = useSelector((state) => state.rgpd);
  const authInfo = useSelector((state) => state.helper?.info_auth_code);
  const privacy = useSelector((state) => state.helper?.privacy);
  const conditions = useSelector((state) => state.helper?.conditions);
  const cookies = useSelector((state) => state.helper?.cookies);

  const dataCookies = cookies?.data;
  const dataConditions = conditions?.data;
  const dataPrivacy = privacy?.data;
  const dataRGPD = data?.data;
  const { error, done } = rgpd;
  const { doneCodePost } = user;
  const {
    active: { company },
  } = line;

  const fetchAPI = useCallback((lang, action) => dispatch(action(lang)), [dispatch]);

  const setRgpdAPI = useCallback(
    (dataUserSubmited) => dispatch(setRgpd(dataUserSubmited)),
    [dispatch]
  );
  const setRgpdAuthAPI = useCallback(
    (dataUserSubmited) =>
      dispatch(
        postOwnerAuthCode(
          authCode,
          dataUserSubmited?.gdpr_accepted,
          dataUserSubmited?.ownerauthcode_login_or_registration
        )
      ),
    [dispatch]
  );
  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  useEffect(() => {
    if (showPrivacy && !dataPrivacy) fetchAPI(actualLanguage, getPrivacy);
  }, [showPrivacy]);

  useEffect(() => {
    if (showConditions && !dataConditions) fetchAPI(actualLanguage, getConditions);
  }, [showConditions]);

  useEffect(() => {
    if (showCookies && !dataCookies) fetchAPI(actualLanguage, getCookies);
  }, [showCookies]);

  useEffect(() => {
    if (user?.done || login?.done || authCode) fetchAPI(actualLanguage, getRGPD);
    if (!user?.done) updateSendButtonStatus();
  }, [user, login]);

  useEffect(() => {
    if (done || doneCodePost) {
      history?.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/ceremony`);
      setEventAnalytics(KEYS_ANALYTICS.SIGN_UP, user, history?.location?.pathname, line?.active, {
        sign_up_type: 'owner',
      });
    }
  }, [done, doneCodePost]);

  useEffect(() => {
    if (rgpd.errorRequest) {
      setEventAnalytics(
        KEYS_ANALYTICS.ALIFE_ERROR,
        user,
        history?.location?.pathname,
        line?.active,
        {},
        error,
        'sign_up_error'
      );
    }
  }, [error]);

  useEffect(() => {
    updateSendButtonStatus();
  }, [dataUser]);

  const optionsRGPD = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const hasUrlOfPrivacy = attribs?.href?.includes('/privacy');

      if (hasUrlOfPrivacy) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkTextRGPD onClick={() => setShowPrivacy(true)}>
            {domToReact(children, optionsRGPD)}
          </LinkTextRGPD>
        );
      }
    },
  };

  const optionsOfPrivacy = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const hasUrlOfCookies = attribs.href && attribs.href.includes('/cookies');

      const linkUrlCookies = () => {
        setShowCookies(true);
        setShowPrivacy(false);
      };

      if (hasUrlOfCookies) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkTextRGPD onClick={linkUrlCookies}>
            {domToReact(children, optionsOfPrivacy)}
          </LinkTextRGPD>
        );
      }
    },
  };

  const optionsOfConditions = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const hasUrlOfPrivacy = attribs.href && attribs.href.includes('/privacy');
      const hasUrlOfCookies = attribs.href && attribs.href.includes('/cookies');
      const linkUrlPrivacy = () => {
        setShowPrivacy(true);
        setShowConditions(false);
      };

      const linkUrlCookies = () => {
        setShowCookies(true);
        setShowConditions(false);
      };
      if (hasUrlOfPrivacy) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkTextRGPD onClick={linkUrlPrivacy}>
            {domToReact(children, optionsOfConditions)}
          </LinkTextRGPD>
        );
      }

      if (hasUrlOfCookies) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkTextRGPD onClick={linkUrlCookies}>
            {domToReact(children, optionsOfConditions)}
          </LinkTextRGPD>
        );
      }
    },
  };

  const updateSendButtonStatus = () => {
    setDisabled(user?.loading || !dataUser?.gdpr_accepted); // activar/desactivar botón enviar
  };

  const handleSubmit = () => {
    if (!authCode) {
      setRgpdAPI(dataUser);
    } else {
      setRgpdAuthAPI(dataUser);
      setEventAnalytics(
        KEYS_ANALYTICS.OWNER_AUTHCODE_SENT,
        user,
        history?.location?.pathname,
        line?.active
      );
    }
  };

  return (
    <>
      <RGPDTemplate
        disabled={disabled}
        customColor={company?.style?.primaryColor}
        customTextColor={company?.style?.textColor}
        isOpen={isOpen && !showPrivacy && !showConditions && !showCookies}
        id="alife-rgpd"
        hasError={!!error}
        errorMessage={
          (error?.message && (
            <FormattedMessage id={error?.message} defaultMessage="Legal Check" />
          )) ||
          (user?.error?.message && (
            <FormattedMessage id={user?.error.message} defaultMessage="Legal Check" />
          ))
        }
        title={<FormattedMessage id="rgpd.title" />}
        labelCheckInput={
          <FormattedMessage
            id="rgpd.labelCheckInput"
            values={{
              policy: (
                <LinkText
                  color="black60"
                  sizeText="display13"
                  underline
                  onClick={() => setShowPrivacy(true)}
                >
                  <FormattedMessage id="rgpd.policies" />
                </LinkText>
              ),
              conditions: (
                <LinkText
                  color="black60"
                  sizeText="display13"
                  underline
                  onClick={() => setShowConditions(true)}
                >
                  <FormattedMessage id="rgpd.conditions" />
                </LinkText>
              ),
            }}
          />
        }
        labelCheckInput2={
          authInfo?.data?.company?.optin != null
            ? authInfo?.data?.company?.optin[actualLanguage]
                ?.split('<a')
                .join(
                  '<a style="font-weight: 500;color: #8A8689;font-size: 13px;line-height: 18px;text-decoration: underline;"'
                )
            : ''
        }
        labelAction={<FormattedMessage id="rgpd.labelAction" />}
        idCheckInput="gdpr_accepted"
        idCheckInput2="ownerauthcode_login_or_registration"
        actionInput={(name, value) => {
          setDataUser({
            ...dataUser,
            [name]: value,
          });
        }}
        content={dataRGPD ? parse(dataRGPD, optionsRGPD) : null}
        onSubmit={handleSubmit}
      />
      <ModalLegal
        isOpen={showPrivacy}
        id="alife-privacy"
        title={<FormattedMessage id="profile.personalData.privacy" />}
        content={dataPrivacy ? parse(dataPrivacy, optionsOfPrivacy) : null}
        onModalClose={() => setShowPrivacy(false)}
      />
      <ModalLegal
        isOpen={showConditions}
        id="alife-conditions"
        title={<FormattedMessage id="profile.personalData.generalConditions" />}
        content={dataConditions ? parse(dataConditions, optionsOfConditions) : null}
        onModalClose={() => setShowConditions(false)}
      />
      <ModalLegal
        isOpen={showCookies}
        id="alife-cookies"
        title={<FormattedMessage id="profile.personalData.cookies" />}
        content={dataCookies ? parse(dataCookies) : null}
        onModalClose={() => setShowCookies(false)}
      />
    </>
  );
};

const withReducer = injectReducer({ key: 'rgpd', reducer });

export default compose(withReducer)(RGPDPage);

RGPDPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
