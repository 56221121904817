import { Button, Container, Icon, Text } from '@lideralia/alife-uikit';
import { IconTree } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { StepsUdianas } from '@lideralia/alife-uikit/dist/molecules/Udianas';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const faqs = [
  {
    question: 'udianas.whereAreTheTreesPlanted',
    answer: 'udianas.treesArePlantedInCarefullySelected',
  },
  {
    question: 'udianas.ecologicalImpactOfSouvenirTrees',
    answer: 'udianas.additionToCarbonOffsetting',
  },
  {
    question: 'udianas.howArePlantationsSelected',
    answer: 'udianas.reforestationProjectsAreCarefullyChosen',
  },
  {
    question: 'udianas.ownTheTreesPlant',
    answer: 'udianas.noPlantingTreeOfMemoryYouBecomeGodfather',
  },
];

const steps = [
  {
    number: 1,
    title: 'udianas.choosingSpeciesNumberTrees',
  },
  {
    number: 2,
    title: 'udianas.addAMessage',
  },
  {
    number: 3,
    title: 'udianas.plant',
  },
];

export const FaqTrees = ({ customColor, customTextColor, goToPlantTreeAction }) => (
  <>
    <Button
      id="plantTree"
      variant="primary"
      sizeButton="medium"
      backgroundColor={customColor}
      colorType={customColor || 'orange'}
      color={customTextColor}
      onClick={goToPlantTreeAction}
      marginBottom={2}
    >
      <Icon marginRight={2} sizeIcon="inherit">
        <IconTree />
      </Icon>
      <FormattedMessage id="udianas.PlantACustomTree" />
    </Button>
    <FaqItem
      faq={{
        question: 'udianas.whyPlantAMemoryTree',
      }}
    >
      <Container paddingBottom={3}>
        <Text as="p" sizeText="display14">
          <FormattedMessage id="udianas.plantingRemembranceTreeTranscends" />
        </Text>
      </Container>
      <Container paddingY={0} paddingX={3}>
        {steps.map(({ title, number }) => (
          <StepsUdianas
            key={number}
            number={number}
            title={intl.formatMessage({ id: title })}
            backgroundColor={customColor}
            width="100%"
            marginBottom={2}
          />
        ))}
      </Container>
    </FaqItem>
    {faqs.map((faq) => (
      <FaqItem key={faq.question} faq={faq}>
        <Container paddingBottom={3}>
          <Text as="p" sizeText="display14">
            <FormattedMessage id={faq.answer} />
          </Text>
        </Container>
      </FaqItem>
    ))}
    <Button
      id="plantTree"
      variant="primary"
      sizeButton="medium"
      backgroundColor={customColor}
      colorType={customColor || 'orange'}
      color={customTextColor}
      onClick={goToPlantTreeAction}
      marginTop={2}
    >
      <Icon marginRight={2} sizeIcon="inherit">
        <IconTree />
      </Icon>
      <FormattedMessage id="udianas.PlantACustomTree" />
    </Button>
  </>
);

const FaqItem = ({ faq, children }) => {
  const { question } = faq;

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Button fullWidth marginBottom={3} onClick={toggleVisibility}>
        <FormattedMessage id={question} />
      </Button>
      {isVisible ? children : null}
    </>
  );
};
