export const constants = {
  GET_LIVE_CHAT_PARAMS: 'GET_LIVE_CHAT_PARAMS',
  GET_LIVE_CHAT_PARAMS_SUCCESS: 'GET_LIVE_CHAT_PARAMS_SUCCESS',
  GET_LIVE_CHAT_PARAMS_FAIL: 'GET_LIVE_CHAT_PARAMS_FAIL',
  RESET_LIVE_CHAT_PARAMS: 'RESET_LIVE_CHAT_PARAMS',
};

export const services = {
  getLiveChatParams: (id) => ({
    route: `/api/v2/lines/${id}/live-chat-params`,
    method: 'get',
  }),
};
