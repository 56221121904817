export const constants = {
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAIL: 'GET_SETTINGS_FAIL',
};

export const services = {
  getSettings: () => ({
    route: `/api/v2/settings/frontend`,
    method: 'get',
  }),
};
