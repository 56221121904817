import { constants } from '../config';

export default function setConditionsReducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_CONDITIONS:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_CONDITIONS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, done: true };
    }
    case constants.GET_CONDITIONS_FAIL:
      return { ...state, loading: false, errorRequest: true };

    default:
      return state;
  }
}
