import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { showProductDetail } from '../../../common/schemes/product_detail/actions';
import reducer from '../../login/reducer';
import { ProductDetailTemplate } from '../template';

const ProductDetailPage = ({ hideModalProductDetail }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.showProductDetail);
  const line = useSelector((state) => state.line);
  const [isOpen, setIsOpen] = useState(false);
  const {
    active: { company },
  } = line;
  const setSelectedShowProductDetailModal = useCallback(
    (selected) => dispatch(showProductDetail(true, product.product, selected)),
    [dispatch, product]
  );

  useEffect(() => {
    setIsOpen(true);
  }, [product]);

  const selectProduct = () => {
    setSelectedShowProductDetailModal(
      product?.selected === product?.product?.id ? 0 : product?.product?.id
    );
  };

  return (
    <ProductDetailTemplate
      selectText={<FormattedMessage id="flowerEcommerce.select" />}
      selectedText={<FormattedMessage id="flowerEcommerce.selected" />}
      legal={<FormattedMessage id="flowerEcommerce.legal" />}
      product={product?.product}
      onSubmit={selectProduct}
      isOpen={isOpen}
      selectedId={product?.selected}
      onModalClose={hideModalProductDetail}
      currency={company?.currency}
    />
  );
};

const withReducer = injectReducer({ key: 'product_detail', reducer });

export default compose(withReducer)(ProductDetailPage);
