import * as FlagIcons from '@lideralia/alife-uikit/dist/atoms/Flags';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCountries } from '../../../helpers/countries';
import { getSettings } from '../../../helpers/settings/actions';
import { updateMobilePasstrough, updateMobileVerify } from '../../../schemes/user';
import { LoginPasscodeTemplate, LoginTemplate } from '../template';

const tokenCaptcha = process.env.REACT_APP_TOKEN_RECAPTCHA_V3 || env?.REACT_APP_TOKEN_RECAPTCHA_V3;
export const PhoneUpdate = ({ hideModalLogin }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenPass, setIsOpenPass] = useState(false);
  const [prefixData, setPrefixData] = useState({
    value: 'ES',
    label: '+34',
    iconLeft: <FlagIcons.Es />,
  });
  const [dataUser, setDataUser] = useState({
    country_code: prefixData.value,
  });
  const [options, setOptions] = useState(null);

  const setVerifyUpdateAPI = useCallback(
    (dataUserSubmited) => dispatch(updateMobileVerify(dataUserSubmited)),
    [dispatch]
  );

  const setCountriesAPI = useCallback(() => dispatch(getCountries('es')), [dispatch]);
  const getSettingsAPI = useCallback(() => dispatch(getSettings()), [dispatch]);

  const setUpdatePhoneAPI = useCallback(
    (dataUserSubmit) => dispatch(updateMobilePasstrough(dataUserSubmit)),
    [dispatch]
  );

  const countries = useSelector((state) => state.helper?.countries);
  const settings = useSelector((state) => state.helper?.settings);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);

  const { error, doneUpdatePhone } = user;
  const [disableLogin, setDisableLogin] = useState(false);

  const onSubmitLogin = () => {
    setDisableLogin(true);
    setUpdatePhoneAPI(dataUser);
  };

  useEffect(() => {
    if (!countries?.done) {
      setCountriesAPI();
    }
    if (!settings?.done) {
      getSettingsAPI();
    }
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  useEffect(() => {
    if (doneUpdatePhone) {
      setTimeout(() => setIsOpenPass(true), 100);
    } else {
      setTimeout(() => setIsOpenPass(false), 100);
    }
  }, [doneUpdatePhone]);

  useEffect(() => {
    setDataUser((prev) => ({
      ...prev,
      country_code: prefixData.value,
    }));
  }, [prefixData]);

  useEffect(() => {
    if (!options) {
      const fetchData = async () => {
        const dataCountries = countries?.data?.filter((elem) => elem.flag);
        let optionsSeted = await dataCountries?.map((element) => {
          const FlagIcon = FlagIcons[element.flag];
          if (FlagIcon) {
            return {
              value: element.iso,
              label: `+${element.phonecode}`,
              iconLeft: <FlagIcon />,
            };
          }
          return false;
        });
        optionsSeted = await optionsSeted?.filter((elem) => elem);
        setOptions(optionsSeted);
      };
      fetchData();
    }
  }, [countries]);
  return (
    <>
      {!doneUpdatePhone && tokenCaptcha && (
        <LoginTemplate
          isOpen={isOpen}
          id="alife-update"
          idPhone="phone_number"
          tokenCaptcha={tokenCaptcha}
          title={<FormattedMessage id="common.phoneMobile" />}
          disabled={disableLogin}
          hasError={!!error}
          errorMessage={
            error?.message && (
              <FormattedMessage id={error?.message} defaultMessage="Invalid phone number" />
            )
          }
          labelPhone={intl.formatMessage({
            id: 'profile.personalData.updateMobileDescriptionLabel',
          })}
          fieldPhone={intl.formatMessage({ id: 'common.phoneDisclaimer' })}
          actionInput={(name, value) => {
            setDataUser({
              ...dataUser,
              [name]: value,
            });
          }}
          prefix={{
            name: 'Prefix',
            placeholderMessage: (
              <FormattedMessage id="common.placeholderPrefix" defaultMessage="Prefijo" />
            ),
            id: 'prefix',
            defaultValue: prefixData,
            options,
            action: (name, value) => {
              setPrefixData(value);
            },
          }}
          actionButton={{
            label: <FormattedMessage id="common.send" defaultMessage="Enviar" />,
            action: onSubmitLogin,
          }}
          onModalClose={hideModalLogin}
        />
      )}
      {doneUpdatePhone && (
        <>
          <LoginPasscodeTemplate
            isOpen={isOpenPass}
            id="alife-login-verify"
            title={<FormattedMessage id="passCode.title" />}
            hasError={!!login?.error}
            errorMessage={
              login?.error?.message && (
                <FormattedMessage
                  id={login?.error?.message}
                  defaultMessage="Invalid phone number"
                />
              )
            }
            description={
              <FormattedMessage
                id="passCode.labelPassCode"
                values={{
                  phoneNumber: user?.data?.phone_number,
                }}
              />
            }
            actionInput={(name, value) => {
              setDataUser({
                ...dataUser,
                [name]: value,
              });
            }}
            labelRetry={intl.formatMessage({ id: 'passCode.labelRetry' })}
            labelRetryAction={<FormattedMessage id="passCode.labelRetryAction" />}
            labelCountDown={intl.formatMessage({ id: 'passCode.labelCountDown' })}
            labelChange={<FormattedMessage id="passCode.labelChange" />}
            labelChangeAction={<FormattedMessage id="passCode.labelChangeAction" />}
            onSubmit={(confirmCode) => {
              setVerifyUpdateAPI({
                ...dataUser,
                confirm_code: confirmCode,
              });
            }}
            actionRetry={onSubmitLogin}
            tokenCaptcha={tokenCaptcha}
            onModalClose={() => {
              history.go(0);
            }}
            onModalBack={() => {
              history.go(0);
            }}
            seconds={
              login?.error && login?.error?.message === 'error.error_too_many_attempts'
                ? settings?.info?.otp_max_attempts_delay_seconds
                : settings?.info?.otp_delay_seconds
            }
            hasWrongCodeError={login?.error}
            onReset={() => {
              login.error = null;
            }}
          />
        </>
      )}
    </>
  );
};

PhoneUpdate.propTypes = {
  hideModalLogin: PropTypes.func,
};

PhoneUpdate.defaultProps = {
  hideModalLogin: () => {},
};
