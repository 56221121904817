/* eslint-disable */
import { Button } from '@lideralia/alife-uikit';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import { IconArrowCircleLeft } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconQuote from '@lideralia/alife-uikit/dist/atoms/Icons/IconQuote';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CarouselScrollFlowers } from '@lideralia/alife-uikit/dist/molecules/CarouselScroll/CarouselScrollFlowers';
import { CardFlowerBack } from '@lideralia/alife-uikit/dist/organisms/Cards/FlowersBack';
import { CardPayInfo } from '@lideralia/alife-uikit/dist/organisms/Cards/PayInfo';
import { ModalLegal } from '@lideralia/alife-uikit/dist/organisms/Modals';
import parse from 'html-react-parser';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export const EcommerceTemplate = (props) => {
  const {
    line,
    company,
    actionGoBack,
    productsCategory,
    openDetailPopUp,
    onSelect,
    selected,
    category_selected,
    typeAction,
    optionsCountry,
    handleSubmit,
    stripeKey,
    stripeAccount,
    locale,
    showConditions,
    setShowConditions,
    dataConditions
  } = props;
  const products = useSelector((state) => state.products_by_category);
  return !products.loading ? (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" flexDirection="column" justifyContet="center" paddingBottom={6}>
        <CardFlowerBack
          titleLabel={<FormattedMessage id="flowerEcommerce.flowerBackTitle" />}
          descriptionLabel={<FormattedMessage id="flowerEcommerce.flowers" />}
        >
          <Button
            colorType="white"
            rounded
            padding={0}
            action={() => actionGoBack()}
            borderColor="black36"
          >
            <Icon sizeIcon="display24" color="black36">
              <IconArrowCircleLeft />
            </Icon>
          </Button>
        </CardFlowerBack>

        <ModalGeneralConditionsEcommerce
          company={company}
          dataConditions={dataConditions}
          setShowConditions={setShowConditions}
          showConditions={showConditions}
        />

        {Object.values(productsCategory).map((element, key) => {
          const productPriceSelected = element?.products.find((product) => product.id === selected)?.total;

          return (
          <>
            {category_selected === 0 || category_selected === element.id ? (
              <Box width="100%" maxWidth="380px" key={element.id}>
                <Text
                  as="h3"
                  sizeText="display24"
                  fontWeight="bold"
                  textAlign="start"
                  marginRight="auto"
                  marginTop="25px"
                  color="black84"
                >
                  {element?.name}
                </Text>

                <CarouselScrollFlowers
                  width="100%"
                  height="375px"
                  widthProducts="200px"
                  widthTransparent="calc(100% - 240px)" // widthProducts - 20px
                  id="scroll-flowers-example"
                  selectText={<FormattedMessage id="flowerEcommerce.select" />}
                  selectedText={<FormattedMessage id="flowerEcommerce.selected" />}
                  key={key}
                  products={element?.products}
                  onDetailClick={() => openDetailPopUp(event)}
                  onSubmit={() => onSelect(event)}
                  selected={selected}
                  currency={company?.currency}
                  customColor={company?.style?.primaryColor}
                  customColorText={company?.style?.textColor}
                ></CarouselScrollFlowers>

                {!!selected && category_selected === element.id && (
                  <>
                    <Card padding="20px" marginTop="20px">
                      <Flex marginBottom="10px">
                        <Icon
                          marginRight="10px"
                          sizeIcon="display22"
                          fontSize="16px"
                          color="orange"
                          marginTop="3px"
                        >
                          <IconQuote />
                        </Icon>
                        <Text as="h3" fontWeight="bold" textAlign="start" marginRight="auto">
                          {<FormattedMessage id="flowerEcommerce.ribbonTitle" />}
                        </Text>
                      </Flex>
                      <Textarea
                        counter
                        maxLength="50"
                        name="ribbon"
                        id="ribbon"
                        placeholderMessage={intl.formatMessage({
                          id: 'flowerEcommerce.ribbonPlaceHolder',
                        })}
                        typeAction={(id, value) => typeAction(id, value)}
                      />
                    </Card>
                      <Card borderWidth="1px" padding={3} marginTop={4}>
                        <Text as="h3" sizeText="display14" fontWeight="bold" textAlign="center">
                          {intl.formatMessage({ id: 'udianas.total_price' }).toUpperCase()}:
                          <Text sizeText="display16" as="span" marginLeft={1}>
                            {productPriceSelected} {company?.currency}
                          </Text>
                        </Text>
                      </Card>
                    <Text
                      as="h2"
                      fontWeight="bold"
                      marginRight="auto"
                      fontSize="24px !important"
                      textAlign="center"
                      marginTop="30px"
                      marginBottom="20px"
                    >
                      {<FormattedMessage id="flowerEcommerce.endPayment" />}
                    </Text>
                    <CardPayInfo
                      id="card-pay-info"
                      maxWidth="100%"
                      labelPayMethod={<FormattedMessage id="flowerEcommerce.labelPayMethod" />}
                      labelCreditCard={<FormattedMessage id="flowerEcommerce.labelCreditCard" />}
                      mathodSelected="card"
                      stripeKey={stripeKey}
                      stripeAccount={stripeAccount}
                      locale={locale}
                      // BODY
                      labelInvoice={<FormattedMessage id="flowerEcommerce.labelInvoice" />}
                      labelNeedInvoice={<FormattedMessage id="flowerEcommerce.labelNeedInvoice" />}
                      labelName={<FormattedMessage id="flowerEcommerce.labelName" />}
                      name=""
                      labelSurname={<FormattedMessage id="flowerEcommerce.labelSurname" />}
                      surname=""
                      labelDNI={<FormattedMessage id="flowerEcommerce.labelDNI" />}
                      dni=""
                      labelEmail={<FormattedMessage id="flowerEcommerce.labelEmail" />}
                      email=""
                      labelAddress={<FormattedMessage id="flowerEcommerce.labelAddress" />}
                      address=""
                      labelLocation={<FormattedMessage id="flowerEcommerce.labelLocation" />}
                      location=""
                      labelCP={<FormattedMessage id="flowerEcommerce.labelCP" />}
                      cp=""
                      labelProvince={<FormattedMessage id="flowerEcommerce.labelProvince" />}
                      province=""
                      labelCountry={<FormattedMessage id="flowerEcommerce.labelCountry" />}
                      country="ES"
                      // FOOTER
                      labelButton={<FormattedMessage id="flowerEcommerce.labelButton" />}
                      optionsCountry={optionsCountry}
                      customColor={company?.style?.primaryColor}
                      customTextColor={company?.style?.textColor}
                      buttonDisabled={false}
                      handleSubmit={(content) => handleSubmit(content)}
                      labelTermsAndConditions={
                        <FormattedMessage
                          id="flowerEcommerce.validatingMyOrderReadTheConditionsInterflora"
                          values={{
                          link: (...chunks) => (
                            <strong
                            style={{ textDecoration: 'underline', cursor: 'pointer', color: company?.style?.primaryColor }}
                            onClick={(e) => {
                              e.preventDefault();
                              setShowConditions(true);
                            }}>
                              {chunks}
                            </strong>
                            ),
                          }}
                        />
                      }
                    />
                  </>
                )}
              </Box>
            ) : null}
          </>
        )})}
      </Flex>
    </Container>) : (
      <Loader customColor={line?.active?.company?.style?.primaryColor} />
    );

};

const ModalGeneralConditionsEcommerce = ({company, showConditions, setShowConditions, dataConditions}) => {
  return (
    <ModalLegal
      customColor={company?.style?.primaryColor}
      customTextColor={company?.style?.textColor}
      isOpen={showConditions}
      id="alife-general-conditions-ecommerce"
      title={<FormattedMessage id="profile.personalData.generalConditions" />}
      content={dataConditions ? parse(dataConditions) : null}
      onModalClose={() => setShowConditions(false)}
    />
  )
}

EcommerceTemplate.defaultProps = {
  line: [],
};
