import { FormatDate } from '../../../common/mappers/FormatDate';
import { CompanyMapper } from '../../../common/schemes/company/mapper';

const PollCustomItemMapper = {
  hydrate: ({ form_name, id, title, type_value }) => ({
    formName: form_name && form_name,
    id: id && id,
    title: title && title,
    typeValue: type_value && type_value,
  }),
};

const PollCustomMapper = {
  hydrate: ({ id, items }) => ({
    id,
    items: items?.map((el) => PollCustomItemMapper.hydrate(el)),
  }),
};

export const PollMapper = {
  hydrate: ({
    poll_type_id,
    company,
    user_attended_event,
    user_owner_auth_code,
    user_is_registered,
    user_is_owner,
    legal,
    poll_custom,
    leads,
    show_nps,
    line_uuid,
    line_name,
    hash,
    branch_name,
    branch_id,
    answered_at,
    answered,
    user_name,
    poll_answers,
  }) => ({
    pollTypeId: poll_type_id,
    company: company && CompanyMapper.hydrate(company),
    lineName: line_name,
    lineId: line_uuid,
    nameUrl: typeof line_name === 'string' ? line_name.split(' ').join('-').toLowerCase() : '',
    answered,
    answeredAt: answered_at && FormatDate.hydrate(answered_at),
    branchId: branch_id && branch_id,
    branchName: branch_name && branch_name,
    hash,
    showNPS: show_nps,
    userAttendedEvent: user_attended_event,
    userIsOwner: user_is_owner,
    userIsRegistered: user_is_registered,
    userOwnerAuthCode: user_owner_auth_code,
    userName: user_name,
    legal: {
      gdprAccepted: legal?.gdpr_accepted,
      gdprUrl: legal?.gdpr_url,
      lastVersionAccepted: legal?.last_version_accepted,
      userLegalTokenNotification: legal?.user_legal_token_notification,
    },
    leads: {
      leadExists: leads?.lead_exists,
      userIsLeadElegible: leads?.user_is_lead_elegible,
    },
    pollCustom: poll_custom && PollCustomMapper.hydrate(poll_custom),
    pollAnswers: poll_answers,
  }),
};
