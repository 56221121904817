export const constants = {
  GET_RGPD: 'GET_RGPD',
  GET_RGPD_SUCCESS: 'GET_RGPD_SUCCESS',
  GET_RGPD_FAIL: 'GET_RGPD_FAIL',
};

export const services = {
  getRGPD: (lang) => ({
    route: `/api/v2/legals/legal-terms?lang=${lang}&title=rgpd`,
    method: 'get',
  }),
};
