import { OrderMapper } from '../../order/mapper';

// es el mismo que el -> TreeProductMapper
export const TreeProductMapper = {
  hydrate: (data) => {
    const hydrated = {
      id: data.id,
      liveSpanInYears: data.live_span_in_years,
      name: data.name,
      family: data.family,
      foliageType: data.foliage_type,
      quantityAvailable: data.quantity_available,
      projectName: data.reforestation_project_name,
      priceInEuros: data.price_in_euros,
      projectHashId: data.reforestation_project_hash_id,
      longitude: data.reforestation_project_longitude,
      latitude: data.reforestation_project_latitude,
      totalKgCo2Offsetted: data.total_kg_co2_offsetted,
      imageUrl: data.tree_image_url,
      vat: data.vat,
      heightInMeters: data.height_in_meters,
      isPrivate: data.is_private,
      CO2AbsorptionPerYear: data.CO2_absorption_per_year
        ? data.CO2_absorption_per_year.map((item) => item.toFixed(2))
        : [],
    };
    return hydrated;
  },
};

export const TreeMapper = {
  hydrate: (data) => {
    const hydrated = {
      userName: data.user_name || data.user?.name || '',
      messageTree: data.message_tree,
      treeProduct:
        data?.tree_product !== null && typeof data.tree_product === 'object'
          ? TreeProductMapper.hydrate(data.tree_product)
          : {},
    };
    return hydrated;
  },
};

export const TreeProductDescriptionMapper = {
  hydrate: (data) => {
    const hydrated = {
      description: TreeProductMapper.hydrate(data.tree_product),
      translations: TreeDescriptionTranslationMapper.hydrate(data.tree_product),
      order: data.order ? TreeOrder.hydrate(data.order) : {},
      canDownloadCertificate: data.can_download_certificate,
      tree: data?.tree ? TreeMapper.hydrate(data?.tree) : {},
    };
    return hydrated;
  },
};

export const TreeDescriptionTranslationMapper = {
  hydrate: (data) => {
    const hydrated = {
      id: data.id,
      treeProductId: data.tree_product_id,
      lang: data.lang,
      description: data.description,
      summary: data.summary,
      utility: data.utility,
      plantersLike: data.planters_like,
      whyPlantIt: data.why_plant_it,
    };
    return hydrated;
  },
};

export const TreeOrder = {
  hydrate: (data) => OrderMapper.hydrate(data),
};

export const UdianaKeyStripe = {
  hydrate: (data) => data.key,
};
