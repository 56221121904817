import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { ProductCategoryMapper } from '../mapper';

export function* getProductsByCategorySaga({ branch_id }) {
  try {
    const response = yield requestApi2(services.getProductsByCategory(branch_id), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        action.getProductsByCategorySuccess(
          data.map((element) => ProductCategoryMapper.hydrate(element))
        )
      );
    } else {
      throw response;
    }
  } catch (error) {
    /* yield put(action.getProductsByCategoryFail(error));
    yield put(
      showAlert(
        'red',
        1,
        window.intl.formatMessage({
          id: error?.message || 'error.generic',
        })
      )
    );
    yield delay(5000);
    yield put(showAlert('', -1, '')); */
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_PRODUCTS_BY_CATEGORY, getProductsByCategorySaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
