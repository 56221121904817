/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import setInfoAuthCodeReducer from '../../cli/common/helpers/authCode/reducers';
import setConditionsReducer from '../../cli/common/helpers/conditions/reducers';
import setConditionsGeneralEcommerceReducer from '../../cli/common/helpers/conditionsGeneralEcommerce/reducers';
import setCookiesReducer from '../../cli/common/helpers/cookies/reducers';
import setCountriesReducer from '../../cli/common/helpers/countries/reducers';
import setPrivacyReducer from '../../cli/common/helpers/privacy/reducers';
import setRgpdReducer from '../../cli/common/helpers/rgpd/reducers';
import setSettingsReducer from '../../cli/common/helpers/settings/reducers';
import setWhoisReducer from '../../cli/common/helpers/whois/reducers';
import setBranchOrderReducer from '../../cli/common/schemes/branch_order/reducer';
import setShowConfirmationReducer from '../../cli/common/schemes/confirmation/reducer';
import setShowCookieReducer from '../../cli/common/schemes/cookies/reducer';
import setLineReducer from '../../cli/common/schemes/line/reducers';
import getLiveChatParamsReducer from '../../cli/common/schemes/live_chat_params/reducer';
import setShowLoadingReducer from '../../cli/common/schemes/loading/reducer';
import setShowLoginReducer from '../../cli/common/schemes/login/reducer';
import setShowNotAllowedToBuyReducer from '../../cli/common/schemes/not_allowed_to_buy/reducer';
import setNotificationsReducer from '../../cli/common/schemes/notifications/reducer';
import setOrderReducer from '../../cli/common/schemes/order/reducers';
import setShowParticipantsReducer from '../../cli/common/schemes/participants/reducer';
import setShowProductDetailReducer from '../../cli/common/schemes/product_detail/reducer';
import setProductsByCategoryReducer from '../../cli/common/schemes/products_by_category/reducer';
import setShowRedirectToRegisterReducer from '../../cli/common/schemes/redirect_to_register/reducer';
import setUdianasReducer from '../../cli/common/schemes/udianas/reducers';
import setMeReducer from '../../cli/common/schemes/user/reducers';
import setAlertReducer from '../../cli/common/states/alerts/reducer';
import setLoadingReducer from '../../cli/common/states/loading/reducer';
import setLinesReducer from '../../cli/pages/lines/reducer';
import setSearchReducer from '../../cli/pages/search/reducer';
import i18nProviderReducer from '../i18n/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    lang: i18nProviderReducer,
    showLogin: setShowLoginReducer,
    showProductDetail: setShowProductDetailReducer,
    showLoading: setShowLoadingReducer,
    showNotAllowedToBuy: setShowNotAllowedToBuyReducer,
    alert: setAlertReducer,
    user: setMeReducer,
    helper: combineReducers({
      rgpd: setRgpdReducer,
      privacy: setPrivacyReducer,
      conditions: setConditionsReducer,
      conditionsGeneralEcommerce: setConditionsGeneralEcommerceReducer,
      cookies: setCookiesReducer,
      countries: setCountriesReducer,
      info_auth_code: setInfoAuthCodeReducer,
      whois: setWhoisReducer,
      settings: setSettingsReducer,
    }),
    notifications: setNotificationsReducer,
    line: setLineReducer,
    live_chat_params: getLiveChatParamsReducer,
    lines: setLinesReducer,
    loading: setLoadingReducer,
    products_by_category: setProductsByCategoryReducer,
    branch_order: setBranchOrderReducer,
    order: setOrderReducer,
    showCookies: setShowCookieReducer,
    showParticipants: setShowParticipantsReducer,
    showConfirmation: setShowConfirmationReducer,
    udianas: setUdianasReducer,
    search: setSearchReducer,
    redirectToRegister: setShowRedirectToRegisterReducer,
    ...injectedReducers,
  });
  return rootReducer;
}
