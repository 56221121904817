import { constants } from '../config';

const initialState = {
  show: false,
  loading: false,
  data: {},
  error: null,
  done: false,
  info: {},
};

export default function setShowParticipantsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_PARTICIPANTS: {
      return {
        ...state,
        show: action.show,
      };
    }
    case constants.GET_PARTICIPANTS: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
      };
    }
    case constants.GET_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        done: true,
      };
    }
    case constants.GET_PARTICIPANTS_FAIL: {
      return {
        ...state,
        loading: false,
        errorRequest: true,
      };
    }
    case constants.PUT_PARTICIPANTS: {
      return {
        ...state,
        loading: true,
        errorRequest: false,
        error: null,
      };
    }
    case constants.PUT_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        info: {
          ...action.payload,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
}
