import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { COUNTRIES_LIST } from '../../../../utilities/constants';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { actualLanguage } from '../../../common/functions/lang';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import { getTrees } from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import {
  getPlantationProjects,
  getTreesFromProject,
  getUdianaKeyStripe,
} from '../../../common/schemes/udianas';
import { showAlert } from '../../../common/states/alerts';
import HeaderWithForest from '../template/HeaderWithForest';
import PlantTree from '../template/PlantTree';

import { showLoading } from '../../../common/schemes/loading';
import { confirmTreeOrder, postTreeOrder } from '../../../common/schemes/order/actions';
import { useLineData } from '../hooks/useLineData';

const PlantTreePage = ({ history, ...props }) => {
  const line = useSelector((state) => state.line);
  const udianas = useSelector((state) => state.udianas);
  const dispatch = useDispatch();

  const getUdianaKeyAccountAPI = useCallback(() => dispatch(getUdianaKeyStripe()), [dispatch]);

  const {
    data: { udianaStripeAccount },
  } = udianas;
  const [stripeConf, setStripeConf] = useState(null);
  const {
    active: { company, isTesting },
  } = line;

  useEffect(() => {
    // 1 -> obtener la clave de stripe de udiana
    getUdianaKeyAccountAPI();
  }, []);

  useEffect(() => {
    if (isTesting) {
      setStripeConf(
        loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST, {}) // para lineas que sean testing que tenga otra key de stripe
      );
    } else {
      if (udianaStripeAccount === null) return;
      setStripeConf(
        loadStripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount: udianaStripeAccount })
      );
    }
  }, [company, udianaStripeAccount]);
  return (
    <>
      {stripeConf !== null && (
        <Elements stripe={stripeConf}>
          <PlantTreePage.Content history={history} {...props} />
        </Elements>
      )}
    </>
  );
};

PlantTreePage.Content = ({ history }) => {
  const { active, forest, idLineStored, idNameStored, lineDefault, nameDefault } = useLineData();
  const line = useSelector((state) => state.line);
  const udianas = useSelector((state) => state.udianas);
  const user = useSelector((state) => state.user);

  const {
    active: { company, isTesting },
  } = line;
  const {
    data: { projects, treesFromProject, udianaStripeAccount },
  } = udianas;
  const stripeKey = isTesting
    ? process.env.REACT_APP_STRIPE_KEY_TEST
    : process.env.REACT_APP_STRIPE_KEY;

  const order = useSelector((state) => state.order);
  const intl = useIntl();
  const { idLine } = useParams();
  const query = useSearchParams();
  const dispatch = useDispatch();
  const getTreesAPI = useCallback((lineId) => dispatch(getTrees(lineId)), [dispatch]);
  const getPlantationProjectsAPI = useCallback(() => dispatch(getPlantationProjects()), [dispatch]);
  const getTreesFromProjectAPI = useCallback((id) => dispatch(getTreesFromProject(id)), [dispatch]);
  const postTreeOrderAPI = useCallback((value) => dispatch(postTreeOrder(value)), [dispatch]);
  const setConfirmOrderState = useCallback((value) => dispatch(confirmTreeOrder(value)));
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));
  const setShowLoadingState = useCallback((value) => dispatch(showLoading(value)));
  const setShowAlert = useCallback(
    (colorType, value, message) => dispatch(showAlert(colorType, value, message)),
    [dispatch]
  );
  const stripe = useStripe();
  // Si es testing no se envia la cuenta de stripe de udianas ya que se hara un charge a la cuenta de stripe de nextlives
  const stripeAccount = isTesting ? undefined : udianaStripeAccount;
  const [checkValue, setCheckValue] = useState(false);
  const hasParams = useRef(query.has('project') && query.has('type_tree'));
  const [formTree, setFormTree] = useState({
    reforestation_project_hash_id: query.get('project'),
    id: query.get('type_tree'),
    quantity_purchased: null,
    message_tree: null,
    total_price: 0,
  });
  const [valueRecommendation, setValueRecommendation] = useState(null);

  // Peticiones iniciales
  useEffect(() => {
    // 1 -> obtener los proyectos de plantación
    getPlantationProjectsAPI();
  }, []);

  useEffect(() => {
    if (
      typeof formTree.reforestation_project_hash_id === 'number' ||
      typeof formTree.reforestation_project_hash_id === 'string'
    ) {
      // 2 -> obtener los árboles de un proyecto
      getTreesFromProjectAPI(formTree.reforestation_project_hash_id);
    }
  }, [formTree.reforestation_project_hash_id]);

  useEffect(() => {
    if (typeof valueRecommendation === 'number') {
      updateForm('quantity_purchased', valueRecommendation);
    }
  }, [valueRecommendation]);

  useEffect(() => {
    if (typeof forest === 'undefined' && idLine) {
      getTreesAPI(idLine);
    }
  }, [idLine]);

  const updateForm = (key, value) => {
    if (key === 'quantity_purchased' && value > 0 && formTree.id) {
      const tree_from_project = treesFromProject.find((tree) => tree.id === formTree.id);
      const total_price = value * tree_from_project.priceInEuros;
      setFormTree({
        ...formTree,
        [key]: value,
        total_price,
      });
    } else {
      setFormTree({
        ...formTree,
        [key]: value,
      });
    }
  };

  const updateFormOrShowLogin = (id) => {
    if (!user.done) {
      setShowRegisterState(1);
    } else {
      updateForm('reforestation_project_hash_id', id);
    }
  };

  const goToDetail = () => {
    history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/branch-order-resume`);
  };

  // memorizando el array de proyectos
  const itemsChooseProject = useMemo(
    () =>
      projects?.map((project) => ({
        id: project.projectHashId,
        title: `${project?.projectName.split(',')[0]}, `,
        subtitle: project?.projectName.split(',')[1],
        actionButton: (id) => updateFormOrShowLogin(id),
        paragraphTitle: intl.formatMessage(
          { id: 'udianas.priceFrom' },
          { price: project.priceInEuros }
        ),
        picture: {
          alt: project?.name,
          src: project?.imageUrl,
        },
      })),
    [projects]
  );

  const treeFromAProject = useMemo(
    () =>
      treesFromProject?.map((tree) => ({
        id: tree.id,
        title: tree.name,
        actionButton: (id) => updateForm('id', id),
        paragraphTitle: tree.projectName,
        picture: {
          alt: tree.name,
          src: tree.imageUrl,
        },
        heightTree: '25m',
        yearsTree: intl.formatMessage({ id: 'udianas.yearsLive' }, { years: tree.liveSpanInYears }),
        weather: intl.formatMessage(
          { id: 'udianas.weatherCo2' },
          { name: tree.totalKgCo2Offsetted }
        ),
        priceTree: `${tree.priceInEuros}€`,
        linkSeeDetail: (id) => goToDescriptionTree(id),
      })),
    [treesFromProject]
  );

  const goToDescriptionTree = (id) => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas/${id}/description?project=${formTree.reforestation_project_hash_id}`
    );
  };

  const submitPayment = (payment) => {
    const {
      payment_method_id,
      alias,
      surname,
      email,
      dni,
      address,
      cp,
      country,
      country_iso,
      location,
      invoice_requested,
    } = payment;

    // edit formTree to change id to tree_product_id
    const tree_product_id = formTree.id;

    const body = {
      ...formTree,
      payment_method_id,
      line_id: idLine,
      tree_product_id,
      fact_name: alias,
      fact_surname: surname,
      fact_email: email,
      fact_cif: dni,
      fact_address: address,
      fact_city: location,
      fact_zipcode: cp,
      fact_country: country,
      fact_country_iso_2: country_iso,
      invoice_requested: invoice_requested ? 1 : 0,
    };

    // remove id from body
    delete body.id;

    postTreeOrderAPI(body);
  };

  useEffect(() => {
    (async () => {
      if (order && order.data && order.done === true) {
        if (order.data.status === 'requires_action') {
          if (!stripe) {
            return;
          }
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            order?.data?.client_secret
          );

          setShowLoadingState(true);

          if (error) {
            setShowLoadingState(false);
            setShowAlert('red', 1, 'Error en la confirmación del pago');
            setTimeout(() => {
              setShowAlert('', -1, '');
            }, 5000);
          }

          if (paymentIntent?.status === 'succeeded') {
            setShowLoadingState(false);
            order.done = false;
            setConfirmOrderState({
              id: order?.data?.id,
              order_status: 'delivered',
            });
            // goToDetail();
          }
        } else if (order.data.status === 'success') {
          setShowLoadingState(false);
          goToDetail();
        }
      }
    })();
  }, [order]);

  const goToUdianas = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.VIEW_UDIANAS_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas`
    );
  };
  return (
    <HeaderWithForest
      titleForestOf={intl.formatMessage({ id: 'udianas.forestOfWithName' }, { name: active?.name })}
      treesPlanted={intl.formatMessage(
        { id: 'udianas.treesQuantity' },
        { trees: active?.treeSumPerLine }
      )}
      weather={forest?.totalKgCo2OffsettedOnForest}
      showButtonBack
      actionGoBack={() => goToUdianas()}
    >
      <PlantTree
        itemsChooseProject={itemsChooseProject}
        treeAlreadyPlanted={forest?.trees || []}
        treeFromAProject={treeFromAProject}
        valueRecommendation={valueRecommendation}
        setValueRecommendation={setValueRecommendation}
        intl={intl}
        checkValue={checkValue}
        setCheckValue={setCheckValue}
        stripeKey={stripeKey}
        stripeAccount={stripeAccount}
        optionsCountry={COUNTRIES_LIST}
        updateForm={updateForm}
        informationForm={formTree}
        submitPayment={submitPayment}
        customColor={company?.style?.primaryColor}
        customTextColor={company?.style?.textColor}
        companyName={company?.base?.name}
        order={order}
        locale={user?.data?.language}
        haveValuesOfQueryParams={hasParams.current}
        totalPrice={formTree.total_price}
      />
    </HeaderWithForest>
  );
};
export default PlantTreePage;
