import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import reducer from '../../login/reducer';
import { NotAllowedBuyFlowersTemplate } from '../template';

const NotAllowedToBuyPage = ({ hideModalNotAllowedToBuy }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <NotAllowedBuyFlowersTemplate
      title={<FormattedMessage id="flowerEcommerce.titleDontAllow" />}
      description={<FormattedMessage id="flowerEcommerce.descriptionDontAllow" />}
      isOpen={isOpen}
      onModalClose={hideModalNotAllowedToBuy}
    />
  );
};

const withReducer = injectReducer({ key: 'not_allowed_to_buy', reducer });

export default compose(withReducer)(NotAllowedToBuyPage);
