import TagManager from 'react-server-side-gtm';
import { requestApi2 } from '../../../../lib/request';
import { services } from '../../helpers/stats/config';

export const setEventAnalytics = (eventName, user, pathname, activeLine, customParams, error) => {
  const tagManagerArgs = {
    event: eventName,
    // eslint-disable-next-line react/destructuring-assignment
    logged_in: user?.done ? 1 : 0,
    alife_source: 'web',
    page_path: pathname,
    // eslint-disable-next-line react/destructuring-assignment
    ...customParams,
  };
  if (activeLine?.lineId) {
    tagManagerArgs.line_id = activeLine?.lineId;
    tagManagerArgs.line_public = activeLine?.isPublic ? 1 : 0;
    tagManagerArgs.line_moderated = activeLine?.isModerated ? 1 : 0;
    tagManagerArgs.company_id = activeLine?.company?.companyId;
    tagManagerArgs.branch_id = activeLine?.company?.branchId;
  }

  if (user?.done) {
    tagManagerArgs.user_id = user?.data?.id;
  }
  if (error) {
    tagManagerArgs.error_type = 'api_error';
    tagManagerArgs.error_message = error.message;
    tagManagerArgs.error_code = error.api_error_code;
    tagManagerArgs.error_api_endpoint = `${error?.data?.method} ${error?.data?.uri}`;
  }
  // Send event
  TagManager.dataLayer({ dataLayer: { ...tagManagerArgs } });
  const tagManagerArgsReset = {};
  const defaultKeys = [
    'alife_source',
    'line_id',
    'company_id',
    'branch_id',
    'line_public',
    'line_moderated',
    'user_id',
  ];
  Object.entries(tagManagerArgs).forEach((entry) => {
    const [key] = entry;
    if (defaultKeys.indexOf(key) < 0) tagManagerArgsReset[key] = null;
  });

  TagManager.dataLayer({ dataLayer: { ...tagManagerArgsReset } });

  requestApi2(services.setStats(tagManagerArgs))
    .then((res) => res)
    .catch((err) => err);
};

export const KEYS_ANALYTICS = {
  MENU_LINES_BUTTON_CLICKED: 'menu_lines_button_clicked',
  MENU_CEREMONY_BUTTON_CLICKED: 'menu_ceremony_button_clicked',
  MENU_FAMILY_ZONE_BUTTON_CLICKED: 'menu_family_zone_button_clicked',
  MENU_PROFILE_BUTTON_CLICKED: 'menu_profile_button_clicked',
  MENU_MORE_OPEN_BUTTON_CLICKED: 'menu_more_open_button_clicked',
  MENU_MORE_CLOSE_BUTTON_CLICKED: 'menu_more_close_button_clicked',
  PAGE_VIEW: 'page_view',
  NAVBAR_SHARE_BUTTON_CLICKED: 'navbar_share_button_clicked',
  NAVBAR_HELP_BUTTON_CLICKED: 'navbar_help_button_clicked',
  ORDER_COMPLETED: 'order_completed',
  ECOMMERCE_ORDER_COMPLETED: 'ecommerce_order_completed',
  VIEW_ECOMMERCE_BUTTON_CLICKED: 'view_ecommerce_button_clicked',
  VIEW_UDIANAS_BUTTON_CLICKED: 'view_udianas_button_clicked',
  CEREMONY_EMAIL_BUTTON_CLICKED: 'ceremony_email_button_clicked',
  CEREMONY_PHONE_BUTTON_CLICKED: 'ceremony_phone_button_clicked',
  CEREMONY_IMAGES_BUTTON_CLICKED: 'ceremony_images_button_clicked',
  CEREMONY_ADDRESS_BUTTON_CLICKED: 'ceremony_address_button_clicked',
  OUTBOUND_LINK_CLICKED: 'outbound_link_clicked',
  SHARE_CEREMONY_BUTTON_CLICKED: 'share_ceremony_button_clicked',
  VIEW_CEREMONY_BUTTON_CLICKED: 'view_ceremony_button_clicked',
  ECOMMERCE_TRY_PAYMENT: 'ecommerce_try_payment',
  ACCESS_TO_FAMILY_ZONE_BUTTON_CLICKED: 'access_to_family_zone_button_clicked',
  UPLOAD_A_MOMENT_BUTTON_CLICKED: 'upload_a_moment_button_clicked',
  INVITE_TO_FAMILY_ZONE_BUTTON_CLICKED: 'invite_to_family_zone_button_clicked',
  FAMIY_ZONE_GRID_VIEW_BUTTON_CLICKED: 'famiy_zone_grid_view_button_clicked',
  FAMIY_ZONE_EXT_VIEW_BUTTON_CLICKED: 'famiy_zone_ext_view_button_clicked',
  MORE_MESSAGES_BUTTON_CLICKED: 'more_messages_button_clicked',
  EDIT_LINE_BUTTON_CLICKED: 'edit_line_button_clicked',
  NEW_OTP_BUTTON_CLICKED: 'new_otp_button_clicked',
  LOGIN_BUTTON_CLICKED: 'login_button_clicked',
  ALIFE_ERROR: 'alife_error',
  LOGIN: 'login',
  PROFILE_ARRANGEMENTS_BEREAVEMENT_SUPPORT_BUTTON_CLICKED:
    'profile_arrangements_bereavement_support_button_clicked',
  PROFILE_NOTIFICATIONS_BUTTON_CLICKED: 'profile_notifications_button_clicked',
  PROFILE_PERSONAL_ZONE_BUTTON_CLICKED: 'profile_personal_zone_button_clicked',
  PROFILE_ARRANGEMENTS_BUTTON_CLICKED: 'profile_arrangements_button_clicked',
  PROFILE_MODERATION_CONTENT_BUTTON_CLICKED: 'profile_moderation_content_button_clicked',
  PROFILE_SETTINGS_BUTTON_CLICKED: 'profile_settings_button_clicked',
  REGISTER_BUTTON_CLICKED: 'register_button_clicked',
  SIGN_UP: 'sign_up',
  OWNER_AUTHCODE_SENT: 'owner_authcode_sent',
};
