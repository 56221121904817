import { actualLanguage } from '../../../functions/lang';
import { FormatDateWithoutTimeZone } from '../../../mappers/FormatDate';

export const MapperVerifyPhone = {
  dehydrate: (data) => {
    const returnData = { ...data };
    if (data.pass1) {
      returnData.confirm_code = `${data?.pass1 || 0}${data?.pass2 || 0}${data?.pass3 || 0}${
        data?.pass4 || 0
      }`;
    }
    return returnData;
  },
};

export const AuthInfoMapper = {
  hydrate: ({
    logged_user_id,
    logged_user_is_different,
    auth_code_with_registered_user_id,
    auth_code_user_full_name,
    auth_code_line_id,
    auth_code_line_name,
    auth_code_line_is_public,
    auth_code_line_is_moderated,
    auth_code_line_type_id,
    auth_code_line_company_id,
    auth_code_line_branch_id,
    phone_number,
    birth_date,
    language,
    legal,
    user_uuid,
    ...data
  }) => ({
    id: user_uuid || null,
    loggedUserId: logged_user_id || null,
    loggedUserIsDifferent: logged_user_is_different || false,
    authCodeWithRegisteredUserId: auth_code_with_registered_user_id || null,
    authCodeUserFullName: auth_code_user_full_name || '',
    authCodeLineId: auth_code_line_id || null,
    authCodeLineName: auth_code_line_name || '',
    authCodeLineIsPublic: auth_code_line_is_public || false,
    authCodeLineIsModerated: auth_code_line_is_moderated || false,
    authCodeLineTypeId: auth_code_line_type_id || null,
    authCodeLineCompanyId: auth_code_line_company_id || null,
    authCodeLineBranchId: auth_code_line_branch_id || null,
    legal: {
      gdprAccepted: Number(legal?.gdpr_accepted) || false,
      gdprUrl: legal?.gdpr_url || null,
    },
    phoneNumber: phone_number || null,
    birthDate: birth_date ? FormatDateWithoutTimeZone.hydrate(birth_date) : null,
    language: language || actualLanguage,
    ...data,
  }),
};
