/* eslint-disable */

export const OrderMapper = {
  hydrate({
    id,
    orderable_id,
    orderable_type,
    line_id,
    invoice_requested,
    ribbon_text,
    fact_name,
    fact_email,
    fact_cif,
    fact_address,
    fact_city,
    fact_zipcode,
    fact_county,
    fact_country_iso2,
    status,
    client_secret,
    order_status,
    ...data
  }) {
    return {
      id: id,
      orderable_id: orderable_id,
      orderable_type: orderable_type,
      line_id: line_id,
      invoice_requested: invoice_requested,
      ribbon_text: ribbon_text,
      fact_name: fact_name,
      fact_email: fact_email,
      fact_cif: fact_cif,
      fact_address: fact_address,
      fact_city: fact_city,
      fact_zipcode: fact_zipcode,
      fact_county: fact_county,
      fact_country_iso2: fact_country_iso2,
      status: status,
      order_status: order_status,
      client_secret: client_secret,
      ...data,
    };
  },
};
