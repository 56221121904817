/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import { CardBadges } from '@lideralia/alife-uikit/dist/organisms/Cards/Badges';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import { NoMessagesSection } from '@lideralia/alife-uikit/dist/organisms/Cards/NoMessages';
import {
  FamilyHomeWithContentSection,
  FamilyHomeWithoutAccessSection,
  FamilyHomeWithoutContentSection,
} from '@lideralia/alife-uikit/dist/organisms/FamilyZone';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { OpenShareMenuContext } from '../../../common/contexts/OpenShareContext';
import { getDateDifferenceInYears } from '../../../common/functions/date';
import { actualLanguage } from '../../../common/functions/lang';
import {
  buttonConfirmation,
  idConfirmation,
  locationConfirmation,
  showConfirmation,
} from '../../../common/schemes/confirmation';
import { setLikeLineBadge } from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import { getParticipants, showParticipants } from '../../../common/schemes/participants';
import { putUserProspect } from '../../../common/schemes/user';
import NotShowingPage from '../../notShowing/container';
import { HeaderSectionStyled, SectionStyled } from '../styles';

export const LineTemplate = (props) => {
  const {
    line,
    line: { badges },
    showMoreMessagesAction,
    isShowMoreMessages,
    addNewBadgeAction,
    goToFamilyZone,
    goToCeremonies,
    onRemoveContentAction,
    onInviteToLine,
    isAdmin,
    user,
    setFormData,
    handleSubmit,
    goToAddContent,
    isEmptyLines,
    company,
    handleChangePicture,
    eventEditAnalytics,
    eventShareAnalytics,
    disabledFamilyHomeWithoutAccess,
  } = props;

  const showParticipantsSelector = useSelector((state) => state.showParticipants);
  const line2 = useSelector((state) => state.line);
  const loading = useSelector((state) => state.loading);
  const userSelector = useSelector((state) => state.user);

  const actionConfirmation = useSelector((state) => state.showConfirmation);

  const { data } = showParticipantsSelector;

  const {
    active: { lineId },
  } = line2;

  const gridWithYears = [
    { yearFrom: line2?.active?.dateFrom?.year },
    ...(line?.content[line?.content?.show].length > 0 ? line.content[line.content.show] : []),
    { yearTo: line2?.active?.dateTo?.year },
  ];

  const dispatch = useDispatch();
  const { changeOpenShareMenu } = useContext(OpenShareMenuContext);

  const showModalParticipants = () => {
    dispatch(showParticipants(true));
  };
  const setLikeLineBadgeAPI = useCallback((id) => dispatch(setLikeLineBadge(id)), [dispatch]);
  const isShowLogin = useSelector((state) => state.showLogin);
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  const totalParticipants = data?.participants?.filter((participant) => participant?.userRole >= 2);

  useEffect(() => {
    // TODO: socket getParticipants
    if (
      lineId !== undefined &&
      (Object.entries(showParticipantsSelector?.data).length === 0 ||
        (data?.participants?.length > 0 && data?.participants[0]?.lineId !== lineId))
    ) {
      dispatch(getParticipants(lineId));
    }
  }, [lineId, dispatch]);

  useEffect(() => {
    if (
      actionConfirmation.location === 'confirmation_message_posted' &&
      actionConfirmation.button
    ) {
      onRemoveContentAction(actionConfirmation.id);
      dispatch(buttonConfirmation(false));
    }
  }, [actionConfirmation]);

  useEffect(() => {
    if (user !== null) {
      if (userSelector.prospect) {
        const body = {
          user_id: userSelector.data.user_id,
          optin: userSelector.prospect,
        };
        dispatch(putUserProspect(body));
      }
    }
  }, [user]);

  const onRemoveContentModel = (id) => {
    dispatch(showConfirmation(true));
    dispatch(locationConfirmation('confirmation_message_posted'));
    dispatch(idConfirmation(id));
  };

  const onClickLike = (id) => {
    if (user === null) {
      setShowRegisterState(1);
    } else if (line?.userGroup >= 0) {
      setLikeLineBadgeAPI(id);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  if (!line?.isLegalTermAccepted) {
    return (
      <Container paddingY={3} paddingBottom={8} maxWidth={['400px']}>
        <meta id="og-title" property="og:title" content={line?.name} />
        {/* {line?.pictures?.m ? (
          <meta property="og:image" content={line?.pictures?.m} />
        ) : (
          <meta property="og:image" content={company?.logo?.m} />
        )} */}
        {isAdmin && (
          <SectionStyled as="section" id="cardGone">
            <CardGone
              name={line?.name}
              date={
                line?.dateFrom?.year &&
                line?.dateTo?.year &&
                `${line?.dateFrom?.year} - ${line?.dateTo?.year}`
              }
              picture={line?.pictures?.m}
              description={
                line?.dateTo?.year &&
                line?.dateFrom?.year &&
                intl.formatMessage(
                  {
                    id: 'lines.descriptionGone',
                  },
                  {
                    name: line?.name,
                    weekendDay: line?.dateTo?.stringDay,
                    numberDay: line?.dateTo?.dateNumberFormat,
                    monthLong: line?.dateTo?.stringMonth,
                    numberYear: line?.dateTo?.year,
                    ages: getDateDifferenceInYears(
                      new Date(line.dateFrom.timeStamp),
                      new Date(line.dateTo.timeStamp)
                    ),
                  }
                )
              }
              rememberBy={line?.rememberedBy}
              rememberByLabel={intl.formatMessage({
                id: 'common.rememberedBy',
              })}
              ceremonyId="id-de-ceremonia"
              ceremonyActionLabel={intl.formatMessage({
                id: 'lines.ceremonyActionLabel',
              })}
              ceremonyAction={line.events?.length ? goToCeremonies : null}
              isOpened
              isAdmin={isAdmin}
              labelShare={<FormattedMessage id="common.actionShareShortLabel" />}
              copyShare={intl.formatMessage(
                {
                  id: 'ceremony.actionShareTextWhatsapp',
                },
                {
                  name: line?.name,
                  url: `${window.location.origin}/${actualLanguage}/lines/${
                    line?.lineId
                  }/${line?.nameUrl?.replaceAll(/[^\w\-\s]/gi, '')}`,
                }
              )}
              labelChangePicture={<FormattedMessage id="common.editPhoto" />}
              handleChangePicture={(file) => {
                handleChangePicture(file);
              }}
              handleSubmit={handleSubmit}
              handleChange={(name, value) => {
                setFormData((prev) => ({ ...prev, [name]: value }));
              }}
              eventEditAction={eventEditAnalytics}
              eventShareAction={eventShareAnalytics}
              labelSave={<FormattedMessage id="common.save" />}
              dateTo={line?.dateTo?.readableDate}
              dateFrom={line?.dateFrom?.readableDate}
              labelName={<FormattedMessage id="common.nameLarge" />}
              labelDateFrom={<FormattedMessage id="common.dateFrom" />}
              labelDateTo={<FormattedMessage id="common.dateTo" />}
              labelRememberBy={<FormattedMessage id="common.rememberedBy" />}
              customColor={company?.style?.primaryColor}
              customTextColor={company?.style?.textColor}
            />
          </SectionStyled>
        )}
        <Container paddingY={3} paddingBottom={8} maxWidth={['400px']}>
          <NotShowingPage actualLanguage={actualLanguage} />
        </Container>
      </Container>
    );
  }

  return (
    <Container paddingY={3} paddingBottom={8} maxWidth={['400px']}>
      <meta id="og-title" property="og:title" content={line?.name} />
      {Object.keys(line)?.length ? (
        <>
          <SectionStyled as="section" id="cardGone">
            <CardGone
              name={line?.name}
              date={
                line?.dateFrom?.year &&
                line?.dateTo?.year &&
                `${line?.dateFrom?.year} - ${line?.dateTo?.year}`
              }
              picture={line?.pictures?.m}
              description={
                line?.dateTo?.year &&
                line?.dateFrom?.year &&
                intl.formatMessage(
                  {
                    id: 'lines.descriptionGone',
                  },
                  {
                    name: line?.name,
                    weekendDay: line?.dateTo?.stringDay,
                    numberDay: line?.dateTo?.dateNumberFormat,
                    monthLong: line?.dateTo?.stringMonth,
                    numberYear: line?.dateTo?.year,
                    ages: getDateDifferenceInYears(
                      new Date(line.dateFrom.timeStamp),
                      new Date(line.dateTo.timeStamp)
                    ),
                  }
                )
              }
              rememberBy={line?.rememberedBy}
              rememberByLabel={intl.formatMessage({
                id: 'common.rememberedBy',
              })}
              ceremonyId="id-de-ceremonia"
              ceremonyActionLabel={intl.formatMessage({
                id: 'lines.ceremonyActionLabel',
              })}
              ceremonyAction={line.events?.length ? goToCeremonies : null}
              isOpened
              isAdmin={isAdmin}
              labelShare={<FormattedMessage id="common.actionShareShortLabel" />}
              copyShare={intl.formatMessage(
                {
                  id: 'ceremony.actionShareTextWhatsapp',
                },
                {
                  name: line?.name,
                  url: `${window.location.origin}/${actualLanguage}/lines/${
                    line?.lineId
                  }/${line?.nameUrl?.replaceAll(/[^\w\-\s]/gi, '')}`,
                }
              )}
              labelChangePicture={<FormattedMessage id="common.editPhoto" />}
              handleChangePicture={(file) => {
                handleChangePicture(file);
              }}
              handleSubmit={handleSubmit}
              handleChange={(name, value) => {
                setFormData((prev) => ({ ...prev, [name]: value }));
              }}
              eventEditAction={eventEditAnalytics}
              eventShareAction={eventShareAnalytics}
              actionButtonShare={() => changeOpenShareMenu()}
              labelSave={<FormattedMessage id="common.save" />}
              dateTo={line?.dateTo?.readableDate}
              dateFrom={line?.dateFrom?.readableDate}
              labelName={<FormattedMessage id="common.nameLarge" />}
              labelDateFrom={<FormattedMessage id="common.dateFrom" />}
              labelDateTo={<FormattedMessage id="common.dateTo" />}
              labelRememberBy={<FormattedMessage id="common.rememberedBy" />}
              customColor={company?.style?.primaryColor}
              customTextColor={company?.style?.textColor}
            />
          </SectionStyled>
          <SectionStyled as="section" id="cardGone">
            <Flex
              justifyContent="center"
              paddingTop="25px"
              flexDirection="column"
              style={{ flexFlow: 'nowrap' }}
              width="100%"
              maxWidth="339px"
            >
              <Button
                id="addNewBadge"
                backgroundColor={company?.style?.primaryColor}
                colorType={company?.style?.primaryColor || 'orange'}
                color={company?.style?.textColor}
                sizeButton="big"
                action={addNewBadgeAction}
                width="100%"
              >
                <Icon marginRight={3} sizeIcon="display24">
                  <IconWrite />
                </Icon>
                <FormattedMessage id="messages.writeYourMessage" />
              </Button>
            </Flex>
          </SectionStyled>
          <SectionStyled as="section" id="messagesZone">
            <HeaderSectionStyled>
              <Text
                as="h3"
                sizeText="display24"
                color={company?.style?.primaryColor || 'orange'}
                fontWeight="bold"
                textAlign="center"
              >
                <FormattedMessage id="messages.published" />
              </Text>
            </HeaderSectionStyled>
            {badges?.length ? (
              badges?.slice(0, 2).map((element) => (
                <CardBadges
                  key={element.id}
                  id={element.id}
                  isAdmin={isAdmin || element.author.id == user?.id}
                  actionRemoveContent={() => onRemoveContentModel(element.id)}
                  contentIcon={element.contentIcon}
                  contentColor={company?.style?.primaryColor || element.contentColor}
                  authorName={intl.formatMessage(
                    {
                      id: 'messages.from',
                    },
                    {
                      name: (
                        <Text
                          as="span"
                          color={company?.style?.primaryColor || element?.contentColor}
                          fontWeight="bold"
                          sizeText="display13"
                          textStyle="uppercase"
                        >
                          {element.author?.nameUppercase}
                        </Text>
                      ),
                    }
                  )}
                  text={element.text}
                  picture={element.pictures?.m}
                  audio={element.audio}
                  actionLike={() => onClickLike(element.id)}
                  numLikes={
                    element.numLikes === 'undefined' || !element.numLikes ? '0' : element.numLikes
                  }
                  hasLiked={element.hasLiked}
                />
              ))
            ) : (
              <>
                <NoMessagesSection customColor={company?.style?.primaryColor || 'green'} />
                <Text
                  textAlign="center"
                  marginBottom={2}
                  marginTop={2}
                  sizeText="display14"
                  color="#5B565A"
                >
                  <FormattedMessage id="badge.emptyLabel" />
                </Text>
              </>
            )}
            {isShowMoreMessages && badges?.length > 2 && (
              <>
                {badges?.slice(2, badges?.length).map((element) => (
                  <CardBadges
                    key={element.id}
                    contentIcon={element.contentIcon}
                    contentColor={company?.style?.primaryColor || element.contentColor}
                    id={element.id}
                    isAdmin={isAdmin || element.author.id == user?.id}
                    actionRemoveContent={() => onRemoveContentModel(element.id)}
                    authorName={intl.formatMessage(
                      {
                        id: 'messages.from',
                      },
                      {
                        name: (
                          <Text
                            as="span"
                            color={company?.style?.primaryColor || element?.contentColor}
                            fontWeight="bold"
                            sizeText="display13"
                            textStyle="uppercase"
                          >
                            {element.author?.nameUppercase}
                          </Text>
                        ),
                      }
                    )}
                    text={element.text}
                    picture={element.pictures?.m}
                    audio={element.audio}
                    actionLike={() => onClickLike(element.id)}
                    numLikes={
                      element.numLikes === 'undefined' || !element.numLikes ? '0' : element.numLikes
                    }
                    hasLiked={element.hasLiked}
                  />
                ))}
              </>
            )}
            {!isShowMoreMessages && badges?.length > 2 && (
              <Flex
                justifyContent="center"
                paddingTop={1}
                paddingBottom={2}
                flexDirection="column"
                style={{ flexFlow: 'nowrap' }}
              >
                <Button colorType="black" sizeButton="small" action={showMoreMessagesAction}>
                  <FormattedMessage id="messages.showMoreLabel" />
                </Button>
              </Flex>
            )}
          </SectionStyled>
          <SectionStyled as="section" id="familyZone">
            <HeaderSectionStyled>
              <Text
                as="h3"
                sizeText="display24"
                color={company?.style?.primaryColor || 'blue'}
                fontWeight="bold"
                textAlign="center"
              >
                <FormattedMessage id="familyZone.title" />
              </Text>
            </HeaderSectionStyled>
            {line?.userGroup < 2 && (
              <>
                {line?.permissionType === 1 && (
                  <Alert
                    title={intl.formatMessage({ id: 'lines.titleAlertInvitation' })}
                    message={intl.formatMessage({ id: 'lines.descriptionAlertInvitation' })}
                    marginBottom={3}
                  />
                )}
                <FamilyHomeWithoutAccessSection
                  userGroup={line.permissionType}
                  accessDescription={intl.formatMessage(
                    {
                      id: 'familyZone.accessDescription',
                    },
                    {
                      name: line?.name,
                    }
                  )}
                  actionGoToFamilyZone={goToFamilyZone}
                  actionAccessLabel={intl.formatMessage(
                    {
                      id: 'familyZone.actionAccessLabel',
                    },
                    {
                      name: line?.name,
                    }
                  )}
                  disabled={disabledFamilyHomeWithoutAccess}
                  customColor={company?.style?.primaryColor}
                  customTextColor={company?.style?.textColor}
                />
                {totalParticipants?.length > 0 ? (
                  <Text
                    as="p"
                    sizeText="display14"
                    color="green"
                    fontWeight="medium"
                    style={{ display: 'inline-block', cursor: 'pointer' }}
                    onClick={() => showModalParticipants()}
                  >
                    {totalParticipants?.length}{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', marginTop: '10px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                ) : (
                  <Text as="p" sizeText="display14" color="green" fontWeight="medium">
                    0{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                )}
              </>
            )}
            {line.userGroup >= 2 && !line.content[line.content?.show]?.length && (
              <>
                <FamilyHomeWithoutContentSection
                  line={{
                    content: line?.content?.contentGrid,
                    userGroup: line?.userGroup,
                  }}
                  title={<FormattedMessage id="familyZone.title" />}
                  actionQuoteLabel={<FormattedMessage id="common.actionQuoteLabel" />}
                  actionInviteLabel={<FormattedMessage id="familyZone.actionInviteLabel" />}
                  actionVideoLabel={<FormattedMessage id="common.actionVideoLabel" />}
                  actionPictureLabel={<FormattedMessage id="common.actionPictureLabel" />}
                  showActionVideo={false}
                  showActionQuote={false}
                  showActionPicture
                  noContentDescription={
                    <FormattedMessage
                      id="familyZone.noContentDescription"
                      values={{
                        name: line?.name,
                      }}
                    />
                  }
                  actionVideo={() => {
                    goToAddContent('video');
                  }}
                  actionPicture={() => {
                    goToAddContent('image');
                  }}
                  actionInvite={() => onInviteToLine()}
                  actionQuote={() => {
                    goToAddContent('text');
                  }}
                  customColor={company?.style?.primaryColor}
                  customTextColor={company?.style?.textColor}
                />
                {totalParticipants?.length > 0 ? (
                  <Text
                    as="p"
                    sizeText="display14"
                    color="green"
                    fontWeight="medium"
                    style={{ display: 'inline-block', cursor: 'pointer' }}
                    onClick={() => showModalParticipants()}
                  >
                    {totalParticipants?.length}{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', marginTop: '10px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                ) : (
                  <Text as="p" sizeText="display14" color="black84" fontWeight="medium">
                    0{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                )}
              </>
            )}
            {line.userGroup >= 2 && line.content[line.content?.show]?.length && (
              <>
                <FamilyHomeWithContentSection
                  line={{
                    content: gridWithYears,
                    userGroup: line?.userGroup,
                  }}
                  title={<FormattedMessage id="familyZone.title" />}
                  actionQuoteLabel={<FormattedMessage id="common.actionQuoteLabel" />}
                  actionInviteLabel={<FormattedMessage id="familyZone.actionInviteLabel" />}
                  actionVideoLabel={<FormattedMessage id="common.actionVideoLabel" />}
                  actionPictureLabel={<FormattedMessage id="common.actionPictureLabel" />}
                  showActionVideo={false}
                  showActionQuote={false}
                  showActionPicture
                  noContentDescription={
                    <FormattedMessage
                      id="familyZone.noContentDescription"
                      values={{
                        name: line?.name,
                      }}
                    />
                  }
                  actionVideo={() => {
                    goToAddContent('video');
                  }}
                  actionPicture={() => {
                    goToAddContent('image');
                  }}
                  actionInvite={() => onInviteToLine()}
                  actionQuote={() => {
                    goToAddContent('text');
                  }}
                  customColor={company?.style?.primaryColor}
                  customTextColor={company?.style?.textColor}
                />
                {totalParticipants?.length > 0 ? (
                  <Text
                    as="p"
                    sizeText="display14"
                    color="green"
                    fontWeight="medium"
                    style={{ display: 'inline-block', cursor: 'pointer' }}
                    onClick={() => showModalParticipants()}
                  >
                    {totalParticipants?.length}{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', marginTop: '10px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                ) : (
                  <Text as="p" sizeText="display14" color="black84" fontWeight="medium">
                    0{' '}
                    <Text
                      as="p"
                      sizeText="display14"
                      color="black84"
                      fontWeight="medium"
                      style={{ marginBottom: '50px', display: 'inline-block' }}
                    >
                      <FormattedMessage id="familyZone.participants" />
                    </Text>
                  </Text>
                )}
              </>
            )}
          </SectionStyled>
        </>
      ) : (
        <Flex>{!loading.show ? <Loader customColor={company?.style?.primaryColor} /> : null}</Flex>
      )}
      {isEmptyLines && line.done && (
        <SectionStyled>
          <HeaderSectionStyled>
            <Text as="h3" sizeText="display24" color="red" fontWeight="bold" textAlign="center">
              <FormattedMessage id="lines.thereIsNoLineAvailable" />
            </Text>
          </HeaderSectionStyled>
        </SectionStyled>
      )}
    </Container>
  );
};

LineTemplate.defaultProps = {
  line: [],
};
