export const constants = {
  GET_COOKIES: 'GET_COOKIES',
  GET_COOKIES_SUCCESS: 'GET_COOKIES_SUCCESS',
  GET_COOKIES_FAIL: 'GET_COOKIES_FAIL',
};

export const services = {
  getCookies: (lang) => ({
    route: `/api/v2/legals/legal-terms?lang=${lang}&title=cookies`,
    method: 'get',
  }),
};
