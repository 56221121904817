import { Button, ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconQuote from '@lideralia/alife-uikit/dist/atoms/Icons/IconQuote';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Input } from '@lideralia/alife-uikit/dist/atoms/Input';
import { Container, Flex, Grid, GridItem } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Radiobox } from '@lideralia/alife-uikit/dist/atoms/Radiobox';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const TextContentTemplate = ({
  line,
  handleChange,
  handleSubmit,
  itemsTab,
  content,
  haveContent,
  type,
  setType,
  maxLength,
  setMaxLength,
  customColor,
  customTextColor,
  haveErrorContent,
  setHaveErrorContent,
}) => {
  const [desactivateButton, setDesactivateButton] = useState(false);
  const intl = useIntl();
  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" justifyContent="center" marginBottom={2} width="100%">
        <CardGone
          name={line?.name}
          date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
          picture={line?.pictures?.m}
          customColor={customColor}
        />
      </Flex>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" paddingBottom={7}>
        <Card padding={3} maxWidth={339} minWidth={290}>
          <Flex marginBottom={4} alignItems="center" justifyContent="center">
            <ButtonTabs items={itemsTab} />
          </Flex>
          <Grid
            gridTemplateAreas="'icon title' 'icon description' 'switchType switchType' 'contentText contentText' 'authorInput authorInput' 'action action'"
            gridTemplateColumns="auto 1fr"
            gridAutoRows="auto"
            gridGap={2}
          >
            <GridItem gridArea="icon">
              <Icon color={customColor || 'blue'} sizeIcon="display24">
                <IconQuote />
              </Icon>
            </GridItem>
            <GridItem gridArea="title">
              <Text sizeText="display16" fontWeight="bold">
                <FormattedMessage id="common.shareQuoteOrWriting" />
              </Text>
            </GridItem>
            <GridItem gridArea="description">
              <Text sizeText="display14" color="black84" fontWeight="medium">
                <FormattedMessage id="common.writeInspiringText" />
              </Text>
            </GridItem>
            <GridItem gridArea="switchType">
              <Flex>
                <Radiobox
                  colorType="blue"
                  items={[
                    {
                      defaultChecked: type === 'text',
                      defaultValue: 'Texto propio',
                      hasError: false,
                      id: 'text',
                      isDisabled: false,
                      label: intl.formatMessage({ id: 'common.ownText' }),
                      name: 'type',
                    },
                    {
                      defaultChecked: type === 'quote',
                      defaultValue: 'Cita célebre',
                      hasError: false,
                      id: 'quote',
                      isDisabled: false,
                      label: intl.formatMessage({ id: 'common.famousQuote' }),
                      name: 'type',
                    },
                  ]}
                  name="type"
                  onBlur={() => {}}
                  marginRight={1}
                  onChange={(label, id) => {
                    setType(id);
                    setMaxLength(type === 'quote' ? 280 : 140);
                  }}
                  padding={10}
                />
              </Flex>
            </GridItem>
            <GridItem gridArea="contentText">
              <Field
                label={intl.formatMessage({ id: 'common.text' })}
                color="black60"
                fontWeight="bold"
                sizeText="display16"
                marginTop={0}
                zIndex={0}
              >
                <Textarea
                  counter
                  name="caption"
                  id="caption"
                  placeholderMessage={intl.formatMessage({ id: 'badge.writeMessageLabel' })}
                  typeAction={(id, caption, hasError) => {
                    if (type === 'quote') {
                      setHaveErrorContent(hasError);
                    }
                    handleChange(id, caption);
                  }}
                  defaultValue={content?.caption}
                  maxLength={maxLength}
                />
              </Field>
            </GridItem>
            {type === 'quote' && (
              <GridItem gridArea="authorInput">
                <Field
                  label={
                    <Text sizeText="display14" fontWeight="bold">
                      <FormattedMessage id="common.author" />{' '}
                      <Text sizeText="display14" as="span">
                        (<FormattedMessage id="common.optional" />)
                      </Text>
                    </Text>
                  }
                  color="black60"
                  fontWeight="bold"
                  sizeText="display16"
                  marginTop={0}
                  zIndex={0}
                >
                  <Input
                    name="content_creator"
                    id="content_creator"
                    sizeInput="big"
                    placeholderMessage={intl.formatMessage({
                      id: 'badge.writeTheAuthorOfTheQuote',
                    })}
                    onChange={(id, url) => handleChange(id, url)}
                    defaultValue={content?.contentCreator}
                    maxLength="30"
                    counter
                  />
                </Field>
              </GridItem>
            )}
            <GridItem gridArea="action">
              <Button
                backgroundColor={customColor}
                colorType={customColor || 'blue'}
                color={customTextColor}
                fullWidth
                sizeButton="big"
                disabled={!haveContent || haveErrorContent || desactivateButton}
                type="submit"
                action={() => {
                  setDesactivateButton(true);
                  handleSubmit();
                  setTimeout(() => {
                    setDesactivateButton(false);
                  }, 3000);
                }}
              >
                <Icon sizeIcon="display24" marginRight={2}>
                  <IconWrite />
                </Icon>
                <FormattedMessage id="common.shareYourText" />
              </Button>
            </GridItem>
          </Grid>
        </Card>
      </Flex>
    </Container>
  );
};

TextContentTemplate.defaultProps = {
  line: [],
};
