import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RgpdPage } from '../../../pages/rgpd';

export const routes = (rgpdPath) => ({
  path: rgpdPath,
  pages: {
    rgpd: {
      path: rgpdPath,
    },
    rgpdAuth: {
      path: `${rgpdPath}/:authCode`,
    },
  },
});

export const RgpdRoutes = ({ match }) => {
  const applicationRoutes = routes(match.url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.rgpd.path} component={RgpdPage} exact />
      <Route path={applicationRoutes.pages.rgpdAuth.path} component={RgpdPage} exact />
      <Redirect to={applicationRoutes.path} />
    </Switch>
  );
};

RgpdRoutes.propTypes = {};
