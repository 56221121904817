import { ModalRedirectToRegister } from '@lideralia/alife-uikit/dist/organisms/Modals/RedirectToRegister';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { actualLanguage } from '../../functions/lang';
import { showModalRedirectToRegister } from '../../schemes/redirect_to_register/actions';

const TIMEOUT = 5000; // 5 segundos

// Las rutas que se quiere que se muestre el modal de redirigir a registro
const routesWithModal = ['/lines'];

export function RedirectToRegister() {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const { show: showCookies } = useSelector((state) => state.showCookies);
  const { show, firstTimeOpened } = useSelector((state) => state.redirectToRegister);
  // mirar en user.data si esta vacio
  const isLoggedIn = user?.data ? Object.keys(user?.data).length !== 0 : false;
  const isShowLogin = useSelector((state) => state.showLogin);
  const isShowLoginRef = useRef(isShowLogin);

  const {
    active: { company },
  } = line;

  useEffect(() => {
    isShowLoginRef.current = isShowLogin;
  }, [isShowLogin]);

  useEffect(() => {
    const normalizedPath = pathname.replace(/^\/[a-z]{2}\//, '/');
    const shouldShowModal = routesWithModal.some((route) => normalizedPath.startsWith(route));

    if (isLoggedIn || !shouldShowModal || !firstTimeOpened || showCookies) {
      return undefined;
    }

    let timer;

    const checkAndShowModal = () => {
      if (!isShowLoginRef.current.showRegister && !isShowLoginRef.current.showLogin && !isShowLoginRef.current.show) {
        dispatch(showModalRedirectToRegister(true));
      } else {
        timer = setTimeout(checkAndShowModal, TIMEOUT);
      }
    };

    // Iniciar el primer timeout
    timer = setTimeout(checkAndShowModal, TIMEOUT);

    return () => clearTimeout(timer);

  }, [isLoggedIn, pathname, showCookies]);


  return (
    <ModalRedirectToRegister
      isOpen={show}
      onModalBack={() => {
        dispatch(showModalRedirectToRegister(false));
      }}
      onModalClose={() => {
        dispatch(showModalRedirectToRegister(false));
      }}
      title={
        <FormattedMessage
          id="lines.titleAlertInvitation"
          values={{
            b: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      }
      titleBody={
        <FormattedMessage
          id="lines.weCanNotifyYouOfScheduleChanges"
          values={{
            b: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      }
      backgroundColor={company?.style?.primaryColor}
      colorType={company?.style?.primaryColor || 'orange'}
      color={company?.style?.textColor}
      actionButton={() => {
        dispatch(showModalRedirectToRegister(false));
        history.push(`/${actualLanguage}/register`);
      }}
      textActionButton={<FormattedMessage id="register.action" />}
    />
  );
}
