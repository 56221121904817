import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Grid } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { LinkText, Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Modal } from '@lideralia/alife-uikit/dist/molecules/Modal';
import { ModalLegal } from '@lideralia/alife-uikit/dist/organisms/Modals/Legal';
import { ModalRegister } from '@lideralia/alife-uikit/dist/organisms/Modals/Register';
import parse from 'html-react-parser';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import { resetErrorLogin } from '../../login/actions';
import { LoginPasscodeTemplate } from '../../login/template';

export const RegisterTemplate = ({
  isOpened,
  isOpen,
  isOpenPass,
  user,
  authCode,
  error,
  setDataUser,
  dataUser,
  prefixData,
  options,
  setPrefixData,
  onSubmitRegister,
  onChangeNumber,
  onModalBack,
  passtroughDone,
  history,
  showLogin,
  hideModalLogin,
  setVerifyRegisterAPI,
  lastLineHaveCompanyOption,
  companyOption,
  setShowConditions,
  setShowPrivacy,
  showConditions,
  showPrivacy,
  dataConditions,
  dataPrivacy,
  backWithButton,
  prefixDataLoaded,
  seconds,
  hasWrongCodeError,
  onReset,
}) => {
  const tokenCaptcha =
    process.env.REACT_APP_TOKEN_RECAPTCHA_V3 || env?.REACT_APP_TOKEN_RECAPTCHA_V3;
  const intl = useIntl();
  const dispatch = useDispatch();
  const search = useSearchParams();
  const queryRedirect = useMemo(() => search.get('redirect'), [search]);

  useEffect(() => {
    if (error?.message === 'error.phone_used_by_somebody_else') {
      if (showLogin) {
        hideModalLogin();
        showLogin();
      } else {
        if (hideModalLogin) hideModalLogin();
        dispatch(resetErrorLogin());
        if (
          dataUser.referrer_line_company_optin !== undefined &&
          dataUser.referrer_line_company_optin
        ) {
          if (queryRedirect) {
            history?.push(
              `/${actualLanguage}/login?phone=${dataUser.phoneNumber}&prospect=${dataUser.referrer_line_company_optin}&redirect=${queryRedirect}`
            );
          } else {
            history?.push(
              `/${actualLanguage}/login?phone=${dataUser.phoneNumber}&prospect=${dataUser.referrer_line_company_optin}`
            );
          }
        } else if (queryRedirect) {
          history?.push(
            `/${actualLanguage}/login?phone=${dataUser.phoneNumber}&redirect=${queryRedirect}`
          );
        } else {
          history?.push(`/${actualLanguage}/login?phone=${dataUser.phoneNumber}`);
        }
      }
    }
  }, [error?.message, showLogin, hideModalLogin, history]);

  const buttonRedirectToLogin = (e) => {
    e.preventDefault();
    if (showLogin) {
      hideModalLogin();
      showLogin();
    } else {
      if (hideModalLogin) hideModalLogin();
      if (queryRedirect) {
        history?.push(`/${actualLanguage}/login?redirect=${queryRedirect}`);
      } else {
        history?.push(`/${actualLanguage}/login`);
      }
    }
  };

  return (
    <>
      {!isOpened && !authCode && tokenCaptcha && !passtroughDone && (
        <>
          <ModalRegister
            isOpen={isOpen && !showConditions && !showPrivacy}
            id="alife-register"
            idPhone="phoneNumber"
            idName="name"
            tokenCaptcha={tokenCaptcha}
            hasError={error}
            lastLineHaveCompanyOption={lastLineHaveCompanyOption}
            labelCheckCompanyOption={
              lastLineHaveCompanyOption ? companyOption : ''
            }
            errorMessage={intl.formatMessage({ id: error?.message || 'error.generic' })}
            title={<FormattedMessage id="register.title" defaultMessage="Regístrate" />}
            labelName={intl.formatMessage({ id: 'common.namePlaceholder' })}
            labelPhone={intl.formatMessage({ id: 'common.phone' })}
            fieldPhone={intl.formatMessage({ id: 'common.phoneDisclaimer' })}
            fieldName={intl.formatMessage({ id: 'common.name' })}
            idCheckInput="gdprAccepted"
            description={<FormattedMessage id="register.description" />}
            labelCheckInput={
              <FormattedMessage
                id="rgpd.labelCheckInput"
                values={{
                  policy: (
                    <LinkText
                      color="black60"
                      sizeText="display13"
                      underline
                      onClick={() => setShowPrivacy(true)}
                    >
                      <FormattedMessage id="rgpd.policies" />
                    </LinkText>
                  ),
                  conditions: (
                    <LinkText
                      color="black60"
                      sizeText="display13"
                      underline
                      onClick={() => setShowConditions(true)}
                    >
                      <FormattedMessage id="rgpd.conditions" />
                    </LinkText>
                  ),
                }}
              />
            }
            actionInput={(name, value) => {
              setDataUser({
                ...dataUser,
                [name]: value,
              });
            }}
            prefix={
              prefixDataLoaded && {
                name: 'Prefix',
                placeholderMessage: (
                  <FormattedMessage id="common.placeholderPrefix" defaultMessage="Prefijo" />
                ),
                id: 'prefix',
                defaultValue: prefixData,
                options,
                action: (name, value) => {
                  setPrefixData(value);
                },
              }
            }
            actionButton={{
              label: <FormattedMessage id="register.action" defaultMessage="Regístrate" />,
              action: onSubmitRegister,
            }}
            footer={{
              label: (
                <FormattedMessage
                  id="register.labelFooter"
                  defaultMessage="¿Ya tienes cuenta en Alife?"
                />
              ),
              action: {
                label: <FormattedMessage id="login.title" />,
                to: buttonRedirectToLogin,
              },
            }}
            onModalClose={hideModalLogin}
            onModalBack={onModalBack}
            backWithButton={backWithButton}
            dataUser={dataUser}
          />
          <ModalLegal
            isOpen={showPrivacy}
            id="alife-privacy"
            title={<FormattedMessage id="profile.personalData.privacy" />}
            content={dataPrivacy ? parse(dataPrivacy) : null}
            onModalClose={() => setShowPrivacy(false)}
          />
          <ModalLegal
            isOpen={showConditions}
            id="alife-conditions"
            title={<FormattedMessage id="profile.personalData.generalConditions" />}
            content={dataConditions ? parse(dataConditions) : null}
            onModalClose={() => setShowConditions(false)}
          />
        </>
      )}
      {passtroughDone && (
        <>
          <LoginPasscodeTemplate
            isOpen={isOpenPass}
            id="alife-register-verify"
            title={<FormattedMessage id="passCode.title" />}
            hasError={!!error}
            errorMessage={
              error?.message && (
                <FormattedMessage id={error?.message} defaultMessage="Invalid phone number" />
              )
            }
            description={
              <FormattedMessage
                id="passCode.labelPassCode"
                values={{
                  phoneNumber: user?.data?.phone_number,
                }}
              />
            }
            actionInput={(name, value) => {
              setDataUser({
                ...dataUser,
                [name]: value,
              });
            }}
            labelRetry={intl.formatMessage({ id: 'passCode.labelRetry' })}
            labelRetryAction={<FormattedMessage id="passCode.labelRetryAction" />}
            labelChange={<FormattedMessage id="passCode.labelChange" />}
            labelCountDown={intl.formatMessage({ id: 'passCode.labelCountDown' })}
            labelChangeAction={<FormattedMessage id="passCode.labelChangeAction" />}
            onSubmit={(confirmCode) => {
              setVerifyRegisterAPI({
                ...dataUser,
                confirmCode,
              });
            }}
            actionRetry={(token) => onSubmitRegister(true, token)}
            actionChange={onChangeNumber}
            tokenCaptcha={tokenCaptcha}
            onModalClose={() => {
              history.go(0);
            }}
            onModalBack={() => {
              history.go(0);
            }}
            seconds={seconds}
            hasWrongCodeError={hasWrongCodeError}
            onReset={onReset}
          />
        </>
      )}
      {!isOpened && authCode && <Loader />}
      {isOpened && authCode && (
        <Modal width="339px" isOpen={isOpened} onModalClose={() => {}}>
          <Modal.Content>
            <Text sizeText="display16" textAlign="center">
              <FormattedMessage
                id="register.closeUserTitle"
                values={{
                  name: user?.data?.name,
                }}
              />
            </Text>
            <Text sizeText="display16" textAlign="center">
              <FormattedMessage id="register.closeUserActionLabel" />
            </Text>
          </Modal.Content>
          <Modal.Actions>
            <Grid gridTemplateColumns="1fr 1fr" gridTemplateRows="1fr" gridColumnGap={3}>
              <Button
                colorType="red"
                id="cancel-button"
                action={() => {
                  if (queryRedirect) {
                    history.push(`${queryRedirect}`);
                  } else {
                    history.push(`/${actualLanguage}/lines`);
                  }
                }}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                colorType="green"
                action={() => {
                  deleteLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME);
                  history.replace(`/${actualLanguage}/rgpd/${authCode}`);
                }}
              >
                <FormattedMessage id="common.accept" />
              </Button>
            </Grid>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
