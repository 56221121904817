import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isAndroid } from '../../../common/functions/devices';
import {
  getGoogleMapWithoutMarker,
  getUrlRedirectMap,
  getUrlRedirectMapOnIos,
} from '../../../common/functions/images';
import { actualLanguage } from '../../../common/functions/lang';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import { showRegister } from '../../../common/schemes/login';
import { getCertificateTree } from '../../../common/schemes/order/actions';
import { getTreeDescription } from '../../../common/schemes/udianas';
import { useLineData } from '../hooks/useLineData';
import { DescriptionTree } from '../template/DescriptionTree';
import HeaderWithForest from '../template/HeaderWithForest';

const DescriptionTreePage = ({ history }) => {
  const { lineDefault, idLineStored, idNameStored, nameDefault } = useLineData();
  const line = useSelector((state) => state.line);
  const udianas = useSelector((state) => state.udianas);
  const user = useSelector((state) => state.user);

  const {
    active,
    active: { company },
  } = line;
  const {
    data: {
      treeDescription,
      treeDescription: { description, order, canDownloadCertificate },
    },
  } = udianas;
  const { idTree } = useParams();
  const intl = useIntl();
  const query = useSearchParams();
  const dispatch = useDispatch();
  const getTreeDescriptionAPI = useCallback(
    (id, udianaUuid) => dispatch(getTreeDescription(id, udianaUuid)),
    [dispatch]
  );
  const getCertificateTreeAPI = useCallback(
    (data) => dispatch(getCertificateTree(data)),
    [dispatch]
  );

  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));
  const udianaUuid = query.get('udiana');

  useEffect(() => {
    // TODO: si ya tengo la descripcion de ese arbol en el store, no hago la peticion
    getTreeDescriptionAPI(idTree, udianaUuid);
  }, [idTree]);

  // get current year
  const year = new Date().getFullYear();
  const dataChart = {
    title: intl.formatMessage({ id: 'udianas.CO2Absorption' }),
    dataSeries: description?.CO2AbsorptionPerYear,
    dataCategories: description?.CO2AbsorptionPerYear?.map((_, index) => year + index),
    color: company?.style?.primaryColor || 'orange',
  };

  const characteristics = useMemo(
    () => ({
      family: description?.family,
      foliageType: description?.foliageType,
      longevity: intl.formatMessage(
        { id: 'udianas.yearsLive' },
        { years: description?.liveSpanInYears || 0 }
      ),
      CO2Compensation: `${description?.totalKgCo2Offsetted || 0} Kg`,
      height: `${description?.heightInMeters || 0} m`,
    }),
    [description]
  );

  const urlMap = useMemo(() => {
    if (isAndroid()) {
      return getUrlRedirectMap({
        lat: description?.latitude,
        lng: description?.longitude,
      });
    }
    return getUrlRedirectMapOnIos({
      lat: description?.latitude,
      lng: description?.longitude,
    });
  }, [description]);

  const pictureMap = getGoogleMapWithoutMarker({
    lat: description?.latitude,
    lng: description?.longitude,
  });

  const goToTrees = () => {
    if (history?.length === 2) {
      // Esto es cuando vienen del whatsapp el link y no tienen historial anterior
      history?.push(
        `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
          nameDefault || idNameStored
        }/udianas`
      );
    } else if (history?.location?.key) {
      history?.goBack();
    } else {
      // Esto es cuando vienen cuando quierer hacer una compra y van al detalle del arbol
      history?.goBack();
      history?.goBack();
    }

    history?.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas`
    );
  };

  const goToTreesWithSelectedThisTree = () => {
    if (!user.done) {
      setShowRegisterState(1);
    } else {
      history.push(
        `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
          nameDefault || idNameStored
        }/udianas/plant-tree?project=${query.get('project')}&type_tree=${idTree}`
      );
    }
  };

  const getCertificate = () => {
    getCertificateTreeAPI({ uuid: order?.uuid });
  };
  return (
    <HeaderWithForest
      height="168px"
      onlyOneText
      titleForestOf={description.name}
      location={description.projectName}
      imagePicture={description?.imageUrl}
    >
      <DescriptionTree
        characteristics={characteristics}
        description={treeDescription?.translations?.description}
        tree={treeDescription?.tree}
        company={company}
        dataChart={dataChart}
        buttonBack={goToTrees}
        urlMap={urlMap}
        pictureMap={pictureMap}
        buttonSelectTree={goToTreesWithSelectedThisTree}
        getCertificate={getCertificate}
        canDownloadCertificate={canDownloadCertificate}
        active={active}
      />
    </HeaderWithForest>
  );
};
export default DescriptionTreePage;
