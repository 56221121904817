import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import {
  getExternalServices,
  getFollowers,
  getLine,
  updateLine,
} from '../../../common/schemes/line';
import { SettingsTemplate } from '../template/settingsTemplate';

const SettingsPage = ({ history }) => {
  const dispatch = useDispatch();
  const [isOpenedFollowerModal, setIsOpenedFollowerModal] = useState(false);
  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const {
    active,
    active: { lineId, company },
  } = line;

  const { idLine, idName } = useParams();

  const getExternalServicesAPI = useCallback(
    (id) => {
      dispatch(getExternalServices(id));
    },
    [dispatch]
  );
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const updateLineAPI = useCallback(
    (id, body) => {
      dispatch(updateLine(id, body));
    },
    [dispatch]
  );
  const getFollowersAPI = useCallback(
    (id, value) => {
      dispatch(getFollowers(id, value));
    },
    [dispatch]
  );
  const onSubmitEditLine = (name, value) => {
    updateLineAPI(lineId, { [name]: (value && 1) || 0 });
  };
  useEffect(() => {
    if (lineId && typeof active?.external === 'undefined') {
      getExternalServicesAPI(active?.lineId);
    }
  }, [lineId]);

  useEffect(() => {
    if (!line.done && !line.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }

    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName]);

  const handleOpenListFollowers = (excludeAdmins) => {
    getFollowersAPI(lineId, excludeAdmins);
    setIsOpenedFollowerModal(true);
  };

  return (
    <SettingsTemplate
      user={user?.data}
      line={active}
      goBack={() => history.goBack()}
      customColor={company?.style?.primaryColor}
      handleSubmit={onSubmitEditLine}
      handleOpenListFollowers={handleOpenListFollowers}
      followers={active?.followers}
      isOpenedFollowerModal={isOpenedFollowerModal}
    />
  );
};

export default SettingsPage;
