import { constants } from '../config';

export const showConfirmation = (show) => ({
  type: constants.SHOW_CONFIRMATION,
  show,
});

export const buttonConfirmation = (action) => ({
  type: constants.BUTTON_CONFIRMATION,
  action,
});

export const locationConfirmation = (action) => ({
  type: constants.LOCATION_CONFIRMATION,
  action,
});

export const idConfirmation = (action) => ({
  type: constants.ID_CONFIRMATION,
  action,
});
