import { Button, Grid, GridItem, Icon, Text } from '@lideralia/alife-uikit';
import { IconTree } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout/Layout';
import { TreeUdianas } from '@lideralia/alife-uikit/dist/molecules/Udianas/TreeUdianas';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HeaderSectionStyled, SectionStyled } from '../styles';

const UdianaTemplate = ({
  showMoreMessagesAction,
  itemsTrees,
  showMoreTrees,
  company,
  customColor,
  customTextColor,
  goToPlantTreeAction,
  goToFaqsAction,
}) => (
  <>
    <Button
      id="plantTree"
      variant="primary"
      sizeButton="medium"
      backgroundColor={customColor}
      colorType={customColor || 'orange'}
      color={customTextColor}
      onClick={goToPlantTreeAction}
    >
      <Icon marginRight={2} sizeIcon="inherit">
        <IconTree />
      </Icon>
      <FormattedMessage id="udianas.PlantACustomTree" />
    </Button>
    <Button
      id="plantTreeFaq"
      variant="primary"
      colorType="black"
      sizeButton="medium"
      color="black"
      onClick={goToFaqsAction}
      marginTop={2}
    >
      <FormattedMessage id="udianas.whyPlantAMemoryTree" />
    </Button>
    <SectionStyled as="section" id="treesInTheirForest">
      <HeaderSectionStyled>
        <Text
          as="h3"
          sizeText="display24"
          color={company?.style?.primaryColor || 'orange'}
          fontWeight="bold"
          textAlign="center"
        >
          <FormattedMessage id="udianas.treesInHisForest" />
        </Text>
      </HeaderSectionStyled>
      <TreesInYourForest
        itemsTrees={itemsTrees}
        showMoreTrees={showMoreTrees}
        showMoreMessagesAction={showMoreMessagesAction}
        company={company}
      />
    </SectionStyled>
  </>
);

const TreesInYourForest = ({ itemsTrees, showMoreTrees, showMoreMessagesAction, company }) =>
  itemsTrees?.length > 0 ? (
    <>
      <Grid gridTemplateColumns="auto 1fr" gridAutoRows="auto" gridGap={2}>
        {itemsTrees?.length > 0
          ? itemsTrees
              .slice(0, showMoreTrees || itemsTrees.length <= 4 ? itemsTrees.length : 4)
              .map((item) => (
                <ItemTreeClosed
                  key={item?.treeProductId}
                  userName={item?.userName || company?.base?.name}
                  clickCardRedirectTo={item?.clickCardRedirectTo}
                  quantityPurchased={item?.quantityPurchased}
                  {...item?.treeProduct}
                />
              ))
          : null}
      </Grid>
      {!showMoreTrees && itemsTrees?.length > 4 ? (
        <Flex
          justifyContent="center"
          paddingTop={2}
          paddingBottom={2}
          flexDirection="column"
          style={{ flexFlow: 'nowrap' }}
        >
          <Button colorType="black" sizeButton="small" action={showMoreMessagesAction}>
            <FormattedMessage id="udianas.seeTheWholeForest" />
          </Button>
        </Flex>
      ) : null}
    </>
  ) : (
    <Text as="p" sizeText="display16">
      <FormattedMessage id="udianas.thereAreNoTreesForestYet" />
    </Text>
  );

const ItemTreeClosed = ({ name, imageUrl, userName, clickCardRedirectTo, quantityPurchased }) => (
  <GridItem>
    <Link to={clickCardRedirectTo}>
      <TreeUdianas
        height="164px"
        title={name}
        paragraphTitle={intl.formatMessage({ id: 'udianas.plantedBy' }, { name: userName })}
        picture={{
          alt: name,
          src: imageUrl,
        }}
        isOpened={false}
        treesPlanted={intl.formatMessage(
          { id: 'udianas.numberOfTreesPlanted' },
          { trees: quantityPurchased }
        )}
      />
    </Link>
  </GridItem>
);

UdianaTemplate.propTypes = {
  showMoreMessagesAction: PropTypes.func.isRequired,
  itemsTrees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      paragraphTitle: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  showMoreTrees: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    style: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
    }),
  }).isRequired,
  customColor: PropTypes.string.isRequired,
  customTextColor: PropTypes.string.isRequired,
  goToPlantTreeAction: PropTypes.func.isRequired,
};

TreesInYourForest.propTypes = {
  itemsTrees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      paragraphTitle: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  showMoreTrees: PropTypes.bool.isRequired,
  showMoreMessagesAction: PropTypes.func.isRequired,
  company: PropTypes.shape({
    base: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

ItemTreeClosed.propTypes = {
  name: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  quantityPurchased: PropTypes.number.isRequired,
  clickCardRedirectTo: PropTypes.string.isRequired,
};

export default UdianaTemplate;
