import { constants } from '../config';

const initialState = {
  data: {},
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
};

export default function pollReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_POLL:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_POLL_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        done: true,
      };
    }
    case constants.GET_POLL_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };

    case constants.SET_POLL:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_POLL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.SET_POLL_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };

    case constants.RESET_POLL:
      return {
        ...state,
        done: false,
        data: {
          lineId: state.data.lineId,
        },
      };
    default:
      return state;
  }
}
