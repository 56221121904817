import React from 'react';
import { Container } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { RequestsTemplate } from './requestsTemplate';
import { NotificationTemplate } from './notificationTemplate';

export const NotificationsViewTemplate = (props) => (
  <Container paddingY={4} paddingBottom={8} minHeight="100vh" {...props}>
    <RequestsTemplate {...props} />
    <NotificationTemplate {...props} />
  </Container>
);
