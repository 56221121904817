import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import {
  deleteBadge,
  followLine,
  getBadges,
  getContents,
  getEvents,
  getLine,
  sendInvitationToLine,
  updateLine,
} from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import { LineTemplate } from '../template';

const LinesPage = ({ history }) => {
  const dispatch = useDispatch();
  const { idLine, idName } = useParams();

  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const lines = useSelector((state) => state.lines);
  const {
    active,
    active: { company },
  } = line;
  const { all } = lines;

  const isShowLogin = useSelector((state) => state.showLogin);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);

  const [isShowMoreMessages, setIsShowMessages] = useState(false);
  const [disabledFamilyHomeWithoutAccess, setDisabledFamilyHomeWithoutAccess] = useState(false);
  const [lineDefault, setLineDefault] = useState(idLine || idLineStored);
  const [nameDefault, setNameDefault] = useState(idName || idNameStored);
  const [formData, setFormData] = useState({});
  const [isEmptyLines, setEmptyLines] = useState(false);

  const updateLineAPI = useCallback(
    (id, body) => {
      dispatch(updateLine(id, body));
    },
    [dispatch]
  );

  const getEventsAPI = useCallback((lineId) => dispatch(getEvents(lineId)), [dispatch]);
  const sendInvitationToLineAPI = useCallback(
    (id, name) => dispatch(sendInvitationToLine(id, name)),
    [dispatch]
  );

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getBadgesAPI = useCallback((lineId) => dispatch(getBadges(lineId)), [dispatch]);
  const getContentsAPI = useCallback((lineId) => dispatch(getContents(lineId)), [dispatch]);
  const followLineAPI = useCallback(
    (id, requestId) => dispatch(followLine(id, requestId)),
    [dispatch]
  );
  const deleteBadgeAPI = useCallback((id) => dispatch(deleteBadge(id)), [dispatch]);
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)), [dispatch]);

  useEffect(() => {
    if (idLine && idName) {
      if (!line.done && !line.loading) {
        getLineAPI(idLine, idName);
      } else if (line.done && line.active?.lineId !== idLine && line.active?.nameUrl !== idName) {
        getLineAPI(idLine, idName);
      }
    }
    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.replace(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }

    if (!line.done && !idLine && !idName && !idLineStored && !idNameStored) {
      setEmptyLines(true);
    }
  }, [user, getLineAPI, idLine, idName, idLineStored, idNameStored, line]);

  useEffect(() => {
    if (!active?.badges && idLine) {
      getBadgesAPI(idLine);
    }
  }, [active?.badges, idLine]);

  useEffect(() => {
    if (!active?.content && idLine) {
      getContentsAPI(idLine);
    }
  }, [active?.content, idLine]);

  useEffect(() => {
    if (
      idLine &&
      (typeof active?.events === 'undefined' ||
        Object.keys(active?.events).length === 0 ||
        line?.active?.events[0].eventLineId !== idLine)
    ) {
      getEventsAPI(idLine);
    }
  }, [idLine]);

  useEffect(() => {
    if (lineDefault || nameDefault) {
      if (line?.done && line?.active?.lineId !== lineDefault) {
        getContentsAPI(idLine);
        getBadgesAPI(idLine);
        getLineAPI(lineDefault, nameDefault);
        history.replace(
          `/${actualLanguage}/lines/${lineDefault || idLineStored}/${nameDefault || idNameStored}`
        );
      }
    }
  }, [lineDefault, nameDefault, getLineAPI]);

  useEffect(() => {
    if (isEmptyLines && active?.lineId) {
      setEmptyLines(false);
    }
  }, [active]);

  useEffect(() => {
    if (idLine !== lineDefault) {
      setLineDefault(idLine);
    }
    if (idName !== nameDefault) {
      setNameDefault(idName);
    }
  }, [idLine, idName]);

  const onShowMoreMessages = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.MORE_MESSAGES_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    setIsShowMessages(!isShowMoreMessages);
  };

  const handleChangePicture = (file) => {
    setFormData((prev) => ({
      ...prev,
      file64: file?.base64,
    }));
  };

  const goToCeremony = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.VIEW_CEREMONY_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/ceremony`
    );
  };

  const goToAddBadge = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/book/write`
    );
  };

  const goToFamilyZone = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.ACCESS_TO_FAMILY_ZONE_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );

    if (user.done) {
      setDisabledFamilyHomeWithoutAccess(true);
      followLineAPI(idLine);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  useEffect(() => {
    if (line?.done || line?.error) {
      setDisabledFamilyHomeWithoutAccess(false);
    }
  }, [line]);

  const goToAddContent = (type, idContent) => {
    setEventAnalytics(
      KEYS_ANALYTICS.UPLOAD_A_MOMENT_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/add/${type}${idContent ? `/${idContent}` : ''}`
    );
  };

  const onInviteToLine = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.INVITE_TO_FAMILY_ZONE_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    if (idLine) {
      sendInvitationToLineAPI(idLine, active.name);
    }
  };

  const onRemoveContentAction = (idBadge) => {
    deleteBadgeAPI(idBadge);
  };

  const onSubmitEditLine = () => {
    updateLineAPI(line?.active?.lineId, formData);
  };

  return active?.content && active?.badges && active?.events ? (
    <LineTemplate
      isEmptyLines={isEmptyLines}
      existLine={!!all.length}
      countLines={all.length}
      line={active}
      handleChangePicture={(file) => handleChangePicture(file)}
      isShowMoreMessages={isShowMoreMessages}
      showMoreMessagesAction={onShowMoreMessages}
      addNewBadgeAction={goToAddBadge}
      goToFamilyZone={goToFamilyZone}
      disabledFamilyHomeWithoutAccess={disabledFamilyHomeWithoutAccess}
      goToCeremonies={goToCeremony}
      onInviteToLine={onInviteToLine}
      onRemoveContentAction={onRemoveContentAction}
      goToAddContent={goToAddContent}
      isAdmin={active?.userGroup > 2}
      user={user.data}
      setFormData={setFormData}
      handleSubmit={onSubmitEditLine}
      company={company}
      eventShareAnalytics={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.SHARE_CEREMONY_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
      eventEditAnalytics={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.EDIT_LINE_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
    />
  ) : (
    <Loader customColor={line?.active?.company?.style?.primaryColor} />
  );
};

export default LinesPage;
