import Axios from 'axios';
import { getLocalStorage } from '../../cli/common/functions/cookies';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_V2_URL_BASE || env?.REACT_APP_V2_URL_BASE,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line global-require
  const { store } = require('../store'); // se importa aquí para evitar el error de dependencia cíclica
  const { line } = store.getState();
  const referrerLineId = line?.active?.lineId || getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(referrerLineId && { 'X-Referrer-LineId': referrerLineId }),
    },
  };
}, (error) => Promise.reject(error));

export default axios;