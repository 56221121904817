export const constants = {
  SHOW_PARTICIPANTS: 'SHOW_PARTICIPANTS',
  GET_PARTICIPANTS: 'GET_PARTICIPANTS',
  GET_PARTICIPANTS_SUCCESS: 'GET_PARTICIPANTS_SUCCESS',
  GET_PARTICIPANTS_FAIL: 'GET_PARTICIPANTS_FAIL',
  PUT_PARTICIPANTS: 'PUT_PARTICIPANTS',
  PUT_PARTICIPANTS_SUCCESS: 'PUT_PARTICIPANTS_SUCCESS',
  PUT_PARTICIPANTS_FAIL: 'PUT_PARTICIPANTS_FAIL',
  PUT_PARTICIPANTS_TO_ADMIN: 'PUT_PARTICIPANTS_TO_ADMIN',
  REMOVE_PARTICIPANTS_ADMIN: 'REMOVE_PARTICIPANTS_ADMIN',
};

export const services = {
  getParticipantsAndAdmins: (lineId) => ({
    route: `/api/v2/lines/${lineId}/participants-and-admins`,
    method: 'get',
  }),

  putParticipantsAndAdmins: (data) => ({
    route: `/api/v2/lines/${data.line_uuid}/participants-and-admins/${data.user_uuid}/user-follows-line`,
    method: 'put',
    body: data,
  }),

  putParticipantsToAdmin: ({ idLine, userId }) => ({
    route: `/api/v2/lines/${idLine}/participants-and-admins/${userId}/add-admin`,
    method: 'put',
  }),

  removeParticipantsAdmin: ({ idLine, userId }) => ({
    route: `/api/v2/lines/${idLine}/participants-and-admins/${userId}/remove-admin`,
    method: 'put',
  }),
};
