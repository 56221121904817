import { actualLanguage } from '../../../common/functions/lang';

export const MapperVerifyLogin = {
  dehydrate: (data) => ({
    confirm_code: data?.confirmCode,
    country_code: data?.countryCode,
    phone_number: data?.phoneNumber,
    gdpr_accepted: data?.gdprAccepted ? 1 : 0,
    name: data?.name,
    referrer_line_company_optin: data?.referrer_line_company_optin || false,
    language: actualLanguage,
  }),
};
