export const ProductCategoryMapper = {
  hydrate({ id, slug, created_at, updated_at, products, name, ...data }) {
    return {
      id,
      slug: typeof slug === 'string' ? slug : '',
      created_at,
      updated_at,
      products: products?.map((el) => ProductsCategoryMapper.hydrate(el)),
      name: typeof name === 'string' ? name : '',
      ...data,
    };
  },
};

export const ProductsCategoryMapper = {
  hydrate({ uuid, ...data }) {
    return {
      id: uuid,
      uuid,
      ...data,
    };
  },
};
