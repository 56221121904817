import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import CeremonyHome from '@lideralia/alife-uikit/dist/atoms/imgs/CeremonyHome';
import FamilyZoneHome from '@lideralia/alife-uikit/dist/atoms/imgs/FamilyZoneHome';
import FlowerHome from '@lideralia/alife-uikit/dist/atoms/imgs/FlowerHome';
import HeroHome from '@lideralia/alife-uikit/dist/atoms/imgs/HeroHome';
import LockHome from '@lideralia/alife-uikit/dist/atoms/imgs/LockHome';
import TranslateHome from '@lideralia/alife-uikit/dist/atoms/imgs/TranslateHome';
import { NoMessagesSection } from '@lideralia/alife-uikit/dist/organisms/Cards/NoMessages';
import React, { useCallback, useEffect, useState } from 'react';
import { space } from 'styled-system';
// import { FormattedMessage } from 'react-intl';
import css from '@styled-system/css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon } from '@lideralia/alife-uikit';
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { IconSee } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { CardFlowerBack } from '@lideralia/alife-uikit/dist/organisms/Cards';
import { CardBadges } from '@lideralia/alife-uikit/dist/organisms/Cards/Badges';
import { FormattedMessage } from 'react-intl';
import { actualLanguage } from '../../common/functions/lang';
import { getLastBadges, setLikesLineBadgeHome } from '../../common/schemes/line';
import { showRegister } from '../../common/schemes/login';

export const HeaderSectionStyled = styled('header')(
  css({
    marginTop: 4,
    marginBottom: 2,
    marginX: 'auto',
    width: '100%',
  }),
  space
);

export const HomePage = ({ history }) => {
  const [isShowMoreMessages, setIsShowMessages] = useState(false);
  const [countMoreMessages, setCountMoreMessages] = useState(5);
  const dispatch = useDispatch();
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const search = useSelector((state) => state.search);
  const {
    home,
    active: { company },
  } = line;

  const { company: companySearch } = search;
  const informationCompany = companySearch || company;
  const getLastBadgesAPI = useCallback((id) => dispatch(getLastBadges(id, 60)), [dispatch]);
  const setLikeLineBadgeAPI = useCallback(
    (id, hasliked) => dispatch(setLikesLineBadgeHome(id, hasliked)),
    [dispatch]
  );
  const isShowLogin = useSelector((state) => state.showLogin);
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  const onShowMoreMessages = () => {
    setIsShowMessages(!isShowMoreMessages);
  };

  useEffect(() => {
    if (!line.loading && company?.companyId) {
      getLastBadgesAPI(company?.companyId || '0');
    }
  }, [getLastBadgesAPI, company]);

  const onClickLike = (id, hasLiked) => {
    if (!user?.data?.id || !user.done) {
      setShowRegisterState(1);
    } else if (line?.active?.userGroup >= 0) {
      setLikeLineBadgeAPI(id, !hasLiked);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  const goToSearchCompany = () => {
    const id = informationCompany?.companyId || informationCompany?.branchId;
    history.push(`/${actualLanguage}/search?company_id=${id}`);
  };

  return (
    <Container paddingY={3} paddingBottom={8} maxWidth={['400px']}>
      <Flex
        as="section"
        id="hero"
        jusitfyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box width="100%" height="330px" marginTop={4} marginBottom={4}>
          <HeroHome width="100%" height="330px" />
        </Box>
        {informationCompany && (
          <CardFlowerBack
            titleLabel={<FormattedMessage id="flowerEcommerce.titleService" />}
            descriptionLabel={<FormattedMessage id="flowerEcommerce.descriptionService" />}
          >
            <Button
              backgroundColor={informationCompany?.style?.primaryColor}
              color={informationCompany?.style?.textColor}
              colorType={informationCompany?.style?.primaryColor || 'orange'}
              action={goToSearchCompany}
              borderColor="black36"
            >
              <FormattedMessage id="flowerEcommerce.actionService" />
            </Button>
          </CardFlowerBack>
        )}
        <HeaderSectionStyled>
          <Text as="h1" sizeText="display24" color="black84" fontWeight="bold">
            <FormattedMessage id="home.title" />
          </Text>
        </HeaderSectionStyled>
        <Text as="h2" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage
            id="home.text1"
            values={{
              sub1: (
                <Text
                  as="span"
                  sizeText="display16"
                  color="black84"
                  fontWeight="bold"
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: company?.style?.primaryColor || '#F27E30',
                    textDecorationThickness: '2px',
                  }}
                >
                  <FormattedMessage id="home.sub1" />
                </Text>
              ),
            }}
          />
        </Text>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage
            id="home.text2"
            values={{
              sub2: (
                <Text
                  as="span"
                  sizeText="display16"
                  color="black84"
                  fontWeight="bold"
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: company?.style?.primaryColor || '#F27E30',
                    textDecorationThickness: '2px',
                  }}
                >
                  <FormattedMessage id="home.sub2" />
                </Text>
              ),
            }}
          />
        </Text>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage
            id="home.text3"
            values={{
              sub3: (
                <Text
                  as="span"
                  sizeText="display16"
                  color="black84"
                  fontWeight="bold"
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: company?.style?.primaryColor || '#F27E30',
                    textDecorationThickness: '2px',
                  }}
                >
                  <FormattedMessage id="home.sub3" />
                </Text>
              ),
            }}
          />
        </Text>
        <Box width="100%" marginTop={4} position="relative">
          <Text
            as="p"
            sizeText="display60"
            color="black12"
            fontWeight="bold"
            textAlign="center"
            style={{
              position: 'absolute',
              top: '-32px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <FormattedMessage id="common.thanks" />
          </Text>
          <Text
            as="p"
            sizeText="display24"
            color="black84"
            marginBottom={3}
            textAlign="center"
            style={{
              position: 'relative',
            }}
          >
            <FormattedMessage
              id="home.text4"
              values={{
                count: (
                  <Text
                    as="p"
                    sizeText="display60"
                    color={company?.style?.primaryColor || 'green'}
                    fontWeight="bold"
                  >
                    {home.totalItems}
                  </Text>
                ),
              }}
            />
          </Text>
        </Box>
      </Flex>
      <Flex as="section" id="messagesZone" flexDirection="column" alignItems="center">
        {home?.badges?.length ? (
          <>
            {home?.badges?.slice(0, countMoreMessages).map((element) => (
              <CardBadges
                id={element.id}
                isAdmin={element.author?.id === user?.data?.id}
                contentIcon={element.contentIcon}
                contentColor={company?.style?.primaryColor || element.contentColor}
                authorName={intl.formatMessage(
                  {
                    id: 'messages.from',
                  },
                  {
                    name: (
                      <Text
                        as="span"
                        color={company?.style?.primaryColor || element.contentColor}
                        fontWeight="bold"
                        sizeText="display13"
                        textStyle="uppercase"
                      >
                        {element.author?.nameUppercase}
                      </Text>
                    ),
                  }
                )}
                showCountry
                countryCode={element?.countryCode}
                text={element.text}
                picture={element.pictures?.m}
                actionLike={() => onClickLike(element.id)}
                numLikes={
                  element.numLikes === 'undefined' || !element.numLikes ? '0' : element.numLikes
                }
                hasLiked={element.hasLiked}
              />
            ))}
            <Button
              backgroundColor={company?.style?.primaryColor}
              color={company?.style?.textColor}
              colorType={company?.style?.primaryColor || 'orange'}
              fullWidth
              sizeButton="medium"
              onClick={() => {
                setCountMoreMessages((value) => value + 5);
              }}
            >
              <Icon sizeIcon="display24" marginRight={2}>
                <IconSee />
              </Icon>
              <FormattedMessage id="messages.showMoreLabel" />
            </Button>
          </>
        ) : (
          <>
            <NoMessagesSection customColor={company?.style?.primaryColor || 'green'} />
            <Text
              textAlign="center"
              marginBottom={2}
              marginTop={2}
              sizeText="display14"
              color="#5B565A"
            >
              <FormattedMessage id="badge.emptyLabel" />
            </Text>
          </>
        )}
        {isShowMoreMessages && home?.length > 6 && (
          <>
            {home?.badges?.slice(6, home.length).map((element) => (
              <CardBadges
                contentIcon={element.contentIcon}
                contentColor={company?.style?.primaryColor || element.contentColor}
                id={element.id}
                isAdmin={element.author.id === user?.data?.id}
                authorName={intl.formatMessage(
                  {
                    id: 'messages.from',
                  },
                  {
                    name: (
                      <Text
                        as="span"
                        color={company?.style?.primaryColor || element?.contentColor}
                        fontWeight="bold"
                        sizeText="display13"
                        textStyle="uppercase"
                      >
                        {element.author?.nameUppercase}
                      </Text>
                    ),
                  }
                )}
                showCountry
                countryCode={element?.countryCode}
                text={element.text}
                picture={element.pictures?.m}
                actionLike={() => onClickLike(element.id)}
                numLikes={
                  element.numLikes === 'undefined' || !element.numLikes ? '0' : element.numLikes
                }
                hasLiked={element.hasLiked}
              />
            ))}
          </>
        )}
        {!isShowMoreMessages && home?.length > 6 && (
          <Flex
            justifyContent="center"
            paddingTop={1}
            paddingBottom={2}
            flexDirection="column"
            style={{ flexFlow: 'nowrap' }}
          >
            <Button colorType="black" sizeButton="small" action={onShowMoreMessages}>
              <FormattedMessage id="messages.showMoreLabel" />
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex as="section" jusitfyContent="center" alignItems="center" flexDirection="column">
        <Box width="100%" height="294px" marginTop={4}>
          <CeremonyHome width="100%" height="294px" />
        </Box>
        <HeaderSectionStyled>
          <Text
            as="h3"
            sizeText="display24"
            color="black84"
            fontWeight="bold"
            style={{
              textDecoration: 'underline',
              textDecorationColor: '#54C0D4',
              textDecorationThickness: '2px',
            }}
          >
            <FormattedMessage id="home.text5" />
          </Text>
        </HeaderSectionStyled>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage id="home.sub4" />
        </Text>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage id="home.sub5" />
        </Text>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage id="home.sub6" />
        </Text>
      </Flex>
      <Flex as="section" jusitfyContent="center" alignItems="center" flexDirection="column">
        <Box width="100%" height="287px" marginTop={4}>
          <FamilyZoneHome width="100%" height="287px" />
        </Box>
        <HeaderSectionStyled>
          <Text
            as="h3"
            sizeText="display24"
            color="black84"
            fontWeight="bold"
            style={{
              textDecoration: 'underline',
              textDecorationColor: company?.style?.primaryColor || '#A0B454',
              textDecorationThickness: '2px',
            }}
          >
            <FormattedMessage id="home.text6" />
          </Text>
        </HeaderSectionStyled>
        <Text as="p" sizeText="display16" color="black84" marginBottom={3}>
          <FormattedMessage id="home.sub7" />
        </Text>
      </Flex>
      <Flex flexDirection={['column', 'column', 'column']} marginBottom={7}>
        <Flex as="section" jusitfyContent="center" alignItems="center" flexDirection="column">
          <Box width="100%" height="96px" marginTop={4}>
            <FlowerHome width="100%" height="96px" />
          </Box>
          <HeaderSectionStyled marginBottom={3} marginTop={5}>
            <Text
              as="h3"
              sizeText="display24"
              color="black84"
              fontWeight="bold"
              textAlign="center"
              style={{
                textDecoration: 'underline',
                textDecorationColor: company?.style?.primaryColor || '#54C0D4',
                textDecorationThickness: '2px',
              }}
            >
              <FormattedMessage id="home.titleService1" />
            </Text>
          </HeaderSectionStyled>
          <Text as="p" textAlign="center" sizeText="display16" color="black84" marginBottom={3}>
            <FormattedMessage id="home.descriptionService1" />
          </Text>
        </Flex>
        <Flex as="section" jusitfyContent="center" alignItems="center" flexDirection="column">
          <Box width="100%" height="96px" marginTop={4}>
            <LockHome width="100%" height="96px" />
          </Box>
          <HeaderSectionStyled marginBottom={3} marginTop={5}>
            <Text
              as="h3"
              textAlign="center"
              sizeText="display24"
              color="black84"
              fontWeight="bold"
              style={{
                textDecoration: 'underline',
                textDecorationColor: company?.style?.primaryColor || '#54C0D4',
                textDecorationThickness: '2px',
              }}
            >
              <FormattedMessage id="home.titleService2" />
            </Text>
          </HeaderSectionStyled>
          <Text as="p" textAlign="center" sizeText="display16" color="black84" marginBottom={3}>
            <FormattedMessage id="home.descriptionService2" />
          </Text>
        </Flex>
        <Flex as="section" jusitfyContent="center" alignItems="center" flexDirection="column">
          <Box width="100%" height="88px" marginTop={4}>
            <TranslateHome width="100%" height="88px" />
          </Box>
          <HeaderSectionStyled marginBottom={3} marginTop={5}>
            <Text
              textAlign="center"
              as="h3"
              sizeText="display24"
              color="black84"
              fontWeight="bold"
              style={{
                textDecoration: 'underline',
                textDecorationColor: company?.style?.primaryColor || '#54C0D4',
                textDecorationThickness: '2px',
              }}
            >
              <FormattedMessage id="home.titleService3" />
            </Text>
          </HeaderSectionStyled>
          <Text as="p" textAlign="center" sizeText="display16" color="black84" marginBottom={3}>
            <FormattedMessage id="home.descriptionService3" />
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};
