import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../lib/request';
import { LineMapper } from '../../../common/schemes/line/mapper';
import * as action from '../actions';
import { constants, services } from '../config';

export function* getLinesSaga() {
  try {
    const response = yield requestApi2(services.getLines());

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getLinesSuccess(data?.map((el) => LineMapper.hydrate(el))));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getLinesFail(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_LINES, getLinesSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
