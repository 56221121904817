export const constants = {
  GET_LINES: 'GET_LINES',
  GET_LINES_SUCCESS: 'GET_LINES_SUCCESS',
  GET_LINES_FAIL: 'GET_LINES_FAIL',
};

export const services = {
  getLines: () => ({
    route: `/api/v2/me/lines`,
    method: 'get',
  }),
};
