/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { ImageRoutes } from '../../../mappers/ImageRoutes';

export const StyleCompanyMapper = {
  hydrate({
    navbar_color,
    navbar_bg_button_color,
    primary_color,
    secondary_color,
    navbar_text_button_color,
    text_color,
    secondary_text_color,
  }) {
    return {
      navbarColor: navbar_color || '',
      navbarBgButtonColor: navbar_bg_button_color || '',
      navbarTextButtonColor: navbar_text_button_color || '',
      primaryColor: primary_color || '',
      secondaryColor: secondary_color || '',
      textColor: text_color || '',
      secondaryTextColor: secondary_text_color || '',
    };
  },
};

export const CompanyMapper = {
  hydrate({
    map_img,
    map_lat,
    map_long,
    phone_number,
    base,
    cmscompany_uuid,
    tradename,
    name,
    company_optin,
    image_logo,
    style_api,
    currency,
    picture_all,
    show_live_streaming_card,
    ...data
  }) {
    const options = {};
    if (!base) options.showLiveStreamingCard = show_live_streaming_card;

    return {
      pictures:
        picture_all && typeof picture_all === 'object' && Object.entries(picture_all)
          ? picture_all.map((el) => ImageRoutes.hydrate(el))
          : null,
      mapImg: typeof map_img === 'string' ? map_img : '',
      mapLat: map_lat || '',
      mapLong: map_long || '',
      phoneNumber: phone_number,
      companyId: base?.cmscompany_uuid || null,
      branchId: cmscompany_uuid && cmscompany_uuid,
      tradename: tradename && tradename,
      name: name && name,
      companyHasOwnOptin:
        company_optin && typeof picture_all === 'object' && JSON.parse(company_optin),
      logo:
        image_logo && typeof image_logo === 'object' && Object.entries(image_logo)
          ? ImageRoutes.hydrate(image_logo)
          : null,
      style: style_api && StyleCompanyMapper.hydrate(style_api),
      currency: currency || null,
      base: base ? CompanyMapper.hydrate(base) : null,
      ...options,
      ...data,
    };
  },
};
