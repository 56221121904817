import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import IconLink from '@lideralia/alife-uikit/dist/atoms/Icons/IconLink';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { LinkText, Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getBereavementServices } from '../../../common/schemes/line';

export const SupportTemplate = ({
  goToBereavement,
  goBack,
  external,
  companyId,
  eventExternalSupportAction,
}) => {
  const dispatch = useDispatch();
  const { active } = useSelector((state) => state.line);

  const getBereavementServicesAPI = useCallback(
    (id) => {
      dispatch(getBereavementServices(id));
    },
    [dispatch]
  );
  useEffect(() => {
    if (companyId) {
      getBereavementServicesAPI(companyId);
    }
  }, [companyId]);
  return companyId ? (
    <Container paddingY={4} height="100vh" paddingBottom={7}>
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        maxWidth="339px"
        minWidth="290px"
        margin="0 auto"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="black84" fontWeight="bold" sizeText="display24">
            <FormattedMessage id="communication.bereavement.title" />
          </Text>
          <Button
            paddingY={1}
            paddingX={1}
            colorType="black"
            sizeButton="big"
            rounded
            action={() => goBack()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconArrowLeft />
            </Icon>
          </Button>
        </Flex>

        {companyId && (
          <Text color="black84" marginTop={2}>
            {active?.company?.bereavement}
          </Text>
        )}
        <Box marginTop={4} marginBottom={4}>
          <Card
            marginY={1}
            padding={3}
            fullWidth
            colorType="white"
            sizeButton="big"
            justifyContent="flex-start"
            action={() => goToBereavement()}
          >
            {external?.map((element) => (
              <LinkText
                href={element.url}
                target="_blank"
                onClick={() => {
                  if (eventExternalSupportAction) {
                    eventExternalSupportAction({
                      outbound_link_type: element.url_service_type,
                      outbound_link_label: element.url_ga_event_label,
                    });
                  }
                }}
              >
                <Flex as="li" alignItems="center" key={element.url_title}>
                  <Icon sizeIcon="display16" color="black60">
                    <IconLink />
                  </Icon>
                  <Text
                    textAlign="left"
                    marginLeft={2}
                    sizeText="display13"
                    color="black60"
                    marginY={1}
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    dangerouslySetInnerHTML={{ __html: element.url_title }}
                  />
                </Flex>
              </LinkText>
            ))}
            {!external?.length && (
              <Text color="black84">
                <FormattedMessage id="communication.bereavement.emptySupport" />
              </Text>
            )}
          </Card>
        </Box>
      </Flex>
    </Container>
  ) : (
    <Loader customColor={active?.company?.style?.primaryColor} />
  );
};
