import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import { IconFileDescription } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconAgency from '@lideralia/alife-uikit/dist/atoms/Icons/IconAgency';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import IconGrave from '@lideralia/alife-uikit/dist/atoms/Icons/IconGrave';
import IconSupport from '@lideralia/alife-uikit/dist/atoms/Icons/IconSupport';
import IconUsers from '@lideralia/alife-uikit/dist/atoms/Icons/IconUsers';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Modal } from '@lideralia/alife-uikit/dist/molecules/Modal';
import { CardAdviser } from '@lideralia/alife-uikit/dist/organisms/Cards/Adviser';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { setEventAnalytics } from '../../../common/components/Analytics';

const IconsTemplate = {
  'admin-agency': <IconAgency />,
  graves: <IconGrave />,
  club: <IconUsers />,
  insurance: <IconUsers />,
};

const IconsLinkTemplate = {
  document: <IconFileDescription />,
};

export const ArrangementsTemplate = (props) => {
  const {
    user,
    active,
    external,
    goToBereavement,
    callbackAjax,
    showAgencyModal,
    setShowAgencyModal,
    goBack,
    customColor,
    customTextColor,
    companyAgent,
    eventExternalSupportAction,
  } = props;
  const userLoad = useSelector((state) => state.user);
  const lineLoad = useSelector((state) => state.line);
  return !userLoad.loading ? (
    <Container paddingY={4} height="100vh" paddingBottom={7}>
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        maxWidth="339px"
        minWidth="290px"
        margin="0 auto"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="black84" fontWeight="bold" sizeText="display24">
            <FormattedMessage id="communication.arrangements.title" values={{ name: user?.name }} />
          </Text>
          <Button
            paddingY={1}
            paddingX={1}
            colorType="black"
            sizeButton="big"
            rounded
            action={() => goBack()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconArrowLeft />
            </Icon>
          </Button>
        </Flex>

        <Text color="black84" marginTop={2}>
          <FormattedMessage id="communication.arrangements.subtitle" />
        </Text>

        <Box marginTop={4} marginBottom={4}>
          {/* Asesor */}
          {companyAgent && (
            <CardAdviser
              name={companyAgent?.name}
              email={companyAgent?.email}
              mobile={companyAgent?.phone_number}
              workingDay={companyAgent?.additional_info}
              customColor={customColor}
              customTextColor={customTextColor}
            />
          )}

          <Button
            marginY={1}
            fullWidth
            colorType="white"
            sizeButton="big"
            justifyContent="flex-start"
            action={() => goToBereavement()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconSupport />
            </Icon>
            <Text
              textAlign="left"
              style={{ flex: 1 }}
              marginLeft={2}
              fontWeight="bold"
              sizeText="display16"
              color="black84"
            >
              <FormattedMessage id="communication.bereavement.title" />
            </Text>
          </Button>
          {external?.map((element) => (
            <Button
              marginY={1}
              key={element.url_service_type}
              fullWidth
              colorType="white"
              sizeButton="big"
              justifyContent="flex-start"
              disabled={!element.url}
              action={() => {
                if (eventExternalSupportAction) {
                  setEventAnalytics(
                    `profile_arrangements_${element.url_service_type}_support_button_clicked`,
                    user,
                    window?.location?.pathname,
                    active,
                    {
                      outbound_link_type: element.url_service_type,
                      outbound_link_label: element.url_ga_event_label,
                      url_id: element.url_id,
                    }
                  );
                  eventExternalSupportAction({
                    outbound_link_type: element.url_service_type,
                    outbound_link_label: element.url_ga_event_label,
                  });
                }
                if (element.url_content_type !== 'ajax_url') {
                  window.open(element.url);
                } else {
                  callbackAjax(element.url);
                }
              }}
            >
              <Icon sizeIcon="display24" color="black84">
                {element.url_service_type !== 'link'
                  ? IconsTemplate[element.url_service_type]
                  : IconsLinkTemplate[element.url_content_type]}
              </Icon>
              <Text
                textAlign="left"
                style={{ flex: 1 }}
                marginLeft={2}
                fontWeight="bold"
                sizeText="display16"
                color="black84"
              >
                {element.url_title}
              </Text>
            </Button>
          ))}
        </Box>
      </Flex>
      <Modal
        width="339px"
        isOpen={showAgencyModal}
        duration={100}
        onModalClose={() => setShowAgencyModal(false)}
        onModalBack={() => setShowAgencyModal(false)}
        backWithButton
        marginX={0}
        marginY={0}
        title={<FormattedMessage id="communication.title" />}
      >
        <Modal.Content>
          <Box overflow="scroll">
            <Text
              sizeText="display14"
              textAlign="center"
              fontWeight="medium"
              color="black84"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'communication.agencyError' }),
              }}
            />
          </Box>
        </Modal.Content>
      </Modal>
    </Container>
  ) : (
    <Loader customColor={lineLoad?.active?.company?.style?.primaryColor} />
  );
};
