import { LinkText } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { domToReact } from 'html-react-parser';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getConditions } from '../../../common/helpers/conditions';
import { getCookies } from '../../../common/helpers/cookies/actions';
import { getPrivacy } from '../../../common/helpers/privacy';
import { postLogout, updateMe } from '../../../common/schemes/user';
import { DataUserTemplate } from '../template/dataUserTemplate';

const DataUserPage = ({ history }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [data, setData] = useState({});
  const [showConditions, setShowConditions] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatePhoneIsOpened, setUpdatePhoneIsOpened] = useState(false);

  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const conditions = useSelector((state) => state.helper?.conditions);
  const privacy = useSelector((state) => state.helper?.privacy);
  const cookies = useSelector((state) => state.helper?.cookies);
  const dataCookies = cookies?.data;
  const dataConditions = conditions?.data;
  const dataPrivacy = privacy?.data;
  const { doneUpdateVerifyPhone } = user;
  const {
    active: { company },
  } = line;

  const optionsLanguage = [
    { value: 'es', label: 'Español' },
    { value: 'es-MX', label: 'Español (México)' },
    { value: 'en', label: 'English' },
    { value: 'ca', label: 'Català' },
    { value: 'eu', label: 'Euskara' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' },
    { value: 'pl', label: 'Polski' },
    { value: 'pt', label: 'Portugués' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'uk', label: 'Українська' },
  ];

  const optionsOfConditions = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const hasUrlOfPrivacy = attribs.href && attribs.href.includes('/privacy');
      const hasUrlOfCookies = attribs.href && attribs.href.includes('/cookies');
      const linkUrlPrivacy = () => {
        setShowPrivacy(true);
        setShowConditions(false);
      };

      const linkUrlCookies = () => {
        setShowCookies(true);
        setShowConditions(false);
      };
      if (hasUrlOfPrivacy) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkText color="blueHover" sizeText="display13" underline onClick={linkUrlPrivacy}>
            {domToReact(children, optionsOfConditions)}
          </LinkText>
        );
      }

      if (hasUrlOfCookies) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkText color="blueHover" sizeText="display13" underline onClick={linkUrlCookies}>
            {domToReact(children, optionsOfConditions)}
          </LinkText>
        );
      }
    },
  };

  const optionsOfPrivacy = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const hasUrlOfCookies = attribs.href && attribs.href.includes('/cookies');

      const linkUrlCookies = () => {
        setShowCookies(true);
        setShowPrivacy(false);
      };

      if (hasUrlOfCookies) {
        // eslint-disable-next-line consistent-return
        return (
          <LinkText color="blueHover" sizeText="display13" underline onClick={linkUrlCookies}>
            {domToReact(children, optionsOfConditions)}
          </LinkText>
        );
      }
    },
  };

  const updateMeAPI = useCallback(
    (body) => {
      dispatch(updateMe(body));
    },
    [dispatch]
  );

  const getConditionsAPI = useCallback((lang) => dispatch(getConditions(lang)), [dispatch]);
  const getPrivacyAPI = useCallback((lang) => dispatch(getPrivacy(lang)), [dispatch]);
  const getCookiesAPI = useCallback((lang) => dispatch(getCookies(lang)), [dispatch]);
  const postLogoutAPI = useCallback((lang) => dispatch(postLogout(lang)), [dispatch]);

  useEffect(() => {
    if (user.doneUpdateVerifyPhone) {
      setUpdatePhoneIsOpened(false);
    }
  }, [doneUpdateVerifyPhone]);

  const handleCloseSession = () => {
    deleteLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME);
    // execute post logout
    postLogoutAPI(actualLanguage);
  };

  useEffect(() => {
    if (showConditions && !dataConditions) {
      getConditionsAPI(actualLanguage);
    }
  }, [showConditions]);

  useEffect(() => {
    if (showPrivacy && !dataPrivacy) {
      getPrivacyAPI(actualLanguage);
    }
  }, [showPrivacy]);

  useEffect(() => {
    if (showCookies && !dataCookies) {
      getCookiesAPI(actualLanguage);
    }
  }, [showCookies]);

  useEffect(() => {
    if (user?.doneUpdate && !user?.loading) {
      setIsEditMode(false);
    }
  }, [user, setIsEditMode]);

  const handleSubmit = () => {
    updateMeAPI(data);
  };

  useEffect(() => {
    if (user?.data?.language && line?.active?.lineId && actualLanguage !== user?.data?.language) {
      // reload page
      window.location.reload();
    }
  }, [user?.data?.language]);

  return (
    <DataUserTemplate
      user={user}
      setShowConditions={setShowConditions}
      setShowCookies={setShowCookies}
      setShowPrivacy={setShowPrivacy}
      showCookies={showCookies}
      showConditions={showConditions}
      showPrivacy={showPrivacy}
      company={company}
      dataConditions={dataConditions}
      dataPrivacy={dataPrivacy}
      dataCookies={dataCookies}
      handleCloseSession={handleCloseSession}
      history={history}
      handleChangePicture={(file) => file && setData((prev) => ({ ...prev, file64: file.base64 }))}
      labelChangePicture={
        user?.data?.picture_all?.m
          ? intl.formatMessage({ id: 'common.updatePhoto' })
          : intl.formatMessage({ id: 'common.addPhoto' })
      }
      picture={user?.data?.picture_all?.m}
      setIsEditMode={setIsEditMode}
      language={user?.data?.language}
      name={user?.data?.name}
      phoneNumber={user?.data?.phoneNumber}
      birthDate={user?.data?.birthDate}
      isEditMode={isEditMode}
      optionsLanguage={optionsLanguage}
      setUpdatePhoneIsOpened={setUpdatePhoneIsOpened}
      handleSubmit={handleSubmit}
      updatePhoneIsOpened={updatePhoneIsOpened}
      handleChange={(id, value) => {
        setData((prev) => ({ ...prev, [id]: value }));
        if (!isEditMode && id === 'language') updateMeAPI({ language: value });
      }}
      optionsOfConditions={optionsOfConditions}
      optionsOfPrivacy={optionsOfPrivacy}
    />
  );
};

export default DataUserPage;
