export const constants = {
  GET_POLL: 'GET_POLL',
  GET_POLL_SUCCESS: 'GET_POLL_SUCCESS',
  GET_POLL_FAIL: 'GET_POLL_FAIL',
  SET_POLL: 'SET_POLL',
  SET_POLL_SUCCESS: 'SET_POLL_SUCCESS',
  SET_POLL_FAIL: 'SET_POLL_FAIL',
  RESET_POLL: 'RESET_POLL',
};

export const services = {
  getPoll: (pollCode) => ({
    route: `/api/v2/polls/${pollCode}`,
    method: 'get',
  }),
  setPoll: (data) => ({
    route: `/api/v2/polls`,
    method: 'post',
    body: {
      ...data,
    },
  }),
};
