/*
 *
 * I18nProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/i18n`)
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { flattenMessages } from '../../cli/common/functions';
import { actualLanguage } from '../../cli/common/functions/lang';

export const I18nProvider = ({ locale = 'es', messages, children }) => {
  const user = useSelector((state) => state.user);
  const [language, setLanguage] = useState(locale);
  const { data } = user;
  useEffect(() => {
    if (data?.language) {
      let lng = data?.language.split('_');
      if (lng.length > 1) {
        lng[1] = lng[1].toLocaleUpperCase();
        lng = lng.join('-');
      }
      setLanguage(lng);
    }
  }, [user]);
  return (
    <IntlProvider
      defaultLocale={language || actualLanguage}
      locale={language || actualLanguage}
      key={language || actualLanguage}
      messages={flattenMessages(messages[language || actualLanguage])}
      textComponent={React.Fragment}
    >
      {React.Children.only(children)}
    </IntlProvider>
  );
};

I18nProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
};

I18nProvider.defaultProps = {
  locale: 'es',
};

export default connect((state) => ({
  locale: state.language.locale,
}))(I18nProvider);
