import * as FlagIcons from '@lideralia/alife-uikit/dist/atoms/Flags';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getConditions } from '../../../common/helpers/conditions';
import { getCountries } from '../../../common/helpers/countries';
import { getPrivacy } from '../../../common/helpers/privacy';
import { getSettings } from '../../../common/helpers/settings/actions';
import { getWhois } from '../../../common/helpers/whois';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import {
  getMe,
  getOwnerAuthCodeInfo,
  postOwnerAuthCode,
  setVerifyRegister,
} from '../../../common/schemes/user';
import { resetPasstrough, setMobilePasstrough } from '../../login/actions';
import reducer from '../../login/reducer';
import { RegisterTemplate } from '../template';

const RegisterPage = ({ hideModalLogin, showLogin }) => {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPass, setIsOpenPass] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [prefixData, setPrefixData] = useState({
    value: 'ES',
    label: '+34',
    iconLeft: <FlagIcons.Es />,
  });
  const [dataUser, setDataUser] = useState({
    countryCode: prefixData.value,
    isFor: 'register',
  });
  const [prefixDataLoaded, setPrefixDataLoaded] = useState(false);
  const [options, setOptions] = useState(null);

  // Actions
  const {
    authCode,
    getAuthCodeInfoAPI,
    getConditionsAPI,
    getMeAPI,
    getPrivacyAPI,
    getSettingsAPI,
    getWhoisInformationAPI,
    setCountriesAPI,
    setRegisterPasstroughAPI,
    setRgpdAuthAPI,
    setVerifyRegisterAPI,
  } = useAPI();

  // Selectors
  const { conditions, countries, line, privacy, register, settings, userStore, whois } =
    useRegisterSelector();

  const dataConditions = conditions?.data;
  const dataPrivacy = privacy?.data;
  const { passtroughDone, error, done, user } = register;
  const {
    active: { company },
  } = line;

  // Helpers
  const query = useSearchParams();
  const queryRedirect = useMemo(() => query.get('redirect'), [query]);

  const onSubmitRegister = (isRetry, token) => {
    setRegisterPasstroughAPI({ ...dataUser, token });
    if (isRetry) {
      setEventAnalytics(
        KEYS_ANALYTICS.NEW_OTP_BUTTON_CLICKED,
        user,
        history?.location?.pathname,
        line?.active
      );
    } else {
      setEventAnalytics(
        KEYS_ANALYTICS.REGISTER_BUTTON_CLICKED,
        user,
        history?.location?.pathname,
        line?.active
      );
    }
  };

  const onChangeNumber = () => {
    dispatch(resetPasstrough());
  };

  const backModal = () => {
    if (history?.length < 3) history.replace(`/${actualLanguage}`);
    else history?.goBack();
  };

  useEffect(() => {
    if (showConditions && !dataConditions) {
      getConditionsAPI(actualLanguage);
    }
  }, [showConditions]);
  useEffect(() => {
    if (register.errorRequest) {
      setEventAnalytics(
        KEYS_ANALYTICS.ALIFE_ERROR,
        user,
        history?.location?.pathname,
        line?.active,
        {},
        error
      );
    }
  }, [error]);

  useEffect(() => {
    if (showPrivacy && !dataPrivacy) {
      getPrivacyAPI(actualLanguage);
    }
  }, [showPrivacy]);

  useEffect(() => {
    if (!countries?.done) {
      setCountriesAPI();
    }
    if (!settings?.done) {
      getSettingsAPI();
    }
    setEventAnalytics(KEYS_ANALYTICS.PAGE_VIEW, user, history?.location?.pathname, line?.active);
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  useEffect(() => {
    if (passtroughDone) {
      setTimeout(() => setIsOpenPass(true), 100);
    } else {
      setTimeout(() => setIsOpenPass(false), 100);
    }
  }, [passtroughDone]);

  useEffect(() => {
    if (done || userStore.done) {
      if (!history?.location?.pathname?.includes('/r/')) {
        setEventAnalytics(KEYS_ANALYTICS.SIGN_UP, user, history?.location?.pathname, line?.active, {
          sign_up_type: 'regular',
        });
      }
      if (history?.location?.pathname?.includes('register')) {
        if (register?.user?.legal?.gdprAccepted || !authCode) {
          if (queryRedirect) {
            history.push(`${queryRedirect}`);
          } else {
            history?.push(`/${actualLanguage}/lines`);
          }
          if (!user.loading) {
            getMeAPI();
          }
        } else if (queryRedirect) {
          history.push(`${queryRedirect}`);
        } else {
          history?.push(`/${actualLanguage}/rgpd`);
        }
      } else if (hideModalLogin) {
        hideModalLogin();
      }
    }
  }, [done, register, userStore, history]);

  useEffect(() => {
    setDataUser((d) => ({
      ...d,
      countryCode: prefixData.value,
    }));
  }, [prefixData]);

  useEffect(() => {
    if (options) {
      return;
    }
    const fetchData = async () => {
      const dataCountries = countries?.data?.filter((elem) => elem.flag);
      let optionsSeted = await dataCountries?.map((element) => {
        const FlagIcon = FlagIcons[element.flag];
        if (FlagIcon) {
          return {
            value: element.iso,
            label: `+${element.phonecode}`,
            iconLeft: <FlagIcon />,
          };
        }
        return false;
      });
      optionsSeted = await optionsSeted?.filter((elem) => elem);
      setOptions(optionsSeted);

      getWhoisInformationAPI();
    };

    fetchData();
  }, [countries]);

  useEffect(() => {
    if (options && !prefixDataLoaded && whois.done === true) {
      const countryToCheck = whois.errorRequest === false ? whois?.info?.country : 'ES';
      const myCountryLocalitation = options.filter((option) =>
        option.value.includes(countryToCheck)
      );

      setPrefixData(
        myCountryLocalitation[0] ?? {
          value: 'ES',
          label: '+34',
          iconLeft: <FlagIcons.Es />,
        }
      );
      setPrefixDataLoaded(true);
    }
  }, [whois, options]);

  const { errorRequest, data } = userStore;
  useEffect(() => {
    if (authCode && !errorRequest && !userStore.done && !userStore.loading) {
      getAuthCodeInfoAPI(authCode);
    }
    if (errorRequest) {
      if (userStore.error?.api_code_error === 'register_auto_invalid_auth_code') {
        if (queryRedirect) {
          history.replace(`/${actualLanguage}/login?redirect=${queryRedirect}`);
        } else {
          history.replace(`/${actualLanguage}/login`);
        }
      }
      if (
        (userStore.error?.api_error_code === 'register_auto_already_auth_code_used' ||
          userStore.error?.api_error_code === 'register_auto_expired_auth_code') &&
        !!userStore.error?.data?.auth_code_line_id &&
        !!userStore?.error?.data?.auth_code_line_name
      ) {
        if (queryRedirect) {
          history.push(`${queryRedirect}`);
        }
        history.push(
          `/${actualLanguage}/lines/${
            userStore?.error?.data?.auth_code_line_id
          }/${userStore?.error?.data?.auth_code_line_name?.split(' ').join('-').toLowerCase()}`
        );
      }
    }
  }, [authCode, errorRequest]);

  useEffect(() => {
    if (!userStore.done || !userStore.data) return;

    const {
      loggedUserIsDifferent,
      legal: { gdprAccepted } = {},
      authCodeLineId,
      authCodeLineName,
    } = userStore.data;
    const { auth_code_line_id, auth_code_line_name } = userStore?.error?.data || {};

    const redirectTo = (path) => history.push(path);
    const generateLinePath = (lineId, lineName) =>
      `/${actualLanguage}/lines/${lineId}/${lineName?.split(' ').join('-').toLowerCase()}/ceremony`;

    if (loggedUserIsDifferent) {
      setIsOpened(true);
      return;
    }

    if (!gdprAccepted) {
      redirectTo(queryRedirect || `/${actualLanguage}/rgpd/${authCode}`);
      return;
    }

    if (!isOpened) {
      if (authCode) setRgpdAuthAPI();
      if (auth_code_line_id && auth_code_line_name) {
        redirectTo(queryRedirect || generateLinePath(auth_code_line_id, auth_code_line_name));
      } else if (authCode) {
        const loginRedirect =
          queryRedirect ||
          (authCodeLineId && authCodeLineName
            ? generateLinePath(authCodeLineId, authCodeLineName)
            : generateLinePath(idLineStored, idNameStored));

        history.replace(`/${actualLanguage}/login?redirect=${loginRedirect}`);
      }
    }
  }, [data, userStore]);

  return (
    <RegisterTemplate
      isOpened={isOpened}
      isOpen={isOpen}
      isOpenPass={isOpenPass}
      user={user}
      authCode={authCode}
      error={userStore.error || error}
      setDataUser={setDataUser}
      dataUser={dataUser}
      prefixData={prefixData}
      options={options}
      setPrefixData={setPrefixData}
      onSubmitRegister={onSubmitRegister}
      onChangeNumber={onChangeNumber}
      passtroughDone={passtroughDone}
      history={history}
      hideModalLogin={hideModalLogin}
      setVerifyRegisterAPI={setVerifyRegisterAPI}
      lastLineHaveCompanyOption={company?.companyHasOwnOptin != null}
      companyOption={
        company?.companyHasOwnOptin != null
          ? company?.companyHasOwnOptin[actualLanguage]
          : ''
      }
      intl={intl}
      setShowConditions={setShowConditions}
      setShowPrivacy={setShowPrivacy}
      showConditions={showConditions}
      showPrivacy={showPrivacy}
      dataConditions={dataConditions}
      dataPrivacy={dataPrivacy}
      onModalBack={backModal}
      backWithButton={window.location.href.indexOf('register') > -1}
      prefixDataLoaded={prefixDataLoaded}
      showLogin={showLogin}
      seconds={
        error && error?.message === 'error.error_too_many_attempts'
          ? settings?.info?.otp_max_attempts_delay_seconds
          : settings?.info?.otp_delay_seconds
      }
      hasWrongCodeError={error}
      onReset={() => {
        console.log('onReset');
        userStore.error = null;
      }}
    />
  );
};

const useAPI = () => {
  const dispatch = useDispatch();
  const { authCode } = useParams();

  const getAuthCodeInfoAPI = useCallback(() => dispatch(getOwnerAuthCodeInfo(authCode)));
  const getMeAPI = useCallback(() => dispatch(getMe()), [dispatch]);
  const getConditionsAPI = useCallback((lang) => dispatch(getConditions(lang)), [dispatch]);
  const getPrivacyAPI = useCallback((lang) => dispatch(getPrivacy(lang)), [dispatch]);
  const getWhoisInformationAPI = useCallback(() => dispatch(getWhois(), [dispatch]));
  const setRgpdAuthAPI = useCallback(
    () => dispatch(postOwnerAuthCode(authCode, { gpdr_accepted: 1 })),
    [dispatch]
  );
  const setCountriesAPI = useCallback(() => dispatch(getCountries('es')), [dispatch]);
  const getSettingsAPI = useCallback(() => dispatch(getSettings()), [dispatch]);
  const setRegisterPasstroughAPI = useCallback(
    (dataUserSubmited) => dispatch(setMobilePasstrough(dataUserSubmited)),
    [dispatch]
  );
  const setVerifyRegisterAPI = useCallback(
    (dataUserSubmited) => dispatch(setVerifyRegister(dataUserSubmited)),
    [dispatch]
  );

  return {
    getAuthCodeInfoAPI,
    getMeAPI,
    getConditionsAPI,
    getPrivacyAPI,
    getWhoisInformationAPI,
    setRgpdAuthAPI,
    setCountriesAPI,
    getSettingsAPI,
    setRegisterPasstroughAPI,
    setVerifyRegisterAPI,
    authCode,
  };
};

const useRegisterSelector = () => {
  const countries = useSelector((state) => state.helper?.countries);
  const register = useSelector((state) => state.register);
  const line = useSelector((state) => state.line);
  const userStore = useSelector((state) => state.user);
  const conditions = useSelector((state) => state.helper?.conditions);
  const privacy = useSelector((state) => state.helper?.privacy);
  const settings = useSelector((state) => state.helper?.settings);
  const whois = useSelector((state) => state.helper?.whois);

  return {
    countries,
    register,
    line,
    userStore,
    conditions,
    privacy,
    settings,
    whois,
  };
};

const withReducer = injectReducer({ key: 'register', reducer });

export default compose(withReducer)(RegisterPage);

RegisterPage.propTypes = {
  // history: PropTypes.shape().isRequired,
};
