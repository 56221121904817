export const constants = {
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAIL: 'GET_COUNTRIES_FAIL',
};

export const services = {
  getCountries: (lang) => ({
    route: `/api/v2/utils/countries?lang=${lang}`,
    method: 'get',
  }),
};
