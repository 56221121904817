import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Box, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { RangeSlider } from '@lideralia/alife-uikit/dist/atoms/RangeSlider';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { QualityStars } from '../components/QualityStars';

export const OwnerNoNpsLead = ({
  poll,
  valueStarsHuman,
  setValueStarsHuman,
  valueStarsInstallations,
  setValueStarsInstallations,
  valueStarsServices,
  setValueStarsServices,
  setValueRangeQuestion,
  valueRangeQuestion,
  valuePollSuggestions,
  setValuePollSuggestions,
  setFinishPoll,
  showFuneralOptions,
  onSubmit,
  handleResetPoll,
  company,
}) => (
  <Card maxWidth="339px" minWidth="290px" position="relative" width="100%">
    <QualityStars
      valueStarsHuman={valueStarsHuman}
      setValueStarsHuman={setValueStarsHuman}
      valueStarsInstallations={valueStarsInstallations}
      setValueStarsInstallations={setValueStarsInstallations}
      valueStarsServices={valueStarsServices}
      setValueStarsServices={setValueStarsServices}
    />
    <Box
      borderBottomWidth="1px"
      borderBottomColor="black12"
      borderBottomStyle="solid"
      width="100%"
      marginBottom={4}
    />
    {poll.data?.pollCustom?.items?.map((elem) => (
      <>
        <Flex flexDirection="column" paddingX={3} width="100%" key={elem.formName}>
          <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
            {elem.title}
          </Text>
          <RangeSlider
            name={elem.formName}
            id={elem.formName}
            minValue={0}
            maxValue={10}
            valueRange={valueRangeQuestion[elem.formName] || 0}
            defaultValue={0}
            setValue={(data) =>
              setValueRangeQuestion((prev) => ({
                ...prev,
                [data.name]: data.value,
              }))
            }
          />
        </Flex>
        <Box
          borderBottomWidth="1px"
          borderBottomColor="black12"
          borderBottomStyle="solid"
          width="100%"
          marginTop={1}
          marginBottom={4}
        />
      </>
    ))}

    <Flex flexDirection="column" paddingX={3} width="100%">
      <Field
        label={intl.formatMessage({ id: 'poll.betterQuestion' })}
        color="black60"
        fontWeight="bold"
        marginTop={0}
        marginBottom={1}
      >
        <Textarea
          counter
          typeAction={(name, value) => setValuePollSuggestions(value)}
          defaultValue={valuePollSuggestions}
          placeholderMessage={intl.formatMessage({ id: 'common.writeSomething' })}
        />
      </Field>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={2} paddingBottom={3}>
      <Button
        backgroundColor={company?.style?.primaryColor}
        colorType={company?.style?.primaryColor || 'orange'}
        color={company?.style?.textColor}
        sizeButton="big"
        fullWidth
        action={() => {
          setFinishPoll(true);
          onSubmit();
          if (!showFuneralOptions) {
            handleResetPoll();
          }
        }}
      >
        <FormattedMessage id="poll.submit" />
      </Button>
    </Flex>
  </Card>
);
