import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';

export function* getConditionsGeneralEcommerceSaga({ lang }) {
  try {
    const response = yield requestApi2(services.getConditionsGeneralEcommerce(lang));
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.getConditionsGeneralEcommerceSuccess(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getConditionGeneralEcommerceFail(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_CONDITIONS_GENERAL_ECOMMERCE, getConditionsGeneralEcommerceSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
