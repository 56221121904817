/* eslint-disable react/prop-types */
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconWhatsapp from '@lideralia/alife-uikit/dist/atoms/Icons/IconWhatsapp';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import { MuxPlayerContainer } from '@lideralia/alife-uikit/dist/molecules/MuxPlayerContainer';
import { VideoPlayer } from '@lideralia/alife-uikit/dist/molecules/VideoPlayer';
import { VideoYT } from '@lideralia/alife-uikit/dist/molecules/VideoYT';
import { CardCeremony } from '@lideralia/alife-uikit/dist/organisms/Cards/Ceremony';
import { CardFlower } from '@lideralia/alife-uikit/dist/organisms/Cards/Flowers';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import { CardLive } from '@lideralia/alife-uikit/dist/organisms/Cards/Live';
import { CardOldLives } from '@lideralia/alife-uikit/dist/organisms/Cards/OldLives';
import { CardUdiana } from '@lideralia/alife-uikit/dist/organisms/Cards/Udiana';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actualLanguage } from '../../../common/functions/lang';
import NotShowingPage from '../../notShowing/container';

export const CeremoniesTemplate = ({
  isTesting,
  line,
  isEcommerceOpen,
  customColor,
  customTextColor,
  infoCompany,
  userPermission,
  actionAttendance,
  actionGoToEcommerce,
  actionGoToUdianas,
  events,
  live,
  olds,
  urlMap,
  session,
  onNotLogged,
  intl,
  eventEmailAction,
  eventPhoneAction,
  eventPictureAction,
  eventLocationAction,
  eventFlowerAction,
  changeOpenShareMenu,
  eventLiveAction,
  fromEU,
  company,
  addNewBadgeAction,
}) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const isAdmin = line?.permissionType > 3;
  const name = line?.name;
  const treesPlanted = line?.treeSumPerLine;
  const hasTreesPlanted = treesPlanted > 0;
  const showAcceptConditions = !line.isLegalTermAccepted && isAdmin;
  const hasOlds = olds?.length > 0;

  const [idTimeRemainingToCloseEcomerce, setIdTimeRemainingToCloseEcomerce] = useState(
    'flowerEcommerce.timeRemainingToCloseEcomerceClosed'
  );

  useEffect(() => {
    const {
      hoursRemainingToCloseEcommerce: hour,
      minutesRemainingToCloseEcommerce: min,
      ecommerceIsOpen,
    } = line;

    if (!ecommerceIsOpen || (min === 0 && hour === 0)) {
      setIdTimeRemainingToCloseEcomerce('flowerEcommerce.timeRemainingToCloseEcomerceClosed');
    } else if (min === 0) {
      setIdTimeRemainingToCloseEcomerce('flowerEcommerce.timeRemainingToCloseEcomerceHour');
    } else if (hour === 0) {
      setIdTimeRemainingToCloseEcomerce('flowerEcommerce.timeRemainingToCloseEcomerceMin');
    } else {
      setIdTimeRemainingToCloseEcomerce('flowerEcommerce.timeRemainingToCloseEcomerceHourMin');
    }
  }, [line]);

  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" flexDirection="column" justifyContet="center" paddingBottom={6}>
        <CardGone
          name={line?.name}
          date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
          picture={line?.pictures?.m}
          isOpened={false}
          customColor={customColor}
          marginBottom={2}
        />
        {showAcceptConditions && (
          <Container paddingBottom={2}>
            <NotShowingPage actualLanguage={actualLanguage} />
          </Container>
        )}
        <CardCompanyLive
          company={company}
          live={live}
          customColor={customColor}
          eventLiveAction={eventLiveAction}
          customTextColor={customTextColor}
          session={session}
          onNotLogged={onNotLogged}
        />
        {hasOlds ? (
          <CardOldLives
            marginBottom={2}
            label={intl.formatMessage({ id: 'ceremony.oldStreamTitle' })}
            streams={olds}
            customColor={customColor}
            customTextColor={customTextColor}
            eventLiveAction={eventLiveAction}
            logged={session}
            onNotLogged={onNotLogged}
          />
        ) : null}
        <CardCompanyFlowers
          line={line}
          company={company}
          isEcommerceOpen={isEcommerceOpen}
          isTesting={isTesting}
          customColor={customColor}
          customTextColor={customTextColor}
          intl={intl}
          idTimeRemainingToCloseEcomerce={idTimeRemainingToCloseEcomerce}
          eventFlowerAction={eventFlowerAction}
          actionGoToEcommerce={actionGoToEcommerce}
        />
        {line?.udianasIsOpen ? (
          <CardUdiana
            id="udianas"
            titleLabel={intl.formatMessage({ id: 'udianas.forestOf' })}
            descriptionLabel={intl.formatMessage({ id: 'udianas.plantTrees' })}
            actionLabel={<FormattedMessage id="udianas.planting" />}
            actionButton={actionGoToUdianas}
            customColor={customColor}
            customTextColor={customTextColor}
            treesPlanted={
              hasTreesPlanted
                ? intl.formatMessage({ id: 'udianas.treesAlreadyPlanted' }, { trees: treesPlanted })
                : intl.formatMessage({ id: 'udianas.forestOfWithName' }, { name })
            }
            marginBottom={2}
          />
        ) : null}
        {events?.map((event) => {
          const urlMapp = urlMap(event?.venue);
          // eslint-disable-next-line no-nested-ternary
          const emailEvent = event?.eventIsOutsourced
            ? event?.venue?.email
            : company?.email
            ? company?.email
            : company?.base?.email;
          return (
            <CardCeremony
              key={event.eventId}
              marginBottom={2}
              eventEmailAction={eventEmailAction}
              eventPhoneAction={eventPhoneAction}
              eventPictureAction={eventPictureAction}
              eventLocationAction={eventLocationAction}
              customColor={customColor}
              customTextColor={customTextColor}
              labelEvent={<FormattedMessage id={event?.eventTypeId} />}
              userCanAttend={userPermission && !event?.eventIsAccomplished}
              confirmed={event?.rsvpStatus === '1'}
              isAnswered={event?.is_answered}
              eventDateStart={event?.eventStartsAtLocaltime}
              eventDateEnd={event?.eventEndsAtLocaltime}
              eventRoom={
                event?.eventRoom ? (
                  <FormattedMessage id="ceremony.roomLabel" values={{ count: event?.eventRoom }} />
                ) : (
                  ''
                )
              }
              eventInfo={event?.eventInfo}
              phone={
                event?.eventIsOutsourced
                  ? event?.venue && event?.venue?.phone
                  : infoCompany?.phoneNumber
              }
              email={emailEvent}
              venueAddress={`${event?.venue?.add1 ? `${event?.venue?.add1},` : ''} ${
                event?.venue?.add2 ? `${event?.venue?.add2},` : ''
              } ${event?.venue?.zip ? `${event?.venue?.zip},` : ''} ${
                event?.venue?.city ? `${event?.venue?.city},` : ''
              } ${event?.venue?.province ? `${event?.venue?.province}` : ''}`}
              venueName={event?.venue.name}
              pictureMap={event?.venue.mapImg}
              pictures={!event?.eventIsOutsourced && infoCompany?.pictures}
              timerange={`${
                event?.eventStartsAtLocaltime?.hours > 12
                  ? event?.eventStartsAtLocaltime?.hours - 12
                  : event?.eventStartsAtLocaltime?.hours
              }:${event?.eventStartsAtLocaltime?.minutes || '00'} ${
                event?.eventStartsAtLocaltime?.hours >= 12 ? 'pm' : 'am'
              } ${event?.eventEndsAtLocaltime ? '-' : ''} ${
                event?.eventEndsAtLocaltime?.hours > 12
                  ? event?.eventEndsAtLocaltime?.hours - 12
                  : event?.eventEndsAtLocaltime?.hours
              }:${event?.eventEndsAtLocaltime?.minutes || '00'} ${
                event?.eventEndsAtLocaltime?.hours >= 12 ? 'pm' : 'am'
              }`}
              urlMap={urlMapp}
              labelAcceptAttend={<FormattedMessage id="common.yes" />}
              labelDenyAttend={<FormattedMessage id="common.no" />}
              labelMayBeAttend={<FormattedMessage id="common.maybe" />}
              actionAcceptAttend={(value) => actionAttendance(event.eventId, value)}
              actionDenyAttend={(value) => actionAttendance(event.eventId, value)}
              actionMayBeAttend={(value) => actionAttendance(event.eventId, value)}
              titleAttend={<FormattedMessage id="ceremony.attendLabel" />}
              isAdmin={line.userGroup > 2}
              numAttendees={event.numAttendees}
              actionSeeAttendees={() => {}}
              showAmFormat={!fromEU}
              hasStreaming={event?.venue?.has_streaming}
              hasUser={user.data !== null}
              onLogin={() => {
                history.push(`/${actualLanguage}/register`);
              }}
              textTitleStreaming={<FormattedMessage id="ceremony.textTitleStreaming" />}
              textBodyStreamingWithUser={
                <FormattedMessage id="ceremony.textBodyStreamingWithUser" />
              }
              textBodyStreamingNoUser={<FormattedMessage id="ceremony.textBodyStreamingNoUser" />}
              textButtonStreaming={<FormattedMessage id="register.action" />}
              textStart={<FormattedMessage id="common.start" />}
              textEnd={<FormattedMessage id="common.end" />}
            />
          );
        })}
        <Flex
          justifyContent="center"
          paddingTop="10px"
          flexDirection="column"
          style={{ flexFlow: 'nowrap' }}
          width="100%"
          maxWidth="339px"
        >
          <Button
            id="addNewBadge"
            backgroundColor={customColor}
            colorType={customColor || 'orange'}
            color={customTextColor}
            sizeButton="big"
            action={addNewBadgeAction}
            width="100%"
            disabled={!line.isLegalTermAccepted}
          >
            <Icon marginRight={3} sizeIcon="display24">
              <IconWrite />
            </Icon>
            <FormattedMessage id="messages.writeYourMessage" />
          </Button>
        </Flex>
        <Button
          className="share-button"
          marginTop={2}
          backgroundColor={customColor}
          colorType={customColor || 'green'}
          color={customTextColor}
          onClick={() => {
            if (changeOpenShareMenu) {
              changeOpenShareMenu();
            }
          }}
          maxWidth="339px"
          fullWidth
          sizeButton="big"
          style={{
            textDecoration: 'none',
          }}
          id="actionShareBtn"
        >
          <Icon marginRight={2} sizeIcon="display24">
            <IconWhatsapp />
          </Icon>
          <FormattedMessage id="common.actionShareShortLabel" />
        </Button>
      </Flex>
    </Container>
  );
};

const CardCompanyLive = ({
  company,
  live,
  customColor,
  eventLiveAction,
  customTextColor,
  session,
  onNotLogged,
}) => {
  if (!company?.base?.showLiveStreamingCard) return null;

  const liveStream = live?.length ? live[0] : null;

  return (
    <CardLive
      marginBottom={2}
      label={intl.formatMessage({ id: 'ceremony.liveStreamTitle' })}
      statusInLive={!!live?.length}
      customColor={customColor}
      customTextColor={customTextColor}
      eventLiveAction={eventLiveAction}
      live={live?.length && live[0]}
      logged={session}
      onNotLogged={onNotLogged}
    >
      <Box padding={3} paddingTop={0}>
        {liveStream ? (
          <>
            {liveStream.streamUrl ? (
              <VideoPlayer src={liveStream.streamUrl} />
            ) : (
              <Box paddingX={3} paddingBottom={3}>
                {liveStream.provider === 'mux' ? (
                  <MuxPlayerContainer id={liveStream.streamEmbedId} streamType="live" />
                ) : (
                  <VideoYT id={liveStream.streamEmbedId} />
                )}
              </Box>
            )}
          </>
        ) : (
          <Alert
            title={<FormattedMessage id="ceremony.alertTitleNotStream" />}
            message={<FormattedMessage id="ceremony.alertDescriptionNotStream" />}
            colorType="yellow"
          />
        )}
      </Box>
    </CardLive>
  );
};

const CardCompanyFlowers = ({
  line,
  company,
  isEcommerceOpen,
  isTesting,
  customColor,
  customTextColor,
  intl,
  idTimeRemainingToCloseEcomerce,
  eventFlowerAction,
  actionGoToEcommerce,
}) => {
  if (!line.ecommerceIsAvailable) return null;

  /* si el ecommerce está desactivado y existe link externo se le da prioridad y se muestra la card de link externo */
  if (!company?.ecommerce_enabled && line?.flowersEcommerce) {
    return (
      <CardFlower
        titleLabel={<FormattedMessage id="flowerEcommerce.titleService" />}
        descriptionLabel={<FormattedMessage id="flowerEcommerce.descriptionService" />}
        actionLabel={<FormattedMessage id="flowerEcommerce.actionServiceVisit" />}
        actionButton={() => window.open(line?.flowersEcommerce?.url)}
        customColor={customColor}
        customTextColor={customTextColor}
        eventFlowerAction={eventFlowerAction}
        marginBottom={2}
      />
    );
  }

  if (isEcommerceOpen && (company?.ecommerce_enabled || isTesting)) {
    return (
      <CardFlower
        id="ecommerce"
        titleLabel={<FormattedMessage id="flowerEcommerce.titleService" />}
        descriptionLabel={<FormattedMessage id="flowerEcommerce.descriptionService" />}
        actionLabel={<FormattedMessage id="flowerEcommerce.actionService" />}
        actionButton={() => actionGoToEcommerce()}
        customColor={customColor}
        customTextColor={customTextColor}
        marginBottom={2}
        timeLeft={intl.formatMessage(
          { id: idTimeRemainingToCloseEcomerce },
          {
            hours: line?.hoursRemainingToCloseEcommerce,
            minutes: line?.minutesRemainingToCloseEcommerce,
          }
        )}
      />
    );
  }

  return null;
};

CeremoniesTemplate.defaultProps = {
  line: [],
};
