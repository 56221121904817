import { ModalLogin, ModalLoginPassCode } from '@lideralia/alife-uikit/dist/organisms/Modals';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LoginTemplate = (props) => <ModalLogin {...props} />;

export const LoginPasscodeTemplate = (props) => (
  <ModalLoginPassCode
    {...props}
    labelButtonCopy={<FormattedMessage id="passCode.labelButtonCopy" />}
    labelButtonReset={<FormattedMessage id="passCode.labelButtonReset" />}
    labelError4Digits={<FormattedMessage id="passCode.labelError4Digits" />}
    labelErrorReadingClipboardContents={
      <FormattedMessage id="passCode.labelErrorReadingClipboardContents" />
    }
  />
);
