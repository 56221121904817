import { constants } from '../config';

/* **** postBranchOrder *** */
export const postBranchOrder = (data) => ({
  type: constants.POST_BRANCH_ORDER,
  data,
});

export const postBranchOrderSuccess = (data) => ({
  type: constants.POST_BRANCH_ORDER_SUCCESS,
  payload: data,
});

export const postBranchOrderFail = (error) => ({
  type: constants.POST_BRANCH_ORDER_FAIL,
  error,
});

export const resetBranchOrder = () => ({
  type: constants.RESET_BRANCH_ORDER,
});

/* **** putBranchOrder *** */
export const putBranchOrder = (data) => ({
  type: constants.PUT_BRANCH_ORDER,
  data,
});

export const putBranchOrderSuccess = (data) => ({
  type: constants.PUT_BRANCH_ORDER_SUCCESS,
  payload: data,
});

export const putBranchOrderFail = (error) => ({
  type: constants.PUT_BRANCH_ORDER_FAIL,
  error,
});
