/* eslint-disable */

import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { resetBranchOrder } from '../../../common/schemes/branch_order';
import { getCertificateTree, resetOrder } from '../../../common/schemes/order/actions';
import { BranchOrderResumeTemplate } from '../template';
import { TreeOrderResumeTemplate } from '../template/TreeOrderResumePage';


const STORE_PRODUCT = "App\\Models\\StoreProduct"

const BranchOrderResumePage = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const branchOrder = useSelector((state) => state.branch_order);
  const orders = useSelector((state) => state.order);
  const setResetBranchOrder = useCallback(() => dispatch(resetBranchOrder()));
  const setResetOrder = useCallback(() => dispatch(resetOrder()));
  const getCertificateTreeAPI = useCallback((data) => dispatch(getCertificateTree(data)), [dispatch]);
  const [order, setOrder] = useState(null);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const [idActive, setIdActive] = useState(idLineStored || null);
  const { active } = line;

  const goHome = () => {
    const toBack = idActive ? `/${actualLanguage}/lines/${idActive}/${idNameStored}/ceremony` : `/${actualLanguage}/lines`
    history.push(`${toBack}`);
  };

  const getCertificate = () => {
    getCertificateTreeAPI({ uuid: order.uuid });
  }

  useEffect(() => {
    if(Object.keys(orders?.data).length > 0){
      if (orders?.data && orders?.data?.status === 'success') {
        setEventAnalytics(KEYS_ANALYTICS.ORDER_COMPLETED, user, history?.location?.pathname, active);
        setOrder(orders.data);
        setResetOrder();
      } else if (order === null) {
        goHome();
      }
    }

    if(Object.keys(branchOrder?.info).length > 0){
      if (branchOrder?.info && branchOrder?.info?.status === 'success') {
        setEventAnalytics(KEYS_ANALYTICS.ECOMMERCE_ORDER_COMPLETED, user, history?.location?.pathname, active);
        setOrder(branchOrder.info);
        setResetBranchOrder();
      } else if (order === null) {
        goHome();
      }
    }
  }, [branchOrder, orders]);

  if (order === null || Object.keys(order).length === 0) {
    return <Loader/>
  }

  if(order.orderable_type === STORE_PRODUCT) return <BranchOrderResumeTemplate goHome={goHome} branch_order={order} />;

  return <TreeOrderResumeTemplate goHome={goHome} order={order} getCertificate={getCertificate} />;
};

export default BranchOrderResumePage;

BranchOrderResumePage.propTypes = {};
