import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getExternalServices, getLine } from '../../../common/schemes/line';
import { SupportTemplate } from '../template/supportTemplate';

const SupportPage = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const {
    active,
    active: { lineId, company },
  } = line;
  const getExternalServicesAPI = useCallback(
    (id) => {
      dispatch(getExternalServices(id));
    },
    [dispatch]
  );
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const { idLine, idName } = useParams();
  useEffect(() => {
    if (lineId && typeof active?.external === 'undefined') {
      getExternalServicesAPI(active?.lineId);
    }
  }, [lineId]);

  useEffect(() => {
    if (!line.done && !line.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }

    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName]);

  const goToBereavement = () => {
    history.push(
      `/${actualLanguage}/profile/${active?.lineId}/${active?.nameUrl}/bereavement-support`
    );
  };
  return (
    <SupportTemplate
      user={user?.data}
      external={line?.active?.external?.filter((item) => item.url_service_type === 'bereavement')}
      goToBereavement={goToBereavement}
      goBack={() => history.goBack()}
      companyId={company?.companyId}
      eventExternalSupportAction={(custom) =>
        setEventAnalytics(
          KEYS_ANALYTICS.OUTBOUND_LINK_CLICKED,
          user,
          history?.location?.pathname,
          active,
          custom
        )
      }
    />
  );
};
export default SupportPage;
