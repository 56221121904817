const ParticipantItemMapper = {
  hydrate: (data) => {
    const fullName = data?.user?.name ?? '';
    const hydrated = {
      id: data?.uuid,
      userId: data?.user?.user_uuid,
      fullName,
      profilePicUrl: data?.user?.profile_pic_url,
      userRole: data?.user_role,
      lineId: data?.line?.line_uuid,
    };
    return hydrated;
  },
};

export const ParticipantsMapper = {
  hydrate: ({ data }) => {
    const hydrated = {
      me: {
        id: data?.me?.uuid,
        userId: data?.me?.user?.user_uuid,
        lineId: data?.me?.line?.line_uuid,
        userRole: data?.me?.user_role,
      },
      participants: data?.data?.map((el) => ParticipantItemMapper.hydrate(el)),
    };

    return hydrated;
  },
};
