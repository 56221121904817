/* eslint-disable no-nested-ternary */
import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { requestApi2, requestApiCSRF } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import { MapperVerifyLogin } from '../../../../pages/login/mapper';
import { deleteLocalStorage, setLocalStorage } from '../../../functions/cookies';
import { setUserLanguage } from '../../../functions/lang';
import { showAlert } from '../../../states/alerts';
import * as action from '../actions';
import { constants, services } from '../config';
import { AuthInfoMapper, MapperVerifyPhone } from '../mapper';

export function* getMeSaga() {
  try {
    const response = yield requestApi2(services.getMe());
    if (response && response?.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getMeSuccess(AuthInfoMapper.hydrate(data)));
      if (data?.language) {
        setUserLanguage(data?.language);
      }
    } else {
      throw response;
    }
  } catch (error) {
    deleteLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME);
    yield put(action.getMeFail(error));
  }
}

export function* updateMeSaga({ body }) {
  try {
    const response = yield requestApiCSRF(services.updateMe(body));
    if (response && response?.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.updateMeSuccess(AuthInfoMapper.hydrate(data)));
      if (data?.language) {
        setUserLanguage(data?.language);
      }
      yield put(showAlert('green', 1, 'Guardado con éxito'));
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.updateMeFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* updatePhonePassTroughSaga({ body }) {
  try {
    const response = yield requestApiCSRF(services.updatePhone(MapperVerifyPhone.dehydrate(body)));
    if (response && response?.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.updateMobilePasstroughSuccess({ phoneNumber: data.phone_number }));
      yield put(showAlert('green', 1, 'Guardado con éxito'));
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.updateMobilePasstroughFail(error));
    console.log('error-updatePhonePassTroughSaga', error, {
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
    /*
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    }); */
  }
}

export function* updatePhoneVerifySaga({ body }) {
  try {
    const response = yield requestApiCSRF(
      services.updatePhoneVerify(MapperVerifyPhone.dehydrate(body))
    );
    if (response && response?.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.updateMobileVerifySuccess({ phoneNumber: data.phone_number }));
      yield put(showAlert('green', 1, 'Guardado con éxito'));
      yield delay(5000);
      yield put(showAlert('', -1, ''));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.updateMobileVerifyFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getOwnerAuthCodeInfoSaga({ authCode }) {
  try {
    const response = yield requestApi2(services.getOwnerAuthCodeInfo(authCode));

    if (response && response.status >= 200 && response.status < 300) {
      const data = response?.data;
      yield put(action.getOwnerAuthCodeInfoSuccess(AuthInfoMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getOwnerAuthCodeInfoFail(error));
  }
}

export function* setVerifyRegisterSaga({ data }) {
  try {
    const dataMapped = MapperVerifyLogin.dehydrate(data);
    const response = yield requestApiCSRF(services.registerWithPhone(dataMapped));
    if (response && response.status >= 200 && response.status < 300) {
      const res = response.data;
      setLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME, res?.alife_token);
      yield put(action.setVerifyRegisterSuccess(AuthInfoMapper.hydrate(res)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setVerifyRegisterFail(error));
    /*
    yield put(
      showAlert(
        'red',
        1,
        window.intl.formatMessage({
          id: error?.message || 'error.generic',
        })
      )
    );
    yield delay(5000);
    yield put(showAlert('', -1, ''));
    */
  }
}

export function* postOwnerAuthCodeSaga({ authCode, rgpd, optinValue }) {
  try {
    const r = rgpd === undefined || rgpd === null ? 0 : rgpd ? 1 : 0;
    const o = optinValue === undefined || optinValue === null ? 0 : optinValue ? 1 : 0;
    const response = yield requestApiCSRF(services.postOwnerAuthCode(authCode, r, o));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      setLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME, data?.alife_token);
      setLocalStorage(
        process.env.REACT_APP_COOKIE_NAME_LINE,
        data?.auth_code_line_name.split(' ').join('-').toLowerCase()
      );
      setLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE, data?.auth_code_line_id);
      yield put(action.postOwnerAuthCodeSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.postOwnerAuthCodeFail(error));
    yield put(
      showAlert(
        'red',
        1,
        window.intl.formatMessage({
          id: 'error.generic',
        })
      )
    );
    yield delay(5000);
    yield put(showAlert('', -1, ''));
  }
}

export function* putUserProspectSaga(data) {
  try {
    const response = yield requestApi2(services.putUserProspect(data.payload), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      // yield put(action.putUserProspectSuccess(response));
    }
  } catch (error) {
    // yield put(action.putParticipantsFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

export function* postLogoutSaga({ lang }) {
  try {
    const response = yield requestApi2(services.postLogout());
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.postLogoutSuccess(response));
      window.location.href = `/${lang}/home`;
    }
  } catch (error) {
    yield put(action.postLogoutFail(error));
    yield put(
      showAlert(
        'red',
        1,
        window.intl.formatMessage({
          id: error?.message || 'error.generic',
        })
      )
    );
    yield delay(5000);
    yield put(showAlert('', -1, ''));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_ME, getMeSaga);
  yield takeLatest(constants.UPDATE_ME, updateMeSaga);
  yield takeLatest(constants.UPDATE_MOBILE_PASSTROUGH, updatePhonePassTroughSaga);
  yield takeLatest(constants.UPDATE_MOBILE_VERIFY, updatePhoneVerifySaga);
  yield takeLatest(constants.GET_OWNER_AUTH_CODE, getOwnerAuthCodeInfoSaga);
  yield takeLatest(constants.POST_LOGOUT, postLogoutSaga);
  yield takeLatest(constants.POST_OWNER_AUTH_CODE, postOwnerAuthCodeSaga);
  yield takeLatest(constants.SET_VERIFY_REGISTER, setVerifyRegisterSaga);
  yield takeLatest(constants.PUT_PROSPECT_USER, putUserProspectSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
