import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { ParticipantsMapper } from '../mapper';

export function* getParticipantsModalSaga({ lineId }) {
  try {
    const response = yield requestApi2(services.getParticipantsAndAdmins(lineId), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.getParticipantsSuccess(ParticipantsMapper.hydrate(response)));
    }
  } catch (error) {
    // yield put(action.getParticipantsFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

export function* putParticipantsModalSaga({ data }) {
  try {
    const response = yield requestApi2(services.putParticipantsAndAdmins(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.putParticipantsSuccess(response));
    }
  } catch (error) {
    // yield put(action.putParticipantsFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

export function* putParticipantsToAdminModalSaga({ data }) {
  try {
    const response = yield requestApi2(services.putParticipantsToAdmin(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.putParticipantsSuccess(response));
      yield put(action.getParticipants(data.idLine));
    }
  } catch (error) {
    // yield put(action.putParticipantsFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}
export function* removeParticipantsAdminModalSaga({ data }) {
  try {
    const response = yield requestApi2(services.removeParticipantsAdmin(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.putParticipantsSuccess(response));
      yield put(action.getParticipants(data.idLine));
    }
  } catch (error) {
    // yield put(action.putParticipantsFail(error));
    // yield put(
    //   showAlert(
    //     'red',
    //     1,
    //     window.intl.formatMessage({
    //       id: error?.message || 'error.generic',
    //     })
    //   )
    // );
    // yield delay(5000);
    // yield put(showAlert('', -1, ''));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_PARTICIPANTS, getParticipantsModalSaga);
  yield takeLatest(constants.PUT_PARTICIPANTS, putParticipantsModalSaga);
  yield takeLatest(constants.PUT_PARTICIPANTS_TO_ADMIN, putParticipantsToAdminModalSaga);
  yield takeLatest(constants.REMOVE_PARTICIPANTS_ADMIN, removeParticipantsAdminModalSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
