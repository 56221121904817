import { constants } from '../config';

const initialState = {
  show: false,
  product: null,
  selected: null,
};

export default function setShowProductDetailReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_PRODUCT_DETAIL: {
      return {
        ...state,
        show: action.show && action.value != null,
        product: action.value,
        selected: action.selected,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
