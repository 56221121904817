import { constants } from '../config';

export const getSettings = () => ({
  type: constants.GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: constants.GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsFail = (error) => ({
  type: constants.GET_SETTINGS_FAIL,
  error,
});
