import { useState } from 'react';
import { useSelector } from 'react-redux';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { actualLanguage } from '../../../common/functions/lang';
import { useLineData } from '../hooks/useLineData';
import UdianaTemplate from '../template';
import HeaderWithForest from '../template/HeaderWithForest';

const UdianasPage = ({ history }) => {
  const { lineDefault, nameDefault, active, forest, idLineStored, idNameStored } = useLineData();

  const user = useSelector((state) => state.user);
  const [showMoreTrees, setShowMoreTrees] = useState(false);

  const showMoreMessagesAction = () => {
    setShowMoreTrees(!showMoreTrees);
  };

  const goToPlantTree = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas/plant-tree`
    );
  };

  const goToFaqs = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas/faqs`
    );
  };

  const goToCeremony = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.VIEW_CEREMONY_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/ceremony`
    );
  };

  const itemsTrees = forest?.trees.map((tree) => ({
    ...tree,
    clickCardRedirectTo: `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
      nameDefault || idNameStored
    }/udianas/${tree.treeProduct.id}/description?project=${tree.treeProduct.projectHashId}&udiana=${
      tree.udianaUuid
    }`,
  }));

  return (
    <HeaderWithForest
      titleForestOf={intl.formatMessage({ id: 'udianas.forestOfWithName' }, { name: active?.name })}
      treesPlanted={intl.formatMessage(
        { id: 'udianas.treesQuantity' },
        { trees: active?.treeSumPerLine }
      )}
      weather={forest?.totalKgCo2OffsettedOnForest}
      showButtonBack
      actionGoBack={() => goToCeremony()}
    >
      <UdianaTemplate
        showMoreMessagesAction={showMoreMessagesAction}
        itemsTrees={itemsTrees}
        showMoreTrees={showMoreTrees}
        company={active?.company}
        customColor={active?.company?.style?.primaryColor}
        customTextColor={active?.company?.style?.textColor}
        goToPlantTreeAction={goToPlantTree}
        goToFaqsAction={goToFaqs}
      />
    </HeaderWithForest>
  );
};
export default UdianasPage;
