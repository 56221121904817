import { constants } from '../config';

export default function setCountriesReducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_COUNTRIES:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_COUNTRIES_SUCCESS: {
      return { ...state, loading: false, data: [...action.payload], done: true };
    }
    case constants.GET_COUNTRIES_FAIL:
      return { ...state, loading: false, errorRequest: true };

    default:
      return state;
  }
}
