import { constants } from '../config';

/* **** getCompanyLines **** */
export const getCompanyLines = (identifier, name, valueToken) => ({
  type: constants.GET_COMPANY_LINE,
  identifier,
  name,
  valueToken,
});

export const getCompanyLinesSuccess = (data) => ({
  type: constants.GET_COMPANY_LINE_SUCCESS,
  payload: data,
});

export const getCompanyLinesFail = (error) => ({
  type: constants.GET_COMPANY_LINE_FAIL,
  error,
});

/* **** getCompany **** */
export const getCompany = (companyUuid) => ({
  type: constants.GET_COMPANY,
  companyUuid,
});

export const getCompanySuccess = (data) => ({
  type: constants.GET_COMPANY_SUCCESS,
  payload: data,
});

export const getCompanyFail = (error) => ({
  type: constants.GET_COMPANY_FAIL,
  error,
});
