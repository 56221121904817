/* eslint-disable no-nested-ternary,no-unused-vars */
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconCheckCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCheckCircle';
import IconCloseCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCloseCircle';
import IconLike from '@lideralia/alife-uikit/dist/atoms/Icons/IconLike';
import IconPhoneCall from '@lideralia/alife-uikit/dist/atoms/Icons/IconPhoneCall';
import IconUser from '@lideralia/alife-uikit/dist/atoms/Icons/IconUser';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Pill } from '@lideralia/alife-uikit/dist/atoms/Pill';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoOwnerNoNpsLead } from './NoOwnerNoNpsLead';
import { NoOwnerNoNpsNoLead } from './NoOwnerNoNpsNoLead';
import { NoOwnerNpsLead } from './NoOwnerNpsLead';
import { NoOwnerNpsNoLead } from './NoOwnerNpsNoLead';
import { OwnerNoNpsLead } from './OwnerNoNpsLead';
import { OwnerNpsLead } from './OwnerNpsLead';

export const PollTemplate = ({
  poll,
  line,
  company,
  valueRecommendation,
  changeValueRecommendation,
  valueStarsHuman,
  setValueStarsHuman,
  valueStarsInstallations,
  setValueStarsInstallations,
  valueStarsServices,
  setValueStarsServices,
  setValueRangeQuestion,
  valueRangeQuestion,
  valuePollFuneralAttendanceQ1,
  setValuePollFuneralAttendanceQ1,
  valuePollSuggestions,
  setValuePollSuggestions,
  setValuePollFuneralOptionsQ1,
  valuePollFuneralOptionsQ1,
  setFinishPoll,
  setValuePollAlifeTechnologyQ1,
  valuePollAlifeTechnologyQ1,
  setValuePollAlifeTechnologyQ2,
  valuePollAlifeTechnologyQ2,
  setValuePollAlifeTechnologyQ3,
  valuePollAlifeTechnologyQ3,
  setValuePollAlifeTechnologyQ4,
  valuePollAlifeTechnologyQ4,
  isFinishPoll,
  showFuneralOptions,
  onSubmit,
  handleResetPoll,
  goToFamilyZone,
  ownerNpsNoLead,
  ownerNoNpsLead,
  ownerNpsLead,
  noOwnerNpsNoLead,
  noOwnerNoNpsNoLead,
  noOwnerNpsLead,
  noOwnerNoNpsLead,
}) => (
  <Container paddingY={3} paddingBottom={8}>
    {!poll.error ? (
      <>
        {!poll.done && !isFinishPoll ? (
          <Loader customColor={company?.style?.primaryColor} />
        ) : !isFinishPoll ? (
          <Flex alignItems="center" flexDirection="column" marginBottom={6}>
            <CardGone
              name={line?.active?.name}
              date={
                line?.active?.dateFrom?.year &&
                `${line?.active?.dateFrom?.year} - ${line?.active?.dateTo?.year}`
              }
              picture={line?.active?.pictures?.m}
              isOpened={false}
              setIsEditMode={() => {}}
              isEditMode={false}
            />
            <Alert
              message={
                <FormattedMessage
                  id="poll.alertMessage"
                  values={{
                    name: poll?.data?.userName,
                    branchName: poll?.data?.branchName,
                    tradename: poll?.data?.company?.tradename,
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              }
              zIndex={0}
              marginBottom={3}
              marginTop={3}
            />
            {ownerNoNpsLead && (
              <OwnerNoNpsLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
              />
            )}
            {(ownerNpsLead || ownerNpsNoLead) && (
              <OwnerNpsLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
                setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
                valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
                setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
                valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
                setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
                valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
                setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
                valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
                valueRecommendation={valueRecommendation}
                changeValueRecommendation={changeValueRecommendation}
              />
            )}
            {noOwnerNpsNoLead && (
              <NoOwnerNpsNoLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
                setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
                valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
                setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
                valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
                setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
                valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
                setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
                valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
                valueRecommendation={valueRecommendation}
                changeValueRecommendation={changeValueRecommendation}
              />
            )}
            {noOwnerNoNpsNoLead && (
              <NoOwnerNoNpsNoLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
                setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
                valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
                setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
                valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
                setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
                valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
                setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
                valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
              />
            )}
            {noOwnerNpsLead && (
              <NoOwnerNpsLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
                setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
                valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
                setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
                valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
                setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
                valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
                setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
                valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
                valueRecommendation={valueRecommendation}
                changeValueRecommendation={changeValueRecommendation}
              />
            )}
            {noOwnerNoNpsLead && (
              <NoOwnerNoNpsLead
                poll={poll}
                company={company}
                valueStarsHuman={valueStarsHuman}
                setValueStarsHuman={setValueStarsHuman}
                valueStarsInstallations={valueStarsInstallations}
                setValueStarsInstallations={setValueStarsInstallations}
                valueStarsServices={valueStarsServices}
                setValueStarsServices={setValueStarsServices}
                setValueRangeQuestion={setValueRangeQuestion}
                valueRangeQuestion={valueRangeQuestion}
                valuePollFuneralAttendanceQ1={valuePollFuneralAttendanceQ1}
                setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1}
                valuePollSuggestions={valuePollSuggestions}
                setValuePollSuggestions={setValuePollSuggestions}
                setFinishPoll={setFinishPoll}
                setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
                valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
                setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
                valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
                setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
                valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
                setValuePollAlifeTechnologyQ4={setValuePollAlifeTechnologyQ4}
                valuePollAlifeTechnologyQ4={valuePollAlifeTechnologyQ4}
                showFuneralOptions={showFuneralOptions}
                onSubmit={onSubmit}
                handleResetPoll={handleResetPoll}
              />
            )}
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="center" flexDirection="column">
            {(!valuePollFuneralOptionsQ1 || valuePollFuneralOptionsQ1 === 'I_DONT_CARE') && (
              <Card
                maxWidth="339px"
                minWidth="290px"
                position="relative"
                width="100%"
                marginBottom={3}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingX={3}
                  width="100%"
                  paddingY={3}
                >
                  <Pill backgroundColor="red" padding={4} borderRadius={7}>
                    <Icon color="white" sizeIcon="display24">
                      <IconLike />
                    </Icon>
                  </Pill>
                  <Text
                    id="pollConfirmTitle"
                    fontWeight="bold"
                    sizeText="display16"
                    color="black84"
                    marginTop={1}
                    textAlign="center"
                  >
                    <FormattedMessage id="poll.confirmPoll.title" />
                  </Text>
                  <Text marginTop={1} sizeText="display13" color="black60" textAlign="center">
                    <FormattedMessage id="poll.confirmPoll.description" />
                  </Text>
                </Flex>
              </Card>
            )}
            {showFuneralOptions && !valuePollFuneralOptionsQ1 && (
              <Card
                maxWidth="339px"
                minWidth="290px"
                position="relative"
                width="100%"
                marginBottom={3}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingX={3}
                  width="100%"
                  paddingY={3}
                >
                  <Text
                    fontWeight="bold"
                    sizeText="display16"
                    color="black"
                    marginTop={1}
                    textAlign="center"
                  >
                    <FormattedMessage id="poll.pollFuneralMoreInfo.titleOptions" />
                  </Text>
                  <Text marginTop={1} sizeText="display13" color="black84" textAlign="center">
                    <FormattedMessage id="poll.pollFuneralMoreInfo.descriptionOptions" />
                  </Text>
                </Flex>
                <Box paddingX={3} paddingBottom={3}>
                  <Button
                    colorType="green"
                    sizeButton="big"
                    fullWidth
                    marginBottom={1}
                    onClick={() => {
                      setValuePollFuneralOptionsQ1('MORE_INFO');
                      handleResetPoll();
                    }}
                  >
                    <Icon sizeIcon="display24" marginRight={2}>
                      <IconCheckCircle />
                    </Icon>
                    <FormattedMessage id="poll.pollFuneralMoreInfo.approveOption" />
                  </Button>
                  <Button
                    colorType="red"
                    fullWidth
                    sizeButton="big"
                    onClick={() => {
                      setValuePollFuneralOptionsQ1('I_DONT_CARE');
                      handleResetPoll();
                    }}
                  >
                    <Icon sizeIcon="display24" marginRight={2}>
                      <IconCloseCircle />
                    </Icon>
                    <FormattedMessage id="poll.pollFuneralMoreInfo.declineOption" />
                  </Button>
                </Box>
              </Card>
            )}
            {valuePollFuneralOptionsQ1 === 'MORE_INFO' && (
              <Card
                maxWidth="339px"
                minWidth="290px"
                position="relative"
                width="100%"
                marginBottom={3}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingX={3}
                  width="100%"
                  paddingY={3}
                >
                  <Pill backgroundColor="green" padding={4} borderRadius={7}>
                    <Icon color="white" sizeIcon="display24">
                      <IconPhoneCall />
                    </Icon>
                  </Pill>
                  <Text
                    fontWeight="bold"
                    sizeText="display16"
                    color="black84"
                    marginTop={1}
                    textAlign="center"
                  >
                    <FormattedMessage id="poll.pollFuneralMoreInfo.titleConfirm" />
                  </Text>
                  <Text marginTop={1} sizeText="display13" color="black60" textAlign="center">
                    <FormattedMessage id="poll.pollFuneralMoreInfo.descriptionConfirm" />
                  </Text>
                </Flex>
              </Card>
            )}
            {(valuePollFuneralOptionsQ1 === 'MORE_INFO' || !showFuneralOptions) && (
              <Button fullWidth colorType="black" maxWidth="339px" action={goToFamilyZone}>
                <Icon sizeIcon="display24" marginRight={1}>
                  <IconUser />
                </Icon>
                <FormattedMessage id="poll.goToFamilyZone" />
              </Button>
            )}
          </Flex>
        )}
      </>
    ) : (
      <Loader customColor={company?.info?.style?.primaryColor} />
    )}
  </Container>
);
