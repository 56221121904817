/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

const enTranslationMessages = require('../../cli/common/i18n/en.json');
const esTranslationMessages = require('../../cli/common/i18n/es.json');
const esMXTranslationMessages = require('../../cli/common/i18n/es-MX.json');
const caTranslationMessages = require('../../cli/common/i18n/ca.json');
const deTranslationMessages = require('../../cli/common/i18n/de.json');
const euTranslationMessages = require('../../cli/common/i18n/eu.json');
const frTranslationMessages = require('../../cli/common/i18n/fr.json');
const itTranslationMessages = require('../../cli/common/i18n/it.json');
const plTranslationMessages = require('../../cli/common/i18n/pl.json');
const ptTranslationMessages = require('../../cli/common/i18n/pt.json');
const nlTranslationMessages = require('../../cli/common/i18n/nl.json');
const ukTranslationMessages = require('../../cli/common/i18n/uk.json');

export const DEFAULT_LOCALE = 'en';

export const appLocales = ['en', 'es', 'es-MX', 'ca', 'de', 'eu', 'fr', 'it', 'pl', 'pt', 'nl', 'uk'];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, esTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  'es-MX': formatTranslationMessages('es-MX', esMXTranslationMessages),
  ca: formatTranslationMessages('ca', caTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  pl: formatTranslationMessages('pl', plTranslationMessages),
  eu: formatTranslationMessages('eu', euTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  it: formatTranslationMessages('it', itTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
  uk: formatTranslationMessages('uk', ukTranslationMessages),
};
