import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { WhoisMapper } from '../mapper';

export function* getWhoisSaga({ lang }) {
  try {
    const response = yield requestApi2(services.getWhois(lang));
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.getWhoisSuccess(WhoisMapper.hydrate(response.data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getWhoisFail(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_WHOIS, getWhoisSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
