import { completeLangFn } from '../functions/lang';

const formatted = (dateFormatISO) => {
  const formattedMoth = dateFormatISO.getMonth() + 1;

  const optionsAll = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsNoYear = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  const optionsNoYearMonthShort = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  };

  const optionsSemi = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsMonth = {
    month: 'long',
  };

  const optionsDay = {
    weekday: 'long',
  };

  const hours = dateFormatISO.getHours();
  const redableHours = hours > 9 ? hours : `0${hours}`;

  const minuts = dateFormatISO.getMinutes();
  const redableMinuts = minuts > 9 ? minuts : `0${minuts}`;

  return {
    isoFormat: dateFormatISO,
    readableStringDate: dateFormatISO.toLocaleDateString(completeLangFn(), optionsSemi),
    readableStringDateNoYear: dateFormatISO.toLocaleDateString(completeLangFn(), optionsNoYear),
    readableStringDateNoYearShortMonth: dateFormatISO.toLocaleDateString(
      completeLangFn(),
      optionsNoYearMonthShort
    ),
    readableStringDateLarge: dateFormatISO.toLocaleDateString(completeLangFn(), optionsAll),
    stringMonth: dateFormatISO.toLocaleDateString(completeLangFn(), optionsMonth),
    stringDay: dateFormatISO.toLocaleDateString(completeLangFn(), optionsDay),
    dateNumberFormat: dateFormatISO.getDate(),
    dayNumberFormat: dateFormatISO.getDay(),
    timeStamp: dateFormatISO.getTime(),
    hours: dateFormatISO.getHours(),
    year: dateFormatISO.getFullYear(),
    minutes: dateFormatISO.getMinutes(),
    seconds: dateFormatISO.getSeconds(),
    readableTime: `${redableHours}:${redableMinuts}`,
    readableDate: `${dateFormatISO.getFullYear()}-${
      formattedMoth < 10 ? `0${formattedMoth}` : formattedMoth
    }-${dateFormatISO.getDate() < 10 ? `0${dateFormatISO.getDate()}` : dateFormatISO.getDate()}`,
    readableTimeAmPm: `${redableHours > 12 ? redableHours - 12 : redableHours}:${redableMinuts} ${
      redableHours >= 12 ? 'pm' : 'am'
    }`,
    isToday:
      new Date().toLocaleDateString(completeLangFn(), optionsSemi) ===
      dateFormatISO.toLocaleDateString(completeLangFn(), optionsSemi),
  };
};

export const FormatDate = {
  hydrate(date) {
    if (date) {
      const dateFormatISO = new Date(date);
      return formatted(dateFormatISO);
    }
    return undefined;
  },

  dehydrate(date, hour, minute) {
    const separatedDate = date.split('-');

    return JSON.stringify(
      new Date(
        parseInt(separatedDate[0], 10),
        parseInt(separatedDate[1], 10) - 1,
        parseInt(separatedDate[2], 10),
        parseInt(hour, 10),
        parseInt(minute, 10),
        0
      )
    ).replace(/"/g, '');
  },
};

export const FormatDateWithoutTimeZone = {
  hydrate(date) {
    if (date) {
      // Z es con Timezone
      const dateFormatISO = new Date(date.replace('Z', ''));
      return formatted(dateFormatISO);
    }
    return undefined;
  },
  dehydrate(date, hour, minute) {
    const separatedDate = date.split('-');

    return JSON.stringify(
      new Date(
        parseInt(separatedDate[0], 10),
        parseInt(separatedDate[1], 10) - 1,
        parseInt(separatedDate[2], 10),
        parseInt(hour, 10),
        parseInt(minute, 10),
        0
      )
    ).replace(/"/g, '');
  },
};
