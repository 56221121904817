export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};

export const getLocalStorage = (name) => localStorage.getItem(name);

export const deleteLocalStorage = (name) => localStorage.removeItem(name);

export const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value);
};

export const getSessionStorage = (name) => sessionStorage.getItem(name);

export const deleteSessionStorage = (name) => sessionStorage.removeItem(name);

// Create cookie
export const setCookieFromStorage = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

// Delete cookie
export const deleteCookieFromStorage = (cname) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=;${expires};path=/`;
};

// Read cookie
export const getCookieFromStorage = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// Set cookie consent
export const acceptCookieConsent = (consent) => {
  deleteCookieFromStorage('user_cookie_consent');
  setCookieFromStorage('user_cookie_consent', consent, 180);
};
