import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconCamera from '@lideralia/alife-uikit/dist/atoms/Icons/IconCamera';
import IconVideo from '@lideralia/alife-uikit/dist/atoms/Icons/IconVideo';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import {
  addContent,
  editContent,
  getContent,
  getLine,
  resetContent,
} from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import { VideoContentTemplate } from '../template/videoContent';

const VideoContentPage = ({ history }) => {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const isShowLogin = useSelector((state) => state.showLogin);
  const loading = useSelector((state) => state.loading);

  const {
    contentActive,
    active: { company },
  } = line;

  const dispatch = useDispatch();

  const { idContent } = useParams();

  const [data, setData] = useState({
    content_type: 'text',
    url: '',
  });

  const [haveContent, setHaveContent] = useState(false);

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getContentAPI = useCallback((id) => dispatch(getContent(id)), [dispatch]);
  const addContentAPI = useCallback((id, content) => dispatch(addContent(id, content)));
  const resetContentAPI = useCallback(() => dispatch(resetContent()));
  const editContentAPI = useCallback(
    (id, content) => dispatch(editContent(id, idContent, content)),
    [dispatch]
  );
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  useEffect(() => {
    if (!user.done && !isShowLogin.show) {
      setShowRegisterState(1);
    }
  }, [user]);

  useEffect(() => {
    if (!line.done && !line.loading && !user.done) {
      getLineAPI(idLineStored, idNameStored);
    }
  }, [line, user, idLineStored, idNameStored]);

  useEffect(() => {
    if (data && (data?.caption || data?.url)) {
      setHaveContent(true);
    }
  }, [data]);

  useEffect(() => {
    if (line.addContentDone && haveContent) {
      history.push(
        `/${actualLanguage}/lines/${contentActive.lineId}/${contentActive.nameUrl}/family-zone#${contentActive.contentId}`
      );
    }

    if (contentActive?.caption) {
      setData((prev) => ({
        ...prev,
        caption: line.contentActive?.caption,
      }));
    }
  }, [line, contentActive]);

  useEffect(() => {
    if (idContent && (!line.contentActive || line.contentActive?.contentId !== idContent)) {
      getContentAPI(idContent);
    }
  }, [idContent]);

  const handleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      content_type: 'video',
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!loading.show) {
      if (idContent) {
        editContentAPI(line?.active?.lineId, data);
      } else {
        addContentAPI(line?.active?.lineId, data);
      }
    }
  };

  return (
    <>
      <VideoContentTemplate
        customColor={company?.style?.primaryColor}
        customTextColor={company?.style?.textColor}
        line={line?.active}
        content={contentActive}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        haveContent={haveContent}
        itemsTab={[
          {
            id: 'image',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconCamera />
                </Icon>
                Foto
              </>
            ),
            isActive: false,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/add/image`);
            },
          },
          {
            id: 'video',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconVideo />
                </Icon>
                Video
              </>
            ),
            isActive: true,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/add/video`);
            },
          },
          {
            id: 'text',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconWrite />
                </Icon>
                Texto
              </>
            ),
            isActive: false,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/add/text`);
            },
          },
        ]}
      />
    </>
  );
};

export default VideoContentPage;

VideoContentPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
