import { constants } from '../config';

export default function setHelperReducer(state = {}, action) {
  switch (action.type) {
    case constants.GET_RGPD:
      return { ...state, loading: true, done: false, errorRequest: false };
    case constants.GET_RGPD_SUCCESS: {
      return { ...state, loading: false, data: action.payload, done: true };
    }
    case constants.GET_RGPD_FAIL:
      return { ...state, loading: false, errorRequest: true };

    default:
      return state;
  }
}
