export const constants = {
  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',

  GET_TREES_FROM_PROJECT: 'GET_TREES_FROM_PROJECT',
  GET_TREES_FROM_PROJECT_SUCCESS: 'GET_TREES_FROM_PROJECT_SUCCESS',
  GET_TREES_FROM_PROJECT_FAILURE: 'GET_TREES_FROM_PROJECT_FAILURE',

  GET_TREE_DESCRIPTION: 'GET_TREE_DESCRIPTION',
  GET_TREE_DESCRIPTION_SUCCESS: 'GET_TREE_DESCRIPTION_SUCCESS',
  GET_TREE_DESCRIPTION_FAILURE: 'GET_TREE_DESCRIPTION_FAILURE',

  GET_UDIANA_KEY_STRIPE: 'GET_UDIANA_KEY_STRIPE',
  GET_UDIANA_KEY_STRIPE_SUCCESS: 'GET_UDIANA_KEY_STRIPE_SUCCESS',
};

export const services = {
  getPlantationProjects: () => ({
    route: `/api/v2/tree-products/projects`,
    method: 'get',
  }),

  getTreesFromProject: (id) => ({
    route: `/api/v2/tree-products/projects/${id}/trees`,
    method: 'get',
  }),

  getTreeDescription: (id, udianaUuid) => ({
    route: `/api/v2/tree-products/${id}/description${
      udianaUuid ? `?udiana_uuid=${udianaUuid}` : ''
    }`,
    method: 'get',
  }),

  getUdianaKeyStripe: () => ({
    route: `/api/v2/udianas-stripe-account`,
    method: 'get',
  }),
};
