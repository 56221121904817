import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconEditText from '@lideralia/alife-uikit/dist/atoms/Icons/IconEditText';
import IconNotification from '@lideralia/alife-uikit/dist/atoms/Icons/IconNotification';
import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardNotification } from '@lideralia/alife-uikit/dist/organisms/Cards/Notification';
import css from '@styled-system/css';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { actualLanguage } from '../../../common/functions/lang';
import { setTypeNotification } from '../config';

const BadgeStyled = styled(Text)(
  css({
    backgroundColor: 'red',
    paddingY: 1,
    paddingX: 2,
    borderRadius: 7,
    color: 'white',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const NotificationTemplate = ({
  info,
  notifications,
  handleClickNotification,
  handleGetHashPoll,
  getLineAPI,
  history: { push },
  customColor,
}) => (
  <>
    <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
      <Text
        fontWeight="bold"
        sizeText="display24"
        color="black84"
        display="flex"
        style={{ alignItems: 'center' }}
      >
        <FormattedMessage id="profile.notifications.title" />
        {(info.unReadNotifications && (
          <BadgeStyled fontWeight="bold" sizeText="display12" marginLeft={2}>
            {info.unReadNotifications}
          </BadgeStyled>
        )) ||
          ''}
      </Text>
    </Flex>
    {!notifications.length ? (
      <Card paddingY={3} padding={4}>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          <Button
            colorType="black"
            sizeButton="big"
            rounded
            boxShadow="none"
            style={{ pointerEvents: 'none' }}
            justifyContent="flex-start"
            action={() => {}}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconNotification />
            </Icon>
          </Button>
          <Text
            fontWeight="bold"
            sizeText="display16"
            color="black84"
            textAlign="center"
            marginTop={2}
            marginBottom={1}
          >
            <FormattedMessage id="profile.notifications.empty" />
          </Text>
          <Text sizeText="display13" color="black60" textAlign="center">
            <FormattedMessage id="profile.notifications.emptyDescription" />
          </Text>
        </Flex>
      </Card>
    ) : (
      <>
        {notifications.map((element) => {
          let logo = element?.author?.pictures?.m;
          if (element.notificationType === 2072) {
            logo = element?.company?.logo?.m;
          }
          if (
            element.notificationType === 2012 ||
            element.notificationType === 210 ||
            element.notificationType === 201 ||
            element.notificationType === 202 ||
            element.notificationType === 2021
          ) {
            logo = element?.line?.pictures?.m;
          }

          return (
            <CardNotification
              {...element}
              key={element.notificationId}
              picture={logo}
              authorName={element.author?.name}
              customColor={customColor}
              message={
                <FormattedMessage
                  id={`notification.${setTypeNotification[element.notificationType]}`}
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                    name: (
                      <Text
                        color={customColor || 'orange'}
                        as="span"
                        fontWeight="bold"
                        sizeText="display14"
                      >
                        {element.author?.name}
                      </Text>
                    ),
                    line: (
                      <Text color="black" as="span" fontWeight="bold" sizeText="display14">
                        {element.line?.name}
                      </Text>
                    ),
                    contentType: (
                      <Text color="black" as="span" fontWeight="bold" sizeText="display14">
                        {intl.formatMessage({
                          id: `contentType.${element.content?.contentType}`,
                        })}
                      </Text>
                    ),
                    yourContentType: (
                      <Text color="black" as="span" fontWeight="bold" sizeText="display14">
                        {intl.formatMessage({
                          id: `yourContentType.${element.content?.contentType}`,
                        })}
                      </Text>
                    ),
                    companyName: (
                      <Text
                        color={customColor || 'orange'}
                        as="span"
                        fontWeight="bold"
                        sizeText="display14"
                      >
                        {element.company?.tradename}
                      </Text>
                    ),
                  }}
                />
              }
              date={element.timeAgo}
              onClick={async () => {
                handleClickNotification(element.notificationId);
                await getLineAPI(element.line?.lineId, element.line?.nameUrl);
                if (element.content?.contentId) {
                  push(
                    `/${actualLanguage}/lines/${element.line?.lineId}/${element.line?.nameUrl}/family-zone#${element.content?.contentId}`
                  );
                } else if (
                  element.line?.lineId &&
                  element.notificationType !== (2070 && 2071 && 2072)
                ) {
                  push(`/${actualLanguage}/lines/${element.line?.lineId}/${element.line?.nameUrl}`);
                } else if (element.line?.lineId && element.notificationType === 2070) {
                  push(
                    `/${actualLanguage}/lines/${element.line?.lineId}/${element.line?.nameUrl}/ceremony`
                  );
                } else if (element.line?.lineId && element.notificationType === 2072) {
                  handleGetHashPoll(element);
                }
              }}
              isSurvey={element.notificationType === 2072}
              contentFooter={
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Text color="black" fontWeight="bold" sizeText="display14">
                    <FormattedMessage id="notification.howCanWeImprove" />
                  </Text>{' '}
                  <Button colorType={customColor || 'green'} sizeButton="small" className="opinion">
                    <Icon sizeIcon="display24" marginRight={1}>
                      <IconEditText />
                    </Icon>{' '}
                    <FormattedMessage id="notification.pollActionLabel" />
                  </Button>
                </Flex>
              }
            />
          );
        })}
      </>
    )}
  </>
);
