import { constants } from '../config';

const initialState = {
  show: 0,
  showRegister: 0,
};

export default function setShowLoginReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_LOGIN: {
      const nextShowValue = state.show + action.value;
      return {
        ...state,
        show: nextShowValue > 0 ? nextShowValue : false,
      };
    }

    case constants.SHOW_REGISTER: {
      const nextShowValue = state.showRegister + action.value;
      return {
        ...state,
        showRegister: nextShowValue > 0 ? nextShowValue : false,
      };
    }
    default:
      return state;
  }
}
