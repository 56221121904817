import { fork } from 'redux-saga/effects';
import infoAuthCode from '../../common/helpers/authCode/sagas';
import conditions from '../../common/helpers/conditions/sagas';
import conditionsGeneralEcommerce from '../../common/helpers/conditionsGeneralEcommerce/sagas';
import cookies from '../../common/helpers/cookies/sagas';
import countries from '../../common/helpers/countries/sagas';
import privacy from '../../common/helpers/privacy/sagas';
import rgpdHelper from '../../common/helpers/rgpd/sagas';
import settings from '../../common/helpers/settings/sagas';
import whois from '../../common/helpers/whois/sagas';
import branch_order from '../../common/schemes/branch_order/sagas';
import showConfirmation from '../../common/schemes/confirmation/sagas';
import showCookies from '../../common/schemes/cookies/sagas';
import line from '../../common/schemes/line/sagas';
import live_chat_params from '../../common/schemes/live_chat_params/sagas';
import showLogin from '../../common/schemes/login/sagas';
import showNotAllowedToBuy from '../../common/schemes/not_allowed_to_buy/sagas';
import notifications from '../../common/schemes/notifications/sagas';
import order from '../../common/schemes/order/sagas';
import showParticipants from '../../common/schemes/participants/sagas';
import products_by_category from '../../common/schemes/products_by_category/sagas';
import udianas from '../../common/schemes/udianas/sagas';
import user from '../../common/schemes/user/sagas';
import lines from '../../pages/lines/sagas';
import login from '../../pages/login/sagas';
import poll from '../../pages/poll/sagas';
import rgpd from '../../pages/rgpd/sagas';
import search from '../../pages/search/sagas';

export default function* root() {
  yield fork(countries);
  yield fork(conditions);
  yield fork(conditionsGeneralEcommerce);
  yield fork(cookies);
  yield fork(privacy);
  yield fork(login);
  yield fork(showLogin);
  yield fork(user);
  yield fork(rgpdHelper);
  yield fork(rgpd);
  yield fork(notifications);
  yield fork(lines);
  yield fork(line);
  yield fork(poll);
  yield fork(infoAuthCode);
  yield fork(products_by_category);
  yield fork(branch_order);
  yield fork(showNotAllowedToBuy);
  yield fork(showCookies);
  yield fork(showParticipants);
  yield fork(whois);
  yield fork(showConfirmation);
  yield fork(live_chat_params);
  yield fork(settings);
  yield fork(udianas);
  yield fork(order);
  yield fork(search);
}
