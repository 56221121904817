import { constants } from '../config';

/* **** getMe *** */
export const getMe = () => ({
  type: constants.GET_ME,
});

export const getMeSuccess = (data) => ({
  type: constants.GET_ME_SUCCESS,
  payload: data,
});

export const getMeFail = (error) => ({
  type: constants.GET_ME_FAIL,
  error,
});

/* **** updateMe *** */
export const updateMe = (body) => ({
  type: constants.UPDATE_ME,
  body,
});

export const updateMeSuccess = (data) => ({
  type: constants.UPDATE_ME_SUCCESS,
  payload: data,
});

export const updateMeFail = (error) => ({
  type: constants.UPDATE_ME_FAIL,
  error,
});

/* **** updateMobilePassTrough *** */
export const updateMobilePasstrough = (body) => ({
  type: constants.UPDATE_MOBILE_PASSTROUGH,
  body,
});

export const updateMobilePasstroughSuccess = (data) => ({
  type: constants.UPDATE_MOBILE_PASSTROUGH_SUCCESS,
  payload: data,
});

export const updateMobilePasstroughFail = (error) => ({
  type: constants.UPDATE_MOBILE_PASSTROUGH_FAIL,
  error,
});

/* **** updateMobileVerify *** */
export const updateMobileVerify = (body) => ({
  type: constants.UPDATE_MOBILE_VERIFY,
  body,
});

export const updateMobileVerifySuccess = (data) => ({
  type: constants.UPDATE_MOBILE_VERIFY_SUCCESS,
  payload: data,
});

export const updateMobileVerifyFail = (error) => ({
  type: constants.UPDATE_MOBILE_VERIFY_FAIL,
  error,
});

/* **** getOwnerAuthCodeInfo *** */
export const getOwnerAuthCodeInfo = (authCode) => ({
  type: constants.GET_OWNER_AUTH_CODE,
  authCode,
});

export const getOwnerAuthCodeInfoSuccess = (data) => ({
  type: constants.GET_OWNER_AUTH_CODE_SUCCESS,
  payload: data,
});

export const getOwnerAuthCodeInfoFail = (error) => ({
  type: constants.GET_OWNER_AUTH_CODE_FAIL,
  error,
});

export const postLogout = (lang) => ({
  type: constants.POST_LOGOUT,
  lang,
});

export const postLogoutSuccess = (data) => ({
  type: constants.GET_ME_SUCCESS,
  payload: data,
});

export const postLogoutFail = (error) => ({
  type: constants.GET_ME_FAIL,
  error,
});

/* **** postOwnerAuthCodeInfo *** */
export const postOwnerAuthCode = (authCode, rgpd, optinValue) => ({
  type: constants.POST_OWNER_AUTH_CODE,
  authCode,
  rgpd,
  optinValue,
});

export const postOwnerAuthCodeSuccess = (data) => ({
  type: constants.POST_OWNER_AUTH_CODE_SUCCESS,
  payload: data,
});

export const postOwnerAuthCodeFail = (error) => ({
  type: constants.POST_OWNER_AUTH_CODE_FAIL,
  error,
});

/* **** setVerifyRegister *** */
export const setVerifyRegister = (data) => ({
  type: constants.SET_VERIFY_REGISTER,
  data,
});

export const setVerifyRegisterSuccess = (data) => ({
  type: constants.SET_VERIFY_REGISTER_SUCCESS,
  payload: data,
});

export const setVerifyRegisterFail = (error) => ({
  type: constants.SET_VERIFY_REGISTER_FAIL,
  error,
});

/* **** setProspect *** */
export const setUserProspect = (data) => ({
  type: constants.SET_PROSPECT_USER,
  payload: data,
});

export const putUserProspect = (data) => ({
  type: constants.PUT_PROSPECT_USER,
  payload: data,
});
