import { constants } from '../config';

export const getPlantationProjects = () => ({
  type: constants.GET_PROJECTS,
});

export const getPlantationProjectsSuccess = (data) => ({
  type: constants.GET_PROJECTS_SUCCESS,
  payload: data,
});

export const getPlantationProjectsFailure = (error) => ({
  type: constants.GET_PROJECTS_FAILURE,
  error,
});

export const getTreesFromProject = (id) => ({
  type: constants.GET_TREES_FROM_PROJECT,
  id,
});

export const getTreesFromProjectSuccess = (data) => ({
  type: constants.GET_TREES_FROM_PROJECT_SUCCESS,
  payload: data,
});

export const getTreesFromProjectFailure = (error) => ({
  type: constants.GET_TREES_FROM_PROJECT_FAILURE,
  error,
});

export const getTreeDescription = (id, udianaUuid) => ({
  type: constants.GET_TREE_DESCRIPTION,
  id,
  udianaUuid,
});

export const getTreeDescriptionSuccess = (data) => ({
  type: constants.GET_TREE_DESCRIPTION_SUCCESS,
  payload: data,
});

export const getTreeDescriptionFailure = (error) => ({
  type: constants.GET_TREE_DESCRIPTION_FAILURE,
  error,
});

export const getUdianaKeyStripe = () => ({
  type: constants.GET_UDIANA_KEY_STRIPE,
});

export const getUdianaKeyStripeSuccess = (data) => ({
  type: constants.GET_UDIANA_KEY_STRIPE_SUCCESS,
  payload: data,
});
