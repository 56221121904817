import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from './cli/common/components/Analytics';
import { Layout } from './cli/common/components/Layout';
import { Providers } from './cli/common/contexts/providers';
import { getLocalStorage, setLocalStorage } from './cli/common/functions/cookies';
import { actualLanguage } from './cli/common/functions/lang';
import { useSearchParams } from './cli/common/hooks/useSearchParams';
import { getLine } from './cli/common/schemes/line';
import { getMe } from './cli/common/schemes/user';
import { OwnerRoutes } from './cli/config/routes';
import { getLines } from './cli/pages/lines/actions';
import { Sockets } from './utilities/sockets';

const AlifeApp = ({ ...props }) => {
  Sockets();
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const { history } = props;
  window.intl = intl;
  const user = useSelector((state) => state.user);
  const lines = useSelector((state) => state.lines);
  const line = useSelector((state) => state.line);

  const { pathname } = location;
  const {
    active,
    active: { lineId },
  } = line;
  const { lang } = useParams();

  const session = getLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const getMeAPI = useCallback(() => dispatch(getMe()), [dispatch]);
  const getLinesAPI = useCallback((id) => dispatch(getLines(id)), [dispatch]);
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const search = useSearchParams();
  const queryRedirect = useMemo(() => search.get('redirect'), [search]);

  // const getStreamsAPI = useCallback((id) => dispatch(getStreams(id)), [dispatch]);
  // const getProductsByCategoryAPI = useCallback(
  //   (branch_id) => dispatch(getProductsByCategory(branch_id)),
  //   [dispatch]
  // );

  useEffect(() => {
    if (!pathname.includes('/i/')) {
      if (actualLanguage !== lang) {
        const newUrl = pathname.split('/');
        newUrl[1] = actualLanguage;
        history.replace(newUrl.join('/'));
      } else if (user?.data?.language && lang !== user?.data?.language) {
        const newUrl = pathname.split('/');
        newUrl[1] = user?.data?.language;
        history.replace(newUrl.join('/'));
      }
    }
  }, [actualLanguage]);

  useEffect(() => {
    if (
      !session &&
      !pathname.includes('login') &&
      !pathname.includes('register') &&
      !pathname.includes('/lines') &&
      !pathname.includes('ceremony') &&
      !pathname.includes('badges') &&
      !pathname.includes('/r/') &&
      !pathname.includes('/i') &&
      !pathname.includes('rgpd') &&
      !pathname.includes('poll/') &&
      pathname !== `/es` &&
      pathname !== `/en` &&
      pathname !== `/ca` &&
      pathname !== `/eu` &&
      pathname !== `/de` &&
      pathname !== `/fr` &&
      pathname !== `/it` &&
      pathname !== `/pl` &&
      pathname !== `/pt` &&
      !pathname.includes('home') &&
      !pathname.includes('maintenance') &&
      !pathname.includes(`/search`)
    ) {
      history.replace(`/${actualLanguage}/register`);
    } else if (session && !user.done && !user.loading && !user.error && !user.doneUpdate) {
      getMeAPI();
      if (pathname.includes('login')) {
        if (queryRedirect) {
          history.push(`${queryRedirect}`);
        } else if (idLineStored) {
          history.push(`/${actualLanguage}/lines`);
        } else {
          history.push(`/${actualLanguage}`);
        }
      }
    }
  }, [pathname, user]);

  useEffect(() => {
    if (lineId && (user.done || (!user.done && !user.loading && !user.error))) {
      if (
        pathname.includes(lineId) ||
        pathname.includes('profile') ||
        pathname.includes('login') ||
        pathname.includes('register') ||
        pathname.includes('rgpd') ||
        pathname.includes('r/') ||
        pathname.includes('rgpd') ||
        pathname.includes('poll/') ||
        pathname.includes('home') ||
        pathname.includes('/i/')
      )
        setEventAnalytics(KEYS_ANALYTICS.PAGE_VIEW, user, pathname, active);
    }
  }, [pathname, lineId, user]);

  useEffect(() => {
    if (
      user?.done &&
      !user?.loading &&
      !user?.data?.legal?.gdprAccepted &&
      !pathname.includes('rgpd') &&
      !user.doneCode &&
      !user.doneUpdate &&
      !user.doneUpdatePhone
    ) {
      history.push(`/${actualLanguage}/rgpd`);
    }
  }, [user]);

  useEffect(() => {
    if (
      user.done &&
      (!lines?.all.length ||
        (lines?.all.length > 0 && lines?.all.map((e) => e.lineId).indexOf(idLineStored) === -1)) &&
      !user.doneUpdate &&
      user?.data?.id
    ) {
      getLinesAPI(user?.data?.user_id);
    }
  }, [user, getLinesAPI]);

  useEffect(() => {
    if (user.done && lines?.all.length && !user.doneUpdate) {
      const sortedLines = lines?.all?.sort((a, b) => b.createdAt.timeStamp - a.createdAt.timeStamp);
      if (!idLineStored) {
        setLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE, sortedLines[0]?.lineId);
        setLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE, sortedLines[0]?.nameUrl);
      }
      if (!line?.done && !line?.loading) {
        getLineAPI(sortedLines[0]?.lineId, sortedLines[0]?.nameUrl);
      }
    }
  }, [lines, getLineAPI]);
  if (session && !user.done && !user.error && !user.doneUpdate) return <Loader />;

  return (
    <Providers>
      <Layout {...props}>{OwnerRoutes}</Layout>
    </Providers>
  );
};

export default AlifeApp;

AlifeApp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};
