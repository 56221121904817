import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MaintenancePage } from '../../../pages/maintenance';

export const routes = (maintenancePath) => ({
  path: maintenancePath,
  pages: {
    maintenance: {
      path: `${maintenancePath}`,
    },
  },
});

export const MaintenanceRoutes = ({ match }) => {
  const applicationRoutes = routes(match.url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.maintenance.path} component={MaintenancePage} />
      <Redirect to={applicationRoutes.path} />
    </Switch>
  );
};

MaintenanceRoutes.propTypes = {};
