export const constants = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',
  GET_REQUESTS: 'GET_REQUESTS',
  GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
  GET_REQUESTS_FAIL: 'GET_REQUESTS_FAIL',
  GET_NOTIFICATION_FROM_SOCKETS: 'GET_NOTIFICATION_FROM_SOCKETS',
  SET_NOTIFICATION_STATUS: 'SET_NOTIFICATION_STATUS',
  SET_NOTIFICATION_STATUS_SUCCESS: 'SET_NOTIFICATION_STATUS_SUCCESS',
  SET_NOTIFICATION_STATUS_FAIL: 'SET_NOTIFICATION_STATUS_FAIL',
  GET_REQUEST_FROM_SOCKETS: 'GET_REQUEST_FROM_SOCKETS',
  SET_REQUEST_ACCEPTED: 'SET_REQUEST_ACCEPTED',
  SET_REQUEST_ACCEPTED_SUCCESS: 'SET_REQUEST_ACCEPTED_SUCCESS',
  SET_REQUEST_ACCEPTED_FAIL: 'SET_REQUEST_ACCEPTED_FAIL',
  SET_REQUEST_REJECT: 'SET_REQUEST_REJECT',
  SET_REQUEST_REJECT_SUCCESS: 'SET_REQUEST_REJECT_SUCCESS',
  SET_REQUEST_REJECT_FAIL: 'SET_REQUEST_REJECT_FAIL',
  ADD_NOTIFICATION_FROM_SOCKETS_SUCCESS: 'ADD_CONTENT_FROM_SOCKETS_SUCCESS',
  ADD_REQUEST_FROM_SOCKETS_SUCCESS: 'ADD_REQUEST_FROM_SOCKETS_SUCCESS',
};

export const services = {
  getNotifications: ({ userId }) => ({
    route: `/api/v2/users/${userId}/notifications`,
    method: 'get',
  }),
  getRequests: () => ({
    route: `/api/v2/me/requests`,
    method: 'get',
  }),
  setNotificationsStatus: ({ notificationId, statusId }) => ({
    route: `/api/v2/notifications/${notificationId}/status`,
    method: 'put',
    body: {
      status_id: statusId,
    },
  }),
  setRequestAccepted: (requestId) => ({
    route: `/api/v2/requests/${requestId}`,
    method: 'put',
    body: {
      status: true,
    },
  }),
  setRequestRejected: (requestId) => ({
    route: `/api/v2/requests/${requestId}`,
    method: 'put',
    body: {
      status: false,
    },
  }),
};
