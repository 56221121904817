import React, { createContext, useCallback, useContext, useState } from 'react';

const HeaderStylesContext = createContext();

export const HeaderStylesProvider = ({ children }) => {
  const [headerStyles, setHeaderStyles] = useState({
    customColor: '',
    customButtonColor: '',
    customTextColor: '',
    logo: '',
  });

  const updateHeaderStyles = useCallback((newStyles) => {
    setHeaderStyles((prevStyles) => {
      // Crear una copia del estado actual
      const updatedStyles = { ...prevStyles };

      // Validar y actualizar cada campo de manera segura
      if (newStyles.customColor) {
        updatedStyles.customColor = newStyles.customColor;
      }
      if (newStyles.customButtonColor) {
        updatedStyles.customButtonColor = newStyles.customButtonColor;
      }
      if (newStyles.customTextColor) {
        updatedStyles.customTextColor = newStyles.customTextColor;
      }
      if (newStyles.logo && typeof newStyles.logo === 'string') {
        updatedStyles.logo = newStyles.logo;
      }

      return updatedStyles;
    });
  }, []);

  return (
    <HeaderStylesContext.Provider value={{ headerStyles, updateHeaderStyles }}>
      {children}
    </HeaderStylesContext.Provider>
  );
};

export const useHeaderStyles = () => useContext(HeaderStylesContext);
