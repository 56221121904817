import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Stars } from '@lideralia/alife-uikit/dist/molecules/Stars';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const QualityStars = ({
  valueStarsHuman,
  setValueStarsHuman,
  valueStarsInstallations,
  setValueStarsInstallations,
  valueStarsServices,
  setValueStarsServices,
}) => (
  <>
    <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={3}>
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.human" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars id="poll-stars-human" value={valueStarsHuman} setValue={setValueStarsHuman} />
      </Flex>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%">
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.installations" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars
          id="poll-stars-installations"
          value={valueStarsInstallations}
          setValue={setValueStarsInstallations}
        />
      </Flex>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%">
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.services" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars
          id="poll-stars-services"
          value={valueStarsServices}
          setValue={setValueStarsServices}
        />
      </Flex>
    </Flex>
  </>
);
