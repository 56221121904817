import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RegisterPage from '../../../pages/register/container';

export const routes = (rAuthPath) => ({
  path: rAuthPath,
  pages: {
    rAuth: {
      path: `${rAuthPath}/:authCode`,
    },
  },
});

export const RAuthRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.rAuth.path} component={RegisterPage} />
      <Redirect to={applicationRoutes.pages.rAuth.path} />
    </Switch>
  );
};

RAuthRoutes.propTypes = {};
