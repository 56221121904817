import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { OpenShareMenuContext } from '../../../common/contexts/OpenShareContext';
import { getLocalStorage } from '../../../common/functions/cookies';
import { isAndroid, isIOS } from '../../../common/functions/devices';
import { actualLanguage } from '../../../common/functions/lang';
import { getWhois } from '../../../common/helpers/whois';
import { getEvents, getLine, getStreams, setEventAttendance } from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import { CeremoniesTemplate } from '../template';

const CeremoniesPage = ({ history }) => {
  const intl = useIntl();
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const isShowLogin = useSelector((state) => state.showLogin);
  const session = getLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME);
  const whois = useSelector((state) => state.helper?.whois);
  const {
    active,
    active: { streams, isTesting },
  } = line;

  const { changeOpenShareMenu } = useContext(OpenShareMenuContext);
  const { idLine, idName } = useParams();

  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const [lineDefault, setLineDefault] = useState(idLine || idLineStored);
  const [nameDefault, setNameDefault] = useState(idName || idNameStored);
  const dispatch = useDispatch();
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getEventsAPI = useCallback((lineId) => dispatch(getEvents(lineId)), [dispatch]);
  const setAttendanceAPI = useCallback(
    (idEvent, status) => dispatch(setEventAttendance(idLine, idEvent, status)),
    [dispatch]
  );
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));
  const getStreamsAPI = useCallback((id) => dispatch(getStreams(id)), [dispatch]);
  const getWhoisInformationAPI = useCallback(() => dispatch(getWhois(), [dispatch]));
  const [fromEU, setFromEU] = useState(true);

  const onNotLogged = () => {
    history.push(`/${actualLanguage}/register`);
  };

  useEffect(() => {
    if (Object.keys(whois.info).length === 0) {
      getWhoisInformationAPI();
    }
  }, [active]);

  useEffect(() => {
    if (whois.done && !whois.errorRequest) {
      setFromEU(whois.info.continent === 'Europe');
    } else if (whois.done && whois.errorRequest) {
      setFromEU(true);
    }
  }, [whois]);

  useEffect(() => {
    if (idLine && idName) {
      if (!line.done && !line.loading) {
        getLineAPI(idLine, idName);
      } else if (line.done && line.active.lineId !== idLine && line.active.nameUrl !== idName) {
        getLineAPI(idLine, idName);
      }
    }
  }, [idLine, idName, getLineAPI]);

  useEffect(() => {
    if ((!idLine && idLineStored) || (!idName && idNameStored)) {
      history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}`);
    }
  }, [user, getLineAPI, idLine, idName]);

  useEffect(() => {
    if (idLine && typeof line?.active?.streams === 'undefined') {
      getStreamsAPI(idLine);
    }

    if (
      idLine &&
      active &&
      (typeof line?.active?.events === 'undefined' ||
        Object.keys(active?.events).length === 0 ||
        line?.active?.events[0].eventLineId !== Number(idLine))
    ) {
      getEventsAPI(idLine);
    }
  }, [idLine]);

  useEffect(() => {
    if (active?.lineId) {
      getStreamsAPI(active?.lineId);
    }
  }, [active?.lineId]);

  useEffect(() => {
    if (line?.error?.message) {
      history.push(`/${actualLanguage}/lines`);
    }
  }, [line]);

  useEffect(() => {
    if (idLine !== lineDefault) {
      setLineDefault(idLine);
    }
    if (idName !== nameDefault) {
      setNameDefault(idName);
    }
  }, [idLine, idName]);

  const handleUpdateAttendance = (idEvent, status) => {
    if (user.done) {
      setAttendanceAPI(idEvent, status);
    } else if (!isShowLogin.show) {
      setShowRegisterState(1);
    }
  };

  const goToEcommerce = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.VIEW_ECOMMERCE_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/ecommerce`);
  };

  const goToUdianas = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.VIEW_UDIANAS_BUTTON_CLICKED,
      user,
      history?.location?.pathname,
      active
    );
    history.push(`/${actualLanguage}/lines/${idLineStored}/${idNameStored}/udianas`);
  };

  const setUrlMap = (event) => {
    if (isAndroid()) {
      if (event?.mapLong && event?.mapLat) {
        return `http://maps.google.co.in/maps/?q=loc:${event?.mapLat},${event?.mapLong}&zoom=17`;
      }
      if (event?.address1) {
        return `http://maps.google.co.in/maps/?q=loc:${event?.address1
          ?.split(' ')
          .join('+')}+${event?.city?.split(' ').join('+')}+${event?.zip
          ?.split(' ')
          .join('+')}&zoom=17`;
      }
      return `http://maps.google.co.in/maps/?q=loc:${event?.add1
        ?.split(' ')
        .join('+')}+${event?.city?.split(' ').join('+')}+${event?.zip
        ?.split(' ')
        .join('+')}&zoom=17`;
    }
    if (isIOS()) {
      if (event?.mapLong && event?.mapLat) {
        return `http://maps.apple.com/?q=${event?.mapLat},${event?.mapLong}&zoom=17`;
      }

      if (event?.address1) {
        return `http://maps.apple.com/?q=${event?.address1?.split(' ').join('+')}+${event?.city
          ?.split(' ')
          .join('+')}+${event?.zip?.split(' ').join('+')}&zoom=17`;
      }
      return `http://maps.apple.com/?q=${event?.add1?.split(' ').join('+')}+${event?.city
        ?.split(' ')
        .join('+')}+${event?.zip?.split(' ').join('+')}&zoom=17`;
    }

    if (event?.mapLong && event?.mapLat) {
      return `http://maps.google.co.in/maps/?q=loc:${event?.mapLat},${event?.mapLong}&zoom=17`;
    }
    if (event?.address1) {
      return `http://maps.google.co.in/maps/?q=loc:${event?.address1
        ?.split(' ')
        .join('+')}+${event?.city?.split(' ').join('+')}+${event?.zip
        ?.split(' ')
        .join('+')}&zoom=17`;
    }
    return `http://maps.google.co.in/maps/?q=loc:${event?.add1?.split(' ').join('+')}+${event?.city
      ?.split(' ')
      .join('+')}+${event?.zip?.split(' ').join('+')}&zoom=17`;
  };

  const goToAddBadge = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/book/write`
    );
  };

  return (
    <CeremoniesTemplate
      isTesting={isTesting}
      fromEU={fromEU}
      name={line?.name}
      line={active}
      isEcommerceOpen
      events={active?.events}
      infoCompany={active?.company}
      company={active?.company}
      userPermission={active?.permissionType < 3}
      actionAttendance={handleUpdateAttendance}
      actionGoToEcommerce={goToEcommerce}
      actionGoToUdianas={goToUdianas}
      live={streams?.filter((el) => el.streamTypeId === '1')}
      olds={streams?.filter((el) => el.streamTypeId === '2')}
      isAdmin={active?.permissionType === 4}
      intl={intl}
      urlMap={setUrlMap}
      session={session != null}
      onNotLogged={onNotLogged}
      addNewBadgeAction={goToAddBadge}
      customColor={active?.company?.style?.primaryColor}
      customTextColor={active?.company?.style?.textColor}
      eventEmailAction={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.CEREMONY_EMAIL_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
      eventPhoneAction={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.CEREMONY_PHONE_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
      eventPictureAction={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.CEREMONY_IMAGES_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
      eventLocationAction={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.CEREMONY_ADDRESS_BUTTON_CLICKED,
          user,
          history?.location?.pathname,
          active
        )
      }
      eventFlowerAction={() =>
        setEventAnalytics(
          KEYS_ANALYTICS.OUTBOUND_LINK_CLICKED,
          user,
          history?.location?.pathname,
          active,
          {
            outbound_link_type: active?.flowersEcommerce?.url_service_type,
            outbound_link_label: active?.flowersEcommerce?.url_ga_event_label,
          }
        )
      }
      changeOpenShareMenu={changeOpenShareMenu}
      eventLiveAction={(name, custom) =>
        setEventAnalytics(name, user, history?.location?.pathname, active, custom)
      }
    />
  );
};

export default CeremoniesPage;

CeremoniesPage.propTypes = {};
