import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconCheckCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCheckCircle';
import IconCloseCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCloseCircle';
import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AttendanceQuestion = ({ setValuePollFuneralAttendanceQ1 }) => (
  <Card maxWidth="339px" minWidth="290px" position="relative" width="100%" marginBottom={3}>
    <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={3}>
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.attendance.title" />
      </Text>
    </Flex>
    <Flex
      alignItems="center"
      paddingBottom={3}
      marginTop={1}
      justifyContent="space-evenly"
      paddingX={3}
    >
      <Button
        className="button-approve"
        colorType="green"
        sizeButton="big"
        minWidth={144}
        onClick={() => setValuePollFuneralAttendanceQ1(1)}
      >
        <Icon sizeIcon="display24" marginRight={2}>
          <IconCheckCircle />
        </Icon>
        <FormattedMessage id="poll.attendance.approveOption" />
      </Button>
      <Button
        className="button-decline"
        colorType="red"
        sizeButton="big"
        minWidth={144}
        onClick={() => setValuePollFuneralAttendanceQ1(0)}
      >
        <Icon sizeIcon="display24" marginRight={2}>
          <IconCloseCircle />
        </Icon>
        <FormattedMessage id="poll.attendance.declineOption" />
      </Button>
    </Flex>
  </Card>
);
