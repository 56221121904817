import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import { showLoading } from '../../loading';
import * as action from '../actions';
import { constants, services } from '../config';
import { BranchOrderMapper } from '../mapper';

export function* postBranchOrderSaga({ data }) {
  try {
    yield put(showLoading(true));
    const response = yield requestApi2(services.postBranchOrder(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      let res = [];
      if (
        response.data.order.order_status === 'preparing' ||
        response.data.order.order_status === 'order_received' ||
        response.data.order.order_status === 'pending'
      ) {
        res = response.data.order;
        res.status = 'success';
        res.client_secret = null;
      } else if (response.data.order.order_status === 'not_paid') {
        res.id = response.data.order.uuid;
        res.status = 'requires_action';
        res.client_secret = response.data.payment_intent.client_secret;
      }
      const c = BranchOrderMapper.hydrate(res);
      yield put(showLoading(false));
      yield put(action.postBranchOrderSuccess(c));
    } else {
      yield put(showLoading(false));
      throw response;
    }
  } catch (error) {
    yield put(action.postBranchOrderFail(error));
    yield put(showLoading(false));
    yield showAlertSaga({
      error: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* putBranchOrderSaga({ data }) {
  try {
    const response = yield requestApi2(services.putBranchOrder(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      let res = [];
      res = response.data;
      res.status = 'success';
      const c = BranchOrderMapper.hydrate(res);
      yield put(action.putBranchOrderSuccess(c));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.putBranchOrderFail(error));
    yield showAlertSaga({
      error: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(constants.POST_BRANCH_ORDER, postBranchOrderSaga);
  yield takeLatest(constants.PUT_BRANCH_ORDER, putBranchOrderSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
