import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  all: [],
};

export default function setLinesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_LINES:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_LINES_SUCCESS: {
      return { ...state, loading: false, done: true, all: action.payload };
    }
    case constants.GET_LINES_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    default:
      return state;
  }
}
