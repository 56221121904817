import { getLocalStorage } from '../../functions/cookies';
import { actualLanguage } from '../../functions/lang';

export const constants = {
  GET_ME: 'GET_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAIL: 'GET_ME_FAIL',
  UPDATE_ME: 'UPDATE_ME',
  UPDATE_ME_SUCCESS: 'UPDATE_ME_SUCCESS',
  UPDATE_ME_FAIL: 'UPDATE_ME_FAIL',
  UPDATE_MOBILE_PASSTROUGH: 'UPDATE_MOBILE_PASSTROUGH',
  UPDATE_MOBILE_PASSTROUGH_SUCCESS: 'UPDATE_MOBILE_PASSTROUGH_SUCCESS',
  UPDATE_MOBILE_PASSTROUGH_FAIL: 'UPDATE_MOBILE_PASSTROUGH_FAIL',
  UPDATE_MOBILE_VERIFY: 'UPDATE_MOBILE_VERIFY',
  UPDATE_MOBILE_VERIFY_SUCCESS: 'UPDATE_MOBILE_VERIFY_SUCCESS',
  UPDATE_MOBILE_VERIFY_FAIL: 'UPDATE_MOBILE_VERIFY_FAIL',
  GET_OWNER_AUTH_CODE: 'GET_OWNER_AUTH_CODE',
  GET_OWNER_AUTH_CODE_SUCCESS: 'GET_OWNER_AUTH_CODE_SUCCESS',
  GET_OWNER_AUTH_CODE_FAIL: 'GET_OWNER_AUTH_CODE_FAIL',
  POST_LOGOUT: 'POST_LOGOUT',
  POST_OWNER_AUTH_CODE: 'POST_OWNER_AUTH_CODE',
  POST_OWNER_AUTH_CODE_SUCCESS: 'POST_OWNER_AUTH_CODE_SUCCESS',
  POST_OWNER_AUTH_CODE_FAIL: 'POST_OWNER_AUTH_CODE_FAIL',
  SET_VERIFY_REGISTER: 'SET_VERIFY_REGISTER_CODE',
  SET_VERIFY_REGISTER_SUCCESS: 'SET_VERIFY_REGISTER_CODE_SUCCESS',
  SET_VERIFY_REGISTER_FAIL: 'SET_VERIFY_REGISTER_CODE_FAIL',
  SET_PROSPECT_USER: 'SET_PROSPECT_USER',
  PUT_PROSPECT_USER: 'PUT_PROSPECT_USER',
  PUT_PROSPECT_USER_SUCCESS: 'PUT_PROSPECT_USER_SUCCESS',
  PUT_PROSPECT_USER_FAIL: 'PUT_PROSPECT_USER_FAIL',
};

export const services = {
  getMe: () => ({
    route: `/api/v2/me`,
    method: 'get',
  }),
  updateMe: (body) => ({
    route: `/api/v2/me`,
    method: 'put',
    body: {
      ...body,
    },
  }),
  updatePhone: (body) => ({
    route: `/api/v2/me/telephone`,
    method: 'put',
    body: {
      ...body,
    },
  }),
  updatePhoneVerify: (body) => ({
    route: `/api/v2/me/telephone/confirm-code`,
    method: 'put',
    body: {
      ...body,
    },
  }),
  getOwnerAuthCodeInfo: (authCode) => ({
    route: `/api/v2/auth/${authCode}/ownerauthcode_info`,
    method: 'get',
  }),
  postOwnerAuthCode: (authCode, rgpd, optinValue) => ({
    route: `/api/v2/auth/ownerauthcode_login_or_registration`,
    method: 'post',
    body: {
      register_type: 'ownerauthcode',
      auth_code: authCode,
      register_source: 'web',
      gdpr_accepted: 1,
      optin: optinValue,
    },
  }),

  postLogout: () => ({
    route: `/api/v2/auth/logout`,
    method: 'post',
  }),

  registerWithPhone: (data) => {
    const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);

    return {
      route: `/api/v2/auth/register`,
      method: 'post',
      body: {
        register_type: 'phone',
        language: actualLanguage,
        register_source: 'web',
        referrer_line_id: idLineStored,
        referrer_line_company_optin: false,
        gdpr_accepted: 1,
        ...data,
      },
    };
  },
  putUserProspect: (data) => ({
    route: `/api/v2/alife-user-third-party-optins/${data.user_id}`,
    method: 'put',
    body: data,
  }),
};
