/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
const getMobileOperatingSystem = () => {
  const { userAgent } = navigator;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
    userAgent.includes('iPhone') ||
    userAgent.includes('Macintosh')
  ) {
    return 'iOS';
  }

  return 'unknown';
};

export const isIOS = () => {
  if (getMobileOperatingSystem() === 'iOS') {
    return true;
  }
  return false;
};

export const isAndroid = () => {
  if (getMobileOperatingSystem() === 'Android') {
    return true;
  }
  return false;
};
