import { GlobalStyles } from '@lideralia/alife-uikit/dist';
import { theme } from '@lideralia/alife-uikit/dist/theme';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import TagManager from 'react-server-side-gtm';
import { ThemeProvider } from 'styled-components';
import AlifeApp from './alifeApp';
import { actualLanguage } from './cli/common/functions/lang';
import { I18nProvider } from './lib/i18n';
import { translationMessages } from './lib/i18n/i18n';
import { store } from './lib/store';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV,
  src: 'https://analytics.nextlives.com/tagging',
};

TagManager.initialize(tagManagerArgs);

const MOUNT_NODE = document.getElementById('root');

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://82b63c0d54e54e66b0ec0e6c35f4ccfd@o368489.ingest.sentry.io/5806677',
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    release: `${process.env.REACT_APP_PACKAGE_NAME}@${process.env.REACT_APP_PACKAGE_VERSION}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

const render = (messages) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nProvider messages={messages} locale={actualLanguage}>
          <BrowserRouter>
            <Switch>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Route path="/:lang" component={AlifeApp} />
                <Route exact path="/" render={() => <Redirect to={`/${actualLanguage}`} />} />
                {/* <Route path="/i" component={AlifeApp} /> */}
              </ThemeProvider>
            </Switch>
          </BrowserRouter>
        </I18nProvider>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Set default global lang
window.locale = 'es';

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./lib/i18n/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([import('intl/locale-data/jsonp/es'), import('intl/locale-data/jsonp/en')])
    )
    .then(() => render(translationMessages))
    .catch((error) => {
      throw error;
    });
} else {
  render(translationMessages);
}
