import { constants } from '../config';

/* **** getProductsByCategory *** */
export const getProductsByCategory = (branch_id) => ({
  type: constants.GET_PRODUCTS_BY_CATEGORY,
  branch_id,
});

export const getProductsByCategorySuccess = (data) => ({
  type: constants.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  payload: data,
});

export const getProductsByCategoryFail = (error) => ({
  type: constants.GET_PRODUCTS_BY_CATEGORY_FAIL,
  error,
});

export const resetProductsByCategory = () => ({
  type: constants.RESET_PRODUCTS_BY_CATEGORY,
});
