import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import { IconHeadphones } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconDraw from '@lideralia/alife-uikit/dist/atoms/Icons/IconDraw';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actualLanguage } from '../../../common/functions/lang';
import { getLine } from '../../../common/schemes/line';
import { postBadge, resetBadge } from '../../../common/schemes/line/actions';
import { showRegister } from '../../../common/schemes/login';
import { TextBadgeTemplate } from '../template/textContent';

const TextContentBadgesPage = ({ history }) => {
  const line = useSelector((state) => state.line);
  const login = useSelector((state) => state.login);
  const user = useSelector((state) => state.user);
  const { active } = line;

  const { addBadgesDone } = line;
  const dispatch = useDispatch();
  const { idLine, idName } = useParams();
  const [data, setData] = useState({
    contentBody: '',
    contentType: '',
  });

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const postBadgeAPI = useCallback(() => dispatch(postBadge(idLine, data)));
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  useEffect(() => {
    if (!line.done && !line.loading && !user.done && idLine && idName) {
      getLineAPI(idLine, idName);
    }
  }, [line, user]);

  useEffect(() => {
    if (data.contentBody && addBadgesDone) {
      dispatch(resetBadge());
      history.push(`/${actualLanguage}/lines/${idLine}/${idName}`);
    }
  }, [data, addBadgesDone]);

  const handleChange = (id, content) => {
    setData({
      ...data,
      contentType: id,
      contentBody: content,
    });
  };

  const handleSubmit = () => {
    if (user?.done || login?.done) {
      postBadgeAPI();
    } else {
      setShowRegisterState(1);
    }
  };

  useEffect(() => {
    if (data.contentBody && login?.done && user?.done) {
      postBadgeAPI();
    }
  }, [login, user]);

  return (
    <>
      <TextBadgeTemplate
        line={line.active}
        isLogged={user?.done || login?.done}
        data={data}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        customColor={active?.company?.style?.primaryColor}
        customTextColor={active?.company?.style?.textColor}
        itemsTab={[
          {
            id: 'message',
            children: (
              <>
                <Icon marginRight={1}>
                  <IconWrite />
                </Icon>{' '}
                <FormattedMessage id="badge.tabMessage" />
              </>
            ),
            isActive: true,
            action: () => {
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/write`);
            },
          },
          {
            id: 'draw',
            children: (
              <>
                <Icon marginRight={1}>
                  <IconDraw />
                </Icon>
                <FormattedMessage id="badge.tabDraw" />
              </>
            ),
            style: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              overflow: 'hidden',
              minWidth: '58px',
              margin: '0',
            },
            isActive: false,
            action: () => {
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/draw`);
            },
          },
          {
            id: 'message_voice',
            children: (
              <>
                <Icon marginRight={1}>
                  <IconHeadphones />
                </Icon>{' '}
                <FormattedMessage id="badge.audio" />
              </>
            ),
            isActive: false,
            action: () => {
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/audio`);
            },
          },
        ]}
      />
    </>
  );
};

export default TextContentBadgesPage;

TextContentBadgesPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
