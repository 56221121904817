import { actualLanguage } from '../../../common/functions/lang';
import { useLineData } from '../hooks/useLineData';
import { FaqTrees } from '../template/FaqTrees';
import HeaderWithForest from '../template/HeaderWithForest';

const FaqTreesPage = ({ history }) => {
  const { lineDefault, idLineStored, nameDefault, idNameStored, active, forest } = useLineData();

  const goToUdianas = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas`
    );
  };

  const goToPlantTreeAction = () => {
    history.push(
      `/${actualLanguage}/lines/${lineDefault || idLineStored}/${
        nameDefault || idNameStored
      }/udianas/plant-tree`
    );
  };

  return (
    <HeaderWithForest
      titleForestOf={intl.formatMessage({ id: 'udianas.forestOfWithName' }, { name: active?.name })}
      treesPlanted={intl.formatMessage(
        { id: 'udianas.treesQuantity' },
        { trees: active?.treeSumPerLine }
      )}
      weather={forest?.totalKgCo2OffsettedOnForest}
      showButtonBack
      actionGoBack={goToUdianas}
    >
      <FaqTrees
        goToPlantTreeAction={goToPlantTreeAction}
        customColor={active?.company?.style?.primaryColor}
        customTextColor={active?.company?.style?.textColor}
      />
    </HeaderWithForest>
  );
};

export default FaqTreesPage;
