import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Box, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { RangeSlider } from '@lideralia/alife-uikit/dist/atoms/RangeSlider';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AttendanceQuestion } from '../components/AttendanceQuestion';
import { QualityStars } from '../components/QualityStars';
import { TechnologyStars } from '../components/TechnologyStars';

export const NoOwnerNoNpsNoLead = ({
  poll,
  valueStarsHuman,
  setValueStarsHuman,
  valueStarsInstallations,
  setValueStarsInstallations,
  valueStarsServices,
  setValueStarsServices,
  setValueRangeQuestion,
  valueRangeQuestion,
  valuePollFuneralAttendanceQ1,
  setValuePollFuneralAttendanceQ1,
  valuePollSuggestions,
  setValuePollSuggestions,
  setFinishPoll,
  setValuePollAlifeTechnologyQ1,
  valuePollAlifeTechnologyQ1,
  setValuePollAlifeTechnologyQ2,
  valuePollAlifeTechnologyQ2,
  setValuePollAlifeTechnologyQ3,
  valuePollAlifeTechnologyQ3,
  setValuePollAlifeTechnologyQ4,
  valuePollAlifeTechnologyQ4,
  showFuneralOptions,
  onSubmit,
  handleResetPoll,
  company,
}) => (
  <>
    {typeof valuePollFuneralAttendanceQ1 !== 'number' && (
      <AttendanceQuestion setValuePollFuneralAttendanceQ1={setValuePollFuneralAttendanceQ1} />
    )}
    {valuePollFuneralAttendanceQ1 === 1 && (
      <Card maxWidth="339px" minWidth="290px" position="relative" width="100%">
        <QualityStars
          valueStarsHuman={valueStarsHuman}
          setValueStarsHuman={setValueStarsHuman}
          valueStarsInstallations={valueStarsInstallations}
          setValueStarsInstallations={setValueStarsInstallations}
          valueStarsServices={valueStarsServices}
          setValueStarsServices={setValueStarsServices}
        />
        <Box
          borderBottomWidth="1px"
          borderBottomColor="black12"
          borderBottomStyle="solid"
          width="100%"
          marginBottom={4}
        />
        {poll.data?.pollCustom?.items?.map((elem) => (
          <>
            <Flex flexDirection="column" paddingX={3} width="100%" key={elem.formName}>
              <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
                {elem.title}
              </Text>
              <RangeSlider
                name={elem.formName}
                id={elem.formName}
                minValue={0}
                maxValue={10}
                valueRange={valueRangeQuestion[elem.formName] || 0}
                defaultValue={0}
                setValue={(data) =>
                  setValueRangeQuestion((prev) => ({
                    ...prev,
                    [data.name]: data.value,
                  }))
                }
              />
            </Flex>
            <Box
              borderBottomWidth="1px"
              borderBottomColor="black12"
              borderBottomStyle="solid"
              width="100%"
              marginTop={1}
              marginBottom={4}
            />
          </>
        ))}

        <Flex flexDirection="column" paddingX={3} width="100%">
          <Textarea
            counter
            typeAction={(name, value) => setValuePollSuggestions(value)}
            defaultValue={valuePollSuggestions}
            placeholderMessage={intl.formatMessage({ id: 'common.writeSomething' })}
          />
        </Flex>
        <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={2} paddingBottom={3}>
          <Button
            className="button-submit"
            backgroundColor={company?.style?.primaryColor}
            colorType={company?.style?.primaryColor || 'orange'}
            sizeButton="big"
            fullWidth
            action={() => {
              setFinishPoll(true);
              onSubmit();
              if (!showFuneralOptions) {
                handleResetPoll();
              }
            }}
          >
            <FormattedMessage id="poll.submit" />
          </Button>
        </Flex>
      </Card>
    )}
    {valuePollFuneralAttendanceQ1 === 0 && (
      <Card maxWidth="339px" minWidth="290px" position="relative" width="100%">
        <TechnologyStars
          valuePollAlifeTechnologyQ1={valuePollAlifeTechnologyQ1}
          setValuePollAlifeTechnologyQ1={setValuePollAlifeTechnologyQ1}
          valuePollAlifeTechnologyQ2={valuePollAlifeTechnologyQ2}
          setValuePollAlifeTechnologyQ2={setValuePollAlifeTechnologyQ2}
          valuePollAlifeTechnologyQ3={valuePollAlifeTechnologyQ3}
          setValuePollAlifeTechnologyQ3={setValuePollAlifeTechnologyQ3}
        />
        <Box
          borderBottomWidth="1px"
          borderBottomColor="black12"
          borderBottomStyle="solid"
          width="100%"
          marginBottom={4}
        />
        <Flex flexDirection="column" paddingX={3} width="100%">
          <Field
            label={intl.formatMessage({ id: 'poll.betterQuestion' })}
            color="black60"
            fontWeight="bold"
            marginTop={0}
            marginBottom={1}
          >
            <Textarea
              counter
              typeAction={(name, value) => setValuePollAlifeTechnologyQ4(value)}
              defaultValue={valuePollAlifeTechnologyQ4}
              placeholderMessage={intl.formatMessage({ id: 'common.writeSomething' })}
            />
          </Field>
        </Flex>
        <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={2} paddingBottom={3}>
          <Button
            className="button-submit"
            backgroundColor={company?.style?.primaryColor}
            colorType={company?.style?.primaryColor || 'orange'}
            color={company?.style?.textColor}
            sizeButton="big"
            fullWidth
            action={() => {
              setFinishPoll(true);
              onSubmit();
              if (!showFuneralOptions) {
                handleResetPoll();
              }
            }}
          >
            <FormattedMessage id="poll.submit" />
          </Button>
        </Flex>
      </Card>
    )}
  </>
);
