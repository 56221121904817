import { constants } from '../config';
/* **** getPrivacy *** */
export const getPrivacy = (lang) => ({
  type: constants.GET_PRIVACY,
  lang,
});

export const getPrivacySuccess = (data) => ({
  type: constants.GET_PRIVACY_SUCCESS,
  payload: data,
});

export const getPrivacyFail = (error) => ({
  type: constants.GET_PRIVACY_FAIL,
  error,
});
