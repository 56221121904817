import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  buttonConfirmation,
  idConfirmation,
  locationConfirmation,
  showConfirmation,
} from '../../../common/schemes/confirmation';
import { ConfirmationTemplate } from '../template';

const ConfirmationPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onModalClose = () => {
    dispatch(showConfirmation(false));
    dispatch(buttonConfirmation(false));
    dispatch(locationConfirmation(''));
    dispatch(idConfirmation(0));
  };

  const actionButton = (action) => {
    dispatch(buttonConfirmation(action));
    dispatch(showConfirmation(false));
  };
  const buttonsText = {
    confirm: <FormattedMessage id="common.yes" />,
    cancel: <FormattedMessage id="common.no" />,
  };
  return (
    <ConfirmationTemplate
      title={<FormattedMessage id="messages.eliminate_title" />}
      body={<FormattedMessage id="messages.eliminate_published" />}
      isOpen={isOpen}
      buttons={buttonsText}
      confirmationButton={actionButton}
      onModalClose={() => onModalClose()}
    />
  );
};

export default ConfirmationPage;
