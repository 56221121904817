import { getLocalStorage } from '../../functions/cookies';

export const constants = {
  GET_LINE: 'GET_LINE',
  GET_LINE_SUCCESS: 'GET_LINE_SUCCESS',
  UPDATE_LINE: 'UPDATE_LINE',
  UPDATE_LINE_SUCCESS: 'UPDATE_LINE_SUCCESS',
  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_CONTENTS: 'GET_CONTENTS',
  GET_CONTENTS_SUCCESS: 'GET_CONTENTS_SUCCESS',
  GET_CONTENT: 'GET_CONTENT',
  GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
  ADD_CONTENT: 'ADD_CONTENT',
  ADD_CONTENT_SUCCESS: 'ADD_CONTENT_SUCCESS',
  ACCEPT_CONTENT: 'ACCEPT_CONTENT',
  EDIT_CONTENT: 'EDIT_CONTENT',
  EDIT_CONTENT_SUCCESS: 'EDIT_CONTENT_SUCCESS',
  DELETE_CONTENT: 'DELETE_CONTENT',
  DELETE_CONTENT_SUCCESS: 'DELETE_CONTENT_SUCCESS',
  RESET_CONTENT: 'RESET_CONTENT',
  FOLLOW_LINE: 'FOLLOW_LINE',
  FOLLOW_LINE_SUCCESS: 'FOLLOW_LINE_SUCCESS',
  SET_LIKES_CONTENT: 'SET_LIKES_CONTENT',
  SET_LIKES_CONTENT_SUCCESS: 'SET_LIKES_CONTENT_SUCCESS',
  SET_LIKES_LINE_BADGE_HOME: 'SET_LIKES_LINE_BADGE_HOME',
  SET_LIKES_LINE_BADGE: 'SET_LIKES_LINE_BADGE',
  SET_LIKES_LINE_BADGE_SUCCESS: 'SET_LIKES_LINE_BADGE_SUCCESS',
  DELETE_BADGE: 'DELETE_BADGE',
  POST_BADGE: 'POST_BADGE',
  POST_BADGE_SUCCESS: 'POST_BADGE_SUCCESS',
  RESET_BADGE: 'RESET_BADGE',
  INVITATION_TO_LINE_SEND: 'INVITATION_TO_LINE_SEND',
  INVITATION_TO_LINE_SEND_SUCCESS: 'INVITATION_TO_LINE_SEND_SUCCESS',
  GET_INVITATION_TO_LINE: 'GET_INVITATION_TO_LINE',
  GET_INVITATION_TO_LINE_SUCCESS: 'GET_INVITATION_TO_LINE_SUCCESS',
  GET_LINE_UUID_FROM_INVITATION_TO_LINE: 'GET_LINE_UUID_FROM_INVITATION_TO_LINE',
  GET_LINE_UUID_FROM_INVITATION_TO_LINE_SUCCESS: 'GET_LINE_UUID_FROM_INVITATION_TO_LINE_SUCCESS',
  PUT_INVITATION_TO_LINE: 'PUT_INVITATION_TO_LINE',
  PUT_INVITATION_TO_LINE_SUCCESS: 'PUT_INVITATION_TO_LINE_SUCCESS',
  GET_COMMENTS: 'GET_COMMENTS',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  ADD_COMMENTS: 'ADD_COMMENTS',
  ADD_COMMENTS_SUCCESS: 'ADD_COMMENTS_SUCCESS',
  SET_EVENT_ATTENDANCE: 'SET_EVENT_ATTENDANCE',
  SET_EVENT_ATTENDANCE_SUCCESS: 'SET_EVENT_ATTENDANCE_SUCCESS',
  GET_STREAMS: 'GET_STREAMS',
  GET_STREAMS_SUCCESS: 'GET_STREAMS_SUCCESS',
  GET_STREAMS_FROM_SOCKETS: 'GET_STREAMS_FROM_SOCKETS',
  ADD_STREAMS_FROM_SOCKETS_SUCCESS: 'ADD_STREAMS_FROM_SOCKETS_SUCCESS',
  FINSIH_STREAMS_FROM_SOCKETS_SUCCESS: 'FINISH_STREAMS_FROM_SOCKETS_SUCCESS',
  GET_EXTERNAL_SERVICES: 'GET_EXTERNAL_SERVICES',
  GET_EXTERNAL_SERVICES_SUCCESS: 'GET_EXTERNAL_SERVICES_SUCCESS',
  GET_BEREAVEMENT_SERVICES: 'GET_BEREAVEMENT_SERVICES',
  GET_BEREAVEMENT_SERVICES_SUCCESS: 'GET_BEREAVEMENT_SERVICES_SUCCESS',
  POST_AJAX: 'POST_AJAX',
  POST_AJAX_SUCCESS: 'POST_AJAX_SUCCESS',
  GET_CONTENTS_MODERATED: 'GET_CONTENTS_MODERATED',
  GET_CONTENTS_MODERATED_SUCCESS: 'GET_CONTENTS_MODERATED_SUCCESS',
  GET_FOLLOWERS: 'GET_FOLLOWERS',
  GET_FOLLOWERS_SUCCESS: 'GET_FOLLOWERS_SUCCESS',
  GET_BADGES: 'GET_BADGES',
  GET_BADGES_SUCCESS: 'GET_BADGES_SUCCESS',
  GET_LAST_BADGES: 'GET_LAST_BADGES',
  GET_LAST_BADGES_SUCCESS: 'GET_LAST_BADGES_SUCCESS',
  GET_BADGE_FROM_SOCKETS: 'GET_BADGE_FROM_SOCKETS',
  ADD_BADGE_FROM_SOCKETS_SUCCESS: 'ADD_BADGE_FROM_SOCKETS_SUCCESS',
  DELETE_BADGE_FROM_SOCKETS_SUCCESS: 'DELETE_BADGE_FROM_SOCKETS_SUCCESS',
  GET_CONTENT_FROM_SOCKETS: 'GET_CONTENT_FROM_SOCKETS',
  ADD_CONTENT_FROM_SOCKETS_SUCCESS: 'ADD_CONTENT_FROM_SOCKETS_SUCCESS',
  UPDATE_CONTENT_FROM_SOCKETS_SUCCESS: 'UPDATE_CONTENT_FROM_SOCKETS_SUCCESS',
  DELETE_CONTENT_FROM_SOCKETS_SUCCESS: 'DELETE_CONTENT_FROM_SOCKETS_SUCCESS',
  GET_COMPANY_LINE_SUCCESS: 'GET_COMPANY_LINE_SUCCESS',
  LINES_REQUEST_FAILS: 'LINES_REQUEST_FAILS',
  MODERATE: 'MODERATE',
  ACCEPT_LEGAL_TERMS: 'ACCEPT_LEGAL_TERMS',
  ACCEPT_LEGAL_TERMS_SUCCESS: 'ACCEPT_LEGAL_TERMS_SUCCESS',
  GET_TREES: 'GET_TREES',
  GET_TREES_SUCCESS: 'GET_TREES_SUCCESS',
  GET_UDIANAS_FROM_SOCKETS: 'GET_UDIANAS_FROM_SOCKETS',
  ADD_UDIANAS_FROM_SOCKETS_SUCCESS: 'ADD_UDIANAS_FROM_SOCKETS_SUCCESS',
};

export const services = {
  getLine: ({ id, name }) => ({
    route: `/api/v2/lines/${id}?line_name=${name}`,
    method: 'get',
  }),
  updateLine: (id, body) => ({
    route: `/api/v2/lines/${id}`,
    method: 'put',
    body,
  }),
  getContents: (lineId) => ({
    route: `/api/v2/lines/${lineId}/contents`,
    method: 'get',
  }),
  getEvents: (lineId) => ({
    route: `/api/v2/lines/${lineId}/events`,
    method: 'get',
  }),
  getBadges: (lineId) => ({
    route: `/api/v2/lines/${lineId}/badges`,
    method: 'get',
  }),
  getTrees: (lineId) => ({
    route: `/api/v2/lines/${lineId}/trees`,
    method: 'get',
  }),
  getLastBadges: (companyId = 0, pageSize, pageNumber = 1) => ({
    route: `/api/v2/companies/${companyId}/badges/last?${
      (pageNumber && `page=${pageNumber}`) || ''
    }${(pageSize && `&per_page=${pageSize}`) || ''}&audio_filename=null`,
    method: 'get',
  }),
  followLine: ({ id }) => ({
    route: `/api/v2/lines/${id}/followers`,
    method: 'post',
  }),
  setContentLike: (id, hasLiked) => ({
    route: `/api/v2/contents/${id}/like`,
    method: 'post',
    body: {
      direction: hasLiked,
    },
  }),
  setContentUnLike: (id, hasLiked) => ({
    route: `/api/v2/contents/${id}/dislike`,
    method: 'post',
    body: {
      direction: hasLiked,
    },
  }),
  setLineBadgeLike: (id) => ({
    route: `/api/v2/badges/${id}/like`,
    method: 'post',
  }),
  getContent: ({ id }) => ({
    route: `/api/v2/contents/${id}`,
    method: 'get',
  }),
  addContent: (id, data) => ({
    route: `/api/v2/lines/${id}/contents`,
    method: 'post',
    body: {
      ...data,
    },
  }),
  editContent: (idContent, data) => ({
    route: `/api/v2/contents/${idContent}`,
    method: 'put',
    body: {
      ...data,
    },
  }),
  deleteContent: ({ id }) => ({
    route: `/api/v2/contents/${id}`,
    method: 'delete',
  }),
  acceptContent: (idContent, value) => ({
    route: `/api/v2/contents/${idContent}/moderate`,
    method: 'put',
    body: {
      accept: value,
    },
  }),
  getComments: (id) => ({
    route: `/api/v2/contents/${id}/comments`,
    method: 'get',
  }),
  addComment: (id, text) => ({
    route: `/api/v2/contents/${id}/comments`,
    method: 'post',
    body: {
      text,
    },
  }),
  deleteBadge: (id) => ({
    route: `/api/v2/badges/${id}`,
    method: 'delete',
  }),
  addBadge: (id, contentType, contentBody) => ({
    route: `/api/v2/lines/${id}/badges`,
    method: 'post',
    body: {
      badge_type: 1,
      [contentType]: contentBody,
    },
  }),
  inviteToLineAnon: (id) => ({
    route: `/api/v2/lines/${id}/lines-invite-anons`,
    method: 'post',
    body: {
      permission_type: 2,
    },
  }),
  getInvitationAnon: (hash) => ({
    route: `/api/v2/lines-invite-anons/${hash}/info`,
    method: 'get',
  }),
  consumeInvitationAnon: (hash) => ({
    route: `/api/v2/lines-invite-anons/${hash}/consume`,
    method: 'put',
  }),
  getLineUuidFromInvitationAnon: (hash) => ({
    route: `/api/v2/lines-invite-anons/${hash}/lines`,
    method: 'get',
  }),
  setEventAttendance: (idLine, idEvent, status) => ({
    route: `/api/v2/lines-events/${idEvent}/attendance`,
    method: 'post',
    body: {
      rsvp_status: status,
    },
  }),
  getStreams: (id) => ({
    route: `/api/v2/lines/${id}/streams`,
    method: 'get',
  }),
  getExternServices: (id) => ({
    route: `/api/v2/lines/${id}/external-services?lang=${getLocalStorage('alife-lang')}`,
    method: 'get',
  }),
  getBereavementServices: (id) => ({
    route: `/api/v2/companies/${id}/translations?type=bereavement&lang=${getLocalStorage(
      'alife-lang'
    )}`,
    method: 'get',
  }),
  postAjax: (url) => ({
    route: url,
    method: 'post',
  }),
  getContentsModerated: (id) => ({
    route: `/api/v2/lines/${id}/moderate/pending`,
    method: 'get',
  }),
  getFollowers: (id, excludeAdmins) => ({
    route: `/api/v2/lines/${id}/followers`,
    method: 'get',
    body: {
      exclude_admins: excludeAdmins,
    },
  }),
  moderate: (id) => ({
    route: `/api/v2/lines/${id}/moderate`,
    method: 'put',
  }),
  acceptLegalTerms: (id) => ({
    route: `/api/v2/lines/${id}/accept-legal-terms`,
    method: 'post',
  }),
};
