import { constants } from '../config';

/* **** getLines *** */
export const getLines = (id) => ({
  type: constants.GET_LINES,
  id,
});

export const getLinesSuccess = (data) => ({
  type: constants.GET_LINES_SUCCESS,
  payload: data,
});

export const getLinesFail = (error) => ({
  type: constants.GET_LINES_FAIL,
  error,
});
