import { constants } from '../config';
/* **** getCookies *** */
export const getCookies = (lang) => ({
  type: constants.GET_COOKIES,
  lang,
});

export const getCookiesSuccess = (data) => ({
  type: constants.GET_COOKIES_SUCCESS,
  payload: data,
});

export const getCookiesFail = (error) => ({
  type: constants.GET_COOKIES_FAIL,
  error,
});
