export const constants = {
  // POST_ORDER: 'POST_ORDER',
  // POST_ORDER_SUCCESS: 'POST_ORDER_SUCCESS',
  // POST_ORDER_FAILURE: 'POST_ORDER_FAILURE',
  POST_TREE_ORDER: 'POST_TREE_ORDER',
  POST_TREE_ORDER_SUCCESS: 'POST_TREE_ORDER_SUCCESS',
  POST_TREE_ORDER_FAILURE: 'POST_TREE_ORDER_FAILURE',
  PUT_ORDER: 'PUT_ORDER',
  PUT_ORDER_SUCCESS: 'PUT_ORDER_SUCCESS',
  PUT_ORDER_FAILURE: 'PUT_ORDER_FAILURE',
  CONFIRM_TREE_ORDER: 'CONFIRM_TREE_ORDER',
  CONFIRM_TREE_ORDER_SUCCESS: 'CONFIRM_TREE_ORDER_SUCCESS',
  CONFIRM_TREE_ORDER_FAILURE: 'CONFIRM_TREE_ORDER_FAILURE',
  RESET_ORDER: 'RESET_ORDER',
  GET_CERTIFICATE_TREE: 'GET_CERTIFICATE_TREE',
  GET_CERTIFICATE_TREE_SUCCESS: 'GET_CERTIFICATE_TREE_SUCCESS',
  GET_CERTIFICATE_TREE_FAILURE: 'GET_CERTIFICATE_TREE_FAILURE',
};

export const services = {
  putOrder: (data) => ({
    route: `/api/v2/orders/${data.id}`,
    method: 'put',
    body: data,
  }),

  confrimTreeOrder: (data) => ({
    route: `/api/v2/trees/${data.id}/confirm`,
    method: 'put',
    body: data,
  }),

  postTreeOrder: (data) => ({
    route: `/api/v2/trees`,
    method: 'post',
    body: data,
  }),

  getCertificateTree: (data) => ({
    route: `/api/v2/orders/${data.uuid}/certificate-trees`,
    method: 'get',
    configRequest: {
      responseType: 'blob',
    },
  }),
};
