import { Container, Flex } from '@lideralia/alife-uikit';
import { ForestUdianas } from '@lideralia/alife-uikit/dist/molecules/Udianas/ForestUdianas';
import PropTypes from 'prop-types';

const HeaderWithForest = ({
  children,
  height,
  onlyOneText,
  titleForestOf,
  treesPlanted,
  weather,
  showButtonBack,
  actionGoBack,
  location,
  imagePicture,
}) => (
  <Container paddingY={3} paddingBottom={8} width="100%" maxWidth="400px">
    <Flex alignItems="center" flexDirection="column" justifyContet="center" paddingBottom={6}>
      <ForestUdianas
        width="100%"
        height={height || '132px'}
        title={titleForestOf}
        trees={treesPlanted}
        weather={intl.formatMessage({ id: 'udianas.weatherCo2' }, { name: weather })}
        onlyOneText={onlyOneText}
        location={location}
        picture={{
          alt: 'Caraipa llanorum',
          src: imagePicture ?? 'https://udiana.blob.core.windows.net/images/foret1.jpg',
        }}
        showButtonBack={showButtonBack}
        actionButton={() => actionGoBack()}
      />
      {children}
    </Flex>
  </Container>
);

HeaderWithForest.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  onlyOneText: PropTypes.bool,
  titleForestOf: PropTypes.string.isRequired,
  treesPlanted: PropTypes.string,
  weather: PropTypes.number,
  location: PropTypes.string,
  actionGoBack: PropTypes.func.isRequired,
  showButtonBack: PropTypes.bool,
  imagePicture: PropTypes.string,
};

HeaderWithForest.defaultProps = {
  children: null,
  height: null,
  onlyOneText: false,
  weather: 0,
  treesPlanted: '0',
  location: '',
  showButtonBack: false,
  imagePicture: null,
};

export default HeaderWithForest;
