export const services = {
  setStats: (data) => {
    console.warn(data);

    return {
      route: `/api/v2/stats`,
      method: 'post',
      body: {
        obj: JSON.stringify(data),
      },
    };
  },
};
