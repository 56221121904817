export const constants = {
  GET_PRIVACY: 'GET_PRIVACY',
  GET_PRIVACY_SUCCESS: 'GET_PRIVACY_SUCCESS',
  GET_PRIVACY_FAIL: 'GET_PRIVACY_FAIL',
};

export const services = {
  getPrivacy: (lang) => ({
    route: `/api/v2/legals/legal-terms?lang=${lang}&title=privacy`,
    method: 'get',
  }),
};
