import { all, call, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import * as Constants from '../../../../../utilities/constants';
import { showLoading } from '../../../states/loading';
import * as action from '../actions';
import { constants, services } from '../config';
import { NotificationMapper, RequestsMapper } from '../mapper';

export function* getNotificationsSaga(userId) {
  try {
    const response = yield requestApi2(services.getNotifications(userId));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        action.getNotificationsSuccess(data.map((element) => NotificationMapper.hydrate(element)))
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getNotificationsFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getRequestsSaga(body) {
  try {
    const response = yield requestApi2(services.getRequests(body));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getRequestsSuccess(data.map((element) => RequestsMapper.hydrate(element))));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getRequestsFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setNotificationsStatusSaga(body) {
  try {
    const response = yield requestApi2(services.setNotificationsStatus(body));

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.setNotificationStatusSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setNotificationStatusFail(error));
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* setRequestAcceptedSaga({ requestId }) {
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.setRequestAccepted(requestId));

    if (response && response.status >= 200 && response.status < 300) {
      const data = response.data?.data;
      yield put(action.setRequestAcceptedSuccess(data));
      yield call(getRequestsSaga, {});
      yield put(showLoading(-1));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setRequestAcceptedFail(error));
    yield put(showLoading(-1));
  }
}

export function* setRequestRejectedSaga({ requestId }) {
  try {
    yield put(showLoading(1));
    const response = yield requestApi2(services.setRequestRejected(requestId));

    if (response && response.status >= 200 && response.status < 300) {
      const data = response.data?.data;
      yield put(action.setRequestRejectedSuccess(data));
      yield call(getRequestsSaga, {});
      yield put(showLoading(-1));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setRequestRejectedFail(error));
    yield put(showLoading(-1));
  }
}

export function* getNotificationFromSockets({ data }) {
  const notification = NotificationMapper.hydrate(data.notification);
  if (data.action === Constants.SOCKET_ADD) {
    yield put(action.AddNotificationFromSocketsSuccess(notification));
  }
}

export function* getRequestFromSockets({ data }) {
  const request = RequestsMapper.hydrate(data.request);
  if (data.action === Constants.SOCKET_ADD) {
    yield put(action.AddRequestFromSocketsSuccess(request));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(constants.GET_REQUESTS, getRequestsSaga);
  yield takeLatest(constants.GET_NOTIFICATION_FROM_SOCKETS, getNotificationFromSockets);
  yield takeLatest(constants.GET_REQUEST_FROM_SOCKETS, getRequestFromSockets);
  yield takeLatest(constants.SET_NOTIFICATION_STATUS, setNotificationsStatusSaga);
  yield takeLatest(constants.SET_REQUEST_ACCEPTED, setRequestAcceptedSaga);
  yield takeLatest(constants.SET_REQUEST_REJECT, setRequestRejectedSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
