import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import IconCheckCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCheckCircle';
import IconCloseCircle from '@lideralia/alife-uikit/dist/atoms/Icons/IconCloseCircle';
import IconStop from '@lideralia/alife-uikit/dist/atoms/Icons/IconStop';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Switch } from '@lideralia/alife-uikit/dist/atoms/Switch';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardContent } from '@lideralia/alife-uikit/dist/organisms/Cards/Content';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export const ModerationTemplate = ({
  handleModerateContent,
  handleModerate,
  goBack,
  moderation,
  isModerated,
  user,
  active,
  themeColor,
}) => {
  const line = useSelector((state) => state.line);
  return !line?.loading ? (
    <Container paddingY={4} height="100vh" paddingBottom={7}>
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        maxWidth="339px"
        minWidth="290px"
        margin="0 auto"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="black84" fontWeight="bold" sizeText="display24">
            <FormattedMessage id="communication.moderation.title" />
          </Text>
          <Button
            paddingY={1}
            paddingX={1}
            colorType="black"
            sizeButton="big"
            rounded
            action={() => goBack()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconArrowLeft />
            </Icon>
          </Button>
        </Flex>
        <Box marginTop={4} marginBottom={4} paddingBottom={8}>
          <Card marginY={1} padding={3}>
            <Flex alignItems="center">
              <Icon sizeIcon="display24" color="black60">
                <IconStop />
              </Icon>
              <Text
                textAlign="left"
                marginLeft={2}
                sizeText="display13"
                color="black60"
                style={{ textTransform: 'uppercase', flex: '1' }}
              >
                <FormattedMessage id="communication.moderation.title" />
              </Text>
              <Switch
                name="is_moderated"
                id="is_moderated"
                defaultValue={isModerated ? 1 : 0}
                sizeInput="small"
                themeColor={themeColor}
                action={() => handleModerate(active.lineId)}
              />
            </Flex>
          </Card>
          <Flex width={[1]} alignItems="center" flexDirection="column">
            {moderation?.map((el) => (
              <Box
                id={`content-${el?.contentId}`}
                width={[1]}
                key={el?.contentId}
                paddingY={1}
                maxWidth={339}
                minWidth={290}
              >
                <CardContent
                  {...el}
                  authorName={el.author?.name}
                  date={el.date?.readableStringDate}
                  authorPicture={el?.author?.pictures?.s}
                  numLikes={el.numLikes}
                  hasLiked={el.hasLiked}
                  numComments={el.numComments}
                  actionLike={() => {}}
                  actionComment={() => {}}
                  onDeleteContent={() => {}}
                  onUpdateContent={() => {}}
                  isAdmin={active?.userGroup > 2}
                  isAuthor={el.author.id === user?.data?.user_id}
                  isModerated={active?.isModerated}
                  haveAccess={active?.permissionType === 2}
                  actionSeeComments={() => {}}
                  commentPlaceholder={intl.formatMessage({ id: 'common.comment' })}
                  actionAccept={(e) => handleModerateContent(el.contentId, e)}
                  labelAccept={
                    <>
                      <Icon marginRight={1} sizeIcon="display24">
                        <IconCheckCircle />
                      </Icon>
                      <FormattedMessage id="common.yes" />
                    </>
                  }
                  actionDeny={(e) => handleModerateContent(el.contentId, e)}
                  labelDeny={
                    <>
                      <Icon marginRight={1} sizeIcon="display24">
                        <IconCloseCircle />
                      </Icon>
                      <FormattedMessage id="common.no" />
                    </>
                  }
                  titleLabelModeration={
                    <FormattedMessage id="communication.moderation.approveQuestionLabel" />
                  }
                />
              </Box>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Container>
  ) : (
    <Loader customColor={line?.active?.company?.style?.primaryColor} />
  );
};
