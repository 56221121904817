import { constants } from '../config';
/* **** getCRGPD *** */
export const getRGPD = (lang) => ({
  type: constants.GET_RGPD,
  lang,
});

export const getRGPDSuccess = (data) => ({
  type: constants.GET_RGPD_SUCCESS,
  payload: data,
});

export const getRGPDFail = (error) => ({
  type: constants.GET_RGPD_FAIL,
  error,
});
