import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getConditions } from '../../../common/helpers/conditions';
import { getPrivacy } from '../../../common/helpers/privacy';
import { acceptLegalTerms } from '../../../common/schemes/line';
import { ModalNotAllowedToSeeTemplate } from '../template';

const NotShowingPage = ({ actualLanguage }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [body, setBody] = useState('');
  const intl = useIntl();

  const getConditionsAPI = useCallback((lang) => dispatch(getConditions(lang)), [dispatch]);
  const getPrivacyAPI = useCallback((lang) => dispatch(getPrivacy(lang)), [dispatch]);

  const history = useHistory();
  const conditions = useSelector((state) => state.helper?.conditions);
  const privacy = useSelector((state) => state.helper?.privacy);

  const dataConditions = conditions?.data;
  const dataPrivacy = privacy?.data;

  const dispatch = useDispatch();
  const line = useSelector((state) => state.line);
  const { active } = line;

  useEffect(() => {
    if (active?.permissionType) {
      setIsAdmin(active?.permissionType > 3);
    }
  }, [active]);

  useEffect(() => {
    if (isAdmin) {
      setBody(<FormattedMessage id="legalTerms.bodyAdmin" />);
    } else {
      setBody(<FormattedMessage id="legalTerms.body" />);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (!dataConditions) {
      getConditionsAPI(actualLanguage);
    }
  }, [dataConditions]);

  useEffect(() => {
    if (!dataPrivacy) {
      getPrivacyAPI(actualLanguage);
    }
  }, [dataPrivacy]);

  const acceptLegalTermsAPI = useCallback((id) => dispatch(acceptLegalTerms(id)), [dispatch]);

  const handleSubmit = () => {
    acceptLegalTermsAPI(active?.lineId);
  };

  if (!active?.lineId) {
    return <Loader />;
  }

  return (
    <ModalNotAllowedToSeeTemplate
      title={<FormattedMessage id="legalTerms.title" />}
      body={body}
      buttons={{
        confirm: intl.formatMessage({ id: 'common.yes' }),
        cancel: intl.formatMessage({ id: 'common.no' }),
      }}
      actionGoToConfiguration={() => {
        history.push(`/${actualLanguage}/profile`);
      }}
      actionRemoveContent={() => {
        history.push(`/${actualLanguage}/profile`);
      }}
      confirmationButton={handleSubmit}
      isAdmin={isAdmin}
      modalText={<FormattedMessage id="legalTerms.generalConditions" />}
      companyColor={active?.company?.style?.primaryColor}
      companyTextButtonColor={active?.company?.style?.textColor}
      modalLegalText={<FormattedMessage id="profile.personalData.generalConditions" />}
      dataConditions={dataConditions}
      dataPrivacy={dataPrivacy}
      modalPrivacyText={<FormattedMessage id="profile.personalData.privacy" />}
      privacyText={<FormattedMessage id="legalTerms.privacy" />}
    />
  );
};
export default NotShowingPage;
