import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardPersonalData } from '@lideralia/alife-uikit/dist/organisms/Cards';
import { ModalLegal } from '@lideralia/alife-uikit/dist/organisms/Modals/Legal';
import parse from 'html-react-parser';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PhoneUpdate } from '../../../common/components/phoneUpdate/container';

export const DataUserTemplate = (props) => {
  const {
    user,
    company,
    handleCloseSession,
    history: { goBack },
    handleChangePicture,
    handleSubmit,
    labelChangePicture,
    isEditMode,
    handleChange,
    picture,
    name,
    phoneNumber,
    birthDate,
    optionsLanguage,
    language,
    setIsEditMode,
    updatePhoneIsOpened,
    setUpdatePhoneIsOpened,
    showConditions,
    setShowConditions,
    dataConditions,
    dataPrivacy,
    dataCookies,
    setShowCookies,
    showCookies,
    showPrivacy,
    setShowPrivacy,
    optionsOfConditions,
    optionsOfPrivacy,
  } = props;
  const intl = useIntl();

  return (
    <>
      {user.loading && <Loader customColor={company?.style?.primaryColor} />}
      <Container paddingY={4} height="100vh" paddingBottom={7}>
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="339px"
          minWidth="290px"
          margin="0 auto"
        >
          <Text
            fontWeight="bold"
            sizeText="display24"
            color="black84"
            display="flex"
            style={{ alignItems: 'center' }}
          >
            <FormattedMessage id="profile.personalData.title" />
          </Text>
          <Button
            paddingY={1}
            paddingX={1}
            colorType="black"
            sizeButton="big"
            rounded
            justifyContent="flex-start"
            action={() => goBack()}
          >
            <Icon sizeIcon="display24" color="black84">
              <IconArrowLeft />
            </Icon>
          </Button>
        </Flex>
        <Flex alignItems="center" flexDirection="column" paddingBottom={isEditMode && 220}>
          <Box marginTop={4} paddingBottom={isEditMode && 8}>
            {!updatePhoneIsOpened && (
              <CardPersonalData
                customColor={company?.style?.primaryColor}
                customTextColor={company?.style?.textColor}
                setIsEditMode={setIsEditMode}
                isEditMode={isEditMode}
                handleChangePicture={handleChangePicture}
                picture={picture}
                name={name}
                labelChangePicture={labelChangePicture}
                labelName={intl.formatMessage({ id: 'common.nameLarge' })}
                labelPhone={intl.formatMessage({ id: 'common.phoneMobile' })}
                labelLang={intl.formatMessage({ id: 'common.language' })}
                labelBirthday={intl.formatMessage({ id: 'common.dateFrom' })}
                labelCancel={<FormattedMessage id="common.cancel" />}
                labelSave={<FormattedMessage id="common.save" />}
                phoneNumber={phoneNumber}
                editLabel={<FormattedMessage id="common.edit" />}
                handleChange={handleChange}
                birthDate={birthDate}
                language={language}
                optionsLanguage={optionsLanguage}
                setUpdatePhoneIsOpened={setUpdatePhoneIsOpened}
                handleSubmit={handleSubmit}
              />
            )}

            {updatePhoneIsOpened && <PhoneUpdate hideModalLogin={setUpdatePhoneIsOpened} />}
          </Box>
        </Flex>
        {!isEditMode && (
          <>
            <Box marginTop={3} marginX="auto" maxWidth="339px" minWidth="290px">
              <Button marginY={2} fullWidth sizeButton="big" action={() => handleCloseSession()}>
                <Text fontWeight="bold" sizeText="display16" color="black84">
                  <FormattedMessage id="profile.personalData.logout" />
                </Text>
              </Button>
            </Box>
            <Flex
              marginTop={3}
              marginBottom={6}
              paddingBottom={8}
              alignItems="center"
              justifyContent="center"
            >
              <Text
                marginRight={2}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontWeight="bold"
                sizeText="display14"
                color="black60"
                onClick={(e) => {
                  e.preventDefault();
                  setShowConditions(true);
                }}
              >
                <FormattedMessage id="profile.personalData.generalConditions" />
              </Text>
              <ModalLegal
                customColor={company?.style?.primaryColor}
                customTextColor={company?.style?.textColor}
                isOpen={showConditions}
                id="alife-conditions"
                title={<FormattedMessage id="profile.personalData.generalConditions" />}
                content={dataConditions ? parse(dataConditions, optionsOfConditions) : null}
                onModalClose={() => setShowConditions(false)}
              />
              <Text
                marginRight={2}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontWeight="bold"
                sizeText="display14"
                color="black60"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPrivacy(true);
                }}
              >
                <FormattedMessage id="profile.personalData.privacy" />
              </Text>
              <ModalLegal
                customColor={company?.style?.primaryColor}
                customTextColor={company?.style?.textColor}
                isOpen={showPrivacy}
                id="alife-privacy"
                title={<FormattedMessage id="profile.personalData.privacy" />}
                content={dataPrivacy ? parse(dataPrivacy, optionsOfPrivacy) : null}
                onModalClose={() => setShowPrivacy(false)}
              />
              <Text
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontWeight="bold"
                sizeText="display14"
                color="black60"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCookies(true);
                }}
              >
                <FormattedMessage id="profile.personalData.cookies" />
              </Text>
              <ModalLegal
                customColor={company?.style?.primaryColor}
                customTextColor={company?.style?.textColor}
                isOpen={showCookies}
                id="alife-cookies"
                title={<FormattedMessage id="profile.personalData.cookies" />}
                content={dataCookies ? parse(dataCookies) : null}
                onModalClose={() => setShowCookies(false)}
              />
            </Flex>
          </>
        )}
      </Container>
    </>
  );
};
