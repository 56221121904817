/*
 *
 * i18nProviderReducer reducer
 *
 */

import { DEFAULT_LOCALE } from './i18n';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const initialState = {
  locale: DEFAULT_LOCALE,
};

function i18nProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      window.locale = action.locale;
      return state.set('locale', action.locale);
    default:
      return state;
  }
}

export default i18nProviderReducer;
