/**
 * This function converts a quantity of time expressed in seconds to its equivalent
 * in minutes and seconds.
 *
 * @param {Integer} duration - quantity of time in seconds.
 *
 * @return {Object.<string, number>} having the original duration, minutes and seconds.
 */

export const convertSecondsToMinutes = (duration) => {
  const minutes = duration / 60;
  const roundMinutes = Math.floor(minutes);
  const seconds = (minutes - roundMinutes) * 60;
  const roundSeconds = Math.round(seconds);
  return {
    duration,
    minutes: roundMinutes || null,
    seconds: roundSeconds || null,
  };
};

/**
 * This function gets the wording to express days in the corresponding language,
 * depending on its number (a single day or more than one).
 *
 * @param {Integer} days - the number of days.
 *
 * @return {String} the translation for the word days - or day -.
 */

export const getDays = (days) => {
  switch (true) {
    case days === 1:
      return window.intl('datetime.distance_in_words.x_days.one');
    case days > 1:
      return window.intl('datetime.distance_in_words.x_days.other', {
        count: days,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express hours in the corresponding language,
 * depending on its number (a single hour or more than one).
 *
 * @param {Integer} hours - the number of hours.
 *
 * @return {String} the translation for the word hours - or hour -.
 */

export const getHours = (hours) => {
  switch (true) {
    case hours === 1:
      return window.intl('datetime.distance_in_words.x_hours.one');
    case hours > 1:
      return window.intl('datetime.distance_in_words.x_hours.other', {
        count: hours,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express minutes in the corresponding language,
 * depending on its number (a single minute or more than one).
 *
 * @param {Integer} minutes - the number of minutes.
 *
 * @return {String} the translation for the word minutes - or minute -.
 */

export const getMinutes = (minutes) => {
  switch (true) {
    case minutes === 1:
      return window.intl('datetime.distance_in_words.x_minutes.one');
    case minutes > 1:
      return window.intl('datetime.distance_in_words.x_minutes.other', {
        count: minutes,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express seconds in the corresponding language,
 * depending on its number (a single second or more than one).
 *
 * @param {Integer} seconds - the number of seconds.
 *
 * @return {String} the translation for the word seconds - or second -.
 */

export const getSeconds = (seconds) => {
  switch (true) {
    case seconds === 1:
      return window.intl('datetime.distance_in_words.x_seconds.one');
    case seconds > 1:
      return window.intl('datetime.distance_in_words.x_seconds.other', {
        count: seconds,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to time ago in the corresponding language.
 *
 * @param {String} date - the number of time.
 *
 * @return {String} the translation for the time ago.
 */

export const timeAgo = (date) => {
  const today = new Date();
  const time = new Date(date?.split('-').join('/'));
  const hours = time.getHours();
  const nowHour = today.getHours();
  const isToday = today.toDateString() === time.toDateString();
  const isThisMonth = today.getMonth() === time.getMonth();
  const isThisYear = today.getFullYear() === time.getFullYear();
  const moreThatHour = nowHour > hours;
  if (isToday && isThisMonth && isThisYear) {
    if (moreThatHour) {
      if (nowHour - hours === 1) return window.intl.formatMessage({ id: 'date.oneHourAgo' });
      return window.intl.formatMessage({ id: 'date.xHoursAgo' }, { count: nowHour - 2 - hours });
    }
    return window.intl.formatMessage({ id: 'date.lessThatHour' });
  }

  return time.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const getDateDifferenceInYears = (dateFrom, dateTo) => {
  let diff = dateTo.getFullYear() - dateFrom.getFullYear();
  if (dateTo.getMonth() === dateFrom.getMonth()) {
    if (dateTo.getDate() < dateFrom.getDate()) diff -= 1;
  } else if (dateTo.getMonth() < dateFrom.getMonth()) diff -= 1;
  return diff;
};
