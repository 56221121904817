import { constants } from '../config';

const initialState = {
  user: {},
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  passtroughDone: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_MOBILE_PASSTROUGH:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_MOBILE_PASSTROUGH_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        passtroughDone: true,
        errorRequest: false,
        error: null,
      };
    }
    case constants.SET_MOBILE_PASSTROUGH_FAIL: {
      const data = { ...state, loading: false, errorRequest: true, error: { ...action.error } };
      // console.log('SET_MOBILE_PASSTROUGH_FAIL', data);
      return data;
    }
    case constants.SET_VERIFY_LOGIN:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_VERIFY_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        errorRequest: false,
        error: null,
        done: true,
      };
    }
    case constants.SET_VERIFY_LOGIN_FAIL: {
      const data = { ...state, loading: false, errorRequest: true, error: { ...action.error } };
      // console.log('SET_VERIFY_LOGIN_FAIL', data);
      return data;
    }
    case constants.RESET_PASSTROUGH:
      return { ...state, passtroughDone: false };
    case constants.RESET_ERROR:
      return { ...state, errorRequest: false, error: null };
    default:
      return state;
  }
}
