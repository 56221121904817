/* eslint-disable no-nested-ternary,no-unused-vars */
import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconLike from '@lideralia/alife-uikit/dist/atoms/Icons/IconLike';
import IconUser from '@lideralia/alife-uikit/dist/atoms/Icons/IconUser';
import { Box, Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { Pill } from '@lideralia/alife-uikit/dist/atoms/Pill';
import { Textarea } from '@lideralia/alife-uikit/dist/atoms/Textarea';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Alert } from '@lideralia/alife-uikit/dist/molecules/Alerts/Alert';
import { Stars } from '@lideralia/alife-uikit/dist/molecules/Stars';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export const PollOrderDeliveredTemplate = ({
  poll,
  line,
  company,
  valueOrderDeliveredAnswers,
  setValueOrderDeliveredAnswers,
  setFinishPoll,
  isFinishPoll,
  onSubmit,
  goToFamilyZone,
}) => {
  useEffect(() => {
    console.log('modificated valueOrderDeliveredAnswers', valueOrderDeliveredAnswers);
  }, [valueOrderDeliveredAnswers]);

  return (
    <Container paddingY={3} paddingBottom={8}>
      {!poll.error ? (
        <>
          {!poll.done && !isFinishPoll ? (
            <Loader customColor={company?.style?.primaryColor} />
          ) : !isFinishPoll ? (
            <Flex alignItems="center" flexDirection="column" marginBottom={6}>
              <CardGone
                name={line?.active?.name}
                date={
                  line?.active?.dateFrom?.year &&
                  `${line?.active?.dateFrom?.year} - ${line?.active?.dateTo?.year}`
                }
                picture={line?.active?.pictures?.m}
                isOpened={false}
                setIsEditMode={() => {}}
                isEditMode={false}
              />
              <Alert
                message={
                  <FormattedMessage
                    id="poll.orderDelivered.alertMessage"
                    values={{
                      name: poll?.data?.userName,
                      branchName: poll?.data?.branchName,
                      tradename: poll?.data?.company?.tradename,
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                }
                zIndex={0}
                marginBottom={3}
                marginTop={3}
              />
              <Card maxWidth="339px" minWidth="290px" position="relative" width="100%">
                <Flex
                  flexDirection="column"
                  paddingX={3}
                  width="100%"
                  paddingTop={3}
                  marginBottom={1}
                >
                  <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
                    <FormattedMessage id="poll.orderDelivered.poll_question_q1" />
                  </Text>
                  <Flex alignItems="center" justifyContent="center" marginBottom={1}>
                    <Stars
                      id="poll-stars-poll_order_delivered_q1"
                      value={valueOrderDeliveredAnswers.poll_order_delivered_q1}
                      setValue={(val) =>
                        setValueOrderDeliveredAnswers({
                          ...valueOrderDeliveredAnswers,
                          poll_order_delivered_q1: val,
                        })
                      }
                    />
                  </Flex>
                  <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
                    <FormattedMessage id="poll.orderDelivered.poll_question_q2" />
                  </Text>
                  <Flex alignItems="center" justifyContent="center" marginBottom={1}>
                    <Stars
                      id="poll-stars-poll_order_delivered_q2"
                      value={valueOrderDeliveredAnswers.poll_order_delivered_q2}
                      setValue={(val) =>
                        setValueOrderDeliveredAnswers({
                          ...valueOrderDeliveredAnswers,
                          poll_order_delivered_q2: val,
                        })
                      }
                    />
                  </Flex>
                  <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
                    <FormattedMessage id="poll.orderDelivered.poll_question_q3" />
                  </Text>
                  <Flex alignItems="center" justifyContent="center" marginBottom={1}>
                    <Stars
                      id="poll-stars-poll_order_delivered_q3"
                      value={valueOrderDeliveredAnswers.poll_order_delivered_q3}
                      setValue={(val) =>
                        setValueOrderDeliveredAnswers({
                          ...valueOrderDeliveredAnswers,
                          poll_order_delivered_q3: val,
                        })
                      }
                    />
                  </Flex>
                </Flex>
                <Box
                  borderBottomWidth="1px"
                  borderBottomColor="black12"
                  borderBottomStyle="solid"
                  width="100%"
                  marginBottom={4}
                />
                <Flex flexDirection="column" paddingX={3} width="100%">
                  <Field
                    label={intl.formatMessage({ id: 'poll.orderDelivered.poll_question_q4' })}
                    color="black60"
                    fontWeight="bold"
                    marginTop={0}
                    marginBottom={1}
                  >
                    <Textarea
                      counter
                      typeAction={(name, val) =>
                        setValueOrderDeliveredAnswers({
                          ...valueOrderDeliveredAnswers,
                          poll_order_delivered_q4: val,
                        })
                      }
                      defaultValue={valueOrderDeliveredAnswers.poll_order_delivered_q4}
                      placeholderMessage={intl.formatMessage({ id: 'common.writeSomething' })}
                    />
                  </Field>
                </Flex>
                <Flex
                  flexDirection="column"
                  paddingX={3}
                  width="100%"
                  paddingTop={2}
                  paddingBottom={3}
                >
                  <Button
                    className="button-submit"
                    backgroundColor={company?.style?.primaryColor}
                    colorType={company?.style?.primaryColor || 'orange'}
                    color={company?.style?.textColor}
                    sizeButton="big"
                    fullWidth
                    action={() => {
                      setFinishPoll(true);
                      onSubmit();
                    }}
                  >
                    <FormattedMessage id="poll.submit" />
                  </Button>
                </Flex>
              </Card>
            </Flex>
          ) : (
            <Flex alignItems="center" justifyContent="center" flexDirection="column">
              <Card
                maxWidth="339px"
                minWidth="290px"
                position="relative"
                width="100%"
                marginBottom={3}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingX={3}
                  width="100%"
                  paddingY={3}
                >
                  <Pill backgroundColor="red" padding={4} borderRadius={7}>
                    <Icon color="white" sizeIcon="display24">
                      <IconLike />
                    </Icon>
                  </Pill>
                  <Text
                    id="pollConfirmTitle"
                    fontWeight="bold"
                    sizeText="display16"
                    color="black84"
                    marginTop={1}
                    textAlign="center"
                  >
                    <FormattedMessage id="poll.confirmPoll.title" />
                  </Text>
                  <Text marginTop={1} sizeText="display13" color="black60" textAlign="center">
                    <FormattedMessage id="poll.confirmPoll.description" />
                  </Text>
                </Flex>
              </Card>

              <Button fullWidth colorType="black" maxWidth="339px" action={goToFamilyZone}>
                <Icon sizeIcon="display24" marginRight={1}>
                  <IconUser />
                </Icon>
                <FormattedMessage id="poll.goToFamilyZone" />
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Loader customColor={company?.info?.style?.primaryColor} />
      )}
    </Container>
  );
};
