import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { getLocalStorage } from '../../../common/functions/cookies';
import { actualLanguage } from '../../../common/functions/lang';
import { getLine, getModerateContents } from '../../../common/schemes/line';
import { getNotifications, getRequests } from '../../../common/schemes/notifications';
import { ProfileTemplate } from '../template';

const ProfilePage = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.notifications);
  const line = useSelector((state) => state.line);
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const {
    active,
    active: { lineId, moderation, permissionType },
  } = line;
  const userId = useSelector((state) => state.user?.data?.id);
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getContentModerateAPI = useCallback((id) => dispatch(getModerateContents(id)), [dispatch]);

  const getNotificationsAPI = useCallback((id) => dispatch(getNotifications(id)), [dispatch]);
  const getRequestsAPI = useCallback(() => dispatch(getRequests()), [dispatch]);

  useEffect(() => {
    if (lineId && permissionType > 2) {
      getContentModerateAPI(active?.lineId);
    }
  }, [lineId]);

  useEffect(() => {
    if (userId != null && notifications?.data?.length === 0) {
      getNotificationsAPI(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userId != null) {
      getRequestsAPI();
    }
  }, [userId]);

  useEffect(() => {
    if (!line.done && !line.loading && idLineStored && idNameStored) {
      getLineAPI(idLineStored, idNameStored);
    }
  }, [user, getLineAPI]);

  const goToNotifications = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_NOTIFICATIONS_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(`/${actualLanguage}/profile/notifications`);
  };
  const goToPersonalZone = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_PERSONAL_ZONE_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(`/${actualLanguage}/profile/personal-zone`);
  };

  const goToArrangements = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_ARRANGEMENTS_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(`/${actualLanguage}/profile/${line.active?.lineId}/${line.active?.nameUrl}/arrangements`);
  };

  const goToModeration = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_MODERATION_CONTENT_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(
      `/${actualLanguage}/profile/${line.active?.lineId}/${line.active?.nameUrl}/moderation-content`
    );
  };

  const goToConfiguration = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_MODERATION_CONTENT_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(`/${actualLanguage}/profile/${line.active?.lineId}/${line.active?.nameUrl}/configuration`);
  };

  const goToSettings = () => {
    setEventAnalytics(
      KEYS_ANALYTICS.PROFILE_SETTINGS_BUTTON_CLICKED,
      user,
      window?.location?.pathname,
      active
    );
    push(`/${actualLanguage}/profile/${line.active?.lineId}/${line.active?.nameUrl}/settings`);
  };
  return (
    <ProfileTemplate
      active={line?.active}
      haveNotifications={notifications?.unReadNotifications || notifications?.unReadRequests}
      actionNotifications={goToNotifications}
      actionArrangements={goToArrangements}
      actionPersonalZone={goToPersonalZone}
      actionModeration={goToModeration}
      actionConfiguration={goToConfiguration}
      actionSettings={goToSettings}
      moderation={moderation}
    />
  );
};

export default ProfilePage;
