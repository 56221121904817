/* eslint-disable react/forbid-prop-types */
import { Text } from '@lideralia/alife-uikit';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import {
  CarouselScrollTreeUdianas,
  NumberOfTreesUdianas,
  StepsUdianas,
} from '@lideralia/alife-uikit/dist/molecules/Udianas';
import { CardPayInfo, CheckShowInput } from '@lideralia/alife-uikit/dist/organisms/Cards';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HeaderSectionStyled, SectionWithMarginStyled } from '../styles';

const URL_UDIANAS_TERMS = 'https://www.udianas.net/home/termsOfUse';

const PlantTree = ({
  itemsChooseProject,
  treeAlreadyPlanted,
  treeFromAProject,
  valueRecommendation,
  setValueRecommendation,
  intl,
  checkValue,
  setCheckValue,
  optionsCountry,
  stripeKey,
  stripeAccount,
  informationForm,
  updateForm,
  submitPayment,
  customColor,
  customTextColor,
  order,
  locale,
  haveValuesOfQueryParams,
  totalPrice,
  companyName,
}) => (
  <>
    <HeaderSectionStyled>
      <Text as="h3" sizeText="display24" color="black84" fontWeight="bold">
        <FormattedMessage id="udianas.plantATreeOfMemories" />
      </Text>
      <Text as="p" sizeText="display16">
        <FormattedMessage id="udianas.leaveALastingMark" />
      </Text>
    </HeaderSectionStyled>
    {itemsChooseProject.length > 0 ? (
      <StepOne
        customColor={customColor}
        customTextColor={customTextColor}
        itemsChooseProject={itemsChooseProject}
        selected={informationForm?.reforestation_project_hash_id}
        intl={intl}
      />
    ) : null}

    {informationForm?.reforestation_project_hash_id !== null ? (
      <StepTwo
        customColor={customColor}
        customTextColor={customTextColor}
        treeFromAProject={treeFromAProject}
        selected={informationForm?.id}
        intl={intl}
        haveValuesOfQueryParams={haveValuesOfQueryParams}
      />
    ) : null}

    {informationForm?.id !== null ? (
      <StepThree
        customColor={customColor}
        customTextColor={customTextColor}
        valueRecommendation={valueRecommendation}
        setValueRecommendation={setValueRecommendation}
        intl={intl}
        updateForm={updateForm}
        totalPrice={totalPrice}
        showTotalPrice={informationForm?.quantity_purchased !== null}
      />
    ) : null}

    {informationForm?.quantity_purchased !== null ? (
      <>
        <StepFour
          customColor={customColor}
          intl={intl}
          checkValue={checkValue}
          setCheckValue={setCheckValue}
          updateForm={updateForm}
        />

        <PaymentTree
          optionsCountry={optionsCountry}
          stripeKey={stripeKey}
          stripeAccount={stripeAccount}
          submitPayment={submitPayment}
          customColor={customColor}
          customTextColor={customTextColor}
          order={order}
          locale={locale}
        />
      </>
    ) : null}

    <AlreadyPlanted treeAlreadyPlanted={treeAlreadyPlanted} companyName={companyName} />
  </>
);
const StepOne = ({ customColor, customTextColor, selected, itemsChooseProject, intl }) => (
  <SectionWithMarginStyled>
    <StepsUdianas
      number={1}
      title={intl.formatMessage({ id: 'udianas.choosePlanting' })}
      paragraph={intl.formatMessage({ id: 'udianas.whereLeaveYourMark' })}
      backgroundColor={customColor}
      width="100%"
    />
    <CarouselScrollTreeUdianas
      id="carousel-scroll-tree-udianas-1"
      width="100%"
      height="255px"
      products={itemsChooseProject}
      customColor={customColor}
      customTextColor={customTextColor}
      isOpened
      textButtonSelect={intl.formatMessage({ id: 'flowerEcommerce.select' })}
      textButtonSelected={intl.formatMessage({ id: 'flowerEcommerce.selected' })}
      selected={selected}
    />
  </SectionWithMarginStyled>
);

const StepTwo = ({
  customColor,
  customTextColor,
  selected,
  treeFromAProject,
  intl,
  haveValuesOfQueryParams,
}) => {
  const [showComponent, setShowComponent] = useState(false);
  const firstTimeRenderComponent = useRef(false);
  useEffect(() => {
    let timeout;
    if (haveValuesOfQueryParams && !firstTimeRenderComponent.current) {
      timeout = setTimeout(() => {
        setShowComponent(true);
      }, 1800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [haveValuesOfQueryParams]);
  return (
    <SectionWithMarginStyled>
      <StepsUdianas
        number={2}
        title={intl.formatMessage({ id: 'udianas.chooseTypeOfPlant' })}
        paragraph={intl.formatMessage({ id: 'udianas.someSpeciesRecommend' })}
        backgroundColor={customColor}
        width="100%"
      />
      {!haveValuesOfQueryParams || showComponent ? (
        <CarouselScrollTreeUdianas
          id="carousel-scroll-tree-udianas-2"
          width="100%"
          height="355px"
          customColor={customColor}
          customTextColor={customTextColor}
          products={treeFromAProject}
          textSeeDetail={intl.formatMessage({ id: 'udianas.seeDetail' })}
          showDescription
          isOpened
          textButtonSelect={intl.formatMessage({ id: 'flowerEcommerce.select' })}
          textButtonSelected={intl.formatMessage({ id: 'flowerEcommerce.selected' })}
          selected={selected}
        />
      ) : null}
    </SectionWithMarginStyled>
  );
};

const StepThree = ({
  customTextColor,
  valueRecommendation,
  setValueRecommendation,
  customColor,
  intl,
  updateForm,
  totalPrice,
  showTotalPrice,
}) => (
  <SectionWithMarginStyled>
    <StepsUdianas
      number={3}
      title={intl.formatMessage({ id: 'udianas.selectNumbersTrees' })}
      paragraph={intl.formatMessage({ id: 'udianas.manyTreesWouldPlant' })}
      backgroundColor={customColor}
      width="100%"
    />
    <NumberOfTreesUdianas
      colorButton={customColor}
      customTextColor={customTextColor}
      handleChange={(name, value) => {
        updateForm('quantity_purchased', Number(value));
      }}
      textOtherQuantity={intl.formatMessage({ id: 'udianas.otherQuantity' })}
      placeholderMessageInput={intl.formatMessage({ id: 'udianas.writeTheAmountHere' })}
      valueRecommendation={valueRecommendation}
      setValueRecommendation={setValueRecommendation}
    />
    {showTotalPrice ? (
      <Card width="339px" borderWidth="1px" padding={3} marginTop={2}>
        <Text as="h3" sizeText="display14" fontWeight="bold" textAlign="center">
          {intl.formatMessage({ id: 'udianas.total_price' }).toUpperCase()}:
          <Text sizeText="display16" as="span" marginLeft={1}>
            {totalPrice}€
          </Text>
        </Text>
      </Card>
    ) : null}
  </SectionWithMarginStyled>
);

const StepFour = ({ customColor, intl, checkValue, setCheckValue, updateForm }) => {
  const updateFormMessage = ({ target }) => {
    updateForm('message_tree', target.value);
  };
  useEffect(() => {
    if (!checkValue) updateForm('stepFourMessageTree', null);
  }, [checkValue]);
  return (
    <SectionWithMarginStyled>
      <StepsUdianas
        number={4}
        title={intl.formatMessage({ id: 'udianas.makeTreeSpecial' })}
        paragraph={intl.formatMessage({ id: 'udianas.includeUniqueMessage' })}
        backgroundColor={customColor}
        width="100%"
      />
      <CheckShowInput
        id="check-show-input"
        customColor={customColor}
        placeholderText={intl.formatMessage({ id: 'flowerEcommerce.ribbonPlaceHolder' })}
        textLabel={intl.formatMessage({ id: 'udianas.customize_your_tree' })}
        maxLengthText={140}
        changeInputValue={updateFormMessage}
        handleSwitch={setCheckValue}
        checkValue={checkValue}
      />
    </SectionWithMarginStyled>
  );
};

const PaymentTree = ({
  customColor,
  customTextColor,
  optionsCountry,
  stripeKey,
  stripeAccount,
  submitPayment,
  order,
  locale,
}) => (
  <>
    {order?.loading && <Loader customColor={customColor} />}
    <SectionWithMarginStyled>
      <Text as="h3" fontWeight="bold" sizeText="display24" marginBottom="18px" color="black84">
        <FormattedMessage id="flowerEcommerce.endPayment" />
      </Text>
      <CardPayInfo
        id="card-pay-info"
        maxWidth="100%"
        labelPayMethod={<FormattedMessage id="flowerEcommerce.labelPayMethod" />}
        labelCreditCard={<FormattedMessage id="flowerEcommerce.labelCreditCard" />}
        mathodSelected="card"
        stripeKey={stripeKey}
        stripeAccount={stripeAccount}
        locale={locale}
        // BODY
        labelInvoiceInfo={<FormattedMessage id="flowerEcommerce.labelInvoiceInfo" />}
        labelInvoice={<FormattedMessage id="flowerEcommerce.labelTreeReceipt" />}
        labelNeedInvoice={<FormattedMessage id="flowerEcommerce.labelRequestDonationReceipt" />}
        labelName={<FormattedMessage id="flowerEcommerce.labelName" />}
        name=""
        labelSurname={<FormattedMessage id="flowerEcommerce.labelSurname" />}
        surname=""
        labelDNI={<FormattedMessage id="flowerEcommerce.labelDNI" />}
        dni=""
        labelEmail={<FormattedMessage id="flowerEcommerce.labelEmail" />}
        email=""
        labelAddress={<FormattedMessage id="flowerEcommerce.labelAddress" />}
        address=""
        labelLocation={<FormattedMessage id="flowerEcommerce.labelLocation" />}
        location=""
        labelCP={<FormattedMessage id="flowerEcommerce.labelCP" />}
        cp=""
        labelProvince={<FormattedMessage id="flowerEcommerce.labelProvince" />}
        province=""
        labelCountry={<FormattedMessage id="flowerEcommerce.labelCountry" />}
        country="ES"
        // FOOTER
        labelButton={
          order?.loading ? (
            <FormattedMessage id="udianas.procesingPayment" />
          ) : (
            <FormattedMessage id="flowerEcommerce.labelButton" />
          )
        }
        labelTermsAndConditions={
          <FormattedMessage
            id="udianas.validatingMyOrderReadTheConditions"
            values={{
              link: (...chunks) => (
                <a
                  href={`${URL_UDIANAS_TERMS}?language=${locale}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        }
        optionsCountry={optionsCountry}
        buttonDisabled={order?.loading}
        handleSubmit={(content) => submitPayment(content)}
        customColor={customColor}
        customTextColor={customTextColor}
      />
    </SectionWithMarginStyled>
  </>
);

const AlreadyPlanted = ({ treeAlreadyPlanted, companyName }) => {
  if (!treeAlreadyPlanted) return null;
  const products = treeAlreadyPlanted.map((item) => ({
    ...item,
    id: item?.treeProductId,
    title: `${item?.treeProduct?.projectName.split(',')[0]}, `,
    subtitle: item?.treeProduct?.projectName.split(',')[1],
    paragraphTitle: intl.formatMessage(
      { id: 'udianas.plantedBy' },
      { name: item?.userName || companyName }
    ),
    picture: {
      alt: item?.treeProduct?.name,
      src: item?.treeProduct?.imageUrl,
    },
    treesPlanted: intl.formatMessage(
      { id: 'udianas.numberOfTreesPlanted' },
      { trees: item?.quantityPurchased }
    ),
  }));
  return (
    <SectionWithMarginStyled>
      <Text as="h3" sizeText="display24" color="black84" fontWeight="bold">
        <FormattedMessage id="udianas.alreadyPlanted" />
      </Text>
      <CarouselScrollTreeUdianas
        width="250px"
        height="180px"
        products={products}
        isOpened={false}
        heightCard="164px"
      />
    </SectionWithMarginStyled>
  );
};

export default PlantTree;

PlantTree.propTypes = {
  itemsChooseProject: PropTypes.array.isRequired,
  treeAlreadyPlanted: PropTypes.array.isRequired,
  treeFromAProject: PropTypes.array.isRequired,
  valueRecommendation: PropTypes.string.isRequired,
  setValueRecommendation: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  checkValue: PropTypes.bool.isRequired,
  setCheckValue: PropTypes.func.isRequired,
  optionsCountry: PropTypes.array.isRequired,
  stripeKey: PropTypes.string.isRequired,
  stripeAccount: PropTypes.string.isRequired,
  informationForm: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
  haveValuesOfQueryParams: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
};

PlantTree.defaultProps = {
  customColor: 'orange',
  customTextColor: 'white',
};

StepOne.propTypes = {
  itemsChooseProject: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
};

StepOne.defaultProps = {
  customColor: 'orange',
  customTextColor: 'white',
};

StepTwo.propTypes = {
  treeFromAProject: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
  haveValuesOfQueryParams: PropTypes.bool.isRequired,
};

StepTwo.defaultProps = {
  customColor: 'orange',
  customTextColor: 'white',
};

StepThree.propTypes = {
  valueRecommendation: PropTypes.string.isRequired,
  setValueRecommendation: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
  totalPrice: PropTypes.number.isRequired,
  showTotalPrice: PropTypes.bool.isRequired,
};

StepThree.defaultProps = {
  customColor: 'orange',
  customTextColor: 'white',
};

StepFour.propTypes = {
  intl: PropTypes.object.isRequired,
  checkValue: PropTypes.bool.isRequired,
  setCheckValue: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  customColor: PropTypes.string,
};

StepFour.defaultProps = {
  customColor: 'orange',
};

PaymentTree.propTypes = {
  optionsCountry: PropTypes.array.isRequired,
  stripeKey: PropTypes.string.isRequired,
  stripeAccount: PropTypes.string.isRequired,
  submitPayment: PropTypes.func.isRequired,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

PaymentTree.defaultProps = {
  customColor: 'orange',
  customTextColor: 'white',
};

AlreadyPlanted.propTypes = {
  treeAlreadyPlanted: PropTypes.array.isRequired,
  companyName: PropTypes.string.isRequired,
};
