import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2, requestApiCSRF } from '../../../../lib/request';
import { setLocalStorage } from '../../../common/functions/cookies';
import * as action from '../actions';
import { constants, services } from '../config';
import { MapperVerifyLogin } from '../mapper';

export function* setMobilePasstroughSaga(body) {
  try {
    const response = yield requestApi2(services.setMobilePasstrough(body));
    console.log('response', response.status);

    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.setMobilePasstroughSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log('error setMobilePasstroughSaga', error);
    yield put(action.setMobilePasstroughFail(error));

    /*
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
    */
  }
}

export function* setVerifyLoginSaga({ data }) {
  try {
    const dataMapped = MapperVerifyLogin.dehydrate(data);
    const response = yield requestApiCSRF(services.setVerifyLogin(dataMapped));
    console.log('response', response.status);
    if (response && response.status >= 200 && response.status < 300) {
      const res = response?.data;
      setLocalStorage(process.env.REACT_APP_COOKIE_LOGIN_NAME, res?.alife_token);
      yield put(action.setVerifyLoginSuccess(res));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.setVerifyLoginFail(error));
    console.log('error setVerifyLoginSaga', error, {
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
    /*
    yield showAlertSaga({
      status: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    }); */
  }
}

function* actionWatcher() {
  yield takeLatest(constants.SET_MOBILE_PASSTROUGH, setMobilePasstroughSaga);
  yield takeLatest(constants.SET_VERIFY_LOGIN, setVerifyLoginSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
