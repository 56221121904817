import { Button } from '@lideralia/alife-uikit/dist/atoms/Button/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import { IconCheck } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconHome from '@lideralia/alife-uikit/dist/atoms/Icons/IconHome';
import IconInfo from '@lideralia/alife-uikit/dist/atoms/Icons/IconInfo';
import { Container, Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const BranchOrderResumeTemplate = (props) => {
  const { goHome, branch_order } = props;
  return (
    <Container paddingY={3} paddingBottom={8}>
      <Flex alignItems="center" flexDirection="column" justifyContet="center" paddingBottom={6}>
        <Card padding="20px">
          <div
            style={{
              backgroundColor: '#A0B454',
              padding: '20px',
              width: '64px',
              height: '64px',
              borderRadius: '50px',
              margin: '0px auto 15px auto',
            }}
          >
            <Icon sizeIcon="display24" marginRight={2} color="white">
              <IconCheck />
            </Icon>
          </div>
          <Text
            as="h3"
            sizeText="display16"
            fontWeight="bold"
            textAlign="center"
            color="black84"
            marginBottom="10px"
          >
            <FormattedMessage id="flowerEcommerce.thanksPurchase" />
          </Text>
          <Text textAlign="center">
            <Text
              as="span"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              <>
                <FormattedMessage id="flowerEcommerce.yourOrder" />{' '}
              </>
            </Text>
            <Text
              as="span"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              <>{branch_order?.orderable?.name} </>
            </Text>
            <Text
              as="span"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              <>
                <FormattedMessage id="flowerEcommerce.ref" />{' '}
              </>
            </Text>
            <Text
              as="span"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              {branch_order?.uuid}{' '}
            </Text>
            <Text
              as="span"
              fontWeight="bold"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              <>
                <FormattedMessage id="flowerEcommerce.right" />
              </>
            </Text>
            <Text
              as="span"
              sizeText="display14"
              textAlign="center"
              marginTop="10px"
              color="black84"
            >
              <>
                <FormattedMessage id="flowerEcommerce.preparing" />
              </>
            </Text>
          </Text>
        </Card>

        <Card padding="20px" marginTop="20px">
          <div
            style={{
              backgroundColor: '#E6E4E5',
              padding: '10px',
              width: '44px',
              height: '44px',
              borderRadius: '50px',
              margin: '0px auto 15px auto',
            }}
          >
            <Icon sizeIcon="display24" marginRight={2} color="black84">
              <IconInfo />
            </Icon>
          </div>
          <Text as="h3" sizeText="display14" textAlign="center" color="black84" marginBottom="10px">
            <FormattedMessage id="flowerEcommerce.calmMessage" />
          </Text>
        </Card>

        <Button fullWidth action={goHome} marginTop="20px" backgroundColor="#black36">
          <Icon marginRight={2} sizeIcon="inherit" color="black84">
            <IconHome />
          </Icon>
          <FormattedMessage id="flowerEcommerce.backHome" />
        </Button>
      </Flex>
    </Container>
  );
};

BranchOrderResumeTemplate.defaultProps = {};
