import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from '../../../pages/login';

export const routes = (loginPath) => ({
  path: loginPath,
  pages: {
    login: {
      path: `${loginPath}`,
    },
  },
});

export const LoginRoutes = ({ match }) => {
  const applicationRoutes = routes(match.url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.login.path} component={LoginPage} />
      <Redirect to={applicationRoutes.path} />
    </Switch>
  );
};

LoginRoutes.propTypes = {};
