/**
 * This function flattens an object recursively, to obtain an object of objects with only one
 * nesting level.
 *
 * @param {Object} nestedMessages - the Object with nested elements.
 * @param {String} prefix - a prefix to use for the new key, initialized to an empty char.
 *
 * @return {Object}
 */

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (!nestedMessages) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else if (typeof value !== 'undefined') {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};
