import { constants } from '../config';

/* **** showAlert *** */
export const showAlert = (colorType, value, message) => ({
  type: constants.SHOW_ALERT,
  colorType,
  value,
  message,
});

export const hideAlert = (delayInMs = null) => ({
  type: constants.HIDE_ALERT,
  delay: delayInMs,
});
