import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PollPage from '../../../pages/poll/container';

export const routes = (pollPath) => ({
  path: pollPath,
  pages: {
    poll: {
      path: `${pollPath}/:pollCode`,
    },
  },
});

export const PollRoutes = ({ match: { url } }) => {
  const applicationRoutes = routes(url);
  return (
    <Switch>
      <Route path={applicationRoutes.pages.poll.path} component={PollPage} />
      <Redirect to={applicationRoutes.pages.poll.path} />
    </Switch>
  );
};

PollRoutes.propTypes = {};
