import { Button, ButtonTabs } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Field } from '@lideralia/alife-uikit/dist/atoms/Field';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconDraw from '@lideralia/alife-uikit/dist/atoms/Icons/IconDraw';
import { Input } from '@lideralia/alife-uikit/dist/atoms/Input';
import { Container, Flex, Grid, GridItem } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardGone } from '@lideralia/alife-uikit/dist/organisms/Cards/Gone';
import { DrawMessage } from '@lideralia/alife-uikit/dist/organisms/DrawMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const DrawBadgeTemplate = ({
  line,
  isLogged,
  isSavedImage,
  saveDraw,
  handleSubmit,
  itemsTab,
  handleChange,
  customColor,
  customTextColor,
}) => (
  <Container paddingY={3} paddingBottom={8}>
    <Flex alignItems="center" justifyContent="center" marginBottom={2} width="100%">
      <CardGone
        name={line?.name}
        date={line?.dateFrom?.year && `${line?.dateFrom?.year} - ${line?.dateTo?.year}`}
        picture={line?.pictures?.m}
        customColor={customColor}
        customTextColor={customTextColor}
      />
    </Flex>
    <Flex alignItems="center" flexDirection="column" justifyContent="center" paddingBottom={7}>
      <Card padding={3}>
        <Flex marginBottom={4} alignItems="center" justifyContent="center">
          <ButtonTabs items={itemsTab} />
        </Flex>
        <Grid
          gridTemplateAreas="'icon title' 'icon description' 'contentBox contentBox' 'nameInput nameInput' 'action action'"
          gridTemplateColumns="auto 1fr"
          gridAutoRows="auto"
          gridGap={2}
        >
          <GridItem gridArea="icon">
            <Icon color={customColor || 'green'} sizeIcon="display24">
              <IconDraw />
            </Icon>
          </GridItem>
          <GridItem gridArea="title">
            <Text sizeText="display16" fontWeight="bold">
              <FormattedMessage id="badge.drawMessageTitle" />
            </Text>
          </GridItem>
          <GridItem gridArea="description">
            <Text sizeText="display14" color="black84" fontWeight="medium">
              <FormattedMessage
                id="badge.drawMessageDescription"
                values={{
                  name: line?.name,
                }}
              />
            </Text>
          </GridItem>
          <GridItem gridArea="contentBox">
            <Field
              label={<FormattedMessage id="badge.writeMessageTitle" />}
              color="black60"
              fontWeight="bold"
              sizeText="display16"
              marginTop={0}
              zIndex={0}
            >
              <DrawMessage
                actionLabel={<FormattedMessage id="badge.drawCanvasAction" />}
                saveDraw={(image, existImage) => {
                  saveDraw(image, existImage);
                }}
                customColor={customColor}
                customTextColor={customTextColor}
                textTurnYourPhone={<FormattedMessage id="badge.turnYourPhone" />}
              />
            </Field>
          </GridItem>
          {!isLogged && (
            <GridItem gridArea="nameInput">
              <Field
                label={<FormattedMessage id="badge.drawMessageLabel" />}
                color="black60"
                fontWeight="bold"
                sizeText="display16"
                marginTop={0}
              >
                <Input
                  onChange={handleChange}
                  id="name"
                  sizeInput="big"
                  placeholder={intl.formatMessage({ id: 'badge.drawMessageLabel' })}
                />
              </Field>
            </GridItem>
          )}
          <GridItem gridArea="action">
            <Button
              backgroundColor={customColor}
              colorType={customColor || 'green'}
              color={customTextColor}
              fullWidth
              sizeButton="big"
              disabled={!isSavedImage}
              type="submit"
              action={() => handleSubmit()}
            >
              <Icon sizeIcon="display24" marginRight={2}>
                <IconDraw />
              </Icon>
              <FormattedMessage id="badge.drawMessageAction" />
            </Button>
          </GridItem>
        </Grid>
      </Card>
    </Flex>
  </Container>
);

DrawBadgeTemplate.defaultProps = {
  line: [],
};
