import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { Stars } from '@lideralia/alife-uikit/dist/molecules/Stars';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TechnologyStars = ({
  valuePollAlifeTechnologyQ1,
  setValuePollAlifeTechnologyQ1,
  valuePollAlifeTechnologyQ2,
  setValuePollAlifeTechnologyQ2,
  valuePollAlifeTechnologyQ3,
  setValuePollAlifeTechnologyQ3,
}) => (
  <>
    <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={3} marginBottom={1}>
      <Text as="h3" sizeText="display24" fontWeight="bold" textAlign="center">
        <FormattedMessage id="poll.stars.title_technology" />
      </Text>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%" paddingTop={3}>
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.utility" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars
          id="poll-stars-utility"
          value={valuePollAlifeTechnologyQ1}
          setValue={setValuePollAlifeTechnologyQ1}
        />
      </Flex>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%">
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.ease" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars
          id="poll-stars-ease"
          value={valuePollAlifeTechnologyQ2}
          setValue={setValuePollAlifeTechnologyQ2}
        />
      </Flex>
    </Flex>
    <Flex flexDirection="column" paddingX={3} width="100%">
      <Text sizeTypo="display16" fontWeight="bold" textAlign="center" marginBottom={1}>
        <FormattedMessage id="poll.stars.experience" />
      </Text>
      <Flex alignItems="center" justifyContent="center" marginBottom={1}>
        <Stars
          id="poll-stars-experience"
          value={valuePollAlifeTechnologyQ3}
          setValue={setValuePollAlifeTechnologyQ3}
        />
      </Flex>
    </Flex>
  </>
);
