import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actualLanguage } from '../../../common/functions/lang';
import { getLine } from '../../../common/schemes/line';
import { AddBadgeTemplate } from '../template';

const BadgesPage = ({ history }) => {
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { active } = line;
  const { idLine, idName } = useParams();

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const goToNewTextContent = () => {
    history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/write`);
  };

  const goToNewDrawContent = () => {
    history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/draw`);
  };

  useEffect(() => {
    if (!line.done && !line.loading && !user.done && idLine && idName) {
      getLineAPI(idLine, idName);
    }
  }, [line, getLineAPI, idLine, idName]);

  return (
    <AddBadgeTemplate
      name={line?.active?.name}
      textContentAction={goToNewTextContent}
      drawContentAction={goToNewDrawContent}
      customColor={active?.company?.style?.primaryColor}
      customTextColor={active?.company?.style?.textColor}
    />
  );
};

export default BadgesPage;

BadgesPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
