export const constants = {
  GET_INFO_AUTH_CODE: 'GET_INFO_AUTH_CODE',
  GET_INFO_AUTH_CODE_SUCCESS: 'GET_INFO_AUTH_CODE_SUCCESS',
  GET_INFO_AUTH_CODE_FAIL: 'GET_INFO_AUTH_CODE_FAIL',
};

export const services = {
  getInfoAuthCode: (authCode) => ({
    route: `/api/v2/auth/${authCode}/ownerauthcode_info`,
    method: 'get',
  }),
};
