import { constants } from '../config';

// export const postOrder = (data) => ({
//   type: constants.POST_ORDER,
//   payload: data,
// });

// export const postOrderSuccess = (data) => ({
//   type: constants.POST_ORDER_SUCCESS,
//   payload: data,
// });

// export const postOrderFailure = (error) => ({
//   type: constants.POST_ORDER_FAILURE,
//   error,
// });
export const postTreeOrder = (data) => ({
  type: constants.POST_TREE_ORDER,
  data,
});

export const postTreeOrderSuccess = (data) => ({
  type: constants.POST_TREE_ORDER_SUCCESS,
  payload: data,
});

export const postTreeOrderFailure = (error) => ({
  type: constants.POST_TREE_ORDER_FAILURE,
  error,
});
export const putOrder = (data) => ({
  type: constants.PUT_ORDER,
  data,
});

export const putOrderSuccess = (data) => ({
  type: constants.PUT_ORDER_SUCCESS,
  payload: data,
});

export const putOrderFailure = (error) => ({
  type: constants.PUT_ORDER_FAILURE,
  error,
});

export const confirmTreeOrder = (data) => ({
  type: constants.CONFIRM_TREE_ORDER,
  data,
});

export const confirmTreeOrderSuccess = (data) => ({
  type: constants.CONFIRM_TREE_ORDER_SUCCESS,
  payload: data,
});

export const confirmTreeOrderFailure = (error) => ({
  type: constants.CONFIRM_TREE_ORDER_FAILURE,
  error,
});

export const resetOrder = () => ({
  type: constants.RESET_ORDER,
});

export const getCertificateTree = (data) => ({
  type: constants.GET_CERTIFICATE_TREE,
  data,
});

export const getCertificateTreeSuccess = (data) => ({
  type: constants.GET_CERTIFICATE_TREE_SUCCESS,
  payload: data,
});

export const getCertificateTreeFailure = (error) => ({
  type: constants.GET_CERTIFICATE_TREE_FAILURE,
  error,
});
