import { ImageLogo } from '@lideralia/alife-uikit/dist/atoms/ImageLogo';
import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import { NotificationAlert } from '@lideralia/alife-uikit/dist/molecules/NotificationAlert';
import { Header } from '@lideralia/alife-uikit/dist/organisms/Header';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LoadingPage from '../../../pages/loading/container';
import { LoginPage } from '../../../pages/login';
import NotAllowedToBuyPage from '../../../pages/not_allowed_to_buy/container';
import ProductDetailPage from '../../../pages/product_detail/container';
import RegisterPage from '../../../pages/register/container';
import {
  acceptCookieConsent,
  getCookieFromStorage,
  getLocalStorage,
} from '../../functions/cookies';
import { actualLanguage } from '../../functions/lang';
import { showCookies } from '../../schemes/cookies';
import { getLiveChatParams } from '../../schemes/live_chat_params';
import { showLogin, showRegister } from '../../schemes/login';
import { showNotAllowedToBuy } from '../../schemes/not_allowed_to_buy';
import { showProductDetail } from '../../schemes/product_detail/actions';
import { hideAlert } from '../../states/alerts';
import { KEYS_ANALYTICS, setEventAnalytics } from '../Analytics';
import { Menu } from '../Menu';

import { hexToHSL } from '../../../../utilities';
import ConfirmationPage from '../../../pages/confirmation/container';
import CookiesPage from '../../../pages/cookies/container';
import ParticipantsPage from '../../../pages/participants/container';
import { useHeaderStyles } from '../../contexts';
import { OpenShareMenuContext } from '../../contexts/OpenShareContext';

const MainStyled = styled('main')(
  css({
    // backgroundColor: 'black06',
    height: '100%',
    paddingTop: 8,
  })
);

export const Layout = (props) => {
  const { children, history } = props;
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const lines = useSelector((state) => state.lines);
  const line = useSelector((state) => state.line);
  const alert = useSelector((state) => state.alert);
  const liveChatParams = useSelector((state) => state.live_chat_params);
  const isShowLogin = useSelector((state) => state.showLogin);
  const isShowProductDetail = useSelector((state) => state.showProductDetail);
  const isShowLoading = useSelector((state) => state.showLoading);
  const isShowNotAllowedToBuy = useSelector((state) => state.showNotAllowedToBuy);
  const isShowCookies = useSelector((state) => state.showCookies);
  const isShowParticipants = useSelector((state) => state.showParticipants);
  const isShowConfirmation = useSelector((state) => state.showConfirmation);
  const user = useSelector((state) => state.user);

  const { headerStyles, updateHeaderStyles } = useHeaderStyles();

  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);

  const setShowCookies = useCallback((value) => dispatch(showCookies(value)), [dispatch]);
  const setUnShowCookies = useCallback((value) => dispatch(showCookies(value)), [dispatch]);
  const getLiveChatParamsAPI = useCallback(
    (idLine) => dispatch(getLiveChatParams(idLine)),
    [dispatch]
  );
  const setShowLoginModal = useCallback(() => dispatch(showLogin(1)), [dispatch]);
  const setUnShowLoginModal = useCallback(() => dispatch(showLogin(-1)), [dispatch]);
  const setUnShowNotAllowedToBuyModal = useCallback(
    () => dispatch(showNotAllowedToBuy(false)),
    [dispatch]
  );
  const setShowRegisterModal = useCallback(() => dispatch(showRegister(1)), [dispatch]);
  const setUnShowRegisterModal = useCallback(() => dispatch(showRegister(-1)), [dispatch]);
  const setUnShowProductDetailModal = useCallback(
    () =>
      dispatch(showProductDetail(false, isShowProductDetail.product, isShowProductDetail.selected)),
    [dispatch, isShowProductDetail]
  );

  const { openShareMenu, changeOpenShareMenu, closeOpenShareMenu } =
    useContext(OpenShareMenuContext);

  const isOut =
    pathname.includes('login') ||
    pathname.includes('register') ||
    pathname.includes('rgpd') ||
    pathname.includes('maintenance');

  const isRouteNotStylesCompanies = pathname.includes('search');

  const setNotificationIsOpen = useCallback(
    (shouldBeOpened) => {
      if (!shouldBeOpened) {
        dispatch(hideAlert());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (getCookieFromStorage('user_cookie_consent') === '' && !isOut) {
      setShowCookies(true);
    } else if (getCookieFromStorage('user_cookie_consent') === '' && isOut) {
      setShowCookies(false);
    } else {
      acceptCookieConsent(getCookieFromStorage('user_cookie_consent'));
    }
  }, [isOut]);

  useEffect(() => {
    if (
      lines.all.length > 0 &&
      idLineStored !== '' &&
      // eslint-disable-next-line valid-typeof
      typeof idLineStored !== undefined &&
      idLineStored !== null
    ) {
      const ll = lines.all.filter((line) => line.lineId === idLineStored.toString())[0];
      if (ll != null) getLiveChatParamsAPI(ll.lineId);
      else getLiveChatParamsAPI(idLineStored);
    }
  }, [lines, idLineStored]);

  useEffect(() => {
    if (user.data !== null && user.data !== undefined) {
      setUnShowLoginModal();
      setUnShowRegisterModal();
    }
  }, [user]);

  useEffect(() => {
    if (line?.active?.company?.style && !isRouteNotStylesCompanies) {
      updateHeaderStyles({
        customColor: line?.active?.company?.style?.navbarColor,
        customButtonColor: line?.active?.company?.style?.navbarBgButtonColor,
        customTextColor: line?.active?.company?.style?.navbarTextButtonColor,
        logo: line?.active?.company?.logo?.src,
      });
    }
  }, [line]);

  const notLoggedIn = () => {
    setShowRegisterModal();
  };

  return (
    <>
      {!isOut && (
        <Header
          eventAction={(name) =>
            setEventAnalytics(name, user, history?.location?.pathname, line?.active)
          }
          shareAction={() =>
            setEventAnalytics(
              KEYS_ANALYTICS.NAVBAR_SHARE_BUTTON_CLICKED,
              user,
              history?.location?.pathname,
              line?.active
            )
          }
          shareLabel={intl.formatMessage({
            id: 'common.actionShareShortLabel',
          })}
          helpLabel={intl.formatMessage({
            id: 'common.actionHelpLabel',
          })}
          adminLineLabel={intl.formatMessage({
            id: 'header.imAdminLabel',
          })}
          noAdminLineLabel={intl.formatMessage({
            id: 'header.imNotAdminLabel',
          })}
          shareCopy={intl.formatMessage(
            {
              id: 'ceremony.actionShareTextWhatsapp',
            },
            {
              name: line?.active?.name,
              url: `${window.location.origin}/${actualLanguage}/lines/${
                line.active?.lineId
              }/${line.active?.nameUrl?.replaceAll(/[^\w\-\s]/gi, '')}/ceremony`,
            }
          )}
          position="fixed"
          lines={lines?.all}
          customColor={headerStyles.customColor}
          customButtonColor={headerStyles.customButtonColor}
          customTextColor={headerStyles.customTextColor}
          onGoTo={(id, name) => history.push(`/${actualLanguage}/lines/${id}/${name}`)}
          goToHome={() => history.push(`/${actualLanguage}`)}
          logo={headerStyles.logo && <ImageLogo src={headerStyles.logo} />}
          allowShowLiveChat={liveChatParams.done}
          paramsLiveChat={
            liveChatParams.done
              ? Object.values(liveChatParams.info)
              : [{ name: 'error', value: 'no custom params' }]
          }
          notLoggedIn={notLoggedIn}
          actionShare={() => {
            changeOpenShareMenu();
          }}
        />
      )}

      <MainStyled
        style={{
          backgroundColor: hexToHSL(line?.active?.company?.style?.secondaryColor || '#8A8689'),
          minHeight: '100vh',
        }}
      >
        {children}
        {!isOut && (
          <Menu
            history={history}
            pathname={pathname}
            openShareMenu={openShareMenu}
            setOpenShareMenu={closeOpenShareMenu}
          />
        )}
        {loading?.show ? <Loader customColor={line?.active?.company?.style?.primaryColor} /> : null}

        <NotificationAlert
          isOpenedAlert={alert?.show}
          colorType={alert?.colorType}
          message={alert?.message}
          setIsOpened={setNotificationIsOpen}
        />
        {isShowLogin.show && !user.done ? (
          <LoginPage hideModalLogin={setUnShowLoginModal} showRegister={setShowRegisterModal} />
        ) : null}
        {isShowLogin.showRegister && !user.done ? (
          <RegisterPage hideModalLogin={setUnShowRegisterModal} showLogin={setShowLoginModal} />
        ) : null}
        {isShowProductDetail.show ? (
          <ProductDetailPage hideModalProductDetail={setUnShowProductDetailModal} />
        ) : null}
        {isShowLoading.show ? <LoadingPage /> : null}

        {isShowNotAllowedToBuy.show ? (
          <NotAllowedToBuyPage hideModalNotAllowedToBuy={setUnShowNotAllowedToBuyModal} />
        ) : null}

        {isShowCookies.show ? <CookiesPage hideModalCookie={setUnShowCookies} /> : null}
        {isShowParticipants.show ? <ParticipantsPage isOpen /> : null}
        {isShowConfirmation.show ? <ConfirmationPage isOpen /> : null}
      </MainStyled>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};
