import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import IconArrowLeft from '@lideralia/alife-uikit/dist/atoms/Icons/IconArrowLeft';
import IconNotification from '@lideralia/alife-uikit/dist/atoms/Icons/IconNotification';
import { Flex } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import { CardNotification } from '@lideralia/alife-uikit/dist/organisms/Cards/Notification';
import css from '@styled-system/css';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { setTypeNotification } from '../config';

const BadgeStyled = styled(Text)(
  css({
    backgroundColor: 'red',
    paddingY: 1,
    paddingX: 2,
    borderRadius: 7,
    color: 'white',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const RequestsTemplate = ({
  info,
  requests,
  history: { goBack },
  handleAcceptRequest,
  handleRejectRequest,
  customColor,
}) => {
  const [disabledButtons, setDisabledButtons] = useState({});

  const handleButtonClick = (id, val) => {
    setDisabledButtons((prev) => ({ ...prev, [id]: val }));
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Text
          fontWeight="bold"
          sizeText="display24"
          color="black84"
          display="flex"
          style={{ alignItems: 'center' }}
        >
          <FormattedMessage id="profile.requests.title" />
          {(info.unReadRequests && (
            <BadgeStyled fontWeight="bold" sizeText="display12" marginLeft={2}>
              {info.unReadRequests}
            </BadgeStyled>
          )) ||
            ''}
        </Text>
        <Button
          paddingY={1}
          paddingX={1}
          colorType="black"
          sizeButton="big"
          rounded
          justifyContent="flex-start"
          action={() => goBack()}
        >
          <Icon sizeIcon="display24" color="black84">
            <IconArrowLeft />
          </Icon>
        </Button>
      </Flex>
      {!requests.length ? (
        <Card paddingY={3} padding={4} marginBottom={3}>
          <Flex alignItems="center" justifyContent="center" flexDirection="column">
            <Button
              colorType="black"
              sizeButton="big"
              rounded
              boxShadow="none"
              style={{ pointerEvents: 'none' }}
              justifyContent="flex-start"
              action={() => {}}
            >
              <Icon sizeIcon="display24" color="black84">
                <IconNotification />
              </Icon>
            </Button>
            <Text
              fontWeight="bold"
              sizeText="display16"
              color="black84"
              textAlign="center"
              marginTop={2}
              marginBottom={1}
            >
              <FormattedMessage id="profile.requests.empty" />
            </Text>
            <Text sizeText="display13" color="black60" textAlign="center">
              <FormattedMessage id="profile.requests.emptyDescription" />
            </Text>
          </Flex>
        </Card>
      ) : (
        <>
          {requests.map((element) => (
            <CardNotification
              {...element}
              key={element.requestId}
              id={element.requestId}
              picture={element?.author?.pictures?.m}
              authorName={element.author?.name}
              customColor={customColor}
              style={{ cursor: 'initial !important' }}
              message={
                <FormattedMessage
                  id={`notification.${setTypeNotification[element.requestType]}`}
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                    name: (
                      <Text color={customColor || 'orange'} as="span" fontWeight="bold">
                        {element.author?.name}
                      </Text>
                    ),
                    line: (
                      <Text color="black" as="span" fontWeight="bold">
                        {element.line?.name}
                      </Text>
                    ),
                    contentType: (
                      <Text color="black" as="span" fontWeight="bold">
                        {element.content?.contentType}
                      </Text>
                    ),
                    companyName: (
                      <Text color={customColor || 'orange'} as="span" fontWeight="bold">
                        {element.line?.branchName}
                      </Text>
                    ),
                  }}
                />
              }
              date={element.timeAgo}
              isRequest
              contentFooter={
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Text color="black" fontWeight="bold" sizeText="display14">
                    <FormattedMessage id="notification.acceptRequestLabel" />
                  </Text>
                  <Flex marginLeft={2}>
                    <Button
                      colorType="green"
                      sizeButton="small"
                      marginRight={1}
                      action={() => {
                        handleButtonClick(element.requestId);
                        handleAcceptRequest(element.requestId);
                      }}
                      disabled={disabledButtons[element.requestId] || false}
                    >
                      <FormattedMessage id="common.yes" />
                    </Button>
                    <Button
                      colorType="red"
                      sizeButton="small"
                      action={() => {
                        handleButtonClick(element.requestId, true);
                        handleRejectRequest(element.requestId);
                      }}
                      disabled={disabledButtons[element.requestId] || false}
                    >
                      <FormattedMessage id="common.no" />
                    </Button>
                  </Flex>
                </Flex>
              }
              notificationType={element.requestType}
            />
          ))}
        </>
      )}
    </>
  );
};
