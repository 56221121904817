import { constants } from '../config';

/* **** setMobilePasstrough *** */
export const setMobilePasstrough = (data) => ({
  type: constants.SET_MOBILE_PASSTROUGH,
  ...data,
});

export const setMobilePasstroughSuccess = (data) => ({
  type: constants.SET_MOBILE_PASSTROUGH_SUCCESS,
  payload: data,
});

export const setMobilePasstroughFail = (error) => ({
  type: constants.SET_MOBILE_PASSTROUGH_FAIL,
  error,
});

export const setVerifyLogin = (data) => ({
  type: constants.SET_VERIFY_LOGIN,
  data,
});

export const setVerifyLoginSuccess = (data) => ({
  type: constants.SET_VERIFY_LOGIN_SUCCESS,
  payload: data,
});

export const setVerifyLoginFail = (error) => ({
  type: constants.SET_VERIFY_LOGIN_FAIL,
  error,
});

export const resetPasstrough = () => ({
  type: constants.RESET_PASSTROUGH,
});

export const resetErrorLogin = () => ({
  type: constants.RESET_ERROR,
});
