import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  data: {},
};

export default function setOrderReducer(state = initialState, action) {
  switch (action.type) {
    // case constants.POST_ORDER:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case constants.POST_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     data: {
    //       ...action.payload,
    //     },
    //   };
    // case constants.POST_ORDER_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    case constants.POST_TREE_ORDER:
      return {
        ...state,
        loading: true,
        done: false,
        error: null,
      };
    case constants.POST_TREE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...action.payload,
        },
      };
    case constants.POST_TREE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case constants.PUT_ORDER:
      return {
        ...state,
        loading: true,
        done: false,
        error: null,
      };
    case constants.PUT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...action.payload,
        },
      };
    case constants.PUT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case constants.CONFIRM_TREE_ORDER:
      return {
        ...state,
        loading: true,
        done: false,
        error: null,
      };
    case constants.CONFIRM_TREE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        data: {
          ...action.payload,
        },
      };
    case constants.CONFIRM_TREE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case constants.RESET_ORDER:
      return initialState;
    default:
      return state;
  }
}
