import { constants } from '../config';

const initialState = {
  show: false,
  button: false,
  location: '',
  id: 0,
};

export default function setShowConfirmationReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_CONFIRMATION: {
      return {
        ...state,
        show: action.show,
      };
    }
    case constants.BUTTON_CONFIRMATION: {
      return {
        ...state,
        button: action.action,
      };
    }
    case constants.LOCATION_CONFIRMATION: {
      return {
        ...state,
        location: action.action,
      };
    }
    case constants.ID_CONFIRMATION: {
      return {
        ...state,
        id: action.action,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
