import { createContext, useState } from 'react';

export const OpenShareMenuContext = createContext(false);

export const OpenShareMenuProvider = ({ children }) => {
  const [openShareMenu, setOpenShareMenu] = useState(false);

  const changeOpenShareMenu = () => {
    setOpenShareMenu((value) => !value);
  };

  const closeOpenShareMenu = () => {
    setOpenShareMenu(false);
  };

  return (
    <OpenShareMenuContext.Provider
      value={{
        openShareMenu,
        changeOpenShareMenu,
        closeOpenShareMenu,
      }}
    >
      {children}
    </OpenShareMenuContext.Provider>
  );
};
