import { getLocalStorage, setLocalStorage } from '../cookies';

const completeLang = {
  es: 'es-ES',
  'es-MX': 'es-MX',
  fr: 'fr-FR',
  ca: 'ca-ES',
  de: 'de-DE',
  en: 'en-EN',
  eu: 'eu-ES',
  it: 'it-IT',
  pl: 'pl-PL',
  pt: 'pt-PT',
  nl: 'nl-NL',
  uk: 'uk-UA',
};

const localeToIntl = {
  'es-ES': 'es',
  'es-MX': 'es-MX',
  'fr-FR': 'fr',
  'ca-ES': 'ca',
  'de-DE': 'de',
  'en-EN': 'en',
  'eu-ES': 'eu',
  'it-IT': 'it',
  'pl-PL': 'pl',
  'pt-PT': 'pt',
  'nl-NL': 'nl',
  'uk-UA': 'uk',
};

const langs = ['es', 'es-MX', 'fr', 'ca', 'de', 'en', 'eu', 'it', 'pl', 'pt', 'nl', 'uk'];

export const getSplitLanguage = (lng) => {
  if (lng) {
    return localeToIntl[lng] !== undefined
      ? localeToIntl[lng]
      : lng.split('-').join('_').toLowerCase();
  }
  return null;
};

export const setUserLanguage = (lang) => {
  setLocalStorage('alife-lang', lang);
};

export const getUserLanguage = () => getLocalStorage('alife-lang');

export const getActualLanguage = () => {
  const actualLanguage =
    getUserLanguage() ||
    getSplitLanguage(navigator.language) ||
    getSplitLanguage(navigator.userLanguage);
  if (langs.includes(actualLanguage)) {
    return actualLanguage;
  }
  return 'en';
};

export const actualLanguage = getActualLanguage();

export const completeLanguage = completeLang[getActualLanguage()];
export const completeLangFn = () => completeLang[getActualLanguage()];
