import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconDraw from '@lideralia/alife-uikit/dist/atoms/Icons/IconDraw';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import { Container, Flex, Grid, GridItem } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AddBadgeTemplate = ({
  textContentAction,
  drawContentAction,
  name,
  customColor,
  customTextColor,
}) => (
  <Container paddingY={3} paddingBottom={8}>
    <Flex alignItems="center" flexDirection="column" justifyContet="center">
      <Card padding={3} maxWidth="339px">
        <Grid
          gridTemplateAreas="'icon title' 'icon description' 'action action'"
          gridTemplateColumns="auto 1fr"
          gridAutoRows="auto"
          gridGap={2}
        >
          <GridItem gridArea="icon">
            <Icon color={customColor || 'orange'} sizeIcon="display24">
              <IconWrite />
            </Icon>
          </GridItem>
          <GridItem gridArea="title">
            <Text sizeText="display16" fontWeight="bold">
              <FormattedMessage id="badge.writeMessageTitle" />
            </Text>
          </GridItem>
          <GridItem gridArea="description">
            <Text sizeText="display14" color="black84" fontWeight="medium">
              <FormattedMessage
                id="badge.writeMessageDescription"
                values={{
                  name,
                }}
              />
            </Text>
          </GridItem>
          <GridItem gridArea="action">
            <Button
              backgroundColor={customColor}
              color={customTextColor}
              colorType={customColor || 'orange'}
              fullWidth
              sizeButton="big"
              action={textContentAction}
            >
              <Icon sizeIcon="display24" marginRight={2}>
                <IconWrite />
              </Icon>
              <FormattedMessage id="badge.writeMessageAction" />
            </Button>
          </GridItem>
        </Grid>
      </Card>
      <Card padding={3} marginTop={2} maxWidth="339px">
        <Grid
          gridTemplateAreas="'icon title' 'icon description' 'action action'"
          gridTemplateColumns="auto 1fr"
          gridAutoRows="auto"
          gridGap={2}
        >
          <GridItem gridArea="icon">
            <Icon color={customColor || 'green'} sizeIcon="display24">
              <IconDraw />
            </Icon>
          </GridItem>
          <GridItem gridArea="title">
            <Text sizeText="display16" fontWeight="bold">
              <FormattedMessage id="badge.drawMessageTitle" />
            </Text>
          </GridItem>
          <GridItem gridArea="description">
            <Text sizeText="display14" color="black84" fontWeight="medium">
              <FormattedMessage id="badge.drawMessageDescription" />
            </Text>
          </GridItem>
          <GridItem gridArea="action">
            <Button
              backgroundColor={customColor}
              color={customTextColor}
              colorType={customColor || 'green'}
              fullWidth
              sizeButton="big"
              action={drawContentAction}
            >
              <Icon sizeIcon="display24" marginRight={2}>
                <IconDraw />
              </Icon>
              <FormattedMessage id="badge.drawMessageAction" />
            </Button>
          </GridItem>
        </Grid>
      </Card>
    </Flex>
  </Container>
);

AddBadgeTemplate.defaultProps = {
  line: [],
};
