import { constants } from '../config';

/* **** getNotifications *** */
export const getNotifications = (userId) => ({
  type: constants.GET_NOTIFICATIONS,
  userId,
});

export const getNotificationsSuccess = (data) => ({
  type: constants.GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getNotificationsFail = (error) => ({
  type: constants.GET_NOTIFICATIONS_FAIL,
  error,
});

/* **** getRequests *** */
export const getRequests = (data) => ({
  type: constants.GET_REQUESTS,
  ...data,
});

export const getRequestsSuccess = (data) => ({
  type: constants.GET_REQUESTS_SUCCESS,
  payload: data,
});

export const getRequestsFail = (error) => ({
  type: constants.GET_REQUESTS_FAIL,
  error,
});

/* **** setNotificationStatus *** */
export const setNotificationStatus = (data) => ({
  type: constants.SET_NOTIFICATION_STATUS,
  ...data,
});

export const setNotificationStatusSuccess = (data) => ({
  type: constants.SET_NOTIFICATION_STATUS_SUCCESS,
  payload: data,
});

export const setNotificationStatusFail = (error) => ({
  type: constants.SET_NOTIFICATION_STATUS_FAIL,
  error,
});

/* **** setRequestAccepted *** */
export const setRequestAccepted = (data) => ({
  type: constants.SET_REQUEST_ACCEPTED,
  ...data,
});

export const setRequestAcceptedSuccess = (data) => ({
  type: constants.SET_REQUEST_ACCEPTED_SUCCESS,
  payload: data,
});

export const setRequestAcceptedFail = (error) => ({
  type: constants.SET_REQUEST_ACCEPTED_FAIL,
  error,
});

/* **** setRequestRejected *** */
export const setRequestRejected = (data) => ({
  type: constants.SET_REQUEST_REJECT,
  ...data,
});

export const setRequestRejectedSuccess = (data) => ({
  type: constants.SET_REQUEST_REJECT_SUCCESS,
  payload: data,
});

export const setRequestRejectedFail = (error) => ({
  type: constants.SET_REQUEST_REJECT_FAIL,
  error,
});

/* **** Sockets of notifications *** */
export const getNotificationFromSockets = (data) => ({
  type: constants.GET_NOTIFICATION_FROM_SOCKETS,
  data,
});

export const AddNotificationFromSocketsSuccess = (data) => ({
  type: constants.ADD_NOTIFICATION_FROM_SOCKETS_SUCCESS,
  payload: data,
});

/* **** Sockets of requests *** */
export const getRequestFromSockets = (data) => ({
  type: constants.GET_REQUEST_FROM_SOCKETS,
  data,
});

export const AddRequestFromSocketsSuccess = (data) => ({
  type: constants.ADD_REQUEST_FROM_SOCKETS_SUCCESS,
  payload: data,
});
