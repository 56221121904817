const listOfErrors = {
  register_auto_invalid_auth_code: 'error.register_auto_invalid_auth_code',
  PHP_ERROR: 'error.generic_error',
  generic_error: 'error.generic_error',
  request_cancelled: 'error.request_cancelled',
  no_connection: 'error.no_connection',
  camera_not_found: 'error.camera_not_found',
  a7c74a8777d4bd65d54105f1377fdc57: 'error.missing_data',
  '8341bdea48c78ceeee463fb75e6763d6': 'error.line_does_not_exist',
  b1422d6ffa54ef8867e5dea41935029b: 'error.only_approved_followers_can_post_in_this_line',
  '407a4ffba2f9784ba112b46c4c5d9203': 'error.only_approved_followers_see_this_line',
  aa3191ffdf4b320894a9e5192b1a6c39: 'error.invalid_line_name',
  b083632486fdee0dbb3e958f6fb546e1: 'error.invalid_date_from_format',
  '16303f76ebd1a27ab5aef04e2419eb03': 'error.invalid_date_to_format',
  b7adca78c686cde0c4260f47bb5b952e: 'error.undefined_error_line_not_created',
  '74e0a39ae87c935beabd7e1399f676ac': 'error.you_are_not_admin_of_this_line_not_allowed',
  e550b0c2406898adb49a32e55a1b610e: 'error.invalid_date_format',
  f4e5e51c1ef786c3b4dc152e491cdbe6: 'error.invalid_name_format',
  f6fa1e18380fe5069cd9e5407c8285e0: 'error.you_are_not_superadmin_of_this_line_not_allowed',
  e94e1abffa2f070211e8d1fcb58bd7c7: 'error.only_approved_followers_can_see_admins_of_the_line',
  '30d5a964cdb2ed0e08dc0d4a2c059919': 'error.user_is_already_an_admin',
  e62cf9ee7d89a500ee9312c5d030ad35: 'error.only_current_followers_of_the_line_can_become_admins',
  d92934739f170fe6031abf8fe719691b: 'error.only_admins_can_add_other_admins',
  '85e40a5400eaefb3a4d7dab0594f818d': 'error.only_superadmins_can_delete_admins',
  bcfbcd93453faf0885c8b5097292e76d: 'error.user_blocked_could_not_follow',
  '71f77e231dd60a36d71afe1270269d3c': 'error.superadin_cannot_unfollow_their_lines',
  '7b8aff6923a81e40f3d48917f47dcce7':
    'error.only_approved_followers_can_see_other_followers_of_the_line',
  '6e9830a9b2106a66f3e23a52b147f726': 'error.user_not_associated_to_this_line',
  '6aedc2a00482c34f95df9c356775cf66':
    'error.only_administrators_of_a_higher_rank_can_ban_other_administrators',
  '27f2963585140d990e87f5a6f9f4609a': 'error.only_administrators_can_ban_users',
  e9da96ec20480ccbd771d15eca5fb3bd: 'error.content_does_not_exist',
  '9dc4931fc2c13119da4709e8e3a4a1f4': 'error.you_are_not_allowed_to_see_this_content',
  '4577baf7224215f05aff19f490dbc024': 'error.content_type_not_allowed',
  '2529aa235ac79f9303a0ec3a46c171c4': 'error.invalid_date_format',
  '8a95a2e998798f6dac64ae86a65b77af': 'error.missing_content',
  e7fdab29e028ca42c1437ad5c0f5e93c: 'error.invalid_content',
  '3c4624b7858146f4e0a05ffda9ded034': 'error.content_does_not_exist',
  '88086b1610b6fbc7d0196071ddd3485d': 'error.only_approved_followers_access_to_this_line',
  '5bdcb05cc375b31e48bf302bab16df87': 'error.content_does_not_exist',
  '10bd5aa4986a22d8b68077360b8df8e4': 'error.content_under_moderation',
  d8f5f0ae11a77e8a8d21e9c897eb81e1: 'error.missing_query_string',
  b8da58e39edc3327b1c862601de026bc: 'error.query_too_short',
  b284d5a3c4b6cdb5ed9c8a94073f9946: 'error.only_admins_can_look_for_future_admins',
  e9f4a6d52d3a6cc7a55d07171123b018: 'error.only_approved_followers_look_for_future_followers',
  '7292c7777da059ce524368730546e9c5': 'error.could_not_upload_undefined',
  '7ef5d2d2bfaea4cb5300a4584cd18ded': 'error.invalid_image',
  '0e581309d2c33d533b0120e58cd3d7d3': 'error.could_not_upload_image',
  '4bb0df1d86ae610616038528929a2449': 'error.invalid_email',
  '738fe0c5a7a90d75dd3a8ce46777b7e2': 'error.invalid_password',
  '3bdea07117414fba98a392128388aa22': 'error.email_already_exists',
  '76d657b6c73d56c831fb5c964417488a': 'error.undefined_register_error',
  '9184a91d457373ad12796cf07574da8d': 'error.wrong_email_or_username_or_password',
  '8a4cfa50f12c33e031289886e68d4297': 'error.missing_user_id',
  '23db1f1fa5b3690a244a01a7495eed26': 'error.user_doesnt_exist',
  c4f135c2c65e54fd94b57055e5f4edcf: 'error.username_exists',
  b06a4e86a3e5ccb8deb38f7b54849648: 'error.method_not_implemented_yet',
  '4d4d8bbf40d6ffbb74c8675bb4e1a661': 'error.user_already_following_line',
  f7c7c966cd1f6cab1c88801c0294da3e: 'error.content_already_moderated',
  '54dbd246bf929561d7f7aeeda8685a7c': 'error.badge_doesnt_exist',
  e82fce9b0ad43938dcc78e6b80cdf87a: 'error.badge_not_deleted',
  '7b08647ccb5118055527b4c9d25b0174': 'error.could_not_crop_image',
  '8341bdear8c78ceeee463fb75e6763d6': 'error.invitation_does_not_exist',
  '9dc4931fc2c131eeea4709e8e3a4a1f4': 'error.only_owner_of_content_can_tag_users',
  '9dcdd31fc2c131eeea4709e8e3a4a1f4': 'error.you_can_only_tag_users_that_you_follow',
  c4f135c2c65eaaad94b5ee55e5f4edcf: 'error.you_must_provide_a_user_name',
  '9dc4931fc2c131eeeaf709e8e3a4a1f4':
    'error.only_owner_of_content_and_tagged_users_can_see_other_tagged_users',
  '9dc4931fc2c131eeea4709e8e3a4a1ee': 'error.tagged_users_array_wrong_format',
  '9dc4931fc2c131eeea4709e8e3a4aeee': 'error.only_owner_or_tagger_or_tagged_can_delete_tags',
  db31c14bbe32c9a86838d7d4d00cad97: 'error.max_inheritors_reached',
  '0c4c1b1cad6ec26c4ee5003c3dc898d8': 'error.phone_used_by_somebody_else',
  c4f135c2c65eaaad94b57055e5feedcf: 'error.wrong_confirm_code',
  error_user_phone_doesnt_exist: 'error.phone_doesnt_exist',
  error_user_phone_already_exist: 'error.phone_used_by_somebody_else',
  error_lines_already_invited_phone: 'error.error_lines_already_invited_phone',
  error_invalid_phone_number: 'error.error_invalid_phone_number',
  error_action_not_allowed: 'error.error_action_not_allowed',
  error_user_is_blocked: 'error.error_user_is_blocked',
  error_disabled_messages: 'error.error_disabled_messages',
  error_thread_message_not_found: 'error_thread_message_not_found',
  error_too_many_attempts: 'error.error_too_many_attempts',
  error_too_many_requests: 'error.error_too_many_requests',
  error_invalid_video_content_type: 'error.invalid_video_content_type',
  alife_poll_already_exists: 'alife_poll_already_exists',
};

export const MapperError = {
  hydrate: (error) => listOfErrors[error],
};
