import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export const CountriesMapper = {
  hydrate: ({ id, iso, name, nicename, numcode, iso3, phonecode }) => {
    const Flag = startCase(toLower(iso));
    return {
      id: typeof id === 'number' ? id : '',
      iso: typeof iso === 'string' ? iso : '',
      name: typeof name === 'string' ? name : '',
      nicename: typeof nicename === 'string' ? nicename : '',
      iso3: typeof iso3 === 'string' ? iso3 : '',
      numcode: typeof numcode === 'number' ? numcode : '',
      phonecode: typeof phonecode === 'number' ? phonecode : '',
      flag: Flag,
    };
  },
};
