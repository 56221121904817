/* eslint-disable camelcase */

export const constants = {
  POST_BRANCH_ORDER: 'POST_BRANCH_ORDER',
  POST_BRANCH_ORDER_SUCCESS: 'POST_BRANCH_ORDER_SUCCESS',
  POST_BRANCH_ORDER_FAIL: 'POST_BRANCH_ORDER_FAIL',
  PUT_BRANCH_ORDER: 'PUT_BRANCH_ORDER',
  PUT_BRANCH_ORDER_SUCCESS: 'PUT_BRANCH_ORDER_SUCCESS',
  PUT_BRANCH_ORDER_FAIL: 'PUT_BRANCH_ORDER_FAIL',
  RESET_BRANCH_ORDER: 'RESET_BRANCH_ORDER',
};

export const services = {
  postBranchOrder: (data) => ({
    route: `/api/v2/branch-orders`,
    method: 'post',
    body: data,
  }),

  putBranchOrder: (data) => ({
    route: `/api/v2/branch-orders/${data.id}`,
    method: 'put',
    body: data,
  }),
};
