import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from '../../../common/functions/cookies';
import { getLine, getTrees } from '../../../common/schemes/line';

export const useLineData = () => {
  const { idLine, idName } = useParams();
  const dispatch = useDispatch();

  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);

  const [lineDefault, setLineDefault] = useState(idLine || idLineStored);
  const [nameDefault, setNameDefault] = useState(idName || idNameStored);

  const line = useSelector((state) => state.line);

  const getTreesAPI = useCallback((lineId) => dispatch(getTrees(lineId)), [dispatch]);
  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);

  const {
    active,
    active: { forest },
  } = line;

  useEffect(() => {
    if (idLine !== lineDefault) {
      setLineDefault(idLine);
    }
    if (idName !== nameDefault) {
      setNameDefault(idName);
    }
  }, [idLine, idName]);

  useEffect(() => {
    if (!line?.done && !line?.loading && idLine && idName) {
      getLineAPI(idLine, idName);
    }
  }, [line]);

  useEffect(() => {
    if (idLine) {
      getTreesAPI(idLine);
    }
  }, [idLine]);

  return {
    lineDefault,
    nameDefault,
    active,
    forest,
    idLineStored,
    idNameStored,
  };
};
