import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBadgeFromSockets,
  getContentFromSockets,
  getStreamsFromSockets,
  getUdianasFromSockets,
} from '../cli/common/schemes/line';
import {
  getNotificationFromSockets,
  getRequestFromSockets,
} from '../cli/common/schemes/notifications';
import { requestApi2 } from '../lib/request';

window.Pusher = Pusher;

// const token = getAuthorizationConfig();
const echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  // transports: ['websocket'],
  // enabledTransports: ['ws', 'wss'],
  forceTLS: false,
  disableStats: true,
  authEndpoint: `${process.env.REACT_APP_V2_URL_BASE}/broadcasting/auth`,
  authorizer: (channel) => ({
    authorize: (socketId, callback) => {
      requestApi2({
        route: `/api/v2/broadcasting/auth`,
        method: 'post',
        body: {
          socket_id: socketId,
          channel_name: channel.name,
        },
      })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  }),
});

export const Sockets = () => {
  const user = useSelector((state) => state.user);
  const line = useSelector((state) => state.line);

  const { active } = line;

  if (active?.company?.companyId) {
    const channel = echo.private(`pages-viewed.${active?.company?.companyId}`);

    const pages_viewed = localStorage.getItem('pages-viewed');
    const activeLineId = active?.lineId;
    if (pages_viewed) {
      // Si tiene el localstorage
      const pagesViewed = JSON.parse(pages_viewed);
      if (pagesViewed.indexOf(activeLineId) === -1) {
        // Si no esta en el localstorage la id de la empresa
        pagesViewed.push(activeLineId);
        localStorage.setItem('pages-viewed', JSON.stringify(pagesViewed));
        // Se hace un timeout de 2 segundos para que se envie el evento, sino no le da tiempo a enviarlo y no funciona
        setTimeout(() => {
          channel.whisper('.socket.pagesViewed', {});
        }, 2000);
      }
    } else {
      // Si no tiene el localstorage
      setTimeout(() => {
        channel.whisper('.socket.pagesViewed', {});
      }, 2000);
      const pagesViewed = [activeLineId];
      localStorage.setItem('pages-viewed', JSON.stringify(pagesViewed));
    }
  }

  const dispatch = useDispatch();
  const getBadgeFromSocketsAPI = useCallback(
    (data) => dispatch(getBadgeFromSockets(data)),
    [dispatch]
  );
  const getContentFromSocketsAPI = useCallback(
    (data) => dispatch(getContentFromSockets(data)),
    [dispatch]
  );
  const getNotificationsFromSocketsAPI = useCallback(
    (data) => dispatch(getNotificationFromSockets(data)),
    [dispatch]
  );
  const getRequestsFromSocketsAPI = useCallback(
    (data) => dispatch(getRequestFromSockets(data)),
    [dispatch]
  );
  const getStreamFromSocketsAPI = useCallback(
    (data) => dispatch(getStreamsFromSockets(data)),
    [dispatch]
  );
  const getUdianasFromSocketsAPI = useCallback(
    (data) => dispatch(getUdianasFromSockets(data)),
    [dispatch]
  );

  // TODO: getLinesAPI(user?.data?.user_id);, getEventsAPI(lineId)
  useEffect(() => {
    if (typeof active.lineId !== 'undefined') {
      echo.leave(`badges.${active.lineId}`);
      echo.leave(`contents.${active.lineId}`);
      echo.leave(`udianas.${active.lineId}`);

      echo.channel(`badges.${active.lineId}`).listen('.socket.badge', (event) => {
        const data = { ...event, author_uuid: user?.data?.id };
        getBadgeFromSocketsAPI(data);
      });

      echo.channel(`contents.${active.lineId}`).listen('.socket.content', (event) => {
        getContentFromSocketsAPI(event);
      });

      echo.channel(`udianas.${active.lineId}`).listen('.socket.udiana', (event) => {
        getUdianasFromSocketsAPI(event);
      });
    }

    return () => {
      echo.leave(`badges.${active.lineId}`);
      echo.leave(`contents.${active.lineId}`);
      echo.leave(`udianas.${active.lineId}`);
    };
  }, [active.lineId]);

  useEffect(() => {
    if (typeof active.lineId !== 'undefined') {
      echo.channel(`streams.${active.lineId}`).listen('.socket.stream', (event) => {
        getStreamFromSocketsAPI(event);
      });
      // if (user?.data && user?.data?.id) {
      //   echo.leave(`streams.${active.lineId}`);
      //   echo
      //     .channel(`streams.${active.lineId}${user?.data?.id}`)
      //     .listen('.socket.stream', (event) => {
      //       getStreamFromSocketsAPI(event);
      //     });
      // } else {
      //   echo.channel(`streams.${active.lineId}`).listen('.socket.stream', (event) => {
      //     getStreamFromSocketsAPI(event);
      //   });
      // }
    }
    return () => {
      echo.leave(`streams.${active.lineId}`);
    };
  }, [active.lineId, user.data]);

  useEffect(() => {
    if (user?.data && user?.data?.id) {
      echo.leave(`notifications.${user?.data?.id}`);
      echo.leave(`requests.${user?.data?.id}`);

      echo.private(`notifications.${user?.data?.id}`).listen('.socket.notification', (event) => {
        getNotificationsFromSocketsAPI(event);
      });

      echo.private(`requests.${user?.data?.id}`).listen('.socket.request', (event) => {
        getRequestsFromSocketsAPI(event);
      });
    }

    return () => {
      echo.leave(`notifications.${user?.data?.id}`);
      echo.leave(`requests.${user?.data?.id}`);
    };
  }, [user]);
};
