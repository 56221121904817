import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IconDraw, IconHeadphones, IconWrite } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { Icon } from '@lideralia/alife-uikit';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AudioBadgeTemplate } from '../template/audioContent';
import { actualLanguage } from '../../../common/functions/lang';
import { showRegister } from '../../../common/schemes/login';
import { postBadge, resetBadge } from '../../../common/schemes/line';

const AudioContentBadgesPage = ({ history }) => {
  const login = useSelector((state) => state.login);
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const { active, addBadgesDone } = line;

  const { idLine, idName } = useParams();
  const [data, setData] = useState({
    contentBody: '',
    contentType: 'audio',
  });
  const dispatch = useDispatch();

  const postBadgeAPI = useCallback(() => dispatch(postBadge(idLine, data)));
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  useEffect(() => {
    if (data.contentBody && addBadgesDone) {
      dispatch(resetBadge());
      history.push(`/${actualLanguage}/lines/${idLine}/${idName}`);
    }
  }, [data, addBadgesDone]);

  const handleChange = (id, content) => {
    if (!content) {
      setData({
        ...data,
        contentBody: '',
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(content);
    reader.onload = (event) => {
      const base64String = event.target.result;
      setData({
        ...data,
        contentType: id,
        contentBody: base64String,
      });
    };
  };

  const handleSubmit = () => {
    if (user?.done || login?.done) {
      postBadgeAPI();
    } else {
      setShowRegisterState(1);
    }
  };

  return (
    <AudioBadgeTemplate
      line={line.active}
      isLogged={user?.done || login?.done}
      customColor={active?.company?.style?.primaryColor || 'orange'}
      customTextColor={active?.company?.style?.textColor}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      data={data}
      itemsTab={[
        {
          id: 'message',
          children: (
            <>
              <Icon marginRight={1}>
                <IconWrite />
              </Icon>
              <FormattedMessage id="badge.tabMessage" />
            </>
          ),
          isActive: false,
          action: () => {
            history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/write`);
          },
        },
        {
          id: 'draw',
          children: (
            <>
              <Icon marginRight={1}>
                <IconDraw />
              </Icon>
              <FormattedMessage id="badge.tabDraw" />
            </>
          ),
          style: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'flex',
            overflow: 'hidden',
            minWidth: '58px',
            margin: '0',
          },
          isActive: false,
          action: () => {
            history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/draw`);
          },
        },
        {
          id: 'message_voice',
          children: (
            <>
              <Icon marginRight={1}>
                <IconHeadphones />
              </Icon>{' '}
              <FormattedMessage id="badge.audio" />
            </>
          ),
          isActive: true,
          action: () => {
            history.push(`/${actualLanguage}/lines/${idLine}/${idName}/book/audio`);
          },
        },
      ]}
    />
  );
};

export default AudioContentBadgesPage;

AudioContentBadgesPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
