import { Button, Flex, Icon, Text } from '@lideralia/alife-uikit';
import { Card } from '@lideralia/alife-uikit/dist/atoms/Card';
import { IconTree } from '@lideralia/alife-uikit/dist/atoms/Icons';
import { ChartUdianas, LocationUdianas } from '@lideralia/alife-uikit/dist/molecules/Udianas';
import {
  CardDescription,
  CardDescriptionText,
  CardDescriptionTitle,
} from '@lideralia/alife-uikit/dist/organisms/Cards/Description';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DivSectionStyled, HeaderSectionDescriptionStyled, HeaderSectionStyled } from '../styles';

export const DescriptionTree = ({
  company,
  dataChart,
  buttonBack,
  buttonSelectTree,
  description,
  characteristics,
  urlMap,
  pictureMap,
  tree,
  getCertificate,
  canDownloadCertificate,
  active,
}) => {
  const hasDescription = description && description.length > 0;
  return (
    <>
      <ButtonsBackOrSelectTree
        company={company}
        buttonBack={buttonBack}
        buttonSelectTree={buttonSelectTree}
        treeProduct={tree?.treeProduct}
      />
      {tree?.messageTree?.length > 0 ? (
        <HeaderSectionStyled>
          <CardDescription>
            <CardDescriptionTitle>
              {intl.formatMessage(
                {
                  id: 'messages.from',
                },
                {
                  name: (
                    <Text
                      as="span"
                      color={company?.style?.primaryColor}
                      fontWeight="bold"
                      sizeText="display13"
                      textStyle="uppercase"
                    >
                      {tree.userName?.toUpperCase() || company?.base?.tradename?.toUpperCase()}
                    </Text>
                  ),
                }
              )}
            </CardDescriptionTitle>
            <CardDescriptionText
              marginTop={1}
              dangerouslySetInnerHTML={{
                __html: tree.messageTree.replace('{{name}}', `<strong>${active.name}</strong>`),
              }}
            />
          </CardDescription>
        </HeaderSectionStyled>
      ) : null}
      <HeaderSectionStyled>
        <DescriptionCharacteristicsTextTree characteristics={characteristics} />
      </HeaderSectionStyled>
      {hasDescription ? (
        <HeaderSectionDescriptionStyled>
          <DescriptionTextTree description={description} />
        </HeaderSectionDescriptionStyled>
      ) : null}
      <CardCertificate
        getCertificate={getCertificate}
        company={company}
        canDownloadCertificate={canDownloadCertificate}
      />
      <ChartUdianas {...dataChart} marginY={1} />
      <DivSectionStyled>
        <Text as="p" sizeText="display16">
          <FormattedMessage id="udianas.plantationMainPosition" />
        </Text>
      </DivSectionStyled>
      <LocationUdianas urlMap={urlMap} pictureMap={pictureMap} marginY={1} />
      <ButtonsBackOrSelectTree
        company={company}
        buttonBack={buttonBack}
        buttonSelectTree={buttonSelectTree}
        treeProduct={tree?.treeProduct}
      />
    </>
  );
};

const DescriptionCharacteristicsTextTree = ({ characteristics }) => (
  <>
    <Text
      as="h3"
      sizeText="display24"
      color="black84"
      // color={company?.style?.primaryColor || 'orange'}
      fontWeight="bold"
    >
      <FormattedMessage id="common.characteristics" />
    </Text>
    <Text as="p" sizeText="display14">
      <FormattedMessage
        id="udianas.characteristicsTree.family"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          name: (
            <Text as="span" fontWeight="bold" sizeText="display14">
              {characteristics?.family}
            </Text>
          ),
        }}
      />
    </Text>
    <Text as="p" sizeText="display14">
      <FormattedMessage
        id="udianas.characteristicsTree.foliageType"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          name: (
            <Text as="span" fontWeight="bold" sizeText="display14">
              {characteristics?.foliageType}
            </Text>
          ),
        }}
      />
    </Text>
    <Text as="p" sizeText="display14">
      <FormattedMessage
        id="udianas.characteristicsTree.longevity"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          name: (
            <Text as="span" fontWeight="bold" sizeText="display14">
              {characteristics?.longevity}
            </Text>
          ),
        }}
      />
    </Text>
    <Text as="p" sizeText="display14">
      <FormattedMessage
        id="udianas.characteristicsTree.CO2Compensation"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          name: (
            <Text as="span" fontWeight="bold" sizeText="display14">
              {characteristics?.CO2Compensation}
            </Text>
          ),
        }}
      />
    </Text>
    <Text as="p" sizeText="display14">
      <FormattedMessage
        id="udianas.characteristicsTree.height"
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
          name: (
            <Text as="span" fontWeight="bold" sizeText="display14">
              {characteristics?.height}
            </Text>
          ),
        }}
      />
    </Text>
  </>
);

const DescriptionTextTree = ({ description }) => (
  <>
    <Text
      as="h3"
      sizeText="display24"
      color="black84"
      // color={company?.style?.primaryColor || 'orange'}
      fontWeight="bold"
    >
      <FormattedMessage id="common.description" />
    </Text>
    <Text
      sizeText="display16"
      as="p"
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  </>
);

const CardCertificate = ({ getCertificate, company, canDownloadCertificate }) => (
  <Card
    minWidth="100%"
    maxWidth="100%"
    position="relative"
    width="100%"
    padding={4}
    marginY={1}
    // maxHeight={maxHeight}
  >
    <Flex justifyContent="center" marginBottom={1}>
      <Icon sizeIcon="display36" color="grey" marginRight={2}>
        <IconTree />
      </Icon>
    </Flex>
    <Text as="h3" sizeText="display16" fontWeight="bold" textAlign="center">
      <FormattedMessage id="udianas.treeCertificate" />
    </Text>
    <Text as="p" sizeText="display14" textAlign="center">
      <FormattedMessage id="udianas.digitalTreeGuarantee" />
    </Text>
    {canDownloadCertificate ? (
      <Button
        fullWidth
        action={getCertificate}
        marginTop="20px"
        backgroundColor={company?.style?.primaryColor}
        colorType={company?.style?.primaryColor || 'orange'}
        color={company?.style?.textColor}
      >
        <Icon marginRight={3} sizeIcon="display24">
          <IconTree />
        </Icon>
        <FormattedMessage id="udianas.downloadTheCertificate" />
      </Button>
    ) : null}
  </Card>
);

const ButtonsBackOrSelectTree = ({ company, buttonBack, buttonSelectTree, treeProduct }) => (
  <Flex
    justifyContent="left"
    paddingTop="10px"
    flexDirection="column"
    style={{ flexFlow: 'nowrap', gap: '10px' }}
    width="100%"
    maxWidth="339px"
  >
    <Flex
      justifyContent="center"
      paddingTop={2}
      paddingBottom={2}
      flexDirection="column"
      style={{ flexFlow: 'nowrap' }}
    >
      <Button colorType="black" sizeButton="small" action={buttonBack}>
        <FormattedMessage id="common.return" />
      </Button>
    </Flex>
    {treeProduct?.isPrivate ? null : (
      <Button
        id="SelectTree"
        backgroundColor={company?.style?.primaryColor}
        colorType={company?.style?.primaryColor || 'orange'}
        color={company?.style?.textColor}
        sizeButton="normal"
        action={buttonSelectTree}
        width="100%"
      >
        <Icon marginRight={3} sizeIcon="display24">
          <IconTree />
        </Icon>
        <FormattedMessage id="udianas.selectThisTree" />
      </Button>
    )}
  </Flex>
);

DescriptionTree.propTypes = {
  company: PropTypes.shape({
    style: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
    }),
  }).isRequired,
  dataChart: PropTypes.shape({
    title: PropTypes.string,
    dataSeries: PropTypes.arrayOf(PropTypes.number),
    dataCategories: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
  }).isRequired,
  buttonBack: PropTypes.func.isRequired,
  buttonSelectTree: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  characteristics: PropTypes.shape({
    family: PropTypes.string,
    foliageType: PropTypes.string,
    longevity: PropTypes.string,
    CO2Compensation: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  urlMap: PropTypes.string.isRequired,
  pictureMap: PropTypes.string.isRequired,
  tree: PropTypes.shape({
    messageTree: PropTypes.string,
    userName: PropTypes.string,
  }),
  canDownloadCertificate: PropTypes.bool,
};

DescriptionTree.defaultProps = {
  tree: {},
  canDownloadCertificate: false,
};

DescriptionCharacteristicsTextTree.propTypes = {
  characteristics: PropTypes.shape({
    family: PropTypes.string,
    foliageType: PropTypes.string,
    longevity: PropTypes.string,
    CO2Compensation: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
};

DescriptionTextTree.propTypes = {
  description: PropTypes.string.isRequired,
};

ButtonsBackOrSelectTree.propTypes = {
  company: PropTypes.shape({
    style: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
    }),
  }).isRequired,
  buttonBack: PropTypes.func.isRequired,
  buttonSelectTree: PropTypes.func.isRequired,
};
