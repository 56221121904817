import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RedirectToRegister } from '../../common/components/modal/redirectToRegister';
import {
  AudioBadgeTemplate,
  BadgesPage,
  DrawContentBadgesPage,
  TextBadgeTemplate,
} from '../../pages/badges';
import BranchOrderResumePage from '../../pages/branch_order_resume/container';
import { CeremoniesPage } from '../../pages/ceremonies';
import { ImageContentPage, TextContentPage, VideoContentPage } from '../../pages/content';
import { EcommercePage } from '../../pages/ecommerce';
import { FamilyZonePage } from '../../pages/familyZone';
import { LinesPage } from '../../pages/lines';
import { SearchPage } from '../../pages/search/container';
import { FaqTreesPage, PlantTreePage, UdianasPage } from '../../pages/udianas';
import DescriptionTreePage from '../../pages/udianas/container/DescriptionTreePage';
import { HomeRoutes, routes as homeRoutes } from './home';
import { InvitationRoutes, routes as invitationRoutes } from './invitation';
import { LoginRoutes, routes as loginRoutes } from './login';
import { MaintenanceRoutes, routes as maintenanceRoutes } from './maintenance';
import { PollRoutes, routes as pollRoutes } from './polls';
import { ProfileRoutes, routes as profileRoutes } from './profile';
import { RAuthRoutes, routes as rAuthRoutes } from './rAuth';
import { RegisterRoutes, routes as registerRoutes } from './register';
import { RgpdRoutes, routes as rgpdRoutes } from './rgpd';

export const appRoutes = {
  home: homeRoutes('/:lang'),
  login: loginRoutes('/:lang/login'),
  rgpd: rgpdRoutes('/:lang/rgpd'),
  // lines: linesRoutes('/:lang/lines'),
  profile: profileRoutes('/:lang/profile'),
  rAuth: rAuthRoutes('/:lang/r'),
  register: registerRoutes('/:lang/register'),
  invitation: invitationRoutes('/i'),
  poll: pollRoutes('/:lang/poll'),
  maintenance: maintenanceRoutes('/:lang/maintenance'),
};

const maintenance = (process.env.REACT_APP_MAINTENANCE || env?.REACT_APP_MAINTENANCE) === 'true';

export const OwnerRoutes =
  maintenance === true ? (
    <Switch>
      <Route path={appRoutes.maintenance.path} component={MaintenanceRoutes} exact />
      <Redirect to={appRoutes.maintenance.path} />
    </Switch>
  ) : (
    <>
      <RedirectToRegister />
      <Switch>
        <Route path={appRoutes.home.path} component={HomeRoutes} exact />
        {/* Esta la rutas asi puestas en vez del `appRoutes` ya que me hacia automaticamente el redirect hacia el login sin entender muy bien por que, ya que me entraba en el home.path siempre */}
        <Route path="/:lang/:slug/search" component={SearchPage} exact />
        <Route path="/:lang/search" component={SearchPage} exact />
        <Route path={appRoutes.login.path} component={LoginRoutes} exact />
        <Route path={appRoutes.rgpd.path} component={RgpdRoutes} />
        {/* <Route path={appRoutes.lines.path} component={LinesRoutes} /> */}
        {/* LINES */}
        <Route path="/:lang/lines" component={LinesPage} exact />
        <Route path="/:lang/lines/:idLine/:idName" component={LinesPage} exact />
        <Route path="/:lang/lines/:idLine/:idName/ceremony" component={CeremoniesPage} exact />
        <Route path="/:lang/lines/:idLine/:idName/ecommerce" component={EcommercePage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/branch-order-resume"
          component={BranchOrderResumePage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/book/add" component={BadgesPage} exact />
        <Route path="/:lang/lines/:idLine/:idName/book/write" component={TextBadgeTemplate} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/book/draw"
          component={DrawContentBadgesPage}
          exact
        />
        <Route
          path="/:lang/lines/:idLine/:idName/book/audio"
          component={AudioBadgeTemplate}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/family-zone" component={FamilyZonePage} exact />
        <Route path="/:lang/lines/:idLine/:idName/add/text" component={TextContentPage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/add/text/:idContent"
          component={TextContentPage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/add/quote" component={TextContentPage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/add/quote/:idContent"
          component={TextContentPage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/add/video" component={VideoContentPage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/add/video/:idContent"
          component={VideoContentPage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/add/image" component={ImageContentPage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/add/image/:idContent"
          component={ImageContentPage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/udianas" component={UdianasPage} exact />
        <Route
          path="/:lang/lines/:idLine/:idName/udianas/:idTree/description"
          component={DescriptionTreePage}
          exact
        />
        <Route
          path="/:lang/lines/:idLine/:idName/udianas/plant-tree"
          component={PlantTreePage}
          exact
        />
        <Route path="/:lang/lines/:idLine/:idName/udianas/faqs" component={FaqTreesPage} exact />

        <Route path={appRoutes.profile.path} component={ProfileRoutes} />
        <Route path={appRoutes.invitation.path} component={InvitationRoutes} />
        <Route path={appRoutes.rAuth.path} component={RAuthRoutes} />
        <Route path={appRoutes.register.path} component={RegisterRoutes} exact />
        <Route path={appRoutes.poll.path} component={PollRoutes} />
        <Redirect to={appRoutes.login.path} />
      </Switch>
    </>
  );
