export const constants = {
  GET_COMPANY_LINE: 'GET_COMPANY_USERS_LINE',
  GET_COMPANY_LINE_SUCCESS: 'GET_COMPANY_USERS_LINE_SUCCESS',
  GET_COMPANY_LINE_FAIL: 'GET_COMPANY_USERS_LINE_FAIL',
  GET_COMPANY: 'GET_COMPANY',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAIL: 'GET_COMPANY_FAIL',
};

export const services = {
  getCompanyLines: ({ name, identifier, valueToken }) => ({
    route: `/api/v2/companies/${identifier}/lines?name=${name}&token=${valueToken}`,
    method: 'get',
  }),
  getCompany: ({ companyUuid }) => ({
    route: `/api/v2/companies/${companyUuid}`,
    method: 'get',
  }),
};
