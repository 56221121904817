import {
  IconCamera,
  IconCeremony,
  IconCloseBig,
  IconInfoCircle,
  IconLock,
  IconMessenger,
  IconPlus,
  IconQuote,
  IconSee,
  IconShare,
  IconTelegram,
  IconUnlock,
  IconUser,
  IconUserFamily,
  IconVideo,
  IconWhatsapp,
  IconWrite,
} from '@lideralia/alife-uikit/dist/atoms/Icons';
import { Navbar } from '@lideralia/alife-uikit/dist/organisms/Navbar';
import { NavbarMoreOptions } from '@lideralia/alife-uikit/dist/organisms/Navbar/NavbarMoreOptions/NavbarMoreOptions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MEDIA_QUERY_MOBILE } from '../../../../utilities/constants';
import { getLocalStorage } from '../../functions/cookies';
import { actualLanguage } from '../../functions/lang';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { KEYS_ANALYTICS, setEventAnalytics } from '../Analytics';

function copyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.style.opacity = '0';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  document.body.removeChild(textArea);
}

export const Menu = ({ history, pathname, openShareMenu, setOpenShareMenu }) => {
  const idLineStored = getLocalStorage(process.env.REACT_APP_COOKIE_ID_LINE);
  const idNameStored = getLocalStorage(process.env.REACT_APP_COOKIE_NAME_LINE);
  const [idActive, setIdActive] = useState(idLineStored || null);
  const notifications = useSelector((state) => state.notifications);
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state?.user);
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const [isOpened, setOpened] = useState(false);
  const { active } = line;
  const SHARE_URL = `${window.location.origin}/${actualLanguage}/lines/${
    line.active?.lineId
  }/${line.active?.nameUrl?.replaceAll(/[^\w\-\s]/gi, '')}`;

  const SHARE_TEXT = intl.formatMessage(
    {
      id: 'ceremony.actionShareTextWhatsapp',
    },
    {
      name: line?.active?.name,
      url: SHARE_URL,
    }
  );
  const menu = [
    {
      icon: <IconInfoCircle />,
      tooltip: 'Info',
      id: 'lines',
      customColorPrimary: active?.company?.style?.primaryColor || '#8A8689',
      action: () => {
        setEventAnalytics(KEYS_ANALYTICS.MENU_LINES_BUTTON_CLICKED, user, pathname, active);
        history.push(idActive ? `/${actualLanguage}/lines/${idActive}/${idNameStored}` : `/${actualLanguage}/lines`);
      }
    },
    {
      icon: <IconCeremony />,
      tooltip: 'ceremony',
      id: 'ceremony',
      customColorPrimary: active?.company?.style?.primaryColor || '#8A8689',
      action: () => {
        setEventAnalytics(KEYS_ANALYTICS.MENU_CEREMONY_BUTTON_CLICKED, user, pathname, active);
        history.push(idActive ? `/${actualLanguage}/lines/${idActive}/${idNameStored}/ceremony` : `/${actualLanguage}/lines`);
      }
    },
    {
      icon: isOpened ? <IconCloseBig /> : <IconPlus />,
      tooltip: 'more',
      id: 'more',
      action: () => {
        if (openShareMenu) {
          setOpenShareMenu();
        }
        setEventAnalytics(isOpened ? KEYS_ANALYTICS.MENU_MORE_CLOSE_BUTTON_CLICKED : KEYS_ANALYTICS.MENU_MORE_OPEN_BUTTON_CLICKED, user, pathname, active);
        setOpened(!isOpened);
      },
      rounded: true,
      customColorSecondary: (!isOpened && active?.company?.style?.secondaryColor) || '#8A8689',
      colorType: isOpened ? 'black' : active?.company?.style?.primaryColor || 'orange',
      colorIcon: active?.company?.style?.secondaryTextColor || 'white',
    },
    {
      icon: <IconUserFamily />,
      tooltip: 'Family',
      id: 'family-zone',
      customColorPrimary: active?.company?.style?.primaryColor || '#8A8689',
      action: () => {
        setEventAnalytics(KEYS_ANALYTICS.MENU_FAMILY_ZONE_BUTTON_CLICKED, user, pathname, active);
        history.push(idActive ? `/${actualLanguage}/lines/${idActive}/${idNameStored}/family-zone` : `/${actualLanguage}/lines`);
      }
    },
    {
      icon: <IconUser />,
      tooltip: 'User',
      id: 'profile',
      customColorPrimary: active?.company?.style?.primaryColor || '#8A8689',
      action: () => {
        setEventAnalytics(KEYS_ANALYTICS.MENU_PROFILE_BUTTON_CLICKED, user, pathname, active);
        history.push(`/${actualLanguage}/profile`);
      }
    },
  ];

  const navbarMenu = [
    {
      id: 'public',
      disabled: false,
      ocultPublicTitle: true,
      icon: <IconSee />,
      title: intl.formatMessage({
        id: 'navbar.publicContent',
      }),
      color: active?.company?.style?.primaryColor || 'orange',
      description: intl.formatMessage({
        id: 'navbar.publicDescription',
      }),
      options: [
        {
          id: 'comment',
          icon: <IconWrite />,
          label: intl.formatMessage({
            id: 'common.actionCommentLabel',
          }),
          style: { color: active?.company?.style?.textColor },
          customColorPrimary: active?.company?.style?.primaryColor,
          colorType: 'orange',
          colorText: active?.company?.style?.secondaryTextColor || 'white',
          action: () => {
            history.push(`/${actualLanguage}/lines/${idActive}/${idNameStored}/book/write`);
            setOpened(false);
          },
        },
      ],
    },
    {
      id: 'private',
      disabled: false,
      icon: line?.active?.permissionType <= 1 ? <IconLock /> : <IconUnlock />,
      title: intl.formatMessage({
        id: 'navbar.privateContent',
      }),
      color: active?.company?.style?.primaryColor || 'green',
      description: intl.formatMessage({
        id: 'navbar.privateDescription',
      }),
      options: [
        {
          id: 'addpicture',
          disabled: line?.active?.permissionType <= 1,
          icon: <IconCamera />,
          label: intl.formatMessage({
            id: 'common.actionPictureLabel',
          }),
          customColorSecondary: active?.company?.style?.secondaryColor,
          colorType: 'blue',
          colorText: active?.company?.style?.secondaryTextColor || 'white',
          action: () => {
            history.push(`/${actualLanguage}/lines/${idActive}/${idNameStored}/add/image`);
            setOpened(false);
          },
        },
        {
          id: 'addvideo',
          disabled: line?.active?.permissionType <= 1,
          icon: <IconVideo />,
          label: intl.formatMessage({
            id: 'common.actionVideoLabel',
          }),
          customColorSecondary: active?.company?.style?.secondaryColor,
          colorType: 'blue',
          colorText: active?.company?.style?.secondaryTextColor || 'white',
          action: () => {
            history.push(`/${actualLanguage}/lines/${idActive}/${idNameStored}/add/video`);
            setOpened(false);
          },
        },
        {
          id: 'addquote',
          disabled: line?.active?.permissionType <= 1,
          icon: <IconQuote />,
          label: intl.formatMessage({
            id: 'common.actionQuoteLabel',
          }),
          customColorSecondary: active?.company?.style?.secondaryColor,
          colorType: 'blue',
          colorText: active?.company?.style?.secondaryTextColor || 'white',
          action: () => {
            history.push(`/${actualLanguage}/lines/${idActive}/${idNameStored}/add/text`);
            setOpened(false);
          },
        },
      ],
    },
  ];
  // let navbarMenuShare = [
  //   {
  //     id: 'public',
  //     disabled: false,
  //     icon: <IconShare />,
  //     title: intl.formatMessage({
  //       id: 'common.actionShareLovedOnesLabel',
  //     }),
  //     ocultPublicTitle: false,
  //     color: 'grey',
  //     description: intl.formatMessage({
  //       id: 'navbar.shareTheCeremonyData',
  //     }),
  //     options: [
  //       {
  //         id: 'whatsapp',
  //         icon: <IconWhatsapp />,
  //         label: intl.formatMessage(
  //           {
  //             id: 'common.actionShareByLabel',
  //           },
  //           {
  //             name: 'WhatsApp',
  //           }
  //         ),
  //         customColorPrimary: '#47CE57',
  //         colorType: '#47CE57',
  //         action: () => {
  //           const shareCopy = intl.formatMessage(
  //             {
  //               id: 'ceremony.actionShareTextWhatsapp',
  //             },
  //             {
  //               name: line?.active?.name,
  //               url: `${window.location.origin}/${actualLanguage}/lines/${
  //                 line.active?.lineId
  //               }/${line.active?.nameUrl?.replaceAll(/[^\w\-\s]/gi, '')}`,
  //             }
  //           );
  //           window.open(
  //             `https://api.whatsapp.com/send?text=${shareCopy}?o=w`,
  //             '_blank',
  //             'noopener,noreferrer'
  //           );
  //         },
  //       },
  //       {
  //         id: 'telegram',
  //         icon: <IconTelegram />,
  //         label: intl.formatMessage(
  //           {
  //             id: 'common.actionShareByLabel',
  //           },
  //           {
  //             name: 'Telegram',
  //           }
  //         ),
  //         customColorPrimary: '#4E9FD9',
  //         colorType: '#4E9FD9',
  //         action: () => {
  //           const shareCopy = intl.formatMessage(
  //             {
  //               id: 'ceremony.actionShareTextWhatsapp',
  //             },
  //             {
  //               name: line?.active?.name,
  //               url: SHARE_URL,
  //             }
  //           );
  //           const telegramLink = `https://t.me/share/url?url=${SHARE_URL}&text=${encodeURIComponent(
  //             shareCopy
  //           )}`;
  //           window.open(telegramLink, '_blank', 'noopener,noreferrer');
  //         },
  //       },
  //     ],
  //   },
  // ];

  // if (isMobile) {
  const navbarMenuShare = [
    {
      id: 'public',
      disabled: false,
      icon: <IconShare />,
      title: intl.formatMessage({
        id: 'common.actionShareLovedOnesLabel',
      }),
      ocultPublicTitle: false,
      color: 'grey',
      description: intl.formatMessage({
        id: 'navbar.shareTheCeremonyData',
      }),
      options: [
        {
          id: 'whatsapp',
          icon: <IconWhatsapp />,
          label: intl.formatMessage(
            {
              id: 'common.actionShareByLabel',
            },
            {
              name: 'WhatsApp',
            }
          ),
          customColorPrimary: '#47CE57',
          colorType: '#47CE57',
          action: () => {
            window.open(
              `https://api.whatsapp.com/send?text=${SHARE_TEXT}/ceremony?o=w`,
              '_blank',
              'noopener,noreferrer'
            );
          },
        },
        {
          id: 'messenger',
          icon: <IconMessenger />,
          label: intl.formatMessage(
            {
              id: 'common.actionShareByLabel',
            },
            {
              name: 'Messenger',
            }
          ),
          customColorPrimary: '#367AF6',
          colorType: '#367AF6',
          action: () => {
            const messengerMobileLink = `fb-messenger://share`;

            const alternativeLink = `https://www.messenger.com/`;

            // eslint-disable-next-line no-alert
            alert(
              intl.formatMessage({
                id: 'navbar.shareTextOnMessengerCopiedToClipboard',
              })
            );
            if (isMobile) {
              if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard
                  .writeText(SHARE_TEXT)
                  .then(() => {
                    window.open(messengerMobileLink, '_blank', 'noopener,noreferrer');
                  })
                  .catch(() => {
                    copyTextToClipboard(SHARE_TEXT);
                    window.open(messengerMobileLink, '_blank', 'noopener,noreferrer');
                  });
              } else {
                copyTextToClipboard(SHARE_TEXT);
                window.open(messengerMobileLink, '_blank', 'noopener,noreferrer');
              }
            } else {
              navigator.clipboard
                .writeText(SHARE_TEXT)
                .then(() => {
                  window.open(alternativeLink, '_blank', 'noopener,noreferrer');
                })
                .catch(() => {});
            }
          },
        },
        {
          id: 'telegram',
          icon: <IconTelegram />,
          label: intl.formatMessage(
            {
              id: 'common.actionShareByLabel',
            },
            {
              name: 'Telegram',
            }
          ),
          customColorPrimary: '#4E9FD9',
          colorType: '#4E9FD9',
          action: () => {
            const telegramLink = `https://t.me/share/url?url=${SHARE_URL}&text=${encodeURIComponent(
              SHARE_TEXT
            )}`;
            window.open(telegramLink, '_blank', 'noopener,noreferrer');
          },
        },
      ],
    },
  ];
  // }

  const [menuProfile, setMenuProfile] = useState(menu);
  const [moreOptions, setMoreOptions] = useState(navbarMenu);

  useEffect(() => {
    if (idActive) {
      setMenuProfile(menu);
    } else {
      const menuWithoutLine = menu.filter((item) => item.id !== 'lines');
      setMenuProfile(menuWithoutLine);
    }
  }, [idActive]);

  useEffect(() => {
    setOpened(false);
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const updatedMenu = menuProfile.map((item) => {
      if (item.id === 'profile') {
        return {
          ...item,
          notify: !!notifications?.unReadNotifications || !!notifications?.unReadRequests,
        };
      }
      return item;
    });

    setMenuProfile([...updatedMenu]);
  }, [notifications, active?.company]);

  useEffect(() => {
    const updateMenu = menu.map((item) => {
      if (item.id === 'lines') {
        return {
          ...item,
          path: idLineStored
            ? `/${actualLanguage}/${item.id}/${idLineStored}/${idNameStored}`
            : `/${actualLanguage}/${item.id}`,
        };
      }
      if (item === 'ceremony' || item.id === 'family-zone') {
        return {
          ...item,
          path: idLineStored
            ? `/${actualLanguage}/lines/${idLineStored}/${idNameStored}/${item.id}`
            : `/${actualLanguage}/lines`,
        };
      }
      if (item.id === 'profile') {
        return {
          ...item,
          path: `/${actualLanguage}/${item.id}`,
        };
      }

      return item;
    });

    setMenuProfile([...updateMenu]);

    if (idActive !== idLineStored) {
      setIdActive(idLineStored);
    }
  }, [idLineStored, active?.company]);

  useEffect(() => {
    setMenuProfile([...menu]);
  }, [isOpened]);

  useEffect(() => {
    let updatedMenu = navbarMenu;
    if (!active?.isLegalTermAccepted) {
      updatedMenu = navbarMenu.map((item) => ({
        ...item,
        options: item.options.map((option) => ({
          ...option,
          disabled: !active?.isLegalTermAccepted,
        })),
      }));
    } else if (line?.active?.userGroup <= 1) {
      updatedMenu = navbarMenu.map((item) => ({
        ...item,
        disabled: true,
      }));
    }
    setMoreOptions([...updatedMenu]);
  }, [active?.isLegalTermAccepted, active?.company, line?.active?.userGroup]);

  useEffect(() => {
    if (openShareMenu) {
      setMoreOptions(navbarMenuShare);
      setOpened(true);
    } else {
      setOpened(false);
      setOpenShareMenu();
      setMoreOptions(navbarMenu);
    }
  }, [openShareMenu]);

  return (
    <>
      {isOpened && <NavbarMoreOptions moreOptions={moreOptions} />}
      <Navbar navItems={menuProfile} history={history} pathname={pathname} />
    </>
  );
};

Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  openShareMenu: PropTypes.bool.isRequired,
  setOpenShareMenu: PropTypes.func.isRequired,
};
