/* eslint-disable */

import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { LiveChatParamsMapper } from '../mapper';

export function* getLiveChatParamsSaga({ id }) {
  try {
    const response = yield requestApi2(services.getLiveChatParams(id));
    if (response && response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(action.getLiveChatParamsSuccess(LiveChatParamsMapper.hydrate(data)));
    } else {
      throw response;
    }
  } catch (error) {
    /*yield put(action.getLiveChatParamsFail(error));
    yield put(
      showAlert(
        'red',
        1,
        window.intl.formatMessage({
          id: error?.message || 'error.generic',
        })
      )
    );
    yield delay(5000);
    yield put(showAlert('', -1, ''));*/
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_LIVE_CHAT_PARAMS, getLiveChatParamsSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
