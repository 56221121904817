import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import showAlertSaga from '../../../../../utilities/alert';
import * as action from '../actions';
import { constants, services } from '../config';
import { OrderMapper } from '../mapper';

export function* confirmTreeOrderSaga({ data }) {
  try {
    const response = yield requestApi2(services.confrimTreeOrder(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      let res = [];
      res = response.data;
      res.status = 'success';
      const c = OrderMapper.hydrate(res);
      yield put(action.confirmTreeOrderSuccess(c));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.confirmTreeOrderFailure(error));
    yield showAlertSaga({
      error: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* postTreeOrderSaga({ data }) {
  try {
    const response = yield requestApi2(services.postTreeOrder(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      let res = [];
      if (response.data.order.order_status === 'delivered') {
        res = response.data.order;
        res.status = 'success';
        res.client_secret = null;
      } else if (response.data.order.order_status === 'not_paid') {
        res.id = response.data.order.uuid;
        res.status = 'requires_action';
        res.client_secret = response.data.payment_intent.client_secret;
      }
      const c = OrderMapper.hydrate(res);
      yield put(action.postTreeOrderSuccess(c));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.postTreeOrderFailure(error));
    yield showAlertSaga({
      error: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

export function* getCertificateTreeSaga({ data }) {
  try {
    const response = yield requestApi2(services.getCertificateTree(data), ['version']);
    if (response && response.status >= 200 && response.status < 300) {
      const res = response.data;
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificate_trees.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put(action.getCertificateTreeSuccess(res));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getCertificateTreeFailure(error));
    yield showAlertSaga({
      error: error.status,
      color: 'red',
      message: window.intl.formatMessage({
        id: error?.message || 'error.generic',
      }),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(constants.CONFIRM_TREE_ORDER, confirmTreeOrderSaga);
  yield takeLatest(constants.POST_TREE_ORDER, postTreeOrderSaga);
  yield takeLatest(constants.GET_CERTIFICATE_TREE, getCertificateTreeSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
