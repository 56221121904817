import { constants } from '../config';

/* **** getLine *** */
export const getLiveChatParams = (id) => ({
  type: constants.GET_LIVE_CHAT_PARAMS,
  id,
});

export const getLiveChatParamsSuccess = (data) => ({
  type: constants.GET_LIVE_CHAT_PARAMS_SUCCESS,
  payload: data,
});

export const getLiveChatParamsFail = (error) => ({
  type: constants.GET_LIVE_CHAT_PARAMS_FAIL,
  error,
});

export const resetLiveChatParams = () => ({
  type: constants.RESET_LIVE_CHAT_PARAMS,
});
