import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  data: [],
  unReadNotifications: 0,
  requests: [],
  unReadRequests: 0,
};

export default function setNotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_NOTIFICATIONS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        data: [...action.payload],
        unReadNotifications: action.payload.filter((el) => el.status === 0).length,
      };
    }
    case constants.GET_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    case constants.GET_REQUESTS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_REQUESTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        requests: [...action.payload],
        unReadRequests: action.payload.filter((el) => el.status === 0).length,
      };
    }
    case constants.GET_REQUESTS_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };

    case constants.SET_NOTIFICATION_STATUS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.SET_NOTIFICATION_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.SET_NOTIFICATION_STATUS_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };
    case constants.GET_NOTIFICATION_FROM_SOCKETS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.ADD_NOTIFICATION_FROM_SOCKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        data: [action.payload, ...state?.data],
        unReadNotifications: state.unReadNotifications + 1,
      };
    case constants.GET_REQUEST_FROM_SOCKETS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.ADD_REQUEST_FROM_SOCKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        done: true,
        requests: [action.payload, ...state.requests],
        unReadRequests: state.unReadRequests + 1,
      };
    default:
      return state;
  }
}
