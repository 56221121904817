/* eslint-disable camelcase */
import { timeAgo } from '../../../functions/date';
import { FormatDate } from '../../../mappers/FormatDate';
import { CompanyMapper } from '../../company/mapper';
import {
  AuthorBadgeMapper,
  ContentSimpleMapper,
  LineMapper,
  LineRequestMapper,
} from '../../line/mapper';

export const NotificationMapper = {
  hydrate: ({ notification_uuid, notification_type, status, date, author, receiver }) => ({
    notificationId: notification_uuid || null,
    notificationType: notification_type || '0',
    status: status || 0,
    date: date ? FormatDate.hydrate(date) : null,
    timeAgo: date ? timeAgo(`${date} GMT+0000 (UTC)`) : null,
    author: author ? AuthorBadgeMapper.hydrate(author) : null,
    line: receiver?.line ? LineMapper.hydrate(receiver.line) : null,
    company:
      typeof receiver?.line?.company === 'object' && CompanyMapper.hydrate(receiver.line.company),
    content: receiver?.content ? ContentSimpleMapper.hydrate(receiver.content) : null,
    targetUser: receiver?.target_user ? AuthorBadgeMapper.hydrate(receiver.target_user) : null,
  }),
};

export const RequestsMapper = {
  hydrate: ({ request_id, request_type, status, date, author, receiver }) => ({
    requestId: request_id || null,
    requestType: request_type || '0',
    status: status || 0,
    date: date ? FormatDate.hydrate(date) : null,
    timeAgo: date ? timeAgo(`${date} GMT+0000 (UTC)`) : null,
    author: author ? AuthorBadgeMapper.hydrate(author) : null,
    line: receiver?.line ? LineRequestMapper.hydrate(receiver.line) : null,
    content: receiver?.content ? ContentSimpleMapper.hydrate(receiver.content) : null,
  }),
};
