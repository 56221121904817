import { constants } from '../config';

const initialState = {
  done: false,
  loading: false,
  errorRequest: false,
  error: null,
  info: {},
};

export default function setSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_SETTINGS:
      return { ...state, loading: true, errorRequest: false, error: null };
    case constants.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true,
        info: {
          ...action.payload,
        },
      };
    }
    case constants.GET_SETTINGS_FAIL:
      return { ...state, loading: false, errorRequest: true, error: { ...action.error } };

    case constants.RESET_SETTINGS:
      return initialState;
    default:
      return state;
  }
}
