import { constants } from '../config';
/* **** getInfoAuthCode *** */
export const getInfoAuthCode = (authCode) => ({
  type: constants.GET_INFO_AUTH_CODE,
  authCode,
});

export const getInfoAuthCodeSuccess = (data) => ({
  type: constants.GET_INFO_AUTH_CODE_SUCCESS,
  payload: data,
});

export const getInfoAuthCodeFail = (error) => ({
  type: constants.GET_INFO_AUTH_CODE_FAIL,
  error,
});
