import { Button } from '@lideralia/alife-uikit/dist/atoms/Button';
import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon';
import { IconConfiguration } from '@lideralia/alife-uikit/dist/atoms/Icons';
import IconManagemnt from '@lideralia/alife-uikit/dist/atoms/Icons/IconManagement';
import IconNotification from '@lideralia/alife-uikit/dist/atoms/Icons/IconNotification';
import IconStop from '@lideralia/alife-uikit/dist/atoms/Icons/IconStop';
import IconUser from '@lideralia/alife-uikit/dist/atoms/Icons/IconUser';
import { Box, Container } from '@lideralia/alife-uikit/dist/atoms/Layout';
import { Text } from '@lideralia/alife-uikit/dist/atoms/Typography';
import css from '@styled-system/css';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const BadgeStyled = styled(Text)(
  css({
    backgroundColor: 'red',
    paddingY: 1,
    paddingX: 2,
    borderRadius: 7,
    color: 'white',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const ProfileTemplate = (props) => {
  const {
    actionPersonalZone,
    actionNotifications,
    actionArrangements,
    actionModeration,
    actionConfiguration,
    // actionSettings,
    haveNotifications,
    active,
    moderation,
  } = props;
  return (
    <Container paddingY={4} {...props}>
      {active && active.permissionType === 5 && (
        <MortuaryServices actionArrangements={actionArrangements} />
      )}
      {active && active.permissionType > 2 && (
        <AdministratorsZone
          moderation={moderation}
          actionModeration={actionModeration}
          actionConfiguration={actionConfiguration}
        />
      )}
      <PersonalZone
        actionNotifications={actionNotifications}
        haveNotifications={haveNotifications}
        actionPersonalZone={actionPersonalZone}
      />
    </Container>
  );
};

const MortuaryServices = ({ actionArrangements }) => (
  <>
    <Text fontWeight="bold" sizeText="display24" color="black84">
      <FormattedMessage id="profile.mortuaryServices.title" />
    </Text>
    <Box marginTop={4} marginBottom={5}>
      <Button
        marginY={1}
        fullWidth
        colorType="white"
        sizeButton="big"
        justifyContent="flex-start"
        action={() => actionArrangements()}
      >
        <Icon sizeIcon="display24" color="black84">
          <IconManagemnt />
        </Icon>
        <Text
          textAlign="left"
          style={{ flex: 1 }}
          marginLeft={2}
          fontWeight="bold"
          sizeText="display16"
          color="black84"
        >
          <FormattedMessage id="communication.title" />
        </Text>
      </Button>
    </Box>
  </>
);

const AdministratorsZone = ({ actionModeration, moderation, actionConfiguration }) => (
  <>
    <Text fontWeight="bold" sizeText="display24" color="black84">
      <FormattedMessage id="profile.administratorsZone.title" />
    </Text>
    <Box marginTop={4} marginBottom={5}>
      <Button
        marginY={2}
        fullWidth
        colorType="white"
        sizeButton="big"
        justifyContent="flex-start"
        action={() => actionModeration()}
      >
        <Icon sizeIcon="display24" color="black84">
          <IconStop />
        </Icon>
        <Text
          textAlign="left"
          style={{ flex: 1 }}
          marginLeft={2}
          fontWeight="bold"
          sizeText="display16"
          color="black84"
        >
          <FormattedMessage id="communication.moderation.title" />
        </Text>
        {(moderation?.length && (
          <BadgeStyled fontWeight="bold" sizeText="display12">
            {moderation.length}
          </BadgeStyled>
        )) ||
          ''}
      </Button>
      <Button
        marginY={2}
        fullWidth
        colorType="white"
        sizeButton="big"
        justifyContent="flex-start"
        action={() => actionConfiguration()}
      >
        <Icon sizeIcon="display24" color="black84">
          <IconConfiguration />
        </Icon>
        <Text
          textAlign="left"
          style={{ flex: 1 }}
          marginLeft={2}
          fontWeight="bold"
          sizeText="display16"
          color="black84"
        >
          <FormattedMessage id="communication.configuration.title" />
        </Text>
        {(moderation?.length && (
          <BadgeStyled fontWeight="bold" sizeText="display12">
            {moderation.length}
          </BadgeStyled>
        )) ||
          ''}
      </Button>
    </Box>
  </>
);

const PersonalZone = ({ actionNotifications, haveNotifications, actionPersonalZone }) => (
  <>
    <Text fontWeight="bold" sizeText="display24" color="black84">
      <FormattedMessage id="profile.personalZone.title" />
    </Text>
    <Box marginTop={4}>
      <Button
        marginY={1}
        fullWidth
        colorType="white"
        sizeButton="big"
        justifyContent="flex-start"
        action={() => actionNotifications()}
        id="notifications"
      >
        <Icon sizeIcon="display24" color="black84">
          <IconNotification />
        </Icon>
        <Text
          textAlign="left"
          style={{ flex: 1 }}
          marginLeft={2}
          fontWeight="bold"
          sizeText="display16"
          color="black84"
        >
          <FormattedMessage id="profile.notifications.title" />
        </Text>
        {(haveNotifications && (
          <BadgeStyled fontWeight="bold" sizeText="display12">
            {haveNotifications}
          </BadgeStyled>
        )) ||
          ''}
      </Button>
      <Button
        id="personalZone"
        marginY={2}
        fullWidth
        colorType="white"
        sizeButton="big"
        justifyContent="flex-start"
        action={() => actionPersonalZone()}
      >
        <Icon sizeIcon="display24" color="black84">
          <IconUser />
        </Icon>
        <Text marginLeft={2} fontWeight="bold" sizeText="display16" color="black84">
          <FormattedMessage id="profile.personalData.title" />
        </Text>
      </Button>
    </Box>
  </>
);
