import * as FlagIcons from '@lideralia/alife-uikit/dist/atoms/Flags';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import { KEYS_ANALYTICS, setEventAnalytics } from '../../../common/components/Analytics';
import { actualLanguage } from '../../../common/functions/lang';
import { getCountries } from '../../../common/helpers/countries';
import { getSettings } from '../../../common/helpers/settings/actions';
import { getWhois } from '../../../common/helpers/whois';
import { useSearchParams } from '../../../common/hooks/useSearchParams';
import { getLine } from '../../../common/schemes/line';
import { getNotifications, getRequests } from '../../../common/schemes/notifications';
import { getMe, setUserProspect } from '../../../common/schemes/user';
import { resetErrorLogin, resetPasstrough, setMobilePasstrough, setVerifyLogin } from '../actions';
import reducer from '../reducer';
import { LoginPasscodeTemplate, LoginTemplate } from '../template';

const LoginPage = ({ hideModalLogin, showRegister }) => {
  const tokenCaptcha =
    process.env.REACT_APP_TOKEN_RECAPTCHA_V3 || env?.REACT_APP_TOKEN_RECAPTCHA_V3;
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPass, setIsOpenPass] = useState(false);
  const [prefixData, setPrefixData] = useState({
    value: 'ES',
    label: '+34',
    iconLeft: <FlagIcons.Es />,
  });
  const [dataUser, setDataUser] = useState({
    countryCode: prefixData.value,
    isFor: 'login',
  });
  const [disableLogin, setDisableLogin] = useState(true);
  const [prospectBool, setProspectBool] = useState(false);
  const [options, setOptions] = useState(null);
  const [prefixDataLoaded, setPrefixDataLoaded] = useState(false);

  // Helper functions
  const query = useSearchParams();
  const queryPhone = useMemo(() => query.get('phone'), [query]);
  const queryProspect = useMemo(() => query.get('prospect'), [query]);
  const queryRedirect = useMemo(() => query.get('redirect'), [query]);

  // Actions
  const {
    setVerifyLoginAPI,
    setCountriesAPI,
    getMeAPI,
    getSettingsAPI,
    setLoginPasstroughAPI,
    getNotificationsAPI,
    getRequestsAPI,
    getWhoisInformationAPI,
  } = useAPI();

  // Selectors
  const { countries, login, line, whois, settings, userId } = useUserSelectors();

  const { passtroughDone, error, errorRequest, user, done } = login;

  const onSubmitLogin = (isRetry, token) => {
    setDisableLogin(true);
    setLoginPasstroughAPI({ ...dataUser, token });
    dispatch(setUserProspect(prospectBool));
    if (isRetry) {
      setEventAnalytics(
        KEYS_ANALYTICS.NEW_OTP_BUTTON_CLICKED,
        user,
        history?.location?.pathname,
        line?.active
      );
    } else {
      setEventAnalytics(
        KEYS_ANALYTICS.LOGIN_BUTTON_CLICKED,
        user,
        history?.location?.pathname,
        line?.active
      );
    }
  };

  const onChangeNumber = () => {
    setDisableLogin(false);
    dispatch(resetPasstrough());
  };

  const backModal = () => {
    if (history?.length < 3) history.replace(`/${actualLanguage}`);
    else history?.goBack();
  };

  useEffect(() => {
    if (queryPhone) {
      setDataUser({
        ...dataUser,
        phoneNumber: queryPhone,
      });
      const loginInfoQuery = {
        countryCode: prefixData.value,
        isFor: 'login',
        phoneNumber: queryPhone,
      };
      if (queryProspect === 'true') {
        setProspectBool(true);
        dispatch(setUserProspect(true));
      }
      setLoginPasstroughAPI(loginInfoQuery);
    }
  }, [queryPhone]);

  useEffect(() => {
    if (!countries?.done) {
      setCountriesAPI();
    }
    if (!settings?.done) {
      getSettingsAPI();
    }

    setTimeout(() => setIsOpen(true), 100);
    setEventAnalytics(KEYS_ANALYTICS.PAGE_VIEW, user, history?.location?.pathname, line?.active);
  }, []);

  useEffect(() => {
    if (errorRequest) {
      setEventAnalytics(
        KEYS_ANALYTICS.ALIFE_ERROR,
        user,
        history?.location?.pathname,
        line?.active,
        {},
        error
      );
      setDisableLogin(false);
    }
  }, [errorRequest]);

  useEffect(() => {
    if (passtroughDone) {
      setTimeout(() => setIsOpenPass(true), 100);
    } else {
      setTimeout(() => setIsOpenPass(false), 100);
    }
  }, [passtroughDone]);

  useEffect(() => {
    if (!done) {
      return;
    }
    const idLine = window.location.pathname.split('/')[3];
    const idName = window.location.pathname.split('/')[4];
    if (idLine && idName) {
      getMeAPI();
      if (userId) {
        getNotificationsAPI(userId);
      }
      getRequestsAPI();
      dispatch(getLine(idLine, idName));
    }

    setEventAnalytics(KEYS_ANALYTICS.LOGIN, user, history?.location?.pathname, line?.active);
    if (history?.location?.pathname?.includes('login')) {
      if (queryRedirect) {
        history.push(`${queryRedirect}`);
      } else if (user?.legal?.gdpr_accepted) {
        history?.push(`/${actualLanguage}/lines`);
        if (!user.loading) {
          getMeAPI();
          if (userId) {
            getNotificationsAPI(userId);
          }
          getRequestsAPI();
        }
      } else {
        history?.push(`/${actualLanguage}/rgpd`);
      }
    } else if (hideModalLogin) {
      hideModalLogin();
    }
  }, [done, user, history]);
  useEffect(() => {
    setDataUser((d) => ({
      ...d,
      countryCode: prefixData.value,
    }));
  }, [prefixData]);

  useEffect(() => {
    if (!options) {
      const fetchData = async () => {
        const dataCountries = countries?.data?.filter((elem) => elem.flag);
        let optionsSeted = await dataCountries?.map((element) => {
          const FlagIcon = FlagIcons[element.flag];
          if (FlagIcon) {
            return {
              value: element.iso,
              label: `+${element.phonecode}`,
              iconLeft: <FlagIcon />,
            };
          }
          return false;
        });
        optionsSeted = await optionsSeted?.filter((elem) => elem);
        setOptions(optionsSeted);

        getWhoisInformationAPI();
      };
      fetchData();
    }
  }, [countries]);

  useEffect(() => {
    if (options && !prefixDataLoaded && whois.done === true) {
      const countryToCheck = whois.errorRequest === false ? whois?.info?.country : 'ES';
      const myCountryLocalitation = options.filter((option) =>
        option.value.includes(countryToCheck)
      );

      setPrefixData(
        myCountryLocalitation[0] ?? {
          value: 'ES',
          label: '+34',
          iconLeft: <FlagIcons.Es />,
        }
      );
      setPrefixDataLoaded(true);
    }
  }, [whois, options]);

  const buttonRegister = (e) => {
    e.preventDefault();
    if (showRegister) {
      hideModalLogin();
      showRegister();
    } else if (queryRedirect) {
      history.replace(`/${actualLanguage}/register?redirect=${queryRedirect}`);
    } else {
      history.replace(`/${actualLanguage}/register`);
    }
  };

  const showLogin = !history?.location?.pathname.includes('register') && tokenCaptcha;
  if (!showLogin) {
    return null;
  }

  return (
    <>
      {!passtroughDone && (
        <LoginTemplate
          isOpen={isOpen}
          id="alife-login"
          disabled={disableLogin}
          idPhone="phoneNumber"
          tokenCaptcha={tokenCaptcha}
          title={<FormattedMessage id="login.title" />}
          hasError={!!error}
          errorMessage={
            error?.message && (
              <FormattedMessage id={error?.message} defaultMessage="Invalid phone number" />
            )
          }
          labelPhone={intl.formatMessage({ id: 'common.phone' })}
          fieldPhone={intl.formatMessage({ id: 'common.phoneDisclaimer' })}
          actionInput={(name, value) => {
            if (name === 'phoneNumber') {
              setDisableLogin(value.length === 0);
            }

            setDataUser({
              ...dataUser,
              [name]: value,
            });
          }}
          defaultInputQuery={dataUser}
          prefix={
            prefixDataLoaded && {
              name: 'Prefix',
              placeholderMessage: (
                <FormattedMessage id="common.placeholderPrefix" defaultMessage="Prefijo" />
              ),
              id: 'prefix',
              defaultValue: prefixData,
              options,
              action: (name, value) => {
                setPrefixData(value);
              },
            }
          }
          actionButton={{
            label: <FormattedMessage id="login.title" defaultMessage="Iniciar sesión" />,
            action: onSubmitLogin,
          }}
          footer={{
            label: (
              <FormattedMessage
                id="login.labelFooter"
                defaultMessage="¿Aún no tienes cuenta en Alife?"
              />
            ),
            action: {
              label: <FormattedMessage id="register.title" defaultMessage="Regístrate" />,
              to: buttonRegister,
            },
          }}
          onModalBack={backModal}
          backWithButton={window.location.href.indexOf('login') > -1}
          onModalClose={hideModalLogin}
        />
      )}
      {passtroughDone && (
        <>
          <LoginPasscodeTemplate
            isOpen={isOpenPass}
            id="alife-login-verify"
            title={<FormattedMessage id="passCode.title" />}
            hasError={!!error}
            errorMessage={
              error?.message && (
                <FormattedMessage id={error?.message} defaultMessage="Invalid phone number" />
              )
            }
            description={
              <FormattedMessage
                id="passCode.labelPassCode"
                values={{
                  phoneNumber: user?.phone_number,
                }}
              />
            }
            actionInput={(name, value) => {
              setDataUser({
                ...dataUser,
                [name]: value,
              });
            }}
            defaultInputQuery={dataUser}
            labelRetry={intl.formatMessage({ id: 'passCode.labelRetry' })}
            labelRetryAction={<FormattedMessage id="passCode.labelRetryAction" />}
            labelCountDown={intl.formatMessage({ id: 'passCode.labelCountDown' })}
            labelChange={<FormattedMessage id="passCode.labelChange" />}
            labelChangeAction={<FormattedMessage id="passCode.labelChangeAction" />}
            onSubmit={(confirmCode) => {
              setVerifyLoginAPI({
                ...dataUser,
                phoneNumber: user?.phone_number,
                confirmCode,
              });
            }}
            actionRetry={(token) => onSubmitLogin(true, token)}
            actionChange={onChangeNumber}
            tokenCaptcha={tokenCaptcha}
            onModalClose={() => {
              history.go(0);
            }}
            onModalBack={() => {
              history.go(0);
            }}
            seconds={
              error && error?.message === 'error.error_too_many_attempts'
                ? settings?.info?.otp_max_attempts_delay_seconds
                : settings?.info?.otp_delay_seconds
            }
            hasWrongCodeError={error}
            onReset={() => {
              dispatch(resetErrorLogin());
            }}
          />
        </>
      )}
    </>
  );
};

const useAPI = () => {
  const dispatch = useDispatch();

  const setVerifyLoginAPI = useCallback((data) => dispatch(setVerifyLogin(data)), [dispatch]);
  const setCountriesAPI = useCallback(() => dispatch(getCountries('es')), [dispatch]);
  const getMeAPI = useCallback(() => dispatch(getMe()), [dispatch]);
  const getSettingsAPI = useCallback(() => dispatch(getSettings()), [dispatch]);
  const setLoginPasstroughAPI = useCallback(
    (data) => dispatch(setMobilePasstrough(data)),
    [dispatch]
  );
  const getNotificationsAPI = useCallback((id) => dispatch(getNotifications(id)), [dispatch]);
  const getRequestsAPI = useCallback(() => dispatch(getRequests()), [dispatch]);
  const getWhoisInformationAPI = useCallback(() => dispatch(getWhois()), [dispatch]);

  return {
    setVerifyLoginAPI,
    setCountriesAPI,
    getMeAPI,
    getSettingsAPI,
    setLoginPasstroughAPI,
    getNotificationsAPI,
    getRequestsAPI,
    getWhoisInformationAPI,
  };
};

const useUserSelectors = () => {
  const countries = useSelector((state) => state.helper?.countries);
  const login = useSelector((state) => state.login);
  const line = useSelector((state) => state.line);
  const whois = useSelector((state) => state.helper?.whois);
  const settings = useSelector((state) => state.helper?.settings);
  const userId = useSelector((state) => state.user?.data?.id);

  return { countries, login, line, whois, settings, userId };
};

const withReducer = injectReducer({ key: 'login', reducer });

export default compose(withReducer)(LoginPage);

LoginPage.propTypes = {
  hideModalLogin: PropTypes.func,
};

LoginPage.defaultProps = {
  hideModalLogin: () => {},
};
