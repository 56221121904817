import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '../../../../lib/reducers/injectReducer';
import reducer from '../../login/reducer';
import { LoadingTemplate } from '../template';

const LoadingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isShowLoading = useSelector((state) => state.showLoading);

  useEffect(() => {
    if (isShowLoading?.show != null) {
      setIsOpen(isShowLoading?.show);
    }
  }, [isShowLoading]);

  return (
    <LoadingTemplate
      title={<FormattedMessage id="flowerEcommerce.loadingTitle" />}
      description={<FormattedMessage id="flowerEcommerce.loadingDescription" />}
      isOpen={isOpen}
    />
  );
};

const withReducer = injectReducer({ key: 'loading', reducer });

export default compose(withReducer)(LoadingPage);
