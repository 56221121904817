import { constants } from '../config';

const initialState = {
  show: false,
};

export default function setShowLoadingReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_LOADING: {
      return {
        ...state,
        show: action.show,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
