import { all, takeLatest } from 'redux-saga/effects';
import { constants } from '../config';

// eslint-disable-next-line require-yield
export function* setShowLoginSaga() {
  // set action state
}

function* actionWatcher() {
  yield takeLatest(constants.SHOW_LOGIN, setShowLoginSaga);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
