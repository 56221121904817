import { constants } from '../config';
/* **** getConditions *** */
export const getConditions = (lang) => ({
  type: constants.GET_CONDITIONS,
  lang,
});

export const getConditionsSuccess = (data) => ({
  type: constants.GET_CONDITIONS_SUCCESS,
  payload: data,
});

export const getConditionsFail = (error) => ({
  type: constants.GET_CONDITIONS_FAIL,
  error,
});
