import { Loader } from '@lideralia/alife-uikit/dist/atoms/Loader';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actualLanguage } from '../../../common/functions/lang';
import {
  getInvitationToLine,
  getLineUuidFromInvitationToLine,
  putConsumeInvitationToLine,
} from '../../../common/schemes/line';

const InvitationPage = ({ history }) => {
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getInvitationToLineAPI = useCallback((hash) => dispatch(getInvitationToLine(hash)));
  const consumeInvitationToLineAPI = useCallback((hash) =>
    dispatch(putConsumeInvitationToLine(hash))
  );
  const getLineUuidFromInvitationToLineAPI = useCallback((hash) =>
    dispatch(getLineUuidFromInvitationToLine(hash))
  );

  const { hash } = useParams();
  const {
    invitation,
    active: { company },
    error,
    redirectLine,
  } = line;

  useEffect(() => {
    if (user.done && hash) {
      getInvitationToLineAPI(hash);
    }
  }, [hash, user]);

  useEffect(() => {
    if (redirectLine) {
      history.push(
        `/${actualLanguage}/lines/${redirectLine.line_uuid}/${redirectLine.name
          .replaceAll(' ', '-')
          .toLowerCase()}/ceremony`
      );
    }
  }, [redirectLine]);

  useEffect(() => {
    if (error && hash) {
      getLineUuidFromInvitationToLineAPI(hash);
    }
  }, [hash, error]);

  useEffect(() => {
    if (invitation) {
      if (user.data.id === invitation.invitingUserId) {
        history.push(
          `/${actualLanguage}/lines/${invitation.id}/${invitation.name
            .replaceAll(' ', '-')
            .toLowerCase()}/ceremony`
        );
      } else if (invitation.isExpired === 1 || invitation.isConsumed === 1) {
        history.push(
          `/${actualLanguage}/lines/${invitation.id}/${invitation.name
            .replaceAll(' ', '-')
            .toLowerCase()}/ceremony`
        );
      } else if (
        hash &&
        typeof invitation.id !== 'undefined' &&
        typeof invitation.name !== 'undefined' &&
        invitation.isExpired === 0 &&
        invitation.isConsumed === 0
      ) {
        consumeInvitationToLineAPI(hash);
        history.push(
          `/${actualLanguage}/lines/${invitation.id}/${invitation.name
            .replaceAll(' ', '-')
            .toLowerCase()}/ceremony`
        );
      }
    }
    const intervalId = setInterval(() => {
      if (user.data === null) {
        const redirectRoute = `/i/${hash}`;
        history.push(`/${actualLanguage}/register?redirect=${redirectRoute}`);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [invitation, user]);

  return (
    <>
      <Loader customColor={company?.style?.primaryColor} />
    </>
  );
};

export default InvitationPage;

InvitationPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
