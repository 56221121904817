import { Icon } from '@lideralia/alife-uikit/dist/atoms/Icon/Icon';
import IconCamera from '@lideralia/alife-uikit/dist/atoms/Icons/IconCamera';
import IconVideo from '@lideralia/alife-uikit/dist/atoms/Icons/IconVideo';
import IconWrite from '@lideralia/alife-uikit/dist/atoms/Icons/IconWrite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actualLanguage } from '../../../common/functions/lang';
import {
  addContent,
  editContent,
  getContent,
  getLine,
  resetContent,
} from '../../../common/schemes/line';
import { showRegister } from '../../../common/schemes/login';
import { ImageContentTemplate } from '../template/imageContent';

const ImageContentPage = ({ history }) => {
  const line = useSelector((state) => state.line);
  const user = useSelector((state) => state.user);
  const isShowLogin = useSelector((state) => state.showLogin);
  const loading = useSelector((state) => state.loading);
  const {
    active: { company },
  } = line;

  const { contentActive } = line;

  const dispatch = useDispatch();

  const { idContent, idLine, idName } = useParams();

  const [data, setData] = useState({
    content_type: 'image',
    caption: '',
  });

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [haveContent, setHaveContent] = useState(false);

  const getLineAPI = useCallback((id, name) => dispatch(getLine(id, name)), [dispatch]);
  const getContentAPI = useCallback((id) => dispatch(getContent(id)), [dispatch]);
  const addContentAPI = useCallback((id, content) => dispatch(addContent(id, content)));
  const resetContentAPI = useCallback(() => dispatch(resetContent()));
  const editContentAPI = useCallback(
    (id, content) => dispatch(editContent(id, idContent, content)),
    [dispatch]
  );
  const setShowRegisterState = useCallback((value) => dispatch(showRegister(value)));

  useEffect(() => {
    if (!line.done && !line.loading && !user.done && idLine && idName) {
      getLineAPI(idLine, idName);
    }
  }, [line, user]);

  useEffect(() => {
    if (!user.done && !isShowLogin.show) {
      setShowRegisterState(1);
    }
  }, [user]);

  useEffect(() => {
    if ((data && data.file) || idContent) {
      setHaveContent(true);
    }
  }, [data]);

  useEffect(() => {
    if (line.addContentDone && haveContent) {
      history.push(
        `/${actualLanguage}/lines/${contentActive.lineId}/${contentActive.nameUrl}/family-zone#${contentActive.contentId}`
      );
    }
    if (contentActive?.picture) {
      setIsImageLoaded(true);
    }
    if (contentActive?.caption && contentActive.contentId === Number(idContent)) {
      setData((prev) => ({
        ...prev,
        caption: contentActive?.caption,
      }));
    }
  }, [line, contentActive]);

  useEffect(() => {
    if (idContent && (!line.contentActive || line.contentActive?.contentId !== idContent)) {
      getContentAPI(idContent);
    }
  }, [idContent]);

  const handleImagesLoad = (image) => {
    setIsImageLoaded(true);
    setData((prev) => ({
      ...prev,
      file: image[0]?.file,
      file64: image[0]?.base64,
    }));
  };

  const handleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      content_type: 'image',
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!loading.show) {
      if (idContent) {
        editContentAPI(line?.active?.lineId, data);
      } else {
        addContentAPI(line?.active?.lineId, data);
      }
    }
  };

  return (
    <>
      <ImageContentTemplate
        line={line?.active}
        customColor={company?.style?.primaryColor}
        customTextColor={company?.style?.textColor}
        content={contentActive.contentId === Number(idContent) ? contentActive : data}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleImagesLoad={handleImagesLoad}
        itemsTab={[
          {
            id: 'image',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconCamera />
                </Icon>
                <FormattedMessage id="common.photo" />
              </>
            ),
            isActive: true,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/add/image`);
            },
          },
          {
            id: 'video',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconVideo />
                </Icon>
                <FormattedMessage id="common.video" />
              </>
            ),
            isActive: false,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/add/video`);
            },
          },
          {
            id: 'text',
            children: (
              <>
                <Icon marginRight={1} sizeIcon="display16">
                  <IconWrite />
                </Icon>
                <FormattedMessage id="common.text" />
              </>
            ),
            isActive: false,
            action: () => {
              resetContentAPI();
              history.push(`/${actualLanguage}/lines/${idLine}/${idName}/add/text`);
            },
          },
        ]}
        haveContent={haveContent}
        isImageLoaded={isImageLoaded}
      />
    </>
  );
};

export default ImageContentPage;

ImageContentPage.propTypes = {
  history: PropTypes.shape().isRequired,
};
