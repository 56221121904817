import { all, put, takeLatest } from 'redux-saga/effects';
import { requestApi2 } from '../../../../../lib/request';
import * as action from '../actions';
import { constants, services } from '../config';
import { SettingsMapper } from '../mapper';

export function* getSettingsSaga() {
  try {
    const response = yield requestApi2(services.getSettings());
    if (response && response.status >= 200 && response.status < 300) {
      yield put(action.getSettingsSuccess(SettingsMapper.hydrate(response.data)));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(action.getSettingsFail(error));
  }
}

function* actionWatcher() {
  yield takeLatest(constants.GET_SETTINGS, getSettingsSaga);
}

export default function* defaultSaga() {
  yield all([actionWatcher()]);
}
